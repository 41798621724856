<template>
  <div
    class="min-w-[240px] rounded-lg bg-white"
    style="border: 1px solid #d8dfe7"
  >
    <image-component
      class="h-[200px] w-full rounded-t-lg object-cover"
      :src="props.image"
      alt="prize image"
    />
    <div class="flex flex-col px-4 pb-5 pt-4">
      <h1 class="mb-2 font-heading text-[20px] font-bold text-dark-grey">
        {{ props.title }}
      </h1>
      <span class="mb-3 font-heading text-sm font-normal text-neutral-300">
        {{ props.subtitle }}
      </span>
      <p class="font-sans text-base font-normal text-light-grey">
        {{ props.description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import ImageComponent from '@/components/ui/ImageComponent.vue'

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
})
</script>

<style lang="scss" scoped></style>
