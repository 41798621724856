<template>
  <component
    :is="badge.type === 'add' || example ? 'button' : 'div'"
    :class="{
      'min-w-[300px]': variant === 'default',
      'min-w-[200px]': variant === 'mini',
    }"
    class="relative disabled:opacity-50"
  >
    <div
      v-if="layout === 'default' && variant !== 'mini' && !example"
      class="absolute -top-8 flex h-10 w-full justify-center"
    >
      <v-icon
        v-if="iconComponent"
        :class="[theme?.iconsMain ? theme.iconsMain : 'text-orange-600']"
        :icon="iconComponent"
        class="z-floating h-full"
      />
    </div>

    <button
      v-if="edit && badge.type !== 'add' && !example"
      v-tooltip="'Hide badge'"
      class="absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-full bg-orange text-white"
      @click="emit('remove')"
    >
      <font-awesome-icon
        class="aspect-square"
        icon="times"
      />
    </button>

    <div
      :class="[
        theme?.backgroundDetails ? theme?.backgroundDetails : 'bg-orange-100',
        {
          'h-20 px-6 py-3': variant === 'default',
          'h-16 px-2 py-1': variant === 'mini',
        },
      ]"
      class="flex items-center gap-2 rounded-lg"
    >
      <v-icon
        v-if="layout === 'icon-left' && iconComponent"
        :icon="iconComponent"
        class="h-6 w-6"
      />
      <strong
        v-else
        :class="[
          theme?.textDetails ? theme?.textDetails : 'text-orange-600',
          {
            'text-[40px]': variant === 'default',
            'text-[32px]': variant === 'mini',
          },
        ]"
        class="font-heading font-bold"
      >
        {{ value }}
      </strong>
      <div class="flex flex-col items-start">
        <span
          :class="[
            theme?.textDetailsDark ? theme?.textDetailsDark : 'text-orange-950',
            {
              'text-base': variant === 'default',
              'text-sm': variant === 'mini',
            },
          ]"
          class="font-heading font-bold"
        >
          {{ heading }}
        </span>
        <span
          v-if="subheading"
          :class="[
            theme?.textDetailsDark ? theme?.textDetailsDark : 'text-orange-950',
          ]"
          class="font-heading text-xs font-normal"
        >
          {{ subheading }}
        </span>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import DiamantIcon from '@/icons/DiamantIcon.vue'
import useConventionList from '@/utils/composables/conventionList'
import { computed, inject, ref } from 'vue'
import CalendarIcon from '@/icons/CalendarIcon.vue'
import VIcon from '@/components/ui/VIcon.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emit = defineEmits(['remove'])

const props = defineProps({
  badge: {
    type: Object,
    required: true,
  },
  theme: {
    type: Object,
    required: true,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
  example: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
    validator(value: string): boolean {
      return ['default', 'mini'].includes(value)
    },
  },
})

const { selected: selectedEvent } = useConventionList()
const edit = inject('showcase-edit-mode')

const layout = computed(() => {
  switch (props.badge.type) {
    case 'add':
    case 'coming-soon':
      return 'icon-left'
    default:
      return 'default'
  }
})

const exampleValue = ref(Math.floor(Math.random() * 100))
const value = computed(() => {
  switch (props.badge.type) {
    case 'add':
      return 'Add'
    default:
      if (props.example || props.badge.value === undefined) {
        return exampleValue.value
      }
      return props.badge.value
  }
})

const heading = computed(() => {
  switch (props.badge.type) {
    case 'exclusives':
      return 'Exclusives'
    case 'activities':
      return 'Activities'
    case 'add':
      return 'Add badge'
    case 'events':
      return 'Events'
    case 'offers':
      return 'Offers'
    case 'news':
      return 'News'
    case 'coming-soon':
      return 'More badges coming soon!'
    case 'yelp_rating':
      return 'Yelp'
    case 'ot_rating':
      return 'Open Table'
    default:
      return 'Undefined type: ' + props.badge.type
  }
})

const subheading = computed<string | undefined>(() => {
  switch (props.badge.type) {
    case 'exclusives':
    case 'activities':
      return 'at ' + selectedEvent.value?.name
    case 'yelp_rating':
    case 'ot_rating':
      return `(${props.badge.reviews ?? 123}) reviews`
    case 'add':
      return 'to your showcase'
  }
  return undefined
})

const iconComponent = computed<string | object | undefined>(() => {
  switch (props.badge.type) {
    case 'exclusives':
      return DiamantIcon
    case 'activities':
      return CalendarIcon
    case 'add':
      return 'plus'
    case 'events':
      return 'people-group'
    case 'offers':
      return 'gift'
    case 'news':
      return 'newspaper'
    case 'coming-soon':
      return 'clock'
  }
  return undefined
})
</script>

<style scoped></style>
