<template>
  <standard-layout
    is-home
    variant="dark"
  >
    <div class="relative flex w-full flex-col bg-text-white text-black">
      <section
        class="relative max-h-[900px] min-h-[400px] w-full bg-white"
        style="height: 80vh"
      >
        <div
          class="absolute bottom-0 top-0 z-[19] my-auto flex w-full flex-col items-center justify-center text-center"
        >
          <div
            class="flex w-full flex-col justify-items-center gap-4 font-bold text-white"
          >
            <h1 class="normal text-2xl leading-[140%] md:text-3xl lg:text-4xl">
              Maximize your fan experience
              <br />
              at
              {{ conventionLines[0] }}
            </h1>

            <p
              class="text-center text-base font-normal tracking-[0.36px] text-white lg:text-lg"
            >
              Are you a comic convention organizer?
              <br />
              Get a demo of our fan-built comic convention management platform.
            </p>
          </div>

          <v-button
            :href="`/events/${featuredConvention?.slug}`"
            class="mt-10"
            size="lg"
            variant="primary"
          >
            Get started
          </v-button>
        </div>

        <div
          class="absolute inset-0 z-10 h-full w-full"
          style="
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.15) 0%,
              rgba(0, 0, 0, 0.15) 100%
            );
          "
        />
        <div
          class="absolute inset-0 z-0 w-full bg-cover bg-no-repeat brightness-[60%]"
          style="background-image: url('/images/backgrounds/hero-bg.png')"
        />
      </section>
      <section
        class="relative flex w-full flex-col items-center px-2 py-20 sm:px-6 sm:py-20 lg:px-5 xl:px-[100px]"
        style="
          background: linear-gradient(
              180deg,
              rgba(210, 218, 255, 0.69) 0%,
              #fff 75%
            ),
            #fff;
        "
      >
        <div
          class="flex h-fit w-full max-w-[1300px] flex-col gap-10 overflow-hidden rounded-3xl bg-white py-8 shadow-lg xl:p-10"
        >
          <section
            v-if="featuredConvention?.featured?.length"
            class="flex w-full flex-col items-center gap-3 px-2 leading-[140%] sm:px-6 md:gap-8"
          >
            <div
              class="text-center font-sourcePro text-xl font-bold text-slate-700"
            >
              <span>Highlighted Features at</span>
              <br />
              <h2 class="mt-2 text-4xl text-dark-menu">
                {{ featuredConvention.name }}
              </h2>
            </div>
            <conventions-section :event="featuredConvention" />
          </section>
          <hr
            v-if="featuredConvention?.featured?.length"
            class="mx-auto w-[95%] bg-slate-100"
          />
          <convention-list :palette="featuredConvention?.palette" />
        </div>
      </section>

      <experience-section />

      <news-section v-if="false" />

      <app-advantages-section />

      <f-a-q-section />

      <section
        class="no-repeat relative mx-auto h-fit w-full items-center gap-y-16 px-4 pb-14 pt-7 sm:py-[112px] lg:px-20 xl:px-[100px]"
      >
        <div class="relative z-10 mx-auto flex flex-col items-center gap-10">
          <p
            class="w-full max-w-[80%] text-center text-lg font-bold text-text-white xl:text-2xl 2xl:max-w-[1200px]"
          >
            Your insights pave the way for Fankind's evolution! Share your
            thoughts and ideas with us, and help shape the future of your
            ultimate comic convention experience. Join our quest to innovate –
            <strong class="text-accent-orange">every voice matters!</strong>
          </p>
          <v-button
            class="font-bold"
            href="https://forms.gle/qh7MyzZK89veLe2o8"
            size="lg"
          >
            Leave a Feedback
          </v-button>
        </div>
        <div
          class="absolute inset-0 bg-[url('/images/backgrounds/home/anime-figures.png')] bg-cover bg-top bg-no-repeat brightness-[30%]"
        />
      </section>

      <!-- <section
        class="w-full px-2 py-8 sm:px-4 md:py-10 lg:px-[100px]"
        style="
          background: linear-gradient(0deg, #0f172a 0%, #0f172a 100%), #1b1b21;
        "
      >
        <div
          class="mx-auto flex w-full w-full max-w-[1300px] flex-col items-center justify-between gap-4 md:flex-row"
        >
          <fankind-logo class="mx-auto h-5 shrink-0 text-slate-100 md:m-0" />

          <div
            class="flex hidden select-none gap-6 text-light-light-grey md:flex"
          >
            <button
              v-for="(event, index) in upcoming"
              id="upcomingEvents"
              :key="index"
              aria-label="Upcoming events"
              @click="selectEvent(event)"
            >
              {{ event.name }}
            </button>
          </div>
          <hr class="background-text-white mt-3 w-full md:hidden" />
          <div class="flex items-center gap-[30px]">
            <a
              aria-label="Fankind Instagram"
              href="https://www.instagram.com/wearefankind/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <instagram-icon class="h-6 w-6 text-text-white" />
            </a>
            <a
              aria-label="Fankind Twitter/X"
              href="https://twitter.com/wearefankind"
              rel="noopener noreferrer"
              target="_blank"
            >
              <twitter-icon class="h-6 w-6 text-text-white" />
            </a>
            <a
              aria-label="Fankind Facebook"
              href="https://www.facebook.com/wearefankind"
              rel="noopener noreferrer"
              target="_blank"
            >
              <facebook-icon class="h-6 w-6 text-text-white" />
            </a>
          </div>
        </div>
      </section> -->
    </div>
  </standard-layout>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { router } from '@inertiajs/vue3'
import useConventionList from '@/utils/composables/conventionList'
import TwitterIcon from '@/icons/TwitterIcon.vue'
import FacebookIcon from '@/icons/FacebookIcon.vue'
import InstagramIcon from '@/icons/InstagramIcon.vue'

import ConventionsSection from '@/components/ui/ConventionsSection.vue'
import ExperienceSection from './Home/ExperienceSection.vue'
import NewsSection from './Home/NewsSection.vue'
import AppAdvantagesSection from './Home/AppAdvantagesSection.vue'
import FAQSection from './Home/FAQSection.vue'

import StandardLayout from '../../components/layout/StandardLayout.vue'
import FankindLogo from '../../icons/FankindLogo.vue'
import ConventionList from '@/components/ui/ConventionList.vue'
import VButton from '@/components/buttons/VButton.vue'

const props = defineProps({
  raffles: {
    type: Array,
    required: true,
  },
})

const {
  upcoming,
  featured: featuredConvention,
  onLoaded,
  fetch,
} = useConventionList()

const selectEvent = (item) => {
  router.visit(`/events/${item.slug}`)
}

const conventionLines = computed(() => {
  let lines = []
  if (!featuredConvention.value?.name) return lines
  let split = featuredConvention.value.name?.split(' ')
  if (!split) return lines
  if (split.length === 4) {
    lines.push(split[0] + ' ' + split[1])
    lines.push(split[2] + ' ' + split[3])
  } else if (split.length === 3) {
    lines.push(split[0] + ' ' + split[1])
    lines.push(split[2])
  } else if (split.length === 2) {
    lines.push(split[0])
    lines.push(split[1])
  } else {
    lines.push(featuredConvention.value.name)
  }
  return lines
})

onMounted(() => {
  onLoaded().then(() => {
    fetch(featuredConvention.value.id)
  })
})
</script>

<style scoped>
.bg-dark-blue-gradient {
  background-image: linear-gradient(180deg, #1f1bdb 40%, #12192c 100%);
}

@media screen and (max-width: 1300px) {
  .bgImages {
    background-image: none !important;
  }

  .fingertipsBG {
    background-image: linear-gradient(
        93deg,
        rgba(89, 28, 219, 0.8) 0%,
        rgba(32, 28, 219, 0.8) 66.94%,
        rgba(16, 13, 168, 0.8) 100%
      ),
      url('/images/backgrounds/home/fingertips_bg.png') !important;
  }
}

.bgImages {
  background-size: 450px, 250px;
  background-image: url('/images/backgrounds/home/commics-bang-1.png'),
    url('/images/backgrounds/home/commics-bang-2.png');
  background-position:
    20px 60px,
    97% bottom;
  background-repeat: no-repeat, no-repeat;
}

.fingertipsBG {
  background-image: linear-gradient(
      180deg,
      rgba(31, 27, 219, 0.9) 21.88%,
      rgba(16, 13, 168, 0.9) 100%
    ),
    url('/images/backgrounds/home/fingertips_bg.png');

  background-color: lightgray;

  background-position: center;

  background-size: cover;

  background-repeat: no-repeat;
}
</style>
