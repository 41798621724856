<template>
  <event-browse-layout>
    <template #section-title>
      <div
        class="relative flex shrink-0 flex-col items-start justify-start gap-3 self-stretch"
      >
        <div class="px-3 text-2xl font-bold text-text-dark-100">
          The Hunt is On!
        </div>

        <div
          class="relative flex shrink-0 flex-row items-start justify-start gap-2.5 self-stretch overflow-x-auto px-3"
        >
          <!--                    <Link-->
          <!--                        :href="'/event/' + event.slug + '/game/scavenger-hunt/sessions'"-->
          <!--                        class="rounded-full aspect-square border-2 border-solid border-blue p-0.5 flex w-24 items-center justify-center shrink-0 relative"-->
          <!--                    >-->
          <!--                        <div-->
          <!--                            class="rounded-full purple-gradient flex flex-col gap-0 items-center justify-center shrink-0 aspect-square w-full relative border-2 border-solid border-blue"-->
          <!--                        >-->
          <!--                            <div class="text-white text-base font-semibold">-->
          <!--                                Start-->
          <!--                            </div>-->
          <!--                            <div class="text-white text-base -mt-1.5">-->
          <!--                                a game-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </Link>-->

          <template v-if="userGameSessions.length > 0">
            <!--                        TODO: Only load other users' latest images-->
            <game-session-item
              v-for="gameSession in gameSessionsSorted"
              :key="gameSession.id"
              :game-session="gameSession"
              class="cursor-pointer"
              @click="handleClickGameSession(gameSession)"
            />
          </template>
          <template v-else>
            Create games for your friends, or join a game below to meet new
            friends.
          </template>
        </div>
      </div>
    </template>
    <template #browse-page>
      <debug-bar>
        <pre>{{ userGameSessions }}</pre>
      </debug-bar>
      <event-game-sessions
        v-if="sessionsLoaded && selectedEventId"
        :convention-event="event"
        :event-activities-with-game-sessions="eventActivitiesWithGameSessions"
        :game-sessions-by-date="groupedEventGameSessions"
        @join-game-session="joinGameSession"
        @leave-game-session="leaveGameSession"
      />
      <div v-else-if="sessionsLoaded && !selectedEventId">
        <!-- section that says that there are no games yet -->
        <div
          class="mt-10 flex flex-col items-center justify-center gap-1 text-center"
        >
          <div class="text-2xl font-bold text-slate-600">No games yet!</div>
          <div class="text-lg text-slate-500">
            Create games for your friends, or join a game below to meet new
            friends.
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex grow items-center justify-center"
      >
        <font-awesome-icon
          class="h-6 w-6 animate-spin"
          icon="fa-circle-notch"
        />
      </div>
      <!--            <div class="flex flex-col bg-white w-full relative overflow-hidden z-0">-->
      <!--                <div-->
      <!--                    class="bg-white py-6 flex flex-col gap-7 items-start justify-start w-full"-->
      <!--                >-->

      <!--                    <div-->
      <!--                        class="flex flex-col gap-3 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                    >-->
      <!--                        <div-->
      <!--                            class="text-text-dark-100 px-3 text-2xl font-bold"-->
      <!--                        >-->
      <!--                            Dishes & Drinks near you-->
      <!--                        </div>-->

      <!--                        <div-->
      <!--                            class="flex flex-row gap-4 items-center justify-start shrink-0 w-full relative overflow-x-auto px-3"-->
      <!--                        >-->
      <!--                            <item-card-->
      <!--                                v-for="menuItem in menuItems"-->
      <!--                                class="relative flex flex-col w-32 flex-shrink-0"-->
      <!--                                :key="menuItem.id"-->
      <!--                                :item="menuItem"-->
      <!--                                event-slug="conInstance.slug"-->
      <!--                            />-->
      <!--                        </div>-->
      <!--                    </div>-->

      <!--                    <div-->
      <!--                        class="flex flex-col gap-3 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                    >-->
      <!--                        <div-->
      <!--                            class="text-[#000000] text-left relative"-->
      <!--                            style="font: 700 24px 'Roboto', sans-serif"-->
      <!--                        >-->
      <!--                            Activities Near You-->
      <!--                        </div>-->

      <!--                        <div-->
      <!--                            class="pt-0 pr-0 pb-4 pl-0 flex flex-col gap-3 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                        >-->
      <!--                            <div-->
      <!--                                class="flex flex-row gap-10 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                style="flex-wrap: wrap"-->
      <!--                            >-->
      <!--                                <div-->
      <!--                                    class="bg-[#feebef] rounded-lg pt-4 pr-3 pb-4 pl-3 flex flex-col gap-2.5 items-start justify-start flex-1 relative"-->
      <!--                                >-->
      <!--                                    <div-->
      <!--                                        class="flex flex-col gap-5 items-center justify-start self-stretch shrink-0 relative"-->
      <!--                                    >-->
      <!--                                        <div-->
      <!--                                            class="flex flex-col gap-[13px] items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                        >-->
      <!--                                            <div-->
      <!--                                                class="flex flex-col gap-1.5 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                            >-->
      <!--                                                <div-->
      <!--                                                    class="flex flex-row gap-1 items-center justify-start shrink-0 relative"-->
      <!--                                                >-->
      <!--                                                    <div-->
      <!--                                                        class="text-[#f43f5e] text-left relative"-->
      <!--                                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                    >-->
      <!--                                                        Meetup-->
      <!--                                                    </div>-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative self-stretch"-->
      <!--                                                    style="font: 700 16px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    Star Wars Cosplay Connect-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative"-->
      <!--                                                    style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    9:00a - 9:30a • Pavilion Steps-->
      <!--                                                </div>-->
      <!--                                            </div>-->
      <!--                                        </div>-->
      <!--                                    </div>-->

      <!--                                    <div-->
      <!--                                        class="text-text-paragraph-300 text-left relative self-stretch"-->
      <!--                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                    >-->
      <!--                                        This is the description for the panel, and it mentions things-->
      <!--                                        about what...-->
      <!--                                    </div>-->
      <!--                                </div>-->
      <!--                            </div>-->

      <!--                            <div-->
      <!--                                class="flex flex-row gap-10 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                style="flex-wrap: wrap"-->
      <!--                            >-->
      <!--                                <div-->
      <!--                                    class="bg-[#feebef] rounded-lg pt-4 pr-3 pb-4 pl-3 flex flex-col gap-2.5 items-start justify-start flex-1 relative"-->
      <!--                                >-->
      <!--                                    <div-->
      <!--                                        class="flex flex-col gap-5 items-center justify-start self-stretch shrink-0 relative"-->
      <!--                                    >-->
      <!--                                        <div-->
      <!--                                            class="flex flex-col gap-[13px] items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                        >-->
      <!--                                            <div-->
      <!--                                                class="flex flex-col gap-1.5 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                            >-->
      <!--                                                <div-->
      <!--                                                    class="flex flex-row gap-1 items-center justify-start shrink-0 relative"-->
      <!--                                                >-->
      <!--                                                    <div-->
      <!--                                                        class="text-[#f43f5e] text-left relative"-->
      <!--                                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                    >-->
      <!--                                                        Meetup-->
      <!--                                                    </div>-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative self-stretch"-->
      <!--                                                    style="font: 700 16px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    Star Wars Cosplay Connect-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative"-->
      <!--                                                    style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    9:00a - 9:30a • Pavilion Steps-->
      <!--                                                </div>-->
      <!--                                            </div>-->
      <!--                                        </div>-->
      <!--                                    </div>-->

      <!--                                    <div-->
      <!--                                        class="text-text-paragraph-300 text-left relative self-stretch"-->
      <!--                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                    >-->
      <!--                                        This is the description for the panel, and it mentions things-->
      <!--                                        about what...-->
      <!--                                    </div>-->
      <!--                                </div>-->
      <!--                            </div>-->

      <!--                            <div-->
      <!--                                class="flex flex-row gap-10 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                style="flex-wrap: wrap"-->
      <!--                            >-->
      <!--                                <div-->
      <!--                                    class="bg-[#feebef] rounded-lg pt-4 pr-3 pb-4 pl-3 flex flex-col gap-2.5 items-start justify-start flex-1 relative"-->
      <!--                                >-->
      <!--                                    <div-->
      <!--                                        class="flex flex-col gap-5 items-center justify-start self-stretch shrink-0 relative"-->
      <!--                                    >-->
      <!--                                        <div-->
      <!--                                            class="flex flex-col gap-[13px] items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                        >-->
      <!--                                            <div-->
      <!--                                                class="flex flex-col gap-1.5 items-start justify-start self-stretch shrink-0 relative"-->
      <!--                                            >-->
      <!--                                                <div-->
      <!--                                                    class="flex flex-row gap-1 items-center justify-start shrink-0 relative"-->
      <!--                                                >-->
      <!--                                                    <div-->
      <!--                                                        class="text-[#f43f5e] text-left relative"-->
      <!--                                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                    >-->
      <!--                                                        Meetup-->
      <!--                                                    </div>-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative self-stretch"-->
      <!--                                                    style="font: 700 16px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    Star Wars Cosplay Connect-->
      <!--                                                </div>-->

      <!--                                                <div-->
      <!--                                                    class="text-text-dark-100 text-left relative"-->
      <!--                                                    style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                                >-->
      <!--                                                    9:00a - 9:30a • Pavilion Steps-->
      <!--                                                </div>-->
      <!--                                            </div>-->
      <!--                                        </div>-->
      <!--                                    </div>-->

      <!--                                    <div-->
      <!--                                        class="text-text-paragraph-300 text-left relative self-stretch"-->
      <!--                                        style="font: 400 14px 'Helvetica', sans-serif"-->
      <!--                                    >-->
      <!--                                        This is the description for the panel, and it mentions things-->
      <!--                                        about what...-->
      <!--                                    </div>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
    </template>
  </event-browse-layout>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { router } from '@inertiajs/vue3'
import GameSessionItem from './Game/GameSessionItem.vue'
import EventBrowseLayout from '../../components/layout/EventBrowseLayout.vue'
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import EventGameSessions from './Game/EventGameSessions.vue'
import DebugBar from '@/components/ui/DebugBar.vue'
import { DateTime } from 'luxon'
defineOptions({
  name: 'EventHome',
})




















































const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  conInstance: {
    type: Object,
    required: true,
  },
  gameSessions: {
    type: Array,
    required: true,
  },
  menuItems: {
    type: Array,
    required: true,
  },
})

const groupedEventGameSessions = ref([])

const userGameSessions = ref([])

const handleClickGameSession = (gameSession) => {
  router.visit('/game-session/' + gameSession.slug)
}

const joinGameSession = (gameSession) => {
  userGameSessions.value.push(gameSession)
}

const leaveGameSession = (gameSession) => {
  const index = userGameSessions.value.findIndex(
    (gs) => gs.id === gameSession.id
  )
  if (index !== -1) {
    userGameSessions.value.splice(index, 1)
  }
}

const sessionsLoaded = ref(false)
const eventActivitiesWithGameSessions = ref([])
const selectedEventId = ref(null)

const gameSessionsSorted = computed(() => {
  const now = DateTime.now()
  const getStatus = (gameSession) => {
    const starts = DateTime.fromISO(gameSession.schedule[0].starts_at)
    const ends = DateTime.fromISO(gameSession.schedule[0].ends_at)
    if (now < starts) {
      return 'upcoming'
    } else if (now > ends) {
      return 'past'
    } else {
      return 'active'
    }
  }
  return userGameSessions.value.slice().sort((a, b) => {
    // show active first, then upcoming, then past. Within each group, sort by start time
    const aStatus = getStatus(a)
    const bStatus = getStatus(b)
    if (aStatus === bStatus) {
      const aStarts = DateTime.fromISO(a.schedule[0].starts_at)
      const bStarts = DateTime.fromISO(b.schedule[0].starts_at)
      return aStarts - bStarts
    } else if (aStatus === 'active') {
      return -1
    } else if (bStatus === 'active') {
      return 1
    } else if (aStatus === 'upcoming') {
      return -1
    } else if (bStatus === 'upcoming') {
      return 1
    } else if (aStatus === 'past') {
      return -1
    } else if (bStatus === 'past') {
      return 1
    }
  })
})

onMounted(() => {
  userGameSessions.value = props.gameSessions
  axios
    .get('/api/event/' + props.event.slug + '/game-sessions')
    .then((response) => {
      sessionsLoaded.value = true
      console.log(response.data)
      groupedEventGameSessions.value = response.data.gameSessionsByDate
      eventActivitiesWithGameSessions.value =
        response.data.gameSessionsByEventActivity
      selectedEventId.value =
        eventActivitiesWithGameSessions.value[0]?.id ?? null
    })
    .catch((error) => {
      console.log(error)
    })
})
</script>


<style scoped>
.purple-gradient {
  background: linear-gradient(
    93.2deg,
    rgba(89, 28, 219, 1) 0%,
    rgba(32, 28, 219, 1) 82.29166865348816%,
    rgba(16, 13, 168, 1) 100%
  );
}

body {
  /* Colors */
  --primary-blue: #1613bd;
  --muted-blue: #302e85;
  --neon-blue: #797fff;
  --gray-500: linear-gradient(to left, #9aa4b6, #9aa4b6),
    linear-gradient(to left, #878d97, #878d97);
  --yellow: #fbc400;
  --spaceblue: #31305d;
  --gray-400: #5f6775;
  --gray-200: #282831;
  --red: #d82525;
  --gradient-light-blue-gray: linear-gradient(
    180deg,
    rgba(241, 241, 254, 1) 21.875%,
    rgba(249, 249, 255, 1) 100%
  );
  --gradient-violetto-blue: linear-gradient(
    93.2deg,
    rgba(89, 28, 219, 1) 0%,
    rgba(32, 28, 219, 1) 82.29166865348816%,
    rgba(16, 13, 168, 1) 100%
  );
  --gradient-light-blue-gray-200: linear-gradient(
    180deg,
    rgba(218, 217, 255, 1) 3.125%,
    rgba(236, 236, 246, 1) 100%
  );
  --gradient-dark-blue: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(15, 14, 62, 1) 31.25%,
    rgba(24, 23, 97, 1) 55.72916865348816%,
    rgba(14, 6, 68, 1) 74.47916865348816%,
    rgba(0, 0, 0, 1) 98.4375%
  );
  --gradient-primary-blue: linear-gradient(
    180deg,
    rgba(31, 27, 219, 1) 21.875%,
    rgba(16, 13, 168, 1) 100%
  );
  --text-dark-100: #1b1b21;
  --text-paragraph-300: #47474f;
  --text-white: #ffffff;
  --text-light-600: #bfc6cd;
  --bg-gray-800: linear-gradient(to left, #eaedf5, #eaedf5),
    linear-gradient(to left, #eaeaf5, #eaeaf5);
  --bg-white: #ffffff;
  --bg-gray-900: #f4f6fa;
  --bg-dark-blue: #050425;
  --bg-dark-menu: #1f1e36;
  --border-gray-700: linear-gradient(to left, #dadee7, #dadee7),
    linear-gradient(to left, #dadae7, #dadae7),
    linear-gradient(to left, #ececf4, #ececf4);
}
</style>
