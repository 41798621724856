<template>
  <div v-if="edit" class="relative inline">
    <transition
      v-if="label"
      enter-active-class="transition duration-100 ease-in-out"
      enter-from-class="scale-0 translate-y-[4px] opacity-0"
      enter-to-class="scale-100"
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="scale-100"
      leave-to-class="scale-0 translate-y-[4px] opacity-0">
      <div
        v-if="focused"
        class="absolute -left-[5px] bottom-[calc(100%+5px)] origin-bottom-left bg-blue-neon px-1 py-0.5 text-start font-heading text-sm font-normal capitalize text-white">
        {{ label }}
      </div>
    </transition>
    <component
      :is="tag"
      v-memo="[tag, classes, edit, focused, empty]"
      :class="classes"
      :contenteditable="edit"
      @focusin="focused = true"
      @focusout="focused = false"
      @input="onInput">
      <slot>
        {{ props.modelValue }}
      </slot>
    </component>
  </div>
  <component :is="tag" v-else :class="classes">
    <slot>
      {{ props.modelValue }}
    </slot>
  </component>
</template>

<script lang="ts" setup>
/**
 * Showcase Text Element
 * displays a text element in the showcase with editable content when in edit mode
 * @file ShowcaseTextElement.vue
 */
import { computed, inject, ref, Ref } from 'vue'
const emit = defineEmits(['update:modelValue'])




















































defineOptions({
  name: 'ShowcaseTextElement',
})




















































const props = defineProps({
  tag: {
    type: String,
    default: 'p',
  },
  class: {
    type: [String, Array<string>],
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Empty',
  },
  label: {
    type: String,
    default: '',
  },
})

const edit = inject('showcase-edit-mode') as Ref<boolean>
const focused = ref(false)
const empty = computed(() => {
  return props.modelValue === ''
})

const classes = computed(() => {
  return [
    props.class,
    edit.value
      ? 'outline outline-2 outline-blue-primary outline-offset-1 hover:outline-blue-neon focus:outline-blue-neon focus:outline-4 drop-shadow-2xl duration-100'
      : '',
  ]
})

function onInput(event: InputEvent) {
  emit('update:modelValue', (event.target as HTMLInputElement).innerText)
}
</script>

<style scoped></style>
