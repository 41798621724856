<template>
  <div>
    <font-awesome-icon
      :icon="getFavoriteIcon"
      :class="getFavoriteColor"
    />
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
defineOptions({
    name: "FavoriteButton",
})


















































const props = defineProps({
    isFavorite: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const getFavoriteIcon = computed(() => {
    if (props.isFavorite === true) {
        return ['fas', 'heart'];
    } else {
        return ['far', 'heart'];
    }
});

const getFavoriteColor = computed(() => {
    if (props.isFavorite === true) {
        return 'text-blue';
    } else {
        return 'text-slate-400';
    }
});
</script>


<style scoped>

</style>
