<template>
  <standard-layout>
    <responsive-layout class="bg-white">
      <div class="relative">
        <background-image-component
          :fallback-src="null"
          :image="userGroup?.banner"
          class="h-[212px] w-full"
          fallback-class="bg-slate-200"
        >
          <form-drop-file-input
            v-if="userGroup?.canEdit && !userGroup.banner"
            :error="form.errors.banner"
            class="h-[212px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
            icon-class="text-slate-400"
            image-type
            @update:model-value="
              userGroup
                .update({ banner: $event })
                .form({
                  only: ['banner'],
                })
                .patch(`/api/groups/${userGroup.id}`)
            "
          />
          <!--            @update:model-value="userGroup.banner = $event;axios.patch(`/api/groups/${userGroup.id}`, { banner: $event })"-->
        </background-image-component>

        <!--        <button-->
        <!--          v-if="userGroup?.canEdit && userGroup.banner"-->
        <!--          class="absolute right-6 top-[162px] flex items-center gap-2 px-10 py-4 text-slate-400"-->
        <!--          @click="modalHandler.openType('group-edit-dialog')"-->
        <!--        >-->
        <!--          <edit-outline-icon />-->
        <!--          Edit picture-->
        <!--        </button>-->

        <div
          class="absolute left-[20px] top-[105px] h-[127px] w-[127px] shrink-0 rounded-lg border border-text-white"
        >
          <image-component
            v-if="userGroup?.avatar?.path"
            :fallback-src="null"
            :image="userGroup?.avatar"
            class="h-[127px] w-full rounded-lg"
            fallback-class="bg-slate-200 object-cover"
          />
          <form-drop-file-input
            v-else-if="editFlag"
            v-model="form.avatar"
            :error="form.errors.avatar"
            class="h-[127px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
            hide-hint
            icon-class="text-slate-400"
            image-type
          />
        </div>
      </div>
      <section
        class="flex w-full flex-col gap-8 bg-slate-100 px-0 pt-8 sm:px-8"
      >
        <div
          class="flex w-full flex-col items-start justify-between gap-4 px-4 sm:flex-row sm:items-center sm:px-0"
        >
          <div class="flex grow flex-col gap-2">
            <h2
              :class="{
                'skeleton w-40 rounded-xl': !userGroup,
              }"
              class="font-sans text-3xl font-bold text-very-dark-blue"
            >
              {{ userGroup?.name }}
            </h2>
            <time
              :class="{
                'skeleton w-1/2 rounded-xl': !userGroup,
              }"
              class="font-sans text-base font-bold text-slate-400"
            >
              {{
                [
                  userGroup?.founded_at
                    ? `Created on ${DateTime.fromISO(userGroup.founded_at).toLocaleString(DateTime.DATE_FULL)}`
                    : null,
                  userGroup?.members_count
                    ? `${userGroup.members_count} ${pluralize(userGroup.members_count, 'Member')}`
                    : null,
                ]
                  .filter(Boolean)
                  .join(' · ')
              }}
            </time>
          </div>

          <div class="flex w-fit items-center gap-4">
            <v-button
              v-if="userGroup?.canEdit"
              class="flex items-center gap-2"
              size="md"
              variant="primary"
              @click="
                modalHandler.openType('group-edit-dialog', {
                  props: {
                    form: userGroup.form({
                      only: ['id', 'name', 'description', 'avatar', 'banner', 'about_image'],
                    }),
                  },
                  events: {
                    onSave: (_, close, modalProps) => {
                      close()
                      modalProps.form
                        .patch(`/api/groups/${modalProps.form.id}`)
                        .then(() => {
                          queryClient.invalidateQueries({
                            queryKey: ['groups', modalProps.form.id],
                          })
                        })
                    },
                  },
                })
              "
            >
              <edit-outline-icon />
              Edit bio
            </v-button>
            <v-button
              v-tooltip="{
                theme: 'light',
                disabled: userGroup?.role !== 'owner',
                content:
                  'You can\'t leave your own group. Transfer ownership to someone else first or delete the group.',
              }"
              :disabled="!userGroup || userGroup.role === 'owner'"
              :variant="
                userGroup?.is_member || userGroup?.request_sent
                  ? 'secondary'
                  : 'primary'
              "
              size="md"
              @click="
                userGroup?.is_member || userGroup?.request_sent
                  ? leaveGroup()
                  : joinGroup()
              "
            >
              {{
                userGroup?.is_member
                  ? 'Leave'
                  : userGroup?.request_sent
                    ? 'Request Sent'
                    : userGroup?.privacy === 'public'
                      ? 'Join'
                      : 'Request'
              }}
            </v-button>
            <v-button
              v-if="featureFlags.GroupNotifications"
              :disabled="!userGroup"
              no-padding
              variant="secondary"
              without-border
            >
              <svg
                class="h-6 w-6 shrink-0"
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0008 19C15.001 19.5046 14.8104 19.9906 14.4673 20.3605C14.1242 20.7305 13.654 20.9572 13.1508 20.995L13.0008 21H11.0008C10.4962 21.0002 10.0103 20.8096 9.64028 20.4665C9.2703 20.1234 9.04367 19.6532 9.00583 19.15L9.00082 19H15.0008ZM12.0008 2C13.8158 1.99997 15.5598 2.70489 16.8651 3.96607C18.1703 5.22726 18.9346 6.94609 18.9968 8.76L19.0008 9V12.764L20.8228 16.408C20.9023 16.567 20.9421 16.7429 20.9388 16.9206C20.9355 17.0984 20.8891 17.2727 20.8037 17.4286C20.7183 17.5845 20.5963 17.7174 20.4483 17.8158C20.3003 17.9143 20.1306 17.9754 19.9538 17.994L19.8388 18H4.16283C3.98499 18.0001 3.80979 17.957 3.65225 17.8745C3.4947 17.792 3.3595 17.6725 3.25824 17.5264C3.15698 17.3802 3.09267 17.2116 3.07083 17.0351C3.04899 16.8586 3.07026 16.6795 3.13283 16.513L3.17883 16.408L5.00083 12.764V9C5.00083 7.14348 5.73832 5.36301 7.05108 4.05025C8.36383 2.7375 10.1443 2 12.0008 2Z"
                  fill="currentColor"
                />
              </svg>
            </v-button>
          </div>
        </div>
      </section>
      <nav
        class="sticky top-16 z-floating+5 border-b border-slate-400 bg-slate-100"
      >
        <div class="mx-auto flex w-fit max-w-full items-center overflow-scroll">
          <button
            v-for="tab in tabs"
            :key="tab.value"
            :class="[
              selectedTab == tab.value
                ? 'border-blue-primary text-blue-primary'
                : 'border-transparent text-slate-500',
            ]"
            class="mx-6 shrink-0 select-none border-b py-4 font-sans text-lg font-normal"
            @click="selectedTab = tab.value"
          >
            {{ tab.title }}
          </button>
        </div>
      </nav>
      <slot :name="selectedTab" />
    </responsive-layout>
  </standard-layout>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'

import axios from 'axios'
import { DateTime } from 'luxon'
import { useModal } from '@/utils/modal'
import { useForm } from '@inertiajs/vue3'
import { pluralize } from '@/utils/string'

import UserGroup from '@/models/UserGroup'
import StandardLayout from '@/components/layout/StandardLayout.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'

import EditOutlineIcon from '@/icons/EditOutlineIcon.vue'
import VButton from '@/components/buttons/VButton.vue'
import ResponsiveLayout from '@/components/layout/ResponsiveLayout.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import DialogGroupRulesView from '@/components/view/DialogGroupRulesView.vue'
import featureFlags from '@/utils/composables/flags'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps({
  userGroup: {
    type: Object as PropType<UserGroup>,
    default: () => undefined,
  },
})

const modalHandler = useModal()
const queryClient = useQueryClient()

const editFlag = true
const form = useForm({
  banner: null,
  avatar: null,
})
const t = [
  { title: 'Home', value: 'home' },
  { title: 'Discussions', value: 'discussions' },
  { title: 'Events', value: 'events' },
  { title: 'Members', value: 'members' },
  { title: 'Gallery', value: 'gallery' },
  { title: 'Rules', value: 'rules' },
]
const tabs = computed(() => {
  if (props.userGroup?.role === 'owner') {
    return t.concat({ title: 'Settings', value: 'settings' })
  }
  return t
})

const selectedTab = ref(tabs.value[0].value)

function invalidate() {
  queryClient.invalidateQueries({
    queryKey: ['group-members', props.userGroup?.id],
  })
}

function sendJoinRequest() {
  axios.post(`/api/groups/${props.userGroup?.id}/join`)
    .then(()=>{
      invalidate()
    })
}

function joinGroup() {
  if (props.userGroup?.rules.length === 0) sendJoinRequest()
  modalHandler.open(DialogGroupRulesView, {
    props: {
      position: 'center-center',
      size: 'md',
      rules: props.userGroup?.rules ?? [],
    },
    events: {
      onConfirm: (payload, close) => {
        close()
        if (!props.userGroup) throw new Error('User group is not defined')
        if (props.userGroup.privacy === 'public')
          props.userGroup.is_member = true
        else props.userGroup.request_sent = true
        sendJoinRequest()
      },
    },
  })
}

function leaveGroup() {
  if (!props.userGroup) throw new Error('User group is not defined')

  modalHandler.openType('confirm-dialog', {
    props: {
      title: props.userGroup.is_member ? 'Leave Group' : 'Cancel Request',
      subtitle: props.userGroup?.is_member
        ? 'Are you sure you want to leave this group?' +
          (props.userGroup.role !== 'member'
            ? ` You will lose your role '${props.userGroup.role}' in this group.`
            : '') +
          (props.userGroup.privacy === 'private'
            ? ' You will need to request to join again.'
            : '')
        : 'Are you sure you want to cancel your request to join this group?',
      primary: props.userGroup.is_member ? 'Leave' : 'Cancel Request',
      secondary: props.userGroup.is_member ? 'Cancel' : 'Close',
    },
    events: {
      onSecondary: (payload, close) => close(),
      onPrimary: (payload, close) => {
        close()
        if (!props.userGroup) throw new Error('User group is not defined')
        props.userGroup.is_member = false
        props.userGroup.request_sent = false
        axios.delete(`/api/groups/${props.userGroup.id}/leave`)
          .then(() => {
            invalidate()
          })
      },
    },
  })
}
</script>

<style scoped></style>
