<template>
  <div class="flex gap-3 overflow-x-auto">
    <div
      v-for="option in options"
      :key="option.id"
      :class="{
        ' bg-blue text-white hover:bg-blue ': selectedOption === option.id,
        ' bg-white text-black hover:bg-indigo-100 ':
          selectedOption !== option.id,
      }"
      class="user-select-none flex cursor-pointer flex-col items-center justify-center gap-0 rounded-xl border px-10 py-3"
      @click="selectOption(option.id)"
    >
      <div class="font-bold">
        {{ option.name }}
      </div>
      <div class="whitespace-nowrap text-sm opacity-50">
        {{ option.caption }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
const emit = defineEmits(['option-selected'])




















































defineOptions({
  name: 'OptionSelector',
})




















































const props = defineProps({
  initialId: {
    type: Number,
    required: true,
  },
  options: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((option) => {
        return (
          Object.prototype.hasOwnProperty.call(option, 'id') &&
          Object.prototype.hasOwnProperty.call(option, 'name') &&
          Object.prototype.hasOwnProperty.call(option, 'caption')
        )
      })
    },
  },
})
const selectedOption = ref(null)

function selectOption(id) {
  selectedOption.value = id
  emit('option-selected', id)
}

onMounted(() => {
  if (selectedOption.value === null && props.options.length > 0) {
    selectedOption.value = props.options[0].id
    emit('option-selected', selectedOption.value)
  }
})
</script>


<style scoped></style>
