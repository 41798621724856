<template>
  <event-browse-layout active="meet">
    <success-popup
      :activity="activity"
      :on-close="closeSuccessPopup"
      :show="showSuccessPopup"
    />
    <div class="grow bg-white">
      <activity-detail
        :activity="activity"
        :highlighted-message-id="chatLineId"
        class="mx-auto w-full max-w-4xl"
      />
    </div>
  </event-browse-layout>
</template>

<script setup>
import ActivityDetail from '@/views/www/ActivityDetail.vue'
import SuccessPopup from '@/components/deprecated/SuccessPopup.vue'
import { ref } from 'vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'

const props = defineProps({
  isNew: {
    type: Boolean,
    default: false,
  },
  activity: {
    type: Object,
    required: true,
  },
  chatLineId: {
    type: Number,
    default: null,
  },
})

const showSuccessPopup = ref(props.isNew)

function closeSuccessPopup() {
  showSuccessPopup.value = false
}
</script>

<style scoped></style>
