<template>
  <section
    class="item-center mx-auto flex w-fit max-w-[1200px] flex-col px-2 py-20 sm:px-5 sm:py-[112px]"
  >
    <div
      ref="scrollTarget"
      class="fingertipsBG lg:p-18 relative flex h-[815px] w-full max-w-[1300px] flex-col items-center overflow-hidden rounded-2xl px-4 py-8 sm:px-6 md:h-[400px] md:items-start lg:h-[450px] xl:p-16"
    >
      <div class="flex w-full flex-col gap-4 md:w-[55%]">
        <h2
          class="w-full text-center font-bold leading-[140%] tracking-[0.8px] text-text-white sm:text-4xl md:w-fit md:text-left md:text-2xl xl:text-3xl"
        >
          Fankind at your fingertips
        </h2>
        <p
          class="max-w-full text-center text-sm font-thin leading-[140%] text-text-white md:max-w-full md:text-left md:text-lg"
        >
          Experience the ultimate convenience and connection, right from your
          browser. No downloads, no hassle – just instant access to a universe
          of comic events, communities, and exclusive content!
        </p>
        <ul
          class="flex flex-col items-center gap-2 text-center text-base md:items-start md:text-left md:text-lg"
        >
          <li
            v-for="(option, id) in [
              'Seamless Event Scheduling',
              ' Real-Time convention updates',
              'Community at Your Fingertips',
            ]"
            :key="id"
            class="w-full items-center gap-2 rounded-lg bg-violet-100 px-6 py-2 text-blue-primary md:flex md:w-[600px] md:bg-transparent md:p-0 md:text-text-white"
          >
            <svg
              class="hidden md:flex"
              fill="none"
              height="25"
              viewBox="0 0 24 25"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13.5L9 17.5L19 7.5"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span>{{ option }}</span>
          </li>
        </ul>
      </div>

      <image-component
        ref="phoneImage"
        alt="phone with fankind schedule"
        class="height-[369px] md:height-[480px] absolute bottom-0 mx-auto w-[250px] object-cover md:bottom-0 md:right-[5%] md:w-[260px]"
        src="images/backgrounds/phone_fankind_schedule.png"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import ImageComponent from '@/components/ui/ImageComponent.vue'
import { onMounted, ref } from 'vue'
import { animate, scroll } from 'motion'

const phoneImage = ref<InstanceType<typeof ImageComponent> | null>(null)
const scrollTarget = ref<HTMLDivElement | null>(null)

onMounted(() => {
  scroll(
    animate(
      phoneImage.value?.$el as Element,
      {
        y: [400, 100],
        opacity: [0, 1, 1],
      },
      {
        direction: 'alternate',
      }
    ),
    {
      target: scrollTarget.value!,
      offset: ['start end', 'end 75%'],
    }
  )
})
</script>

<style lang="css" scoped>
@media screen and (max-width: 1300px) {
  .fingertipsBG {
    background-image: linear-gradient(
        93deg,
        rgba(89, 28, 219, 0.8) 0%,
        rgba(32, 28, 219, 0.8) 66.94%,
        rgba(16, 13, 168, 0.8) 100%
      ),
      url('/images/backgrounds/home/fingertips_bg.png') !important;
  }
}

.fingertipsBG {
  background-image: linear-gradient(
      180deg,
      rgba(31, 27, 219, 0.9) 21.88%,
      rgba(16, 13, 168, 0.9) 100%
    ),
    url('/images/backgrounds/home/fingertips_bg.png');

  background-color: lightgray;

  background-position: center;

  background-size: cover;

  background-repeat: no-repeat;
}
</style>
