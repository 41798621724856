<template>
  <section
    class="flex w-full flex-col items-center gap-8 bg-text-white px-0 py-10 sm:px-8"
  >
    <h3
      v-if="title"
      class="mx-auto text-center font-sans text-2xl font-bold text-very-dark-blue"
    >
      {{ title }}
    </h3>

    <div class="mx-auto flex w-fit max-w-full items-center overflow-scroll">
      <button
        v-for="tab in tabs"
        :key="tab.value"
        :class="
          selectedTab == tab.value
            ? 'border-b border-blue-primary text-blue-primary'
            : 'text-slate-500'
        "
        class="mx-5 shrink-0 select-none pb-4 font-sans text-lg font-normal"
        @click="selectedTab = tab.value"
      >
        {{ tab.title }}
      </button>
    </div>

    <div
      v-if="activities?.length"
      class="flex flex-col items-center gap-8 px-4 sm:px-0 md:items-start"
    >
      <group-activity-card
        v-for="activity in activities"
        :key="activity.id"
        :activity="activity"
        :is-user="props.isUser"
        :past-activities="selectedTab === 'past'"
      />
    </div>
    <div
      v-else
      class="mx-auto flex h-[220px] w-full max-w-[830px] flex-col items-center rounded-lg !bg-cover !bg-center !bg-no-repeat p-4 sm:p-8"
      style="
        background:
          linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.45) 0%,
            rgba(0, 0, 0, 0.45) 100%
          ),
          url(/images/backgrounds/group_placeholder.png) lightgray 0px -94.942px /
            100% 245.229% no-repeat;
      "
    >
      <h6 class="mb-0 mt-auto font-sans text-2xl font-bold text-text-white">
        No Activities Yet
        <button
          class="mx-auto flex cursor-pointer select-none items-center gap-2 font-sans text-sm font-bold text-text-white"
          @click="
            modalHandler.openType('meetup-create', {
              events: {
                onPrimary: (payload, close, props) => {
                  props.form
                    .post(`/api/groups/${userGroup.id}/activities`)
                    .then((res: AxiosResponse) => {
                      close()
                      modalHandler.openType('activityDetail', {
                        props: {
                          activity: res.data,
                        },
                      })
                    })
                },
              },
              onClosed: () => {
                queryClient.invalidateQueries({
                  queryKey: ['group-activities', props.userGroup.id],
                })
              },
            })
          "
        >
          <font-awesome-icon
            class="h-4 w-4 text-text-white"
            icon="plus"
          />
          Create activity
        </button>
      </h6>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { PropType, ref, toRaw, watch } from 'vue'

import axios, { AxiosResponse } from 'axios'
import { useModal } from '@/utils/modal'
import { useQuery, useQueryClient } from '@tanstack/vue-query'

import UserGroup from '@/models/UserGroup'
import GroupActivityCard from '@/components/ui/GroupActivityCard.vue'

const props = defineProps({
  isUser: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  userGroup: {
    type: Object as PropType<UserGroup>,
    required: true,
  },
})

const tabs = [
  { title: 'Upcoming Activities', value: 'upcoming' },
  { title: 'Ongoing Activities', value: 'today' },
  { title: 'Past Activities', value: 'past' },
]
const selectedTab = ref(tabs[0].value)

const activities = ref([])
const { data } = useQuery({
  queryKey: ['group-activities', props.userGroup.id, selectedTab],
  queryFn: async () =>
    axios.get(`/api/groups/${props.userGroup.id}/activities`, {
      params: { date: selectedTab.value },
    }),
  select: (data) => data.data,
})
const queryClient = useQueryClient()
watch(
  () => data.value,
  (newData) => {
    activities.value = toRaw(newData ?? [])
  },
  {
    immediate: true,
  }
)

const modalHandler = useModal()
</script>
