<template>
  <div
    class="raffle_card flex flex-col justify-between flex-shrink-0 w-[350px] max-w-screen-sm rounded-2xl overflow-hidden shadow-xl bg-dark-blue-gradient text-slate-100"
  >
    <background-image-component
      class="flex w-full bg-center bg-cover h-48 flex-shrink-0"
      :src="raffle.image?.path"
    />
    <div class="w-full flex flex-col justify-start h-full">
      <div class="flex flex-col w-full px-4 mt-2.5 mx-auto text-center">
        <h3 class="">
          {{ raffle.name }}
        </h3>
        <div class="text-sm text-blue-300">
          {{ raffle.description }}
        </div>
      </div>
      <div
        v-if="raffle.user_tickets > 0"
        class="flex flex-col justify-center items-center py-6 w-fit mx-auto"
      >
        <div class="text-sm text-blue-300">
          You've earned
        </div>
        <h2 class="text-3xl font-bold uppercase my-0">
          {{ raffle.user_tickets }} tickets
        </h2>
        <div class="text-sm text-blue-300">
          Enter raffle to learn how to earn more
        </div>
        <!--                                    TODO: Need to fix the counter, as it shows that the user cannot earn any more when they can earn up to 50.-->
        <!--                                    <div class="text-sm text-blue-300">-->
        <!--                                        Earn up to {{ Math.max(Math.min(raffle.max_earn_tickets, raffle.max_spend_tickets) - raffle.user_tickets, 0) }} more-->
        <!--                                    </div>-->
        <!--                                    <div class="px-4 w-full mt-2">-->
        <!--                                        <div class="w-full h-2.5 bg-slate-600 rounded-full overflow-hidden">-->
        <!--                                            <div class="h-2.5 bg-tangerine" :style="progressStyle(raffle)"></div>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
      </div>
      <div
        v-else
        class="flex mt-auto flex-col justify-center items-center py-6 w-fit mx-auto"
      >
        <div class="text-sm text-blue-300">
          <template v-if="raffle.max_tickets === 0">
            Earn <span class="font-bold underline">unlimited</span> tickets by using Fankind
          </template>
          <template v-else>
            Earn up to <span class="font-bold">{{ raffle.max_tickets }}</span> {{ pluralize(raffle.max_tickets, 'entry', 'entries') }}
            <br/> simply by using Fankind
          </template>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1 items-center bg-slate-900 w-full py-4">
      <Link
        :href="'/raffles/'+raffle.id"
        as="button"
        class="bg-slate-100 hover:bg-white text-slate-900 hover:text-black rounded-full px-12 py-2.5 font-bold"
      >
        {{ getCtaText(raffle) }}
      </Link>

      <div class="text-sm text-slate-400">
        {{ getLabelText(raffle) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {DateTime} from "luxon";
import {Link} from "@inertiajs/vue3";
import {inject} from "vue";
import {pluralize} from "../../utils/string";
import BackgroundImageComponent from "@/components/ui/BackgroundImageComponent.vue";

const props = defineProps({
    raffle: {
        type: Object,
        required: true,
    },
})

const store = inject('store');


function getCtaText(raffle) {
    if (store.currentUser === null) {
        return 'See prizes'
    }
    const statusCode = getStatusByTime(raffle);
    if (statusCode === 'active') {
        return raffle.is_attending ? 'Set your tickets' : 'Enter now'
    } else if (statusCode === 'upcoming') {
        return 'See prizes'
    } else if (statusCode === 'waiting_for_announcement') {
        return raffle.is_attending ? 'Set your selection' : 'See prizes'
    } else if (statusCode === 'ended') {
        return 'See results'
    }
}

function getStatusByTime(raffle) {
    if (DateTime.fromISO(raffle.schedule.starts_at, {zone: raffle.timezone}) > DateTime.now()) {
        return 'upcoming'
    } else if (DateTime.fromISO(raffle.schedule.ends_at, {zone: raffle.timezone}) > DateTime.now()) {
        return 'active'
    } else if (DateTime.fromISO(raffle.announces_at, {zone: raffle.timezone}) > DateTime.now()) {
        return 'waiting_for_announcement'
    } else {
        return 'ended'
    }
}

function getLabelText(raffle) {
    const statusCode = getStatusByTime(raffle);
    if (statusCode === 'active') {
        return 'Selection closes ' + DateTime.fromISO(raffle.schedule.ends_at, {zone: raffle.timezone}).toRelative()
    } else if (statusCode === 'upcoming') {
        return 'Selection opens ' + DateTime.fromISO(raffle.schedule.starts_at, {zone: raffle.timezone}).toRelative()
    } else if (statusCode === 'waiting_for_announcement') {
        return 'Winners will be announced ' + DateTime.fromISO(raffle.announces_at, {zone: raffle.timezone}).toRelative()
    } else if (statusCode === 'ended') {
        return 'Winners announced '
    }
}
</script>

<style scoped>

</style>
