<template>
  <div class="fixed inset-0 flex flex-col items-center">
    <div class="flex flex-col w-full items-center bg-gradient-to-br from-indigo-900 to-blue">
      <div class="flex flex-col w-full max-w-screen-sm">
        <font-awesome-icon
          class="absolute text-white p-4 text-white text-xl opacity-90 hover:opacity-100 cursor-pointer"
          icon="fas fa-caret-left"
          @click="goBack"
        />
        <div class="flex w-full justify-center items-center mb-4 text-white p-4">
          <h2 class="text-white text-xl">
            Create a Game
          </h2>
        </div>
        <div class="flex flex-col relative gap-8 h-[400px] justify-center items-center">
          <div
            class="datePicker flex flex-row w-full pl-3 justify-start items-stretch space-x-3 mb-4 overflow-x-auto user-select-none"
          >
            <button
              v-for="date in dates"
              :key="date.id"
              :class="{'bg-slate-200': selectedDate === date.id, 'bg-indigo-200 opacity-60': selectedDate !== date.id}"
              class="flex flex-col px-8 md:px-10 py-3 rounded-md text-blue-900"
              @click="handleDateClick(date)"
            >
              <div class="block text-base md:text-lg font-bold">
                {{ date.shortDay }}
              </div>
              <div class="block text-xs md:text-sm whitespace-nowrap">
                {{ date.shortDate }}
              </div>
            </button>
          </div>
          <div class="flex flex-col w-full items-center mb-4 p-4">
            <input
              v-model="gameSessionName"
              :maxlength="maxNameLength"
              class="flex w-full border-b border-indigo-700 text-white text-xl bg-transparent pb-2 mb-2 outline-none"
              placeholder="Give an awesome name"
              type="text"
            />
            <div class="text-indigo-500 user-select-none">
              {{ gameSessionName.length }}/{{
                maxNameLength
              }}
            </div>
            <div
              v-if="errors.name"
              class="user-select-none"
            >
              {{ errors.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white flex flex-col h-full w-full items-center justify-center">
      <div v-if="!gameSessionUrl">
        <div class="pb-20">
          <button
            :class="{'bg-slate-400': !gameSessionName, 'bg-blue': gameSessionName}"
            :disabled="processing.value"
            class="px-4 py-2 rounded-md text-slate-200 hover:text-white"
            @click="submit"
          >
            <span v-if="!processing">Save and share</span>
            <span v-if="processing">
              <font-awesome-icon
                class="animate-spin"
                icon="fas fa-spinner"
              />
              <span class="ml-2">Saving...</span>
            </span>
          </button>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col gap-10 pb-20">
          <div class="flex flex-col gap-4 items-center">
            <div class="text-2xl font-bold text-indigo-800">
              Your game session is ready!
            </div>
            <div class="text-indigo-800">
              Share this link with your friends to join the game session.
            </div>
          </div>

          <div class="bg-indigo-200 rounded text-indigo-800">
            {{ gameSessionUrl }}
          </div>
          <div class="flex items-center flex-row gap-8">
            <!--                        <button-->
            <!--                            @click="$emit('back')"-->
            <!--                            class="px-4 py-2 rounded-md text-slate-200 hover:text-white bg-slate-400">-->
            <!--                            <span>Close</span>-->
            <!--                        </button>-->
            <Link
              :href="gameSessionUrl"
              class="px-4 py-2 rounded-md text-slate-200 hover:text-white bg-slate-400"
            >
              <span>Go to your game</span>
            </Link>
            <button
              class="px-4 py-2 rounded-md text-slate-100 hover:text-white bg-blue"
              @click="handleShareButtonClick"
            >
              <span>Share</span>
            </button>
            <p
              v-if="copiedPopup"
              class="text-indigo-800"
            >
              Copied to clipboard!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, useAttrs } from 'vue'
import { DateTime } from 'luxon'
import axios from 'axios'
import { Link, router } from '@inertiajs/vue3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

defineOptions({
  name: 'CreatePrivateGameSession',
})


const props = defineProps({
  conventionEvent: {
    type: Object,
    required: true,
  },
  game: {
    type: Object,
    required: true,
  },
  selectionOptions: {
    type: Array,
    required: true,
  },
})

const attrs = useAttrs()
const currentUser = computed(() => {
  return attrs.auth?.user ?? null
})

const maxNameLength = ref(40)

const gameSessionUrl = ref(null)

const selectedDate = ref(null)
const startsAt = ref(null)
const endsAt = ref(null)
const eventActivityId = ref(null)
const gameId = ref(null)
const gameSessionName = ref('')
const processing = ref(false)
const errors = ref({
  name: null,
})

const goBack = () => {
  router.visit(`/event/${props.conventionEvent.slug}/home`)
}

const formData = computed(() => {
  return {
    name: gameSessionName.value,
    starts_at: startsAt.value,
    ends_at: endsAt.value,
    game_id: props.game.id,
    convention_event_id: props.conventionEvent.id,
    host_id: currentUser.value.id,
  }
})

const dates = computed(() => {
  return props.selectionOptions
  // const interval = {
  //     start: new Date("2023-07-19"),
  //     end: new Date("2023-07-23"),
  // };
  // const days = eachDayOfInterval(interval).filter((day) =>
  //     !isBefore(day, startOfToday())
  // );
  // return days.map((day) => ({
  //     id: format(day, "yyyy-MM-dd"),
  //     shortDay: format(day, "EEE"),
  //     shortDate: format(day, "MMM d"),
  // }));
})

onMounted(() => {
  selectedDate.value = dates.value[0].id
  updateDate()
})

function handleDateClick(date) {
  selectedDate.value = date.id
  updateDate()
}

const copiedPopup = ref(false)

function handleShareButtonClick() {
  const url = gameSessionUrl.value

  if (navigator.share) {
    console.log('navigator.share')
    navigator
      .share({
        title: 'Game Session',
        text: 'Join my game session',
        url: url,
      })
      .then(() => {
        console.log('Successful share')
      })
      .catch((error) => {
        console.error('Error sharing:', error)
      })
  } else if (navigator.clipboard) {
    console.log('navigator.clipboard')
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log('URL copied to clipboard')
        copiedPopup.value = true
        setTimeout(() => {
          copiedPopup.value = false
        }, 10000)
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error)
      })
  } else {
    console.log('Share not supported')
  }
}


const updateDate = () => {
  let date = DateTime.fromISO(selectedDate.value)

  let starts_at = date.setZone(props.conventionEvent.timezone).startOf('day').toUTC().toFormat('yyyy-MM-dd HH:mm:ss')
  let ends_at = date.setZone(props.conventionEvent.timezone).endOf('day').toUTC().toFormat('yyyy-MM-dd HH:mm:ss')

  eventActivityId.value = dates.value.find((date) => date.id === selectedDate.value).event_activity_id
  gameId.value = dates.value.find((date) => date.id === selectedDate.value).game_id
  startsAt.value = starts_at
  endsAt.value = ends_at
}

const submit = () => {
  processing.value = true
  axios.post('/api/game/session', {
    name: gameSessionName.value,
    starts_at: startsAt.value,
    ends_at: endsAt.value,
    game_id: gameId.value,
    convention_event_id: props.conventionEvent.id,
    host_id: currentUser.value.id,
    event_activity_id: eventActivityId.value,
  }, {
    preserveState: true,
  })
    .then((response) => {
      // TODO: Make the domain dynamic, and the return dynamic
      gameSessionUrl.value = response.data.share_url
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      processing.value = false
    })
}
</script>

<style scoped>
/* Custom styles here */
</style>
