<template>
  <div
    class="flex h-[273px] w-full min-w-[200px] max-w-[253px] shrink-0 flex-col gap-4 rounded-xl"
  >
    <image-component
      class="h-[193px] w-full rounded-xl object-cover object-center"
      :class="{ 'grayscale-50 brightness-50': timePeriod == 'past' }"
      :src="item.thumbnail?.path"
      alt="convention logo"
    />

    <p class="text-xl font-bold leading-[140%] text-slate-900">
      <span
        v-if="timePeriod == 'upcoming'"
        class="block text-accent-orange"
      >
        Upcoming on
      </span>
      <span
        v-if="timePeriod == 'current'"
        class="block text-blue-primary"
      >
        Already start!
      </span>
      <span
        v-if="timePeriod == 'past'"
        class="block text-slate-500"
      >
        Look back to
      </span>
      <time
        :datetime="item.date"
        :class="{ 'text-slate-500': timePeriod == 'past' }"
      >
        {{ formattedDate }}
      </time>
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import ImageComponent from "@/components/ui/ImageComponent.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const timePeriod = computed(() => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  const startDate = new Date(props.item.starts_at)
  startDate.setHours(0, 0, 0, 0)

  const endDate = new Date(props.item.ends_at)
  endDate.setHours(0, 0, 0, 0)

  if (endDate < now) {
    return 'past'
  } else if (startDate <= now && now <= endDate) {
    return 'current'
  } else {
    return 'upcoming'
  }
})

const formattedDate = computed(() => {
  const startDate = new Date(props.item.starts_at)
  const endDate = new Date(props.item.ends_at)

  const optionsMonth = { month: 'long', timeZone: 'UTC' }
  const optionsDay = { day: 'numeric', timeZone: 'UTC' }

  const month = new Intl.DateTimeFormat('en-US', optionsMonth).format(startDate)
  const startDay = new Intl.DateTimeFormat('en-US', optionsDay).format(
    startDate
  )
  const endDay = new Intl.DateTimeFormat('en-US', optionsDay).format(endDate)

  if (timePeriod.value === 'past') {
    return month
  } else {
    return `${month} ${startDay}-${endDay}`
  }
})
</script>

<style lang="scss" scoped></style>
