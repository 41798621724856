<template>
  <event-browse-layout active="eat">
    <showcase-renderer
      :enable-edit="vendor.allow_editing"
      :showcase-page="showcasePage"
      include-default-sections
      page-type="Restaurant"
    />
  </event-browse-layout>
</template>

<script lang="ts" setup>
import ShowcaseRenderer from '@/components/ui/showcase/ShowcaseRenderer.vue'
import ShowcasePage from '@/models/ShowcasePage'
import Vendor from '@/models/Vendor'
import { ref } from 'vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'

const props = defineProps<{
  vendor: Vendor,
  page: ShowcasePage<Vendor>
}>()

function buildShowcasePage(): ShowcasePage<Vendor> {
  return ShowcasePage.fromData<ShowcasePage<Vendor>>({
    ...props.page,
    showcaseable: props.vendor as Vendor,
    theme: {
      name: 'default',
      textPrimary: '',
      backgroundPrimary: '',

      textSecondary: '',
      backgroundSecondary: '',

      textHighlight: '',
      backgroundHighlight: 'bg-blue-primary',

      textDark: '',
      backgroundDark: '',

      textLight: '',
      backgroundLight: '',

      textDetails: '',
      backgroundDetails: '',

      textDetailsLight: '',
      backgroundDetailsLight: '',

      textDetailsDark: '',
      backgroundDetailsDark: '',

      textTags: '',
      buttonColor: '',
      backgroundGradient: 'bg-gradient-to-b from-amber-600 to-amber-500',

      iconsMain: '',
      iconsSecondary: '',
    },
  })
}

const showcasePage = ref<ShowcasePage<Vendor>>(buildShowcasePage())
</script>

<style scoped></style>
