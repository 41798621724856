<template>
  <div class="relative mx-auto flex w-full flex-col gap-6 text-center">
    <h2
      class="text-2xl font-bold leading-[140%] tracking-[0.8px] text-dark-grey"
    >
      Check out these other great conventions
    </h2>

    <div
      ref="scrollContainer"
      class="mx-auto flex w-full max-w-full flex-col items-center gap-16 overflow-scroll"
    >
      <div
        ref="elements"
        class="flex w-full items-center"
      >
        <Link
          v-for="(item, index) in conventionList?.slice().sort((a,b)=> {
            return a.startsAt < b.startsAt ? 1 : -1
          })"
          :key="index"
          :href="`/events/${item.slug}`"
          class="px-4"
        >
          <convention-card
            :item="item"
            class="cursor-pointer"
          />
        </Link>
      </div>
    </div>
    <button
      class="scroll-left-button absolute -left-6 top-[45%] hidden select-none xl:flex"
      @click="scrollBy(-1)"
    >
      <chevron-right class="rotate-180 text-slate-400" />
    </button>
    <button
      class="scroll-right-button absolute left-[101.5%] top-[45%] hidden select-none xl:flex"
      @click="scrollBy(1)"
    >
      <chevron-right class="text-slate-400" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import ChevronRight from '@/icons/ChevronRight.vue'
import ConventionCard from '@/components/ui/ConventionCard.vue'
import { Link } from '@inertiajs/vue3'
import useConventionList from '@/utils/composables/conventionList'
import { animate, inView } from 'motion'

const props = defineProps({
  palette: {
    type: Object,
    default: () => ({}),
  },
})

const { list: conventionList } = useConventionList()

const scrollContainer = ref<HTMLDivElement | null>(null)
const elements = ref<HTMLDivElement | null>(null)

function scrollBy(direction: number) {
  const container = scrollContainer.value
  if (!container) return

  const scrollAmount = 253 + 64
  container.scrollBy({ left: scrollAmount * direction, behavior: 'smooth' })
}

onMounted(() => {
  inView(elements.value as Element, () => {
    const { width } = elements.value?.getBoundingClientRect() ?? { width: 0 }
    animate(
      elements.value as Element,
      { x: [Math.max(-width, -500), 0] },
      { duration: 0.75, easing: 'ease-out' }
    )

  })
})
</script>

<style scoped></style>
