<template>
  <event-browse-layout>
    <responsive-layout>
      <div class="flex grow items-start gap-4 py-12">
        <aside
          class="flex w-1/4 shrink-0 flex-col gap-3 divide-y rounded-lg bg-slate-50 px-4 py-4"
        >
          <div class="flex flex-col gap-6">
            <h2 class="text-lg font-semibold">Categories</h2>
            <ul v-if="showAllCategories">
              <Link
                v-for="category in categories"
                :key="category.value"
                :class="{
                  'font-bold': page.props.query?.category === category.value,
                }"
                :href="`/events/${selectedEvent?.id}/browse?category=${category.value}`"
                as="li"
                class="cursor-pointer"
              >
                {{ category.label }}
              </Link>
            </ul>
            <div
              v-else
              class="flex flex-col items-start gap-2"
            >
              <h3>
                {{ selectedCategory?.label }}
              </h3>
              <button
                class="flex items-center gap-2 rounded-lg border border-slate-300 bg-[#F2F5F9] px-2 py-1 font-source3 text-lg font-normal"
                @click="showAllCategories = true"
              >
                <icon-arrow-down class="h-4.5 w-4.5 rotate-90" />
                See all categories
              </button>
            </div>
          </div>
          <v-button
            :disabled="
              loading ||
              fetching ||
              !isDifferent(only(filters, filterKeys), cleanFilters) ||
              (Object.keys(_filters).length === 0 &&
                Object.keys(only(filters, filterKeys)).length === 0)
            "
            class="sticky top-20 z-floating"
            variant="primary"
            @click="applyFilters"
          >
            Apply filters
          </v-button>
          <browse-filter
            v-for="filter in selectedCategory?.filters"
            :key="filter.value"
            :direction="filter.direction"
            :item-component="filter.component"
            :label="filter.label"
            :model-value="_filters[filter.key]"
            :sort="filter.sort"
            :suggestions="availableFilters?.[filter.key]"
            :type="filter.type"
            class="flex flex-col gap-6 pt-3"
            @update:model-value="
              (value) => {
                if (value) {
                  _filters[filter.key] = value
                } else {
                  delete _filters[filter.key]
                }
              }
            "
          >
            <template
              v-if="filter.component"
              #item="{ item, isChecked }"
            >
              <component
                :is="filter.component"
                :item="item"
                :selected="isChecked"
              />
            </template>
          </browse-filter>
        </aside>
        <main class="flex w-3/4 flex-col gap-8">
          <div class="flex flex-col gap-4">
            <div class="flex justify-between">
              <h1 class="text-[32px]">
                {{ selectedCategory?.title }}
              </h1>
              <form-field-input-search
                :placeholder="`Search ${selectedCategory?.title}`"
                class="w-1/3"
                variant="searchbar-gray"
                @search="filters.query = $event"
              />
            </div>
            <div
              v-if="Object.keys(_filters).length > 0"
              class="flex flex-wrap gap-2"
            >
              <template
                v-for="[key, group] in Object.entries(_filters)"
                :key="key"
              >
                <button
                  v-for="item in group"
                  :key="item.id"
                  class="flex items-center gap-2 rounded-full bg-blue-50 px-3 py-2 font-roboto text-sm text-slate-500"
                  @click="
                    () => {
                      _filters[key] = _filters[key].filter(
                        (f) => f.id !== item.id
                      )
                      applyFilters()
                    }
                  "
                >
                  {{
                    selectedCategory?.filters
                      ?.find((f) => f.key === key)
                      ?.render?.(item) ?? item.name
                  }}
                  <font-awesome-icon icon="times" />
                </button>
              </template>
            </div>
          </div>
          <div
            v-if="selectedCategory"
            class="flex flex-col"
          >
            <p class="text-base text-slate-400">
              {{ total }} {{ selectedCategory?.title }}
            </p>
            <div
              :class="{
                'flex flex-col': selectedCategory.layout === 'col',
                'grid md:grid-cols-2 xl:grid-cols-3 justify-center': selectedCategory.layout === 'grid',
                'grid 2xl:grid-cols-2 justify-center': selectedCategory.layout === 'grid-2',
              }"
              class="gap-6"
            >
              <template
                v-for="item in items"
                :key="item.id"
              >
                <special-guest-card
                  v-if="selectedCategory.value === 'guests'"
                  :event="selectedEvent"
                  :guest="item"
                  :palette="selectedEvent?.palette"
                />
                <item-card
                  v-else-if="
                    ['dishesdrinks', 'products'].includes(
                      selectedCategory?.value
                    )
                  "
                  :event-slug="selectedEvent?.slug"
                  :is-menu-item="selectedCategory.value === 'dishesdrinks'"
                  :item="item"
                />
                <restaurant-card-with-products
                  v-else-if="selectedCategory.value === 'restaurants'"
                  :event-slug="selectedEvent?.slug"
                  :restaurant="item"
                />
                <shop-card-with-products
                  v-else-if="selectedCategory.value === 'shops'"
                  :event-slug="selectedEvent?.slug"
                  :shop="item"
                />
                <!--                <div-->
                <!--                  v-else-if="selectedCategory.value === 'meetups'"-->
                <!--                  class="h-32 border"-->
                <!--                >-->
                <!--                  {{ item.name }}-->
                <!--                </div>-->
                <meet-hover-card
                  v-else-if="selectedCategory.value === 'meetups'"
                  :item="item"
                  :palette="selectedEvent?.palette"
                />
                <!-- TODO: change this to designed card -->
                <meet-hover-card
                  v-else-if="selectedCategory.value === 'activities'"
                  :item="item"
                  :palette="selectedEvent?.palette"
                />
              </template>
            </div>
            <button
              v-if="hasNextPage"
              :style="{
                color: selectedEvent?.palette?.primary ?? '#1613BD',
              }"
              class="mt-6 p-2.5 text-lg font-semibold"
              @click="fetchNextPage"
            >
              Show more
            </button>
          </div>
        </main>
      </div>
    </responsive-layout>
  </event-browse-layout>
</template>

<script lang="ts" setup>
import StandardLayout from '@/components/layout/StandardLayout.vue'
import { useInfiniteTable } from '@/utils/composables/table'
import useConventionList from '@/utils/composables/conventionList'
import { Link, usePage } from '@inertiajs/vue3'
import ResponsiveLayout from '@/components/layout/ResponsiveLayout.vue'
import { computed, ref } from 'vue'
import BrowseFilter from '@/components/input/BrowseFilter.vue'
import EventDayPresenter from '@/components/ui/EventDayPresenter.vue'
import { DateTime } from 'luxon'
import EventDay from '@/models/EventDay'
import FormFieldInputSearch from '@/components/input/FormFieldInputSearch.vue'
import SpecialGuestCard from '@/shared/components/event/sections/elements/SpecialGuestCard.vue'
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import IconArrowDown from '@/icons/IconArrowDown.vue'
import VButton from '@/components/buttons/VButton.vue'
import RestaurantCardWithProducts from '@/components/ui/RestaurantCardWithProducts.vue'
import ShopCardWithProducts from '@/components/ui/ShopCardWithProducts.vue'
import { isDifferent, only } from '@/utils/form'
import MeetHoverCard from '@/components/ui/MeetHoverCard.vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'

const { selected: selectedEvent } = useConventionList()

const page = usePage()

const categories = [
  {
    label: 'Guests',
    value: 'guests',
    title: 'Special Guests',
    layout: 'grid',
    url: `/api/conventions/${selectedEvent.value?.id}/guests/browse`,
    filters: [
      { label: 'Fandom', key: 'tags', type: 'checkbox' },
      {
        label: 'Day',
        key: 'event_days',
        type: 'single',
        component: EventDayPresenter,
        direction: 'row',
        sort: (a: EventDay, b: EventDay) =>
          DateTime.fromISO(a.date).toMillis() -
          DateTime.fromISO(b.date).toMillis(),
        render: (day: EventDay) =>
          DateTime.fromISO(day.date).toFormat('ccc, LLL d'),
      },
      { label: 'Activity type', key: 'event_types', type: 'checkbox' },
    ],
  },
  {
    label: 'Dishes & Drinks',
    value: 'dishesdrinks',
    title: 'Dishes&Drinks',
    layout: 'grid',
    url: `/api/conventions/${selectedEvent.value?.id}/products/browse?type=meal`,
    filters: [{ label: 'Meal Type', key: 'categories', type: 'checkbox' }],
  },
  {
    label: 'Products',
    value: 'products',
    title: 'Products',
    layout: 'grid',
    url: `/api/conventions/${selectedEvent.value?.id}/products/browse?type=shop`,
    filters: [
      { label: 'Fandom', key: 'tags', type: 'checkbox' },
      { label: 'Product Type', key: 'categories', type: 'checkbox' },
    ],
  },
  {
    label: 'Restaurants',
    value: 'restaurants',
    title: 'Restaurants',
    layout: 'col',
    url: `/api/conventions/${selectedEvent.value?.id}/vendors/browse?type=restaurant`,
    filters: [
      { label: 'Restaurant Type', key: 'categories', type: 'checkbox' },
    ],
  },
  {
    label: 'Shops',
    value: 'shops',
    title: 'Shops',
    layout: 'col',
    url: `/api/conventions/${selectedEvent.value?.id}/vendors/browse?type=shop`,
    filters: [
      { label: 'Fandom', key: 'tags', type: 'checkbox' },
      { label: 'Shop Type', key: 'categories', type: 'checkbox' },
    ],
  },
  {
    label: 'Meetups',
    value: 'meetups',
    title: 'Meetups',
    layout: 'grid-2',
    url: `/api/conventions/${selectedEvent.value?.id}/activities/browse?type=meetup&with=banner,location`,
    filters: [
      { label: 'Fandom', key: 'tags', type: 'checkbox' },
      {
        label: 'Day',
        key: 'event_days',
        type: 'single',
        component: EventDayPresenter,
        direction: 'row',
        sort: (a: EventDay, b: EventDay) =>
          DateTime.fromISO(a.date).toMillis() -
          DateTime.fromISO(b.date).toMillis(),
        render: (day: EventDay) =>
          DateTime.fromISO(day.date).toFormat('ccc, LLL d'),
      },
    ],
  },
  {
    label: 'Activities',
    value: 'activities',
    title: 'Activities',
    layout: 'grid-2',
    url: `/api/conventions/${selectedEvent.value?.id}/activities/browse?type=-meetup&with=banner,location`,
    filters: [
      { label: 'Fandom', key: 'tags', type: 'checkbox' },
      { label: 'Activity Type', key: 'categories', type: 'checkbox' },
      {
        label: 'Day',
        key: 'event_days',
        type: 'single',
        component: EventDayPresenter,
        direction: 'row',
        sort: (a: EventDay, b: EventDay) =>
          DateTime.fromISO(a.date).toMillis() -
          DateTime.fromISO(b.date).toMillis(),
        render: (day: EventDay) =>
          DateTime.fromISO(day.date).toFormat('ccc, LLL d'),
      },
    ],
  },
]

const selectedCategory = computed(() => {
  return categories.find(
    (category) => category.value === page.props.query?.category
  )
})

const filterKeys = computed(() => {
  return selectedCategory.value?.filters.map((f) => f.key) ?? []
})

const _filters = ref({})
const showAllCategories = ref(false)

const {
  data,
  loading,
  fetching,
  filters,
  total,
  items,
  hasNextPage,
  fetchNextPage,
  availableFilters,
} = useInfiniteTable({
  enabled: computed(() => !!selectedCategory.value),
  baseUrl: selectedCategory.value?.url,
})

const cleanFilters = computed(() => {
  return Object.entries(_filters.value)
    .map(([key, value]) => {
      return {
        [key]: Array.isArray(value) ? value.map((v: any) => v.id) : value,
      }
    })
    .reduce((acc, filter) => {
      return { ...acc, ...filter }
    }, {})
})

function applyFilters() {
  Object.keys(cleanFilters.value).forEach((key) => {
    filters.value[key] = cleanFilters.value[key]
  })
  Object.keys(only(filters.value, filterKeys.value)).forEach((key) => {
    if (!cleanFilters.value[key]) {
      delete filters.value[key]
    }
  })
  console.log(filters.value)
}
</script>

<style scoped></style>
