import { ref } from 'vue'

const location = ref({
  loading: false,
  loaded: false,
  error: false,
  latitude: 0,
  longitude: 0,
})

export function useUserLocation() {
  if (!location.value.loading && !location.value.loaded) {
    location.value.loading = true
      console.log('loading location')
    navigator.geolocation.getCurrentPosition(
      (position) => {
        location.value.latitude = position.coords.latitude
        location.value.longitude = position.coords.longitude
        location.value.loaded = true
        location.value.loading = false
      },
      () => {
        location.value.error = true
        location.value.loading = false
      }
    )
  }

  return location
}
