<template>
  <section
    class="flex w-full flex-col items-center gap-y-8 bg-black/50 bg-[url('https://picsum.photos/200/300')] bg-cover bg-center bg-no-repeat px-4 py-14 pt-[100px] text-white bg-blend-darken md:px-10"
  >
    <h1
      :class="theme?.textPrimary ? theme.textPrimary : 'text-white'"
      class="text-center font-heading text-[28px]"
    >
      {{ props.section.title ?? 'Title' }}
    </h1>
    <div class="flex w-full flex-col gap-8 xl:flex-row">
      <div
        class="mx-auto flex h-60 w-full max-w-[800px] flex-col justify-end rounded-lg bg-white lg:min-w-[500px] xl:m-0 xl:max-w-[500px]"
        :class="
          checkAnyCoordinatesInMapElements(section.locations)
            ? 'lg:h-[540px]'
            : detailedInfoMissed()
              ? 'hidden'
              : 'h-fit'
        "
      >
        <div class="relative flex h-full w-full text-black">
          <map-with-markers
            v-if="checkAnyCoordinatesInMapElements(section.locations)"
            :class="
              selectedPoint
                ? 'rounded-lg lg:rounded-b-none lg:rounded-t-lg'
                : 'rounded-lg'
            "
            :coordinates="pointOfInterest"
            :disable-controls="false"
            :selected-point="selectedPoint"
            class="w-full"
          />
        </div>

        <section
          v-if="selectedPoint && !detailedInfoMissed()"
          class="hidden h-fit max-h-[507px] lg:flex"
        >
          <map-location-info
            :class="[
              'max-h-[507px] w-full overflow-y-scroll sm:p-[25px]',
              theme?.backgroundPrimary ? theme.backgroundPrimary : '',
              !checkAnyCoordinatesInMapElements(section?.locations)
                ? 'rounded'
                : 'rounded-b',
            ]"
            :item="selectedPoint"
          />
        </section>
      </div>
      <div
        class="flex h-fit w-full flex-col items-center gap-8 lg:min-w-[320px]"
      >
        <template
          v-for="(item, index) in props.section.locations"
          :key="index"
        >
          <!-- Show MapLocationInfo if item is selected -->
          <section
            v-if="selectedPoint?.id === item.id"
            class="flex h-full max-h-[507px] w-full flex-col items-center"
          >
            <map-location-info
              :class="[
                'max-h-[507px] w-full max-w-[800px] cursor-pointer overflow-y-scroll rounded lg:hidden xl:max-w-[605px]',
                theme?.backgroundPrimary ? theme.backgroundPrimary : '',
              ]"
              :item="selectedPoint"
            />
            <location-venue-card
              :class="[
                'hidden h-fit w-full max-w-[800px] cursor-pointer lg:flex',
                theme?.backgroundPrimary ? theme.backgroundPrimary : '',
                item.image ? 'min-h-[116px]' : 'min-h-fit',
              ]"
              :image="item.image"
              image-class="max-h-[100px] max-w-[100px]"
              :location="item"
              :title="item.title"
              @click="centerOnMap(item)"
            />
          </section>
          <!-- Show LocationVenueCard if item is not selected -->
          <location-venue-card
            v-else
            :class="[
              'h-full min-h-[64px] w-full max-w-[800px] cursor-pointer',
              theme?.backgroundPrimary ? theme.backgroundPrimary : '',
            ]"
            :image="item.image"
            image-class="max-h-[48px] max-w-[48px]"
            :location="item"
            :title="item.title"
            @click="centerOnMap(item)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

import MapWithMarkers from '@/components/ui/MapWithMarkers.vue'
import MapLocationInfo from '@/shared/components/event/sections/elements/MapLocationInfo.vue'
import LocationVenueCard from '@/shared/components/event/sections/elements/LocationVenueCard.vue'

const emit = defineEmits(['edit'])

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: Object,
    required: true,
  },
})

const selectedPoint = ref(null)

const detailedInfoMissed = () => {
  if (
    selectedPoint.value?.description ||
    selectedPoint.value?.schedule ||
    selectedPoint.value?.contacts?.phone ||
    selectedPoint.value?.contacts?.email
  )
    return false
  else return true
}

const centerOnMap = (item) => {
  selectedPoint.value = {
    id: item.id,
    longitude: item.longitude,
    latitude: item.latitude,
    image: item.image,
    title: item.title,
    description: item.description,
    address: `${item.address} ${item.name ?? ''} ${item.state ?? ''} ${
      item.zip ?? ''
    }`,
    contacts: item.contacts,
    schedule: item.schedule,
  }
}

const pointOfInterest = ref([])
if (props.section.locations?.length > 0) {
  for (let i = 0; i < props.section.locations.length; i++) {
    pointOfInterest.value.push({
      longitude: props.section.locations[i]?.longitude,
      latitude: props.section.locations[i]?.latitude,
      image: props.section.locations[i].image,
    })
  }
}

function checkAnyCoordinatesInMapElements(locations) {
  return locations?.some(
    (element) =>
      typeof element.longitude === 'number' &&
      typeof element.latitude === 'number'
  )
}
</script>

<style lang="scss" scoped></style>
