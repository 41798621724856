<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <g clip-path="url(#clip0_8532_6454)">
      <path
        d="M9.5 1.5C13.6423 1.5 17 4.85775 17 9C17 13.1423 13.6423 16.5 9.5 16.5C5.35775 16.5 2 13.1423 2 9C2 4.85775 5.35775 1.5 9.5 1.5ZM9.5 4.5C9.30109 4.5 9.11032 4.57902 8.96967 4.71967C8.82902 4.86032 8.75 5.05109 8.75 5.25V9C8.75004 9.1989 8.82909 9.38963 8.96975 9.53025L11.2198 11.7802C11.3612 11.9169 11.5507 11.9925 11.7473 11.9908C11.9439 11.989 12.1321 11.9102 12.2711 11.7711C12.4102 11.6321 12.489 11.4439 12.4908 11.2473C12.4925 11.0507 12.4169 10.8612 12.2802 10.7198L10.25 8.6895V5.25C10.25 5.05109 10.171 4.86032 10.0303 4.71967C9.88968 4.57902 9.69891 4.5 9.5 4.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8532_6454">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
