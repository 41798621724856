<template>
  <div class="h-full w-full overflow-hidden bg-white">
    <div class="flex h-full w-full flex-col gap-4 overflow-auto">
      <div
        class="sticky top-0 z-fixed border-b bg-white bg-opacity-75 p-6 backdrop-blur"
      >
        <input-field
          v-model="searchQuery"
          placeholder="Search"
          variant="gray"
        >
          <template #inner>
            <button
              class="h-full"
              @click="searchQuery = ''"
            >
              <svg
                v-if="searchQuery"
                class="h-full w-6 cursor-pointer text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6l12 12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
          </template>
        </input-field>
      </div>
      <div class="flex flex-col gap-2 px-6">
        <schedule-activity-presenter
          v-for="activity in searchResults"
          :key="activity.id"
          :activity="activity"
          default-expanded
          :disable-click="false"
          :hide-date="false"
          :highlight-query="searchQuery"
          direction="row"
          expanded
          hover-buttons
          @open="emit('open', activity)"
          @rsvp="attending.rsvp(activity)"
          @unrsvp="attending.unrsvp(activity)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InputField from '@/components/input/InputField.vue'
import ScheduleActivityPresenter from '@/components/ui/ScheduleActivityPresenter.vue'
import { computed, ref } from 'vue'
import { useAttending } from '@/utils/attending'

const emit = defineEmits(['open'])

const props = defineProps({
  activities: {
    type: Array,
    default: () => [],
  },
  typeTags: {
    type: Array,
    default: () => [],
  },
  topicTags: {
    type: Array,
    default: () => [],
  },
})

const attending = useAttending()

const searchQuery = ref('')

const searchResults = computed(() => {
  let searchKeywords = searchQuery.value.toLowerCase().split(' ')

  const filterTags = (tags) => {
    return tags
      .filter((tag) => {
        return searchKeywords.some((k) => tag.name.toLowerCase().includes(k))
      })
      .map((tag) => {
        return tag.id
      })
  }

  let availableTypes = filterTags(props.typeTags)
  let availableTopics = filterTags(props.topicTags)

  return props.activities
    .filter((a) => {
      return (
        searchKeywords.some((k) => a.name?.toLowerCase().includes(k)) ||
        searchKeywords.some((k) =>
          a.short_description?.toLowerCase().includes(k)
        ) ||
        availableTypes.includes(a.typeTag?.id) ||
        a.topicTags.map((t) => t.id).some((t) => availableTopics.includes(t))
      )
    })
    .sort((a, b) => {
      let aScore = 0
      let bScore = 0
      searchKeywords.forEach((k) => {
        if (a.name?.toLowerCase().includes(k)) {
          aScore += 3
        }
        if (b.name?.toLowerCase().includes(k)) {
          bScore += 3
        }
        if (a.short_description?.toLowerCase().includes(k)) {
          aScore += 2
        }
        if (b.short_description?.toLowerCase().includes(k)) {
          bScore += 2
        }
        if (availableTypes?.includes(a.typeTag)) {
          aScore += 1
        }
        if (availableTypes?.includes(b.typeTag)) {
          bScore += 1
        }
        if (a.topicTags?.some((t) => availableTopics.includes(t))) {
          aScore += 1
        }
        if (b.topicTags?.some((t) => availableTopics.includes(t))) {
          bScore += 1
        }
      })
      return bScore - aScore
    })
    .slice(0, 20)
})
</script>

<style scoped></style>
