<template>
  <ol class="list-ordering list-inside">
    <li
      v-for="(item, i) in items"
      :key="i"
      class="flex gap-6 border-b last:border-b-0 border-slate-300 pb-4 text-[32px] font-bold"
    >
      <slot
        :item="item"
        name="item"
      >
        <div class="flex grow flex-col">
          <label
            :for="`accordion-${i}`"
            class="cursor-pointer select-none text-lg font-normal"
          >
            {{ item.title }}
          </label>
          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <p
              v-if="expandedKeys.includes(i)"
              class="text-base font-normal"
            >
              {{ item.description }}
            </p>
          </transition>
        </div>
        <button
          :id="`accordion-${i}`"
          class="ml-2 flex h-4 w-4 items-center justify-center"
          @click="toggle(i)"
        >
          <font-awesome-icon
            :icon="expandedKeys.includes(i) ? 'minus' : 'plus'"
            class="h-4 w-4 text-slate-600"
          />
        </button>
      </slot>
    </li>
  </ol>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

interface AccordionItem {
  title: string
  description: string
}

const props = defineProps({
  items: {
    type: Array as PropType<AccordionItem[]>,
    required: true,
  },
  defaultExpanded: {
    type: Boolean,
    default: false,
  },
})

const expandedKeys = ref<number[]>(props.defaultExpanded ? props.items.map((_, i) => i) : [])

function toggle(index: number) {
  if (expandedKeys.value.includes(index)) {
    expandedKeys.value = expandedKeys.value.filter((key) => key !== index)
  } else {
    expandedKeys.value = [...expandedKeys.value, index]
  }
}

function enter(element: Element) {
  if (!(element instanceof HTMLElement)) return

  const width = getComputedStyle(element).width

  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = 'auto'
  element.style.position = 'static'
  element.style.visibility = 'visible'
  element.style.height = '0px'

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height
  })
}

function afterEnter(element: Element) {
  if (!(element instanceof HTMLElement)) return

  element.style.height = 'auto'
}

function leave(element: Element) {
  if (!(element instanceof HTMLElement)) return

  const height = getComputedStyle(element).height

  element.style.height = height

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = '0px'
  })
}
</script>

<style scoped>
.list-ordering {
  counter-reset: item;
  list-style-type: none;
}

.list-ordering li:before {
  content: counter(item, decimal-leading-zero);
  counter-increment: item;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 100ms ease-in-out;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0;
}
</style>
