<template>
  <standard-layout>
    <div class="flex grow items-start justify-center">
      <div class="mt-16 flex w-full max-w-xl flex-col gap-8">
        <h1 class="text-center text-4xl font-bold">Create your group</h1>
        <multistep-form
          v-model:current-step="step"
          :steps="[
            {
              label: 'Name',
              name: 'name',
              title: 'Choose the name',
              subtitle:
                'Use the words that share your values. You will be able to change the name later.',
            },
            {
              label: 'Fandom',
              name: 'fandom',
              title: 'Choose your fandom',
            },
            {
              label: 'Image',
              name: 'image',
              title: 'Select group image',
            },
            {
              label: 'Description',
              name: 'description',
              title: 'Add description',
            },
          ]"
          enable-step-skip
          form-class="max-w-lg"
          @submit="
            form.post('/api/groups', { forceFormData: true }).then((res) => {
              modalHandler.openType('success-dialog', {
                props: {
                  title: 'Congrats, your group has been created!',
                  subtitle:
                    'You can now invite your friends and start sharing your passion.',
                  primary: 'Go to the group',
                },
                events: {
                  onPrimary: (payload, close) => {
                    close()
                    router.visit('/groups/' + res.data.id)
                  },
                },
              })
            })
          "
        >
          <template #name>
            <input-layout
              label="Name"
              layout="col"
              variant="default"
            >
              <form-field-input
                v-model="form.name"
                placeholder="Choose an awesome name"
              />
            </input-layout>
            <input-layout
              label="Privacy type"
              layout="col"
              variant="default"
            >
              <form-field-select-item
                :selected="
                  privacyOptions.find((i) => i.value === form.privacy) ?? null
                "
                :suggestions="privacyOptions"
                key-by="value"
                placeholder="Private"
                search-by="name"
                single-select
                variant="default"
                @update:selected="form.privacy = $event?.value ?? null"
              />
            </input-layout>
          </template>
          <template #fandom>
            <input-layout
              label="Name"
              layout="col"
              variant="default"
            >
              <form-field-select-tag
                v-model:selected="form.tags"
                labels="top"
                type="fandom"
                variant="default"
              />
            </input-layout>
          </template>
          <template #image>
            <form-drop-file-input
              v-model="form.image"
              class="h-40"
              image-aspect-ratio="1"
              image-resize
              image-type
            />
          </template>
          <template #description>
            <input-layout
              hint="Max 245 characters"
              label="Description"
              layout="col"
              variant="default"
            >
              <form-field-input
                v-model="form.description"
                :rules="{
                  max: 245,
                }"
                placeholder="Tell something about your group"
                type="textarea"
              />
            </input-layout>
          </template>
        </multistep-form>
      </div>
    </div>
  </standard-layout>
</template>

<script lang="ts" setup>
import StandardLayout from '@/components/layout/StandardLayout.vue'
import MultistepForm from '@/components/forms/MultistepForm.vue'
import { ref } from 'vue'
import InputLayout from '@/components/layout/InputLayout.vue'
import FormFieldInput from '@/components/input/FormFieldInput.vue'
import useAxiosForm from '@/utils/form'
import FormFieldSelectItem from '@/components/input/FormFieldSelectItem.vue'
import FormFieldSelectTag from '@/components/input/FormFieldSelectTag.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import { useModal } from '@/utils/modal'
import { router } from '@inertiajs/vue3'

const modalHandler = useModal()

const step = ref(0)

const privacyOptions = [
  {
    name: 'Public',
    value: 'public',
  },
  {
    name: 'Private',
    value: 'private',
  },
]

const form = useAxiosForm({
  name: '',
  description: '',
  privacy: 'public',
  tags: [],
  image: null,
})
</script>

<style scoped></style>
