<template>
  <div class="photo-uploader">
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept="image/*"
      @change="handleFileUpload"
    />
    <button
      type="button"
      class="bg-blue-primary text-white px-4 py-2 rounded"
      @click="triggerFileUpload"
    >
      Upload Photo
    </button>
    <div class="flex flex-wrap gap-2 mt-4">
      <div
        v-for="(photo, index) in uploadedPhotos"
        :key="index"
        class="relative"
      >
        <img
          :src="photo"
          class="w-24 h-24 object-cover rounded"
        />
        <button
          type="button"
          class="absolute top-0 right-0 text-white bg-red-600 rounded-full p-1"
          @click="removePhoto(index)"
        >
          <font-awesome-icon icon="close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const emit = defineEmits(['update:modelValue']);




















































defineOptions({
    name: "PhotoUploader"
})




















































const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
});

const fileInput = ref(null);
const uploadedPhotos = ref([...props.modelValue]);

const triggerFileUpload = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileUpload = (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
            uploadedPhotos.value.push(e.target.result);
            emit('update:modelValue', uploadedPhotos.value);
        };
        reader.readAsDataURL(files[i]);
    }
};

const removePhoto = (index) => {
    uploadedPhotos.value.splice(index, 1);
    emit('update:modelValue', uploadedPhotos.value);
};
</script>



<style scoped>
/* Add any necessary styles here */
</style>

<style scoped>

</style>
