<template>
  <div
    class="relative flex w-full min-w-[280px] max-w-[340px] shrink-0 flex-col items-center justify-between gap-5 rounded-lg border-t border-solid border-slate-100 px-4 pb-4 pt-3 shadow-lg"
  >
    <dropdown-button
      v-if="enableActions && (isOwner || featureFlags.GroupMemberBackground)"
      class="!absolute right-2 top-2"
      rounded
      variant="gray"
    >
      <template #button>
        <font-awesome-icon
          class="h-6 w-6"
          icon="ellipsis"
        />
      </template>
      <template #buttons>
        <button
          v-if="featureFlags.EditGroupMemberBackground"
          class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
        >
          Edit background
        </button>

        <!-- admin actions -->
        <template v-if="isOwner">
          <!-- user request pending -->
          <template v-if="isPending">
            <button
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="emit('accept')"
            >
              Accept request
            </button>
            <button
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="emit('reject')"
            >
              Reject request
            </button>
          </template>
          <!-- user blocked -->
          <template v-else-if="isBlocked">
            <button
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="emit('unblock')"
            >
              Unblock user
            </button>
          </template>
          <!-- user joined -->
          <template v-else>
            <button
              v-if="isOwner"
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="emit(member.role === 'member' ? 'promote' : 'demote')"
            >
              {{
                member.role === 'member'
                  ? 'Promote to moderator'
                  : 'Demote to member'
              }}
            </button>
            <button
              class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
              @click="emit('kick')"
            >
              Remove member
            </button>
          </template>
          <button
            v-if="!isBlocked"
            v-tooltip="{
              theme: 'light',
              content:
                (isPending ? 'Reject request ' : 'Remove ') +
                'and block ' +
                (isPrivateGroup
                  ? 'future requests from this user'
                  : 'user from joining the group'),
            }"
            class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
            @click="emit('block')"
          >
            Block user
          </button>
        </template>
      </template>
    </dropdown-button>

    <div class="flex w-full flex-col items-center gap-4 px-4 text-center">
      <div class="relative h-fit w-fit">
        <avatar-image
          :image="member.avatar"
          class="mb-2 flex h-44 w-44 shrink-0 rounded-full"
        />

        <span
          v-if="member.role && member.role !== 'member'"
          class="absolute -bottom-[6px] left-0 right-0 mx-auto w-fit whitespace-nowrap bg-[#1613BD] px-6 py-1 text-xs text-[#e0d666]"
        >
          {{ member.role }}
        </span>
      </div>

      <div class="flex flex-col gap-2">
        <div class="flex w-full flex-col items-center justify-center gap-3">
          <h5
            class="flex flex-col items-center text-center text-2xl font-semibold text-slate-900"
          >
            {{ member.name !== '' ? member.name : '@' + member.username }}
          </h5>
        </div>
        <div class="line-clamp-5 text-base text-slate-800">
          {{ member.bio }}
        </div>
      </div>
    </div>
    <div class="text-sm text-slate-400">
      {{ member.user_tag?.name ?? 'No additional details' }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'

import AvatarImage from '@/components/ui/AvatarImage.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import User from '@/models/User'
import featureFlags from '@/utils/composables/flags'

const emit = defineEmits([
  'promote',
  'demote',
  'block',
  'kick',
  'unblock',
  'accept',
  'reject',
])

const props = defineProps({
  member: {
    type: Object as PropType<User>,
    required: true,
  },
  enableActions: {
    type: Boolean,
    default: false,
  },
  isOwner: {
    type: Boolean,
    default: false,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  isBlocked: {
    type: Boolean,
    default: false,
  },
  isPrivateGroup: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped></style>
