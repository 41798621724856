<template>
  <section
    :class="[
      theme?.backgroundPrimary ? theme.backgroundPrimary : 'bg-white',
      pageType === 'Showcase' ? 'pt-6' : 'pt-20',
    ]"
    class="relative flex w-full flex-col items-center gap-y-4 px-2 pb-20 xs:px-5 sm:px-4 md:px-10 lg:px-14"
  >
    <div class="flex w-full justify-between gap-3">
      <h2
        :class="theme?.textPrimary ? theme.textPrimary : 'text-slate-800'"
        class="text-left font-heading text-[22px]"
      >
        {{ props.section.title ?? 'All Dishes and Drinks' }}
      </h2>
      <v-button
        v-if="edit"
        class="absolute right-10 top-2 z-20 rounded-full bg-violet-100 px-10 py-2 text-sm md:top-4"
        variant="secondary"
        @click="openProductList"
      >
        Edit Products
      </v-button>
    </div>
    <div
      v-if="props.section.products.length"
      class="grid w-full grid-cols-1 gap-1 sm:grid-cols-2 lg:grid-cols-3"
    >
      <item-card
        v-for="item in props.section.products.slice(0, 6)"
        :key="item.id"
        :class="[
          theme?.backgroundSecondary
            ? theme.backgroundSecondary
            : 'bg-transparent',
        ]"
        :event-slug="selectedEvent?.slug"
        :is-menu-item="props.pageType === 'Restaurant'"
        :item="item"
        class="grid-item mx-auto"
      />
    </div>
    <div
      v-else
      :class="[
        theme?.backgroundDark ? theme?.backgroundDark : 'bg-slate-300',
        theme?.textDark ? theme?.textDark : 'text-slate-950',
      ]"
      class="mx-auto rounded-lg p-4"
    >
      No products to show
    </div>

    <span
      v-if="props.section.products.length > 8"
      class="mx-auto font-sans text-base"
    >
      View all
    </span>
  </section>
</template>

<script setup lang="ts">
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'
import VButton from '@/components/buttons/VButton.vue'
import ProductListFormView from '@/components/view/ProductListFormView.vue'
import { useModal } from '@/utils/modal'
import { inject, Ref, ref } from 'vue'
import useConventionList from '@/utils/composables/conventionList'
import axios from 'axios'

const emit = defineEmits(['edit'])
const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  pageType: {
    type: String,
    default: 'Restaurant',
  },
  theme: {
    type: Object,
    required: true,
  },
  vendor: {
    type: Object,
    required: true,
  },
})

const modalHandler = useModal()

const edit = inject('showcase-edit-mode')

const products = ref(props.section.products)
const { selected: selectedEvent } = useConventionList()

function openProductList() {
  axios.get(`/api/partner/vendors/${props.vendor.id}/products`).then((res) => {
    products.value = res.data
  })
  modalHandler.open(ProductListFormView, {
    props: {
      vendor: props.vendor,
      products: products,
    },
  })
}
</script>

<style scoped></style>
