<template>
  <standard-layout>
    <div
      class="mx-auto w-full max-w-screen-md grow flex-col bg-white px-6 pb-6 pt-3"
    >
      <div class="md:my-10">
        <meetup-form
          :form="form"
          class="md:rounded-xl"
        />
        <div
          class="create-footer flex items-center justify-between px-4 py-4 pt-5 text-right sm:px-4 md:px-16"
        >
          <Link
            :disabled="form.processing"
            as="button"
            class="text-blue-primary disabled:opacity-75"
            href="/"
          >
            Cancel
          </Link>

          <button
            :disabled="form.processing"
            class="rounded-lg bg-blue-primary px-12 py-2.5 text-white disabled:opacity-50"
            @click="
              form.post(
                `/api/conventions/${form.convention_event_id}/activities?type=meetup`,
                { forceFormData: true }
              ).then((res)=>{
                router.visit(`/activities/${res.data.id}`)
              })
            "
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </standard-layout>
</template>

<script lang="ts" setup>
import StandardLayout from '@/components/layout/StandardLayout.vue'
import MeetupForm from '@/components/forms/activity/MeetupForm.vue'
import { Link, router } from '@inertiajs/vue3'
import { EventActivity } from '@/models/EventActivity'
import { useModelForm } from '@/utils/form'

const form = useModelForm<EventActivity>(
  EventActivity.make({
    name: '',
    description: '',
  })
)
</script>

<style scoped>
.create-meetup-bg {
  background-color: #0a0584;
  background-image: url('/images/backgrounds/meetup-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
