<template>
  <div
    ref="container"
    class="mx-auto mt-2 flex w-full max-w-screen-2xl flex-col gap-4"
  >
    <slot
      :items="items"
      name="items"
    >
      <section :class="listClass">
        <slot
          v-for="item in items"
          :item="item"
          name="item"
        >
          {{ item.name }}
        </slot>
      </section>
    </slot>
    <div class="flex flex-row items-end justify-center gap-2 py-5">
      <button
        v-if="loading"
        :class="buttonClass"
      >
        Loading...
      </button>
      <button
        v-else-if="!reachedEnd"
        :class="buttonClass"
        @click="emit('update:page', page + 1)"
      >
        Load more
      </button>
      <small
        v-else
        class="text-gray-500"
      >
        No more items
      </small>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'

const emit = defineEmits(['load-next-page', 'update:page'])

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
  listClass: {
    type: String,
    default:
      'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-6 gap-y-12 w-full mx-auto max-w-screen-xl px-2 md:px-4 lg:px-6 xl:px-8',
  },
  buttonClass: {
    type: String,
    default:
      'bg-gray-200 hover:bg-gray-300 px-2.5 py-1 text-base rounded select-none',
  },
  buttonActiveClass: {
    type: String,
    default:
      'bg-blue-primary text-white px-2.5 py-1 text-base rounded select-none',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  reachedEnd: {
    type: Boolean,
    default: false,
  },
})

const container = ref(null)
const scrollContainer = ref<HTMLElement>()

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

function handleScroll(e) {
  if (props.loading) return
  if (props.reachedEnd) return
  if (
    window.innerHeight + window.scrollY >=
    document.body.offsetHeight - 100
  ) {
    console.log('load next page')
    emit('load-next-page')
  }
}
</script>

<style scoped></style>
