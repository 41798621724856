<template>
  <div class="flex flex-row justify-between items-center">
    <label class="mb-2 select-none">{{ label }}</label>
    <div class="flex flex-row gap-6">
      <button
        v-for="(rating, index) in ratings"
        :key="index"
        class="inline-block text-3xl p-2 focus:outline-none"
        :class="selectedRatingClass(rating.value)"
        @click="selectRating(rating.value)"
      >
        <font-awesome-icon
          :icon="rating.icon"
          :style="selectedRatingClass(rating.value)"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const emit = defineEmits(['update:modelValue']);




















































defineOptions({
    name: "RatingInput"
})




















































const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Number,
        default: null,
    },
});

const ratings = [
    { value: 1.0, icon: 'face-smile-beam', selectedColor: '#1613BD'},
    { value: 0.5, icon: 'face-meh', selectedColor: '#DD700C' },
    { value: 0.0, icon: 'face-frown', selectedColor: '#BD1313' },
];

const selectRating = (value) => {
    emit('update:modelValue', value);
};

const selectedRatingClass = (value) => {
    return {
        color: props.modelValue === value ? ratings.find(rating => rating.value === value).selectedColor : 'gray',
    };
};
</script>



<style scoped>

</style>
