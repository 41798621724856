<template>
  <div class="flex min-h-screen flex-col gap-0">
    <slide-modal-cta
      :is-popup-open="flashStatus && !flashDismissed"
      class="text-center"
      @close="flashDismissed = true"
    >
      {{ flashStatus }}
    </slide-modal-cta>

    <div class="bg-gradient-to-br from-indigo-900 to-blue pb-4">
      <!-- Navigation -->
      <nav
        class="relative flex items-center justify-between px-5 py-3 text-sm md:text-base"
      >
        <font-awesome-icon
          class="cursor-pointer text-xl text-white opacity-90 hover:opacity-100"
          icon="fas fa-caret-left"
          @click="goBack"
        />
        <div class="text-center">
          <h1 class="text-white">{{ weekday }}'s Games</h1>
          <template v-if="selectedGameSession && !isEnded">
            <p
              v-if="
                selectedGameSession.max_players <=
                selectedGameSession.users.length
              "
              class="text-white"
            >
              The game is full and cannot accept new players
            </p>
            <button
              v-else
              class="rounded bg-indigo-200 px-4 text-sm text-indigo-800 hover:bg-indigo-300 active:bg-indigo-400"
              @click="share"
            >
              {{ selectedGameSession.share_url }}
            </button>
          </template>
        </div>
        <small
          v-if="copiedPopup"
          class="absolute right-0 top-0 bg-blue-primary px-2 py-3 text-white"
        >
          Copied to clipboard!
        </small>
        <button
          :disabled="
            selectedGameSession?.max_players <=
              selectedGameSession?.users.length || isEnded
          "
          class="user-select-none cursor-pointer text-white disabled:text-gray-500"
          @click="share"
        >
          Share
        </button>
      </nav>
      <!--            <pre>{{ selectedGameSession }}</pre>-->
      <!-- Game stats / switcher -->
      <div class="flex flex-col gap-4">
        <div class="flex flex-row gap-3 overflow-y-scroll px-2 text-sm">
          <div
            v-for="gameSession in latestGameSessions"
            :key="gameSession.id"
            :class="{
              'bg-white': selectedGameSession === gameSession,
            }"
            class="user-select-none flex cursor-pointer items-center justify-between whitespace-nowrap rounded-full bg-indigo-300 px-2 py-0.5 hover:bg-white md:px-2.5 md:py-1.5"
            @click="selectGameSession(gameSession)"
          >
            {{ gameSession.name }}
          </div>
        </div>
        <div
          v-if="!isEnded"
          class="flex w-full flex-col items-center justify-center gap-3 px-2 text-white"
        >
          <div
            v-for="player in getSelectedGameSessionUsers"
            :key="player.id"
            class="flex w-full flex-row items-center justify-between gap-2.5"
          >
            <div class="w-32 text-right text-sm md:text-base">
              {{ player.first_name }}
            </div>
            <div
              class="flex h-3.5 w-full overflow-hidden rounded-xl bg-white bg-opacity-20"
            >
              <div
                :style="'width:' + getUserPointsWidth(player) + '%;'"
                class="flex h-full items-stretch bg-tangerine"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tab switcher -->
    <div class="flex w-full grow flex-col gap-2 bg-white pt-2">
      <div
        :class="{ 'blur-md': !isGameSessionStarted }"
        class="flex w-full flex-row gap-3 overflow-x-auto pl-2 text-base"
      >
        <div
          :class="{
            'border-b-2 border-solid border-blue font-bold text-blue':
              activeTab === 'challenges',
          }"
          class="cursor-pointer whitespace-nowrap px-2 py-1.5"
          @click="setActiveTab('challenges')"
        >
          {{ isEnded ? 'Results' : 'All Challenges' }}
        </div>
        <div
          :class="{
            'border-b-2 border-solid border-blue font-bold text-blue':
              activeTab === 'timeline',
          }"
          class="cursor-pointer whitespace-nowrap px-2 py-1.5"
          @click="setActiveTab('timeline')"
        >
          Photo Timeline
        </div>
        <div
          v-if="getSelectedGameSession"
          :class="{
            'border-b-2 border-solid border-blue font-bold text-blue':
              activeTab === 'chat',
          }"
          class="cursor-pointer whitespace-nowrap px-2 py-1.5"
          @click="setActiveTab('chat')"
        >
          {{ getSelectedGameSession.name }} Chat
          <span v-if="unreadNotificationsCount > 0">
            (+{{ unreadNotificationsCount }})
          </span>
        </div>
      </div>

      <debug-bar>
        <pre>slug: {{ eventActivity.slug }}</pre>
        <pre>ended: {{ isEnded }}</pre>
        <pre>challenges: {{ selectedGameSession.challenge_count }}</pre>
        <pre>{{ selectedGameSession.results }}</pre>
      </debug-bar>

      <div class="relative flex grow flex-col p-2">
        <!-- Not started blur -->
        <template v-if="!isGameSessionStarted">
          <div
            class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center"
          >
            <div class="mt-40 text-center text-2xl font-bold">
              Game starts
              <span
                class="bg-gradient-to-r from-blue to-indigo bg-clip-text text-transparent"
              >
                {{
                  DateTime.fromISO(
                    selectedGameSession?.schedule[0].starts_at
                  ).toRelative()
                }}
              </span>
            </div>
            <small class="mt-2 text-center text-sm font-medium">
              Check back later!
            </small>
          </div>
          <div class="flex flex-col gap-4 blur-md">
            <div
              v-for="challenge in 5"
              :key="challenge"
              class="user-select-none flex w-full cursor-pointer flex-row justify-between overflow-hidden rounded-lg border-2 border-indigo-400 hover:shadow"
            >
              <div class="flex flex-col justify-start gap-2 px-4 py-3">
                <div class="flex flex-col gap-0">
                  <div class="text-base leading-tight">
                    Nothing to see here yet :)
                  </div>
                  <div class="text-xs">
                    I see you're eager to start, but the game hasn't started
                    yet. Come back later!
                  </div>
                </div>
              </div>
              <div
                class="flex shrink-0 flex-col items-stretch justify-center bg-indigo-900 p-4"
              >
                <div
                  class="flex flex-col items-center justify-center gap-2 text-white"
                >
                  <div
                    class="flex h-16 w-16 items-center justify-center overflow-hidden rounded border border-dashed border-indigo-400 bg-indigo-500 bg-opacity-30"
                  >
                    +{{ Math.floor(Math.random() * 10) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- All Challenges Tab -->
        <div
          v-else-if="activeTab === 'challenges' && !isEnded"
          class="flex flex-col gap-4"
        >
          <div
            v-for="challenge in currentUserGameChallenges"
            :key="challenge.id"
            :class="{
              'border-indigo-400': challenge.is_completed_by_user,
              'border-blue': !challenge.is_completed_by_user,
            }"
            class="user-select-none flex w-full cursor-pointer flex-row justify-between overflow-hidden rounded-lg border border-2 hover:shadow"
            @click="selectChallenge(challenge)"
          >
            <div class="flex flex-col justify-start gap-2 px-4 py-3">
              <div class="flex flex-col gap-0">
                <div class="text-base leading-tight">
                  {{ challenge.name }}
                </div>
                <div class="text-xs">
                  {{ challenge.game_name }}
                </div>
              </div>
              <div v-if="challenge.submissions.length > 0">
                <user-avatar-group
                  :max="8"
                  :show-user-counts="false"
                  size="sm"
                  :users="
                    getSelectedGameSessionSubmissionsUsers(
                      challenge.submissions
                    )
                  "
                />
              </div>
            </div>
            <div
              :class="{
                'bg-indigo-900': !challenge.is_completed_by_user,
                'bg-indigo-400': challenge.is_completed_by_user,
              }"
              class="flex shrink-0 flex-col items-stretch justify-center p-4"
            >
              <div
                class="flex flex-col items-center justify-center gap-2 text-white"
              >
                <div v-if="getChallengeSubmissionForCurrentUser(challenge)">
                  <img
                    :src="
                      getChallengeSubmissionForCurrentUser(challenge).image[0]
                        .xs_path
                    "
                    class="flex h-16 w-16 overflow-hidden rounded border border-solid border-indigo-700"
                  />
                </div>
                <div
                  v-else
                  class="flex h-16 w-16 items-center justify-center overflow-hidden rounded border border-dashed border-indigo-400 bg-indigo-500 bg-opacity-30"
                >
                  +{{ challenge.points }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- End game results -->
        <div
          v-else-if="activeTab === 'challenges' && isEnded"
          class="flex grow flex-col gap-6"
        >
          <div class="flex gap-6 text-sm font-bold">
            <div>{{ selectedGameSession.name }}</div>
            <div class="text-gray-600">
              {{ selectedGameSession.users.length }}
              {{ pluralize(selectedGameSession.users.length, 'player') }}
            </div>
          </div>
          <div
            v-if="selectedGameSession.results.length"
            :key="selectedGameSession.id"
            class="flex flex-col gap-4"
          >
            <div
              v-for="(result, index) in selectedGameSession.results"
              :key="result.id"
              class="flex items-center gap-2"
            >
              <div class="w-6 text-center">
                <font-awesome-icon
                  v-if="index === 0"
                  class="text-yellow-400"
                  icon="trophy"
                />
                <div
                  v-else
                  class="text-sm text-gray-600"
                >
                  {{ index + 1 }}
                </div>
              </div>
              <div class="flex gap-2">
                <image-component
                  :src="result.user?.avatar?.path"
                  class="h-10 w-10 rounded-full"
                />
                <div class="flex flex-col justify-between">
                  <h1 class="text-sm">
                    {{ result.user.first_name }}
                  </h1>
                  <div class="text-xs text-gray-400">
                    Challenges: {{ result.submissions_count }}/{{
                      selectedGameSession.challenge_count
                    }}
                  </div>
                </div>
              </div>
              <div class="grow" />
              <div
                :class="[`bg-[${resultLevels[index]}]`]"
                class="flex h-10 w-10 items-center justify-center rounded-full"
              >
                {{ result.points }}
              </div>
            </div>
          </div>
          <div
            v-else
            :class="{ 'dark-souls-background': gameOverPhraseIndex === 0 }"
            class="flex grow flex-col items-center justify-center gap-3"
          >
            <h1
              :class="[
                gameOverPhraseIndex === 0 ? 'dark-souls-text' : 'text-2xl',
              ]"
            >
              {{ gameOverPhrases[gameOverPhraseIndex] }}
            </h1>
            <p :class="{ 'text-white': gameOverPhraseIndex === 0 }">
              No one has completed any challenges.
            </p>
          </div>
        </div>

        <!-- Photo Timeline Tab -->
        <div
          v-else-if="activeTab === 'timeline'"
          class="flex h-full flex-col items-stretch gap-10"
        >
          <div
            v-for="date in Object.keys(photoTimeline)"
            :key="date"
            class="flex w-full flex-row gap-4"
          >
            <div class="text-slate-400">
              <!--{{ subtractHoursFromTime(date) }}-->
              <!-- we dont need to convert time, if we pass dates in convention's timezone -->
              {{ date }}
            </div>
            <div
              class="grid w-full grid-cols-2 gap-3 gap-y-6 md:grid-cols-3 lg:grid-cols-4"
            >
              <div
                v-for="submission in photoTimeline[date]"
                :key="submission.id"
                class="flex w-full flex-col items-center justify-center gap-1"
              >
                <div
                  :style="
                    'background-image: url(' + submission.md_image_path + ')'
                  "
                  class="aspect-square h-full w-full overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat"
                />
                <div class="flex text-sm text-slate-400">
                  {{ submission.user.first_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Chat Tab -->
        <app-chat
          v-else-if="activeTab === 'chat'"
          :api-url="`/api/game/session/${selectedGameSession.slug}/messages`"
          :channel="`game-chat.${selectedGameSession.id}`"
          class="grow"
          full-page
          reverse-messages
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, useAttrs } from 'vue'
import UserAvatarGroup from '../../../components/ui/UserAvatarGroup.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { router, usePage } from '@inertiajs/vue3'
import { DateTime } from 'luxon'
import SlideModalCta from '@/components/deprecated/SlideModalCta.vue'
import DebugBar from '@/components/ui/DebugBar.vue'
import { pluralize } from '../../../utils/string'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import AppChat from '@/components/widgets/AppChat.vue'
defineOptions({
  name: 'ActiveUserGameSessions',
})











const props = defineProps({
  eventActivity: {
    type: Object,
    required: true,
  },
  selectedGameSessionId: {
    type: Number,
    required: false,
    default: null,
  },
  gameSessionsWithUserScores: {
    type: Array,
    required: true,
  },
  currentUserGameChallenges: {
    type: Array,
    required: true,
  },
  shareUrl: {
    type: String,
    required: true,
  },
  notificationsCount: {
    type: Number,
    required: true,
  },
})

const unreadNotificationsCount = ref(+props.notificationsCount)

const attrs = useAttrs()
const currentUser = computed(() => {
  return attrs.auth?.user ?? null
})

const weekday = computed(() =>
  new Date().toLocaleString('default', { weekday: 'short' })
)

const activeTab = ref('challenges')

function setActiveTab(tab) {
  if (tab === 'chat') {
    unreadNotificationsCount.value = 0
  }
  activeTab.value = tab
}

const latestGameSessions = ref([])
const selectedGameSession = ref(null)

const getSelectedGameSessionUsers = computed(() => {
  if (selectedGameSession.value) {
    return selectedGameSession.value.users
  } else {
    return []
  }
})

function selectGameSession(gameSession) {
  gameOverPhraseIndex.value = Math.floor(Math.random() * gameOverPhrases.length)
  selectedGameSession.value = gameSession
}

const getSelectedGameSession = computed(() => {
  return latestGameSessions.value.find(
    (gameSession) => gameSession.id === selectedGameSession.value.id
  )
})

// const getSelectedGameSessionUsers = computed( () => {
//     return getSelectedGameSession.value?.users;
// });

// get array of users from array of submissions.user
const getSelectedGameSessionSubmissionsUsers = (submissions) => {
  // return this as an array of users
  // filter through submission.users to return only those where matches selectedGameSession.value.users

  return submissions
    .filter((submission) =>
      selectedGameSession.value?.users
        .map((u) => u.id)
        .includes(submission.user.id)
    )
    .map((submission) => submission.user)
}

const getChallengeSubmissionForCurrentUser = (challenge) => {
  return challenge.submissions.find(
    (submission) => submission.user.id === currentUser.value.id
  )
}

const selectChallenge = (challenge) => {
  router.visit('/game-challenge/' + challenge.id)
}

function subtractHoursFromTime(timeString) {
  // Create a new Date object with the given time string
  const time = new Date(`1970-01-01T${timeString}`)

  // Subtract 7 hours from the time
  time.setHours(time.getHours() - 7)

  // Format the updated time as a string
  const updatedTime = time.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  })

  // Return the updated time string
  return updatedTime
}

const getUserPointsWidth = (user) => {
  const userScore =
    user.game_wide_challenge_points + user.session_challenge_points
  const totalPoints = selectedGameSession.value?.total_possible_score
  const width = (userScore / totalPoints) * 100
  const barWidth = width > 100 ? 100 : width
  console.log(
    'userScore',
    userScore,
    'totalPoints',
    totalPoints,
    'width',
    width,
    'barWidth',
    barWidth
  )
  return barWidth
}

onMounted(() => {
  if (props.gameSessionsWithUserScores.length > 0) {
    if (props.selectedGameSessionId) {
      selectedGameSession.value = props.gameSessionsWithUserScores.find(
        (gameSession) => gameSession.id === props.selectedGameSessionId
      )
    } else {
      selectedGameSession.value = props.gameSessionsWithUserScores[0]
    }
    latestGameSessions.value = props.gameSessionsWithUserScores
  }
  callPhotoTimelineApi()
})

// get photoTimeline via GET /api/event/{conventionEvent}/game/{game}/timeline/{date}
const photoTimeline = ref([])
const callPhotoTimelineApi = async () => {
  const response = await fetch(
    '/api/event-activity/' + props.eventActivity.slug + '/photo-timeline'
  )
  const data = await response.json()
  photoTimeline.value = data
}

// Handle back button click
const goBack = () => {
  window.history.back()
}

const isGameSessionStarted = computed(() => {
  return (
    DateTime.fromISO(selectedGameSession.value?.schedule[0].starts_at) <
    DateTime.now()
  )
})

const copiedPopup = ref(false)
// Handle share button click
const share = () => {
  const url = selectedGameSession.value.share_url

  console.log(url)

  if (navigator.share) {
    console.log('navigator.share')
    navigator
      .share({
        title: 'Game Session',
        text: 'Join my game session',
        url: url,
      })
      .then(() => {
        console.log('Successful share')
      })
      .catch((error) => {
        console.error('Error sharing:', error)
      })
  } else if (navigator.clipboard) {
    console.log('navigator.clipboard')
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log('URL copied to clipboard')
        copiedPopup.value = true
        setTimeout(() => {
          copiedPopup.value = false
        }, 2000)
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error)
      })
  } else {
    console.log('Share not supported')
  }
}

const page = usePage()
const flashDismissed = ref(false)
const flashStatus = computed(() => {
  if (page.props.flash.status) {
    return page.props.flash.status
  }
  return null
})

const resultLevels = [
  '#FFB240',
  '#FFB240',
  '#FFC266',
  '#FFD699',
  '#FFD699',
  '#FFE5BF',
  '#FFF2DE',
  '#FFF2DE',
]

const isEnded = computed(() => {
  return (
    DateTime.fromISO(selectedGameSession.value?.schedule[0].ends_at) <
    DateTime.now()
  )
})

const gameOverPhrases = [
  'You Lost', // leave this at top to use dark souls animation
  'Game Over, man! Game Over!',
  'You were killed by Herobrine',
  'Snake? Snake?! Snaaaake!',
  'Your journey ends here.',
  'Wasted',
  'The cake is a lie.',
  'Your princess is in another castle!',
  'You have been slain.',
  'Fatality!',
]

const gameOverPhraseIndex = ref(
  Math.floor(Math.random() * gameOverPhrases.length)
)
</script>

<style scoped>
.dark-souls-background {
  background: black;
  opacity: 1;
  animation: fade-in 2.5s linear;
}

.dark-souls-text {
  color: #ff0000;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 5px;
  font-size: 3em;
  font-weight: 400;
  animation:
    fade-in 10s linear,
    text-zoom 5s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-zoom {
  0% {
    font-size: 2em;
  }
  100% {
    font-size: 3em;
  }
}
</style>
