<template>
  <slide-up-popup
    :is-popup-open="isOpen"
    title="Write Review"
    @close-popup="emit('close')"
  >
    <div class="my-6 flex flex-col gap-8">
      <search-with-results
        v-model="vendorQuery"
        v-model:selected="selectedVendor"
        :results="vendors"
        label="Vendor"
        @search="searchVendor"
      >
        <template #item="{ item }">
          <div class="flex items-center gap-1">
            <image-component
              class="h-8 w-8 shrink-0"
              :src="item.image_url"
            />
            <span
              v-html="highlightMatches(item.name, vendorQuery)"
            />
          </div>
        </template>
        <template #selected="{ item }">
          <div class="flex items-center gap-2">
            <image-component
              class="h-32 w-32 shrink-0"
              :src="item.image_url"
            />
            <div>
              <b>{{ item.name }}</b>
              <p class="text-sm">
                {{ item.description }}
              </p>
            </div>
          </div>
        </template>
      </search-with-results>

      <search-with-results
        v-model="productQuery"
        v-model:selected="selectedProduct"
        :results="products"
        label="Product"
        @search="searchProduct"
      >
        <template #item="{ item }">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-1">
              <image-component
                class="h-8 w-8"
                :src="item.image_url"
              />
              <span
                v-html="highlightMatches(item.name, productQuery)"
              />
            </div>
            <small class="flex-1 whitespace-nowrap text-end text-sm">
              {{ item.price ?? 'N/A' }}
              {{ item.currency }}
            </small>
          </div>
        </template>
        <template #selected="{ item }">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <image-component
                class="h-32 w-32 shrink-0"
                :src="item.image_url"
              />
              <div>
                <b>{{ item.name }}</b>
                <p class="text-sm">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <small class="flex-1 whitespace-nowrap text-end text-sm">
              {{ item.price ?? 'N/A' }}
              {{ item.currency }}
            </small>
          </div>
        </template>
      </search-with-results>

      <div class="flex flex-col">
        <label class="text-sm">Rate</label>
        <div class="flex w-full max-w-[280px] flex-col gap-4 text-xl">
          <rating-input
            v-model="form.taste_rating"
            label="Taste"
          />
          <rating-input
            v-model="form.value_rating"
            label="Value"
          />
          <rating-input
            v-model="form.service_rating"
            label="Service"
          />
        </div>
        <validation-error
          v-if="
            form.errors['taste_rating'] ||
              form.errors['value_rating'] ||
              form.errors['service_rating']
          "
        >
          You must rate all 3 categories.
        </validation-error>
      </div>
      <form-image-collection
        v-if="!photosDisabled"
        v-model="form.images"
        label="Photos (Optional)"
        class="h-32"
      />

      <form-input
        v-if="!textDisabled"
        v-model="form.text"
        label="Review"
        type="textarea"
        placeholder="Write a review..."
        :rows="5"
      />

      <v-button
        variant="primary"
        class="text-lg"
        @click="emit('submit', form)"
      >
        Submit Review
      </v-button>

      <debug-bar>
        <pre>{{ form }}</pre>
      </debug-bar>
    </div>
  </slide-up-popup>
</template>

<script setup>
import { ref, computed, reactive, watch, onMounted } from 'vue'
import SlideUpPopup from '../deprecated/SlideUpPopup.vue'
import RatingInput from '../input/RatingInput.vue'
import FormInput from '../deprecated/FormInput.vue'
import DebugBar from '../ui/DebugBar.vue'
import FormImageCollection from '../input/FormImageCollection.vue'
import VButton from '../buttons/VButton.vue'
import { router, useForm } from '@inertiajs/vue3'
import ValidationError from '../ui/ValidationError.vue'
import SearchWithResults from '../input/SearchWithResults.vue'
import axios from 'axios'
import ImageComponent from '../ui/ImageComponent.vue'

const emit = defineEmits(['close', 'submit'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  photosDisabled: {
    type: Boolean,
    default: false,
  },
  textDisabled: {
    type: Boolean,
    default: false,
  },
  vendorProductId: {
    type: Number,
    required: true,
  },
})

const form = useForm({
  taste_rating: null,
  value_rating: null,
  service_rating: null,
  text: null,
  images: [],
  vendor_product_id: null,
})

onMounted(() => {
  if (props.vendorProductId) {
    // if we have a vendor product id, we can load the vendor and product
    axios
      .get('/api/autocomplete/products', {
        params: {
          id: props.vendorProductId,
        },
      })
      .then((response) => {
        selectedProduct.value = response.data[0]
      })
  } else {
    // otherwise, load first products and vendors
    searchVendor()
    searchProduct()
  }
})

function sortResults(a, b, query) {
  const regex = new RegExp(`\\b${query}`, 'i')

  const aMatch = a.name.match(regex)
  const bMatch = b.name.match(regex)

  if (aMatch && bMatch) {
    return a.name.localeCompare(b.name)
  }

  // If the first element has a match, it goes first
  if (aMatch) {
    return -1
  }

  // If the second element has a match, it goes first
  if (bMatch) {
    return 1
  }

  const aContains = a.name.toLowerCase().includes(query)
  const bContains = b.name.toLowerCase().includes(query)

  // If both elements match, sort them alphabetically
  if (aContains && bContains) {
    return a.name.localeCompare(b.name)
  }

  if (aContains) {
    return -1
  }

  if (bContains) {
    return 1
  }

  // If there are no matches, sort in alphabetical order
  return a.name.localeCompare(b.name)
}

const vendors = ref([])
const selectedVendor = ref(null)
const vendorQuery = ref('')

function searchVendor() {
  axios
    .get('/api/autocomplete/vendors', {
      params: {
        query: vendorQuery.value,
      },
    })
    .then((response) => {
      const query = vendorQuery.value.toLowerCase()
      vendors.value = response.data.sort((a, b) => sortResults(a, b, query))
    })
}

const products = ref([])
const selectedProduct = ref(null)
const productQuery = ref('')

watch(selectedVendor, (vendor) => {
  searchProduct()
})

watch(selectedProduct, (product) => {
  if (product) {
    if (
      !selectedVendor.value ||
      selectedVendor.value.id !== product.vendor.id
    ) {
      selectedVendor.value = product.vendor
    }
  }
})

function searchProduct() {
  axios
    .get('/api/autocomplete/products', {
      params: {
        query: productQuery.value,
        vendor: selectedVendor.value?.id ?? null,
      },
    })
    .then((response) => {
      const query = productQuery.value.toLowerCase()
      products.value = response.data.sort((a, b) => sortResults(a, b, query))
    })
}

function highlightMatches(text, query) {
  const regex = new RegExp(`(${query})`, 'ig')
  return text.replace(regex, '<strong>$1</strong>')
}
</script>

<style scoped></style>