<template>
  <auth-layout :background-image="backgroundImage">
    <div class="flex w-full border-b border-gray-200 pb-3 text-center">
      <h2 class="text-xl">Reset password</h2>
    </div>
    <div class="my-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <label
          class="text-sm"
          for="email"
        >
          Email
        </label>
        <input
          id="email"
          v-model="form.email"
          class="rounded bg-gray-200 px-3 py-2"
          type="email"
        />
        <div v-if="form.errors.email">
          {{ form.errors.email }}
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <template v-if="status">
        <div class="flex w-full gap-1 text-sm text-green-500">
          {{ status }}
        </div>
        <div class="flex w-full gap-1 text-sm text-gray-400">
          Didn't receive an email? (Check your spam folder)
        </div>
      </template>
      <button
        class="rounded-full bg-blue-primary px-16 py-2.5 text-white"
        type="submit"
        @click.prevent="form.post('/password/email')"
      >
        {{ status ? 'Resend email' : 'Reset' }}
      </button>
      <div class="flex w-full gap-1 text-sm text-gray-400">
        Don't have an account?
        <Link
          class="font-bold text-blue-primary"
          href="/register"
        >
          Sign up now
        </Link>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>
import { Link, useForm } from '@inertiajs/vue3'
import AuthLayout from '../../../components/layout/AuthLayout.vue'

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    default: undefined,
  },
})

const form = useForm({
  email: '',
})
</script>

<style scoped>
form {
  width: 70%;
  min-width: 300px;
}

form > div {
  display: flex;
  flex-direction: column;
}
</style>
