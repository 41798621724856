<template>
  <div class="flex w-full max-w-[320px] flex-col items-center rounded-lg">
    <div
      class="h-fit w-full rounded-t-lg"
      :class="props.type !== 'Add on' ? 'p-6' : 'p-3'"
      :style="
        image
          ? `background: rgba(0, 0, 0, 0.5) url(${props.image}); background-blend-mode: darken; background-repeat: no-repeat; background-size: cover;`
          : 'background: linear-gradient(93deg, #591CDB 0%, #201CDB 82.29%, #100DA8 100%);'
      "
    >
      <time
        v-if="props.type !== 'Add on'"
        :datetime="props.date.fullDate"
        class="flex flex-col items-center gap-2 font-heading text-base text-slate-100"
      >
        <span class="text-3xl">{{ props.date.weekDay }}</span>
        {{ props.date.fullDate }}
      </time>
      <h2
        v-else
        class="text-center font-heading text-lg font-normal text-slate-100"
      >
        Autograph Add -On
      </h2>
    </div>

    <div class="flex w-full flex-col gap-6 rounded-b-lg bg-slate-100 p-6">
      <div class="flex w-full flex-col items-center gap-5">
        <h3
          class="flex items-center gap-1 font-heading text-3xl font-normal text-blue-primary"
        >
          $ {{ props.price }}
          <span
            v-if="props.type === 'Add on'"
            class="text-base text-indigo-400"
          >
            /per day
          </span>
        </h3>
        <ul
          class="flex flex-col gap-2 font-heading text-base font-normal text-dark-grey"
        >
          <span
            v-if="props.type !== 'Add on'"
            :class="
              props.type === 'Bundles' ? 'text-indigo-400' : 'text-paragraph'
            "
          >
            Included:
          </span>
          <p
            v-else
            class="mb-2 text-center text-paragraph"
          >
            This fan-driven spectacle is the place to see and be seen for
            cosplayers.
          </p>
          <li
            v-for="(string, index) in props.list.slice(0, 4)"
            :key="index"
          >
            • {{ string }}
          </li>
          <li
            v-if="props.list.length > 4"
            class="text-blue-neon"
          >
            •
            <span class="cursor-pointer select-none underline">See More</span>
          </li>
        </ul>
      </div>
      <div
        v-if="props.type !== 'Add on'"
        class="flex w-full flex-col gap-4 font-heading text-base"
      >
        <button
          class="w-full select-none rounded-full bg-ultra-light-blue py-2 text-blue-primary"
        >
          Buy Ticket
        </button>
        <button class="select-none text-light-grey-100">
          Learn More
        </button>
      </div>
      <button
        v-else
        class="w-full select-none rounded-full bg-ultra-light-blue py-2 text-blue-primary"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'ticket',
  },
  image: {
    type: String,
    default: null,
  },
  date: {
    type: Object,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  list: {
    type: Array,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
})
</script>

<style lang="scss" scoped></style>
