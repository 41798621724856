const featureFlags = {
  GroupNotifications: false,
  GroupMemberBackground: false,
  GroupMemberPrerequisites: false,
  LinkToBrowsePage: false,
  UserPreferences: false,
  Sponsors: false,
  Volunteers: false,
  HomeNews: false,
  HomeRaffles: false,
  HomeTickets: false,
  HomeKeyDates: false,
  HomeLocations: false,
  AddToFavorite: false,
}

export default featureFlags