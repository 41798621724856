<template>
  <div
    ref="mapRef"
    class="map-container cursor-pointer hover:shadow-lg"
  >
    <!-- Loop through coordinates and display images as markers -->

    <div
      v-for="(coordinate, index) in coordinates"
      :key="index"
      class="absolute z-[1000] -translate-x-1/2 -translate-y-1/2 transform"
      :class="`left-[${coordinate.x}] top-[${coordinate.y}]`"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import 'ol/ol.css'
import { Feature, Map, View } from 'ol'
import { fromLonLat, toLonLat } from 'ol/proj'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, Vector as VectorSource } from 'ol/source'
import { Style, Circle, Fill, Stroke, Icon } from 'ol/style'
import { Point } from 'ol/geom'

const props = defineProps({
  selectedPoint: { type: Object, default: () => ({}) },
  coordinates: { type: Array, default: () => [] },
  disableControls: { type: Boolean, default: false },
})
const mapRef = ref(null)
let map
let markerSource

onMounted(async () => {
  const initialCenter =
    props.coordinates.length > 0
      ? fromLonLat([
          props.coordinates[0].longitude,
          props.coordinates[0].latitude,
        ])
      : fromLonLat([0, 0]) // Fallback to (0,0) if no coordinates provided

  map = new Map({
    target: mapRef.value,
    layers: [new TileLayer({ source: new OSM() })],
    view: new View({
      zoom: 16,
      center: initialCenter,
      constrainResolution: true,
    }),
  })

  if (props.disableControls) {
    map.getControls().forEach((control) => {
      map.removeControl(control)
    })

    map.getInteractions().forEach((interaction) => {
      interaction.setActive(false)
    })
  }

  markerSource = new VectorSource()
  const markerLayer = new VectorLayer({
    source: markerSource,
  })
  map.addLayer(markerLayer)

  props.coordinates.forEach((coordinate) => {
    addMarker(coordinate)
  })

  // Await for all markers to be added
  await Promise.all(
    props.coordinates.map((coordinate) => addMarker(coordinate))
  )

  fitMapToMarkers()

  map.on('click', function (event) {
    map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
      const clickedCoordinate = feature.getGeometry().getCoordinates()
      const [longitude, latitude] = toLonLat(clickedCoordinate)
      console.log(latitude, longitude)
      // todo: fix the openMap using
      // openMap(latitude, longitude)
    })
  })
})

const fitMapToMarkers = () => {
  const extent = markerSource.getExtent()
  if (!isEmpty(extent)) {
    map
      .getView()
      .fit(extent, { padding: [20, 20, 20, 20], constrainResolution: false })
  }
}

const isEmpty = (extent) =>
  extent[0] === Infinity &&
  extent[1] === Infinity &&
  extent[2] === -Infinity &&
  extent[3] === -Infinity

const centerOnMarker = (coordinate) => {
  if (coordinate.longitude && coordinate.latitude) {
    const newCenter = fromLonLat([coordinate.longitude, coordinate.latitude])
    map.getView().animate({
      center: newCenter,
      zoom: 18,
      duration: 250,
    })
  }
}

watch(
  [() => props.latitude, () => props.longitude],
  ([newLat, newLon], [oldLat, oldLon]) => {
    if (
      newLat && newLon &&
      typeof newLat === 'number' &&
      typeof newLon === 'number' &&
      (newLat !== oldLat || newLon !== oldLon)
    ) {
      const newCenter = fromLonLat([newLon, newLat])
      map.getView().setCenter(newCenter)
    }
  }
)

watch(
  () => props.coordinates,
  async (newCoordinates) => {
    markerSource.clear()

    // Await for all markers to be added
    await Promise.all(newCoordinates.map((coordinate) => addMarker(coordinate)))

    // Now fit map to markers
    fitMapToMarkers()
  }
)

const addMarker = (coordinate) => {
  const newMarker = new Feature({
    geometry: new Point(
      fromLonLat([coordinate.longitude, coordinate.latitude])
    ),
  })

  if (coordinate.image) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const img = new Image()
    img.src = coordinate.image
    img.onload = () => {
      canvas.width = 50
      canvas.height = 50

      context.beginPath()
      context.arc(25, 25, 25, 0, Math.PI * 2, true)
      context.closePath()
      context.clip()

      context.drawImage(img, 0, 0, 50, 50)

      const iconStyle = new Style({
        image: new Icon({
          img: canvas,
          imgSize: [50, 50],
        }),
      })

      newMarker.setStyle(iconStyle)
    }
  } else {
    const circleStyle = new Style({
      image: new Circle({
        radius: 25,
        fill: new Fill({ color: 'blue' }),
        stroke: new Stroke({ color: 'white', width: 1 }),
      }),
    })
    newMarker.setStyle(circleStyle)
  }
  markerSource.addFeature(newMarker)
}

watch(
  () => props.selectedPoint,
  (newPoint, oldPoint) => {
    if (newPoint && newPoint !== oldPoint) {
      centerOnMarker(newPoint)
    }
  },
  { deep: true }
)
</script>

<style scoped>
.map-container {
  @apply overflow-hidden border;
}

.map-container div canvas {
  @apply rounded-lg;
}
</style>
