<template>
  <div
    v-if="props.titleCard.length == 0"
    :class="isHovered ? 'items-center justify-center' : 'justify-between gap-5'"
    class="group/guest-card relative flex h-[356px] w-full min-w-[280px] max-w-[300px] flex-col items-center overflow-hidden rounded-lg border-t border-solid border-slate-100 px-4 pb-4 pt-3 shadow-lg"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-if="!loading"
      :style="`background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%), url(${guest.avatar?.path || '/images/branding/fankind_placeholder.png'}) lightgray 50% / cover no-repeat`"
      class="absolute inset-0 opacity-0 duration-100 group-hover/guest-card:opacity-100"
    ></div>
    <div
      v-if="!loading"
      class="absolute inset-0 flex translate-y-full flex-col items-center justify-center gap-4 p-4 text-center duration-100 group-hover/guest-card:translate-y-0"
    >
      <h5
        :style="{
          color: palette.primary ?? '#ffffff',
        }"
        class="mb-2 flex flex-col items-center text-center text-2xl font-semibold text-slate-900"
      >
        {{ guest.name !== '' ? guest.name : '@' + guest.username }}
        <span
          v-if="guest?.location?.length"
          class="flex items-center gap-1 text-base font-bold text-slate-400"
        >
          <pin-icon-fill class="h-5 w-5 shrink-0 text-slate-400" />
          {{ guest.location }}
        </span>
      </h5>
      <div class="mb-4 line-clamp-5 text-base text-white mx-2">
        {{ guest.bio }}
      </div>
      <a
        v-if="getGuestLink(guest) !== null"
        :href="getGuestLink(guest)"
        :style="{
          color: palette.primary ?? '#ffffff',
        }"
        class="font-sans text-base font-bold hover:text-blue-primary"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn more
      </a>
    </div>
    <div
      v-if="props.backgroundImage == true"
      :style="dynamicStyle"
      :class="{
        'group-hover/guest-card:opacity-0': !loading,
      }"
      class="absolute left-0 top-0 h-36 w-full rounded-t-lg opacity-50 duration-100"
    />

    <div
      :class="{
        'group-hover/guest-card:-translate-y-16 group-hover/guest-card:opacity-0':
          !loading,
      }"
      class="flex w-full translate-y-0 flex-col items-center gap-4 text-center duration-100"
    >
      <div class="relative h-fit w-fit">
        <avatar-image
          :force-loading="loading"
          :image="loading ? null : guest.avatar"
          :square="guest.type === 'group'"
          class="mb-2 flex h-60 w-60 shrink-0 rounded-full"
        />
        <span
          v-if="guest.caption && !loading"
          :style="{
            color: invert(palette.primary ?? '#1613BD', true),
            backgroundColor: palette.primary ?? '#1613BD',
          }"
          class="absolute -bottom-[6px] left-0 right-0 mx-auto w-fit whitespace-nowrap rounded px-6 py-1 text-xs"
        >
          {{ guest.caption }}
        </span>
      </div>

      <div class="flex w-full flex-col gap-2">
        <div class="flex w-full flex-col items-center justify-center gap-3">
          <div
            v-if="loading"
            class="skeleton w-full"
          />
          <div
            v-else
            class="flex w-full flex-col items-start text-center text-2xl font-semibold"
          >
            <h5
              :style="{
                color: palette.primary ?? '#000000',
              }"
              class="mx-auto max-w-full overflow-hidden whitespace-nowrap"
            >
              {{ guest.name !== '' ? guest.name : '@' + guest.username }}
            </h5>
            <span
              v-if="guest?.location?.length"
              class="mx-auto flex items-center gap-1 text-base font-bold text-slate-400"
            >
              <pin-icon-fill class="h-5 w-5 shrink-0 text-slate-400" />
              {{ guest.location }}
            </span>
          </div>
        </div>
        <div
          v-if="guest?.profession_tags?.length && !loading"
          class="no-scrollbar mx-auto flex max-w-[332px] items-center gap-2 overflow-x-auto"
        >
          <span
            v-for="(tag, tagID) in guest.profession_tags"
            :key="tagID"
            class="flex gap-2 whitespace-nowrap text-sm font-normal text-black"
          >
            {{ tag.name }}
            <template v-if="tagID != guest.profession_tags.length - 1">
              <div class="my-auto h-1 w-1 shrink-0 rounded-full bg-black" />
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import PinIconFill from '@/icons/PinIconFill.vue'

import AvatarImage from '@/components/ui/AvatarImage.vue'
import SpecialGuest from '@/models/SpecialGuest'
import DropdownButton from '@/components/buttons/DropdownButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import invert from 'invert-color'

const props = defineProps({
  titleCard: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  event: {
    type: Object,
    default: () => undefined,
  },
  guest: {
    type: Object as PropType<SpecialGuest>,
    required: true,
  },
  palette: {
    type: Object,
    default: () => ({}),
  },
  backgroundImage: {
    type: Boolean,
    default: false,
  },
})
const isHovered = ref(false)

const getGuestLink = (guest) => {
  if (!guest.links) {
    return null
  }
  if (guest.links?.length === 0) {
    return null
  }
  return guest.links[0].url
}

function getSortedProfessionTags(tags) {
  // if null return empty array
  if (!tags) {
    return []
  }
  return tags
    .filter((tag) => tag.type === 'profession')
    .sort((a, b) => a.name.localeCompare(b.name))
}

const getTags = computed(() => {
  const tags = props.guest.tags
  // if null return empty array
  if (!tags) {
    return []
  }
  if (Array.isArray(tags)) {
    // Check if the array contains strings or objects
    if (tags.length > 0 && typeof tags[0] === 'string') {
      return tags // Return as-is because it's an array of strings
    } else {
      // Assuming that if they're not strings, they're objects with a 'name' property
      return tags.map((tag) => tag.name) // Extract the 'name' from each object in the array
    }
  } else {
    // Handle the case where tags is not an array
    console.error('tags must be an array') // or handle this case appropriately
    return []
  }
})

function hexToRgba(hex, alpha) {
  let r = 0,
    g = 0,
    b = 0

  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const dynamicStyle = computed(() => {
  const primaryColor = props.palette.primary
    ? hexToRgba(props.palette.primary, 0.75)
    : hexToRgba('#1613BD', 0.75)
  const secondaryColor = props.palette.highlight
    ? hexToRgba(props.palette.highlight, 0.75)
    : hexToRgba('#e0d666', 0.75)

  return `
    background: 
      linear-gradient(180deg, rgba(255, 255, 255, 0) 46.5%, #FFFFFF 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), 
      linear-gradient(180deg, ${primaryColor} 0%, ${secondaryColor} 100%), 
      url('/images/backgrounds/guest-card-bg.jpeg') center/cover no-repeat;
  `
})
</script>

<style lang="scss" scoped></style>
