<template>
  <auth-layout :background-image="backgroundImage">
    <div class="flex w-full justify-center border-b border-gray-200 pb-3">
      <h2 class="text-xl">
        Sign up
      </h2>
    </div>
    <div class="my-6 flex flex-col gap-4">
      <div class="flex w-full flex-row gap-6">
        <div class="flex w-full min-w-0 flex-col">
          <label for="firstName">First Name</label>
          <input
            id="firstName"
            v-model="form.firstName"
            autocomplete="given-name"
            class="rounded bg-gray-200 px-3 py-2"
            type="text"
          />
          <div
            v-if="form.errors.firstName"
            class="text-sm text-red-500"
          >
            {{ form.errors.firstName }}
          </div>
        </div>
        <div class="flex w-full min-w-0 flex-col">
          <label for="lastName">Last Name</label>
          <input
            id="lastName"
            v-model="form.lastName"
            autocomplete="family-name"
            class="rounded bg-gray-200 px-3 py-2"
            type="text"
          />
          <div
            v-if="form.errors.lastName"
            class="text-sm text-red-500"
          >
            {{ form.errors.lastName }}
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <label for="email">Email</label>
        <input
          id="email"
          v-model="form.email"
          autocomplete="email"
          class="rounded bg-gray-200 px-3 py-2"
          type="email"
        />
        <div
          v-if="form.errors.email"
          class="text-sm text-red-500"
        >
          {{ form.errors.email }}
        </div>
      </div>
      <div class="flex flex-col">
        <label for="password">Password</label>
        <input
          id="password"
          v-model="form.password"
          autocomplete="new-password"
          class="rounded bg-gray-200 px-3 py-2"
          type="password"
        />
        <div
          v-if="form.errors.password"
          class="text-sm text-red-500"
        >
          {{ form.errors.password }}
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <label class="flex items-center">
          <input
            v-model="form.agreeToTerms"
            class="form-checkbox border-gray-300 bg-gray-200 text-blue-primary"
            type="checkbox"
          />
          <span class="ml-2 text-sm">
            I agree to the
            <a
              class="text-blue-primary"
              href="/legal/terms"
              target="_blank"
            >
              Terms of Use
            </a>
            and
            <a
              class="text-blue-primary"
              href="/legal/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </span>
        </label>
        <div
          v-if="form.errors.agreeToTerms"
          class="text-sm text-red-500"
        >
          {{ form.errors.agreeToTerms }}
        </div>
        <label class="flex items-center">
          <input
            v-model="form.sendEmails"
            class="form-checkbox border-gray-300 bg-gray-200 text-blue-primary"
            type="checkbox"
          />
          <span class="ml-2 text-sm">
            Send the fewest emails to keep me in the know.
          </span>
        </label>
      </div>
    </div>
    <div class="flex w-full flex-col gap-3">
      <button
        class="rounded-full bg-blue-primary px-16 py-2.5 text-white"
        type="submit"
        @click.prevent="form.post('/register')"
      >
        Get Started
      </button>
      <div class="flex w-full justify-center gap-1 text-sm text-gray-400">
        Already signed up?
        <Link
          class="font-bold text-blue-primary"
          href="/login"
        >
          Login here
        </Link>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>
import { Link, useForm } from '@inertiajs/vue3'
import AuthLayout from '../../../components/layout/AuthLayout.vue'

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
})

const form = useForm({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  agreeToTerms: false,
  sendEmails: true,
})
</script>

<style scoped></style>