<template>
  <event-browse-layout>
    <template #sub-stick-header>
      <div
        class="mx-auto flex w-full flex-row justify-between gap-4 bg-white px-4 py-2.5 md:justify-center"
      >
        <dropdown
          :options="getFoodCategories"
          class="block w-52 md:hidden"
          @selected="handledSelected"
        />
        <div class="hidden flex-row flex-nowrap justify-center gap-4 md:flex">
          <div
            v-for="(category, categoryIndex) in getFoodCategories"
            :key="categoryIndex"
            :class="{
              'bg-gray-200': categoryIndex !== selectedCategory,
              'bg-blue-primary text-white': categoryIndex === selectedCategory,
              'cursor-pointer': categoryIndex !== selectedCategory,
              'hover:bg-gray-300': categoryIndex !== selectedCategory,
            }"
            class="z-20 col-span-2 select-none rounded p-2.5 text-center text-base font-bold md:text-lg"
            @click="changeCategory(categoryIndex)"
          >
            {{ category }}
          </div>
        </div>
        <div
          class="flex cursor-pointer select-none items-center justify-center rounded bg-slate-100 p-4 hover:bg-slate-200"
        >
          <font-awesome-icon
            class=""
            icon="search"
          />
        </div>
        <div
          :class="{
            ' bg-blue-primary text-white hover:bg-black ': areFiltersSet,
            ' bg-slate-100 hover:bg-slate-200 ': !areFiltersSet,
          }"
          class="flex cursor-pointer select-none items-center justify-center rounded p-4"
          @click="openFilterPopup"
        >
          <font-awesome-icon icon="filter" />
        </div>
      </div>
    </template>

    <template #browse-page>
      <slide-up-popup
        :is-popup-open="popupFilm != null"
        title="Showtimes"
        @close-popup="popupFilm = null"
      >
        <activity-slots :activity="popupFilm" />
      </slide-up-popup>
      <div class="mx-auto mt-2 flex w-full max-w-screen-lg flex-col gap-4 px-2">
        <section
          class="mx-auto grid w-full max-w-screen-md grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 2xl:grid-cols-3"
        >
          <div
            v-for="(film, index) in films"
            :key="index"
            class="flex w-full cursor-pointer flex-row rounded-lg hover:shadow-lg"
          >
            <div
              :style="'background-image: url(' + film.poster.path + ')'"
              class="flex aspect-poster w-1/3 flex-shrink-0 items-end justify-start self-start overflow-hidden rounded bg-cover bg-center"
            />
            <div class="flex w-full flex-col justify-between self-stretch p-2">
              <div class="flex w-full flex-col gap-1">
                <div class="flex flex-row gap-2">
                  <div
                    v-for="(highlight, ih) in film.highlights"
                    :key="ih"
                    class="inline-block self-start rounded-full bg-tangerine px-2 text-xs text-white"
                  >
                    {{ highlight.name }}
                  </div>
                </div>
                <h2
                  class="whitespace-pre-line text-base font-medium uppercase md:text-base"
                >
                  {{ film.name }}
                </h2>
                <div class="flex flex-row justify-start gap-2 text-sm">
                  <div
                    class="rounded bg-slate-400 px-2 text-2xs font-bold text-white"
                  >
                    {{ film.age_rating }}
                  </div>
                  <div class="text-slate-400">
                    {{ formattedTime(film.length) }}
                  </div>
                  <div class="text-slate-400">See details</div>
                </div>
                <div class="text-sm text-slate-700">
                  {{ film.short_description }}
                </div>
              </div>
              <button
                class="self-start rounded-full bg-black px-4 py-1 text-sm text-white"
                @click="openShowtimes(film)"
              >
                {{ film.slots.length }} Showtimes
              </button>
            </div>
          </div>
        </section>
      </div>
    </template>
  </event-browse-layout>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import EventBrowseLayout from '../../../components/layout/EventBrowseLayout.vue'
import { calculateDistance } from '../../../plugins/CalculateDistance.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Dropdown from '../../../components/input/Dropdown.vue'
import SlideUpPopup from '../../../components/deprecated/SlideUpPopup.vue'
import ActivitySlots from '../../../components/ui/ActivitySlots.vue'
defineOptions({
  name: 'BrowseWatch',
})




















































const props = defineProps({
  films: {
    type: Array,
    required: true,
  },
})

const state = reactive({
  latitude: null,
  longitude: null,
  error: null,
})

const popupFilm = ref(null)

function openShowtimes(film) {
  popupFilm.value = film
}

const formattedTime = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`
  } else {
    return `${remainingMinutes}m`
  }
}

const getDistance = (latitude, longitude) => {
  return calculateDistance(state.latitude, state.longitude, latitude, longitude)
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    )
  })
}

const selectedCategory = ref(0)

const handledSelected = (index) => {
  changeCategory(index)
}

const changeCategory = (index) => {
  selectedCategory.value = index
}

const getFoodCategories = computed(() => {
  return ['Fri, Mar 24', 'Sat, Mar 25', 'Sun, Mar 26']
})

// Filters
const showFilterPopup = ref(false)
const openFilterPopup = () => {
  showFilterPopup.value = true
}

const closeFilterPopup = () => {
  showFilterPopup.value = false
}

const areFiltersSet = computed(() => {
  return false
})

onMounted(async () => {
  try {
    const { coords } = await getLocation()
    state.latitude = coords.latitude
    state.longitude = coords.longitude
  } catch (error) {
    state.error = error.message
  }
})

const ratingBackgroundHexColors = [
  '#4a190f',
  '#873306',
  '#983307',
  '#a93408',
  '#b93509',
  '#c9360a',
  '#d9370b',
  '#e9370c',
  '#f9380d',
  '#ff390e',
]
</script>


<style scoped></style>
