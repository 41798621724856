<template>
  <user-settings-layout>
    <div class="flex flex-col gap-3 rounded border px-3 py-2 shadow">
      <h1 class="text-xl font-normal">
        General Settings
      </h1>
      <div class="grid grid-cols-2 gap-5">
        <input-field
          v-model="form.first_name"
          :error="form.errors.first_name"
          label="First Name"
          variant="gray"
        />
        <input-field
          v-model="form.last_name"
          :error="form.errors.last_name"
          label="Last Name"
          variant="gray"
        />
      </div>
      <input-field
        v-model="form.username"
        :error="form.errors.username"
        label="Username"
        variant="gray"
      >
        <template #before-inner>
          @
        </template>
      </input-field>
      <input-field
        v-model="form.description"
        :error="form.errors.description"
        label="Bio"
        type="textarea"
        variant="gray"
      />
      <div class="flex justify-end">
        <button
          :disabled="!form.isDirty"
          class="rounded bg-blue-primary px-4 py-2 font-bold text-white hover:brightness-90 disabled:bg-blue-700"
          @click="
            form.put(`/users/${props.user.id}`, {
              onSuccess: ()=>auth.refresh()
            })
          "
        >
          Save
        </button>
      </div>
    </div>
  </user-settings-layout>
</template>

<script setup>
import UserSettingsLayout from '@/components/layout/UserSettingsLayout.vue'
import InputField from '@/components/input/InputField.vue'
import { useForm } from '@inertiajs/vue3'
import useAuth from '@/utils/composables/auth'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const auth = useAuth()

const form = useForm({
  first_name: props.user.first_name,
  last_name: props.user.last_name,
  username: props.user.username,
  description: props.user.description,
})
</script>

<style scoped></style>
