<template>
  <div
    ref="wrapper"
    class="z-10 flex min-h-screen w-full flex-col items-center px-3"
    style="
      background: linear-gradient(
        180deg,
        #000000 0%,
        #0f0e3e 31.25%,
        #181761 55.73%,
        #0e0644 74.48%,
        #000000 98.44%
      );
    "
  >
    <div
      class="mt-10 flex w-full max-w-screen-md flex-col gap-6 px-4 md:mt-12 md:gap-8"
    >
      <div class="flex w-full flex-col gap-4">
        <fankind-logo class="h-4 text-white" />
        <div class="mx-auto w-1/2 rounded-full">
          <div class="h-1 w-full bg-slate-600">
            <div
              :style="{ width: progressWidth }"
              class="progress-indicator h-1 bg-white"
            />
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col items-center justify-center text-center">
        <div
          v-if="stage.avatars !== undefined"
          :style="'background-image: url(' + selectedAvatar + ')'"
          class="bg-color mx-auto mb-4 h-28 w-28 flex-shrink-0 rounded-full border border-white bg-cover bg-center md:h-32 md:w-32"
        />
        {{ step }}
        <h2 class="mb-4 w-full text-xl font-medium text-white md:text-3xl">
          <template v-if="step === 0">
            <span style="color: #797fff">Choose</span>
            your avatar
          </template>
          <template v-else-if="step === 1">
            How would you
            <span style="color: #797fff">describe</span>
            yourself?
          </template>
          <template v-else-if="step === 2">
            What
            <span style="color: #797fff">activities</span>
            are you interested in?
          </template>
          <template v-else-if="step === 3">
            Welcome to the
            <span style="color: #797fff">Fankind</span>
            community!
            <br />
            Let's maximize your con experience.
          </template>
        </h2>
      </div>
    </div>

    <div
      class="mt-2 flex w-full max-w-screen-md overflow-auto md:mt-10 md:px-4"
    >
      <div
        v-if="stage.avatars !== undefined"
        :style="`max-height: ${maxHeight}`"
        class="scrollable-container scrollbar-hide mx-auto grid w-full max-w-lg grid-cols-3 justify-items-center gap-y-8 overflow-y-auto pb-32 md:gap-y-16 md:pb-48"
      >
        <div class="relative h-20 w-20">
          <div
            class="flex h-16 w-16 cursor-pointer flex-col items-center justify-center rounded-full border border-white bg-blue-neon bg-opacity-50 bg-cover bg-center text-slate-200 opacity-50 hover:opacity-100 md:h-20 md:w-20"
            @click="fileInput.click()"
          >
            <font-awesome-icon
              class="text-lg"
              icon="plus"
            />
            <div class="text-2xs text-slate-200 md:text-xs">Add yours</div>
          </div>
          <input
            ref="fileInput"
            accept="image/jpeg, image/png, image/webp, image/gif"
            class="hidden"
            name="image"
            type="file"
            @change="handleFileUpload"
          />
          <div
            v-if="selectedAvatar === ''"
            class="absolute inset-0 flex items-center justify-center"
          >
            <svg
              class="h-6 w-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
        <div
          v-for="(avatar, index) in avatarOptions"
          :key="index"
          :class="{
            'opacity-50': selectedAvatar !== avatar,
            selected: selectedAvatar === avatar,
          }"
          :style="'background-image: url(' + avatar + ')'"
          class="bg-color h-16 w-16 cursor-pointer rounded-full border border-white bg-cover bg-center hover:opacity-75 md:h-20 md:w-20"
          @click="selectAvatar(avatar)"
        />
      </div>

      <div
        v-if="stage.tags !== undefined"
        :style="`max-height: ${maxHeight}`"
        class="scrollable-container mx-auto grid w-full max-w-lg justify-items-center gap-y-2 overflow-y-auto pb-0 md:pb-48"
      >
        <div
          v-for="tag in stage.tags"
          :key="tag.id"
          :class="{ 'opacity-50': !tag.selected }"
          class="flex w-full cursor-pointer flex-row items-center rounded-md p-2"
          @click="tag.selected = !tag.selected"
        >
          <img
            :alt="tag.name"
            :src="tag.imageUrl"
            class="mr-4 h-14 w-14 rounded md:h-24 md:w-24"
          />
          <div class="font-semibold text-white md:text-lg">
            {{ tag.name }}
          </div>
          <div class="ml-auto">
            <font-awesome-icon
              v-if="tag.selected"
              icon="circle-check"
              class="text-white"
            />
            <font-awesome-icon
              v-else
              :icon="['far', 'circle']"
              class="text-white"
            />
          </div>
        </div>
        <div
          class="py-6 pb-28 text-center text-base text-white md:py-16 md:pb-32 md:text-lg"
        >
          <p class="text-lg md:text-xl">Want to add more?</p>
          <p class="opacity-60">
            We have hundreds more to choose from in your profile.
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="stage.type === 'final'"
      class="mt-10 flex w-full flex-col justify-center gap-10 md:mt-20"
    >
      <div
        class="flex w-full flex-col justify-center gap-6 text-center font-semibold text-white md:flex-row md:gap-10"
      >
        <a
          class="rounded-lg bg-slate-400 bg-opacity-25 p-10 hover:bg-blue-neon hover:bg-opacity-75"
          href="meetups/create"
        >
          Create Fan Meetup
        </a>
        <a
          :href="`/event/${currentConvention.slug}/home`"
          class="rounded-lg bg-slate-400 bg-opacity-25 p-10 hover:bg-blue-neon hover:bg-opacity-75"
        >
          <!-- TODO: this is a hack -->
          See
          {{ currentConvention.name.split(' ').slice(-2).join(' ') }} Schedule
        </a>
      </div>
    </div>
  </div>
  <div
    class="fixed z-fixed bottom-0 w-full pb-8 text-center md:pb-12"
    style="
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      );
    "
  >
    <button
      v-if="stage.type !== 'final'"
      class="inline-block rounded-full bg-white px-16 py-3 font-bold text-black"
      @click="nextStage"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script setup>
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { router, usePage } from '@inertiajs/vue3'
import FankindLogo from '../../icons/FankindLogo.vue'

const props = defineProps({
  onboardingData: {
    type: Object,
    required: true,
  },
  step: {
    type: Number,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
})

const page = usePage()
const currentConvention = computed(() => page.props.shared.currentConvention)

const wrapper = ref(null)

const windowSize = reactive({ height: window.innerHeight })

const updateWindowSize = () => {
  windowSize.height = window.innerHeight
}

const avatarOptions = ref(null)

const selectAvatar = (avatar) => {
  if (selectedAvatar.value !== avatar) {
    selectedAvatar.value = avatar
    selectedFile.value = null
  }
}

const fileInput = ref(null)
const selectedFile = ref(null)
const selectedAvatar = ref(avatarOptions.value ? avatarOptions.value[0] : '')
const hasSelectedAvatar = computed(() => selectedAvatar.value !== '')

onBeforeMount(() => {
  if (props.onboardingData.type === 'avatar') {
    avatarOptions.value = props.onboardingData.avatars
    selectedAvatar.value = avatarOptions.value[0] // path & url
  }
})

onMounted(() => {
  window.addEventListener('resize', updateWindowSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowSize)
})

const topHeight = computed(() => {
  return `${windowSize.height * 0.2}px`
})

const maxHeight = computed(() => {
  let height = topHeight.value
  if (wrapper.value) {
    const firstScrollableContainer = wrapper.value.querySelector(
      '.scrollable-container'
    )
    if (firstScrollableContainer) {
      height = firstScrollableContainer.getBoundingClientRect().top
    }
  }
  return `${windowSize.height - height}px`
})

const stageIndex = ref(0)

const stage = computed(() => {
  return props.onboardingData
})
computed(() => {
  return stageIndex.value <= 2
})
const handleFileUpload = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    selectedFile.value = file
    selectedAvatar.value = e.target.result
  }

  console.log(selectedAvatar.value, selectedFile.value)

  reader.readAsDataURL(file)
}

const selectedTags = computed(() => {
  if (!stage.value.tags) return []
  return stage.value.tags.filter((tag) => tag.selected).map((tag) => tag.id)
})

const progressWidth = computed(() => {
  const currentStage = +props.step + 1
  const totalStages = +props.totalSteps + 1
  return `${(currentStage / totalStages) * 100}%`
})

const buttonText = computed(() => {
  if (props.step === props.totalSteps) {
    return 'Set Schedule'
  } else if (props.step === props.totalSteps - 1) {
    return 'Finish'
  } else {
    return 'Next'
  }
})

function nextStage() {
  if (props.step < props.totalSteps) {
    const firstScrollableContainer = wrapper.value.querySelector(
      '.scrollable-container'
    )
    if (firstScrollableContainer) {
      firstScrollableContainer.scrollTo({ top: 0, behavior: 'smooth' })
    }

    if (stage.value.avatars && !hasSelectedAvatar.value) {
      alert('Please select an avatar')
      return
    }

    try {
      if (props.step === 0) {
        // Upload avatar
        const formData = new FormData()
        if (selectedFile.value)
          formData.append('avatar_file', selectedFile.value)
        else formData.append('avatar_filename', selectedAvatar.value)
        formData.append('step', props.step)
        router.post('/onboarding', formData, {
          preserveState: true,
          preserveScroll: true,
        })
      } else {
        router.post('/onboarding', {
          step: props.step,
          tags: selectedTags.value,
        })
      }
    } catch (error) {
      console.error(error)
    }
  } else {
    // Redirect to the schedule page or perform the desired action
    console.log('no')
  }
}
</script>
