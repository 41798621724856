<template>
  <Link
    as="button"
    :href="linkToItem"
    class="h-full w-full"
  >
    <div
      class="relative h-full w-full flex-col rounded-lg px-2 pb-4 pt-2 hover:shadow"
    >
      <span
        v-if="props.item?.is_exclusive"
        class="absolute -left-2 top-3 z-20 w-fit rotate-[-30deg] bg-[#D82525] px-3 text-sm text-white"
      >
        Exclusive
      </span>
      <background-image-component
        :class="imageClass"
        :fallback-src="getPlaceholderBackgroundImage()"
        :image="item.thumbnail"
        class="flex w-auto items-end overflow-hidden rounded-[6px] bg-slate-100 bg-cover bg-center"
      >
        <div
          v-if="props.isMenuItem"
          class="flex h-fit w-full items-center bg-gradient-to-t from-black/30 to-black/0 pt-6 text-white"
        >
          <div
            v-if="props.item.percentile > 0"
            class="flex flex-row items-center gap-1 bg-slate-900 px-2 py-1 text-xs"
          >
            {{ getPercentileLanguage }}
          </div>
          <div
            v-if="getOverallRating > 0"
            :style="'background-color: ' + getRatingBackgroundColor + ';'"
            class="flex flex-row items-center gap-1 rounded-tr-lg px-2 py-1"
          >
            <div class="text-xs">
              {{ getOverallRating }}
            </div>
            <font-awesome-icon
              class="text-xs"
              icon="star"
            />
          </div>
        </div>
      </background-image-component>
      <div class="flex flex-col flex-wrap justify-between pt-4">
        <h3 class="text-start font-sans text-base font-normal text-dark-grey">
          {{ props.item.name }}
        </h3>

        <div
          :class="isMenuItem ? 'justify-between' : 'gap-2'"
          class="w-ful flex items-center"
        >
          <strong class="font-sans text-xl text-paragraph">
            ${{ item.price }}
          </strong>
          <p
            v-if="showQuantity && !props.isMenuItem"
            class="font-sans text-sm font-normal text-blue-primary"
          >
            only
            <strong>{{ item.quantity }}</strong>
            available
          </p>
        </div>
        <div class="mt-2 flex w-full items-center justify-between gap-2">
          <p
            v-if="item?.vendor_location"
            class="ml-0 mr-auto flex items-center gap-1 font-sans text-sm font-normal text-slate-400"
          >
            <pin-icon-fill />
            {{ item.vendor_location }}
          </p>

          <p
            v-if="
              props.isMenuItem &&
              item?.latitude &&
              item?.longitude &&
              location?.latitude &&
              location?.longitude
            "
            class="font-sans text-sm font-normal text-slate-400"
          >
            {{ getDistance(item?.latitude, item?.longitude) }}
            10 meters away
          </p>
        </div>
      </div>
    </div>
  </Link>
</template>

<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { computed, PropType, ref } from 'vue'
import { useUserLocation } from '@/utils/composables/location.js'
import { calculateDistance } from '../../../plugins/CalculateDistance'

import PinIconFill from '@/icons/PinIconFill.vue'
import VendorProduct from '@/models/VendorProduct'
import BackgroundImageComponent from '../../../components/ui/BackgroundImageComponent.vue'

const props = defineProps({
  isMenuItem: {
    type: Boolean,
    default: true,
  },
  item: {
    type: Object as PropType<VendorProduct>,
    required: true,
  },
  eventSlug: {
    type: String,
    required: true,
  },
  imageClass: {
    type: String,
    default: 'aspect-square',
  },
})

const location = useUserLocation()

const getDistance = (vendorLatitude, vendorLongitude) => {
  return calculateDistance(
    location.value.latitude,
    location.value.longitude,
    vendorLatitude,
    vendorLongitude
  )
}

const getBackgroundImage = (item) => {
  if (!item.images) {
    return getPlaceholderBackgroundImage()
  }
  // check if item.images[0] exists
  if (item.images[0]) {
    return item.images[0]?.path
  }
  return getPlaceholderBackgroundImage()
}

const getPlaceholderBackgroundImage = () => {
  return '/images/placeholder_product.jpg'
}

const getOverallRating = computed(() => {
  // round to one decimal place
  return Math.round(props.item.overall_rating * 10) / 10
})

const getPercentileLanguage = computed(() => {
  let percentile = props.item.percentile
  if (percentile === 1) {
    percentile = 0.99
  }

  return 'Top ' + Math.ceil(((1 - percentile) * 100) / 5) * 5 + '%'
})

const showQuantity = computed(() => {
  return props.item.quantity > 0
})

const linkToItem = computed(() => {
  if (props.isMenuItem) {
    return '/events/' + props.eventSlug + '/eat/' + props.item.id
  } else {
    return '/events/' + props.eventSlug + '/shop/' + props.item.id
  }
})

const getRatingBackgroundColor = computed(() => {
  const index = Math.round((props.item.overall_rating / 5) * 9)
  return ratingBackgroundHexColors[index]
})

const ratingBackgroundHexColors = [
  '#4a190f',
  '#873306',
  '#983307',
  '#a93408',
  '#b93509',
  '#c9360a',
  '#d9370b',
  '#e9370c',
  '#f9380d',
  '#ff390e',
]
</script>

<style scoped></style>
