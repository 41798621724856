<template>
  <auth-layout :background-image="backgroundImage">
    <div class="flex w-full border-b border-gray-200 pb-3 text-center">
      <h2 class="text-xl">
        Login
      </h2>
    </div>
    <div class="my-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <label
          class="text-sm text-neutral-300"
          for="email"
        >Email</label>
        <input
          id="email"
          v-model="form.email"
          class="rounded bg-slate-200 px-3 py-2"
          :class="{
            'border border-red-600': form.errors.password || form.errors.email,
          }"
          autocomplete="email"
          type="email"
        />
      </div>
      <div class="flex flex-col">
        <label
          class="text-sm text-neutral-300"
          for="password"
        >Password</label>
        <input
          id="password"
          v-model="form.password"
          class="rounded bg-slate-200 px-3 py-2"
          :class="{
            'border border-red-600': form.errors.password || form.errors.email,
          }"
          autocomplete="current-password"
          type="password"
        />
        <div
          v-if="form.errors.password"
          class="font-sans text-xs font-normal text-red-700"
        >
          {{ form.errors.password }}
        </div>
      </div>
      <small>
        <Link
          href="/password/reset"
          class="font-heading text-sm font-normal text-blue-neon"
        >
          Forgot password?
        </Link>
      </small>
      <div
        v-if="form.errors.email"
        class="text-center font-sans text-xs font-normal text-red-700"
      >
        {{ form.errors.email }}
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <button
        type="submit"
        class="rounded-full bg-blue-primary px-16 py-2.5 text-white"
        @click.prevent="form.post('/login')"
      >
        Login
      </button>
      <div class="flex w-full gap-1 text-sm text-gray-400">
        Don't have an account?
        <Link
          href="register"
          class="font-bold text-blue-primary"
        >
          Sign up now
        </Link>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'
import FankindLogo from '../../../icons/FankindLogo.vue'
import { Link } from '@inertiajs/vue3'
import AuthLayout from '../../../components/layout/AuthLayout.vue'

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
})

const form = useForm({
  email: '',
  password: '',
})
</script>

<style scoped>
form {
  width: 70%;
  min-width: 300px;
}

form > div {
  display: flex;
  flex-direction: column;
}
</style>
