<template>
  <!-- small dialog with information -->
  <slide-modal
    class="w-full md:max-w-[400px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    :is-popup-open="isPopupOpen"
    direction="custom"
    @close="emit('close')"
  >
    <div class="flex flex-col h-full rounded-lg overflow-hidden p-2 bg-white">
      <!--            <div class="flex justify-end">-->
      <!--                <font-awesome-icon icon="xmark" class="cursor-pointer" @click="emit('close')"/>-->
      <!--            </div>-->
      <slot />
      <div class="flex justify-center">
        <button
          class="mt-4 px-4 py-2 bg-gray-200 rounded-lg"
          @click="emit('close')"
        >
          Dismiss
        </button>
      </div>
    </div>
  </slide-modal>
</template>

<script setup>
import SlideModal from "@/components/deprecated/SlideModal.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const emit = defineEmits(["close"])

const props = defineProps({
    isPopupOpen: {
        type: Boolean,
        default: false,
    },
})
</script>

<style scoped>

</style>
