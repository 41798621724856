<template>
  <background-image-component
    :image="event.banner"
    class="relative z-[21] flex w-full flex-col items-start bg-cover bg-center bg-no-repeat p-14"
    fallback-src=""
  >
    <div
      :style="`
        background: linear-gradient(
            98deg,
            ${hexToRgba(selectedEvent?.palette?.primary, 0.25) || 'rgba(0, 0, 0, 0.25)'} ${event?.banner == null ? '100%' : '2.51%'},
            rgba(30, 0, 94, 0.19) 44.97%,
            rgba(0, 0, 0, 0) 80.42%
          ),
          linear-gradient(
            98deg,
            #000 2.51%,
            rgba(0, 0, 0, 0.75) 44.97%,
            rgba(0, 0, 0, 0)
            ${event?.banner == null ? '100%' : '80.42%'}
          );
      `"
      class="absolute inset-0 z-10 h-full w-full"
    />

    <div
      class="relative z-20 flex max-w-[90%] grow flex-col text-white sm:max-w-[480px]"
    >
      <h4 class="text-left font-heading text-base text-white opacity-50">
        {{ event.dates }}
      </h4>
      <div>
        <image-component
          v-if="false"
          :image="event.logo"
          alt="logo"
          class="my-2 h-40 w-full max-w-[460px] object-contain"
        />
        <h1 class="my-2 text-left text-4xl font-bold sm:text-5xl">
          {{ event.name }}
        </h1>
        <h4 class="text-left font-heading text-base text-white opacity-50">
          {{ getLocationAndFounding }}
        </h4>
      </div>
      <p
        class="mt-8 text-left font-sans text-sm font-bold leading-[140%] md:text-[22px]"
      >
        {{ event.description }}
      </p>
    </div>
    <div class="relative z-20 mt-10 flex w-fit flex-col items-center gap-x-20">
      <div
        v-if="leftTime?.length"
        class="flex w-fit flex-row gap-x-2"
      >
        <div
          v-for="(item, index) in leftTime"
          :key="index"
          class="flex w-fit flex-row items-center gap-x-2"
        >
          <div
            class="flex w-14 flex-col items-center rounded-lg px-2.5 py-2 font-heading text-2xl text-white"
            style="background: rgba(255, 255, 255, 0.15)"
          >
            {{ item.value }}
            <span
              class="font-heading text-xs uppercase"
              style="color: rgba(255, 255, 255, 0.66)"
            >
              {{ item.unit }}
            </span>
          </div>
          <div
            v-if="index !== leftTime.length - 1"
            class="flex flex-col gap-[5px]"
          >
            <svg
              fill="none"
              height="5"
              viewBox="0 0 4 5"
              width="4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="2"
                cy="2.5"
                fill="white"
                fill-opacity="0.33"
                r="2"
              />
            </svg>
            <svg
              fill="none"
              height="5"
              viewBox="0 0 4 5"
              width="4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="2"
                cy="2.5"
                fill="white"
                fill-opacity="0.33"
                r="2"
              />
            </svg>
          </div>
        </div>
      </div>
      <button
        v-if="ticketButtonEnabled"
        :style="{
          backgroundColor: selectedEvent?.palette?.primary
            ? selectedEvent?.palette?.primary
            : '#1613BD',
        }"
        class="mt-4 w-full cursor-pointer select-none rounded-lg px-8 py-4 font-heading text-lg text-white duration-100 hover:!bg-blue-primary"
        @click="getTickets"
      >
        Get Tickets
      </button>
    </div>
  </background-image-component>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'

import useConventionList from '@/utils/composables/conventionList'
import ConventionEvent from '@/models/ConventionEvent'
import { DateTime } from 'luxon'

const props = defineProps({
  event: {
    type: ConventionEvent,
    required: true,
  },
})

let leftTime = ref<{ unit: string; value: number }[]>([])

function updateCountdownData() {
  if (!props.event) return
  const eventStartDate = DateTime.fromISO(props.event.startsAt)
  const currentDate = DateTime.now()
  let timeDifference = eventStartDate.diff(
    currentDate,
    'milliseconds'
  ).milliseconds

  // Clear leftTime if the event is in the past
  if (timeDifference < 0) {
    leftTime.value = []
    return
  }

  // Calculation remains the same
  const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  const totalHours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const totalMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  )

  let weeks = Math.floor(totalDays / 7)
  let days = totalDays % 7 // Remaining days after full weeks
  let hours = totalHours // Hours are calculated the same, total hours within the last day
  let minutes = weeks > 0 ? 0 : totalMinutes // If there are weeks, don't show minutes

  const timeUnits = [
    { value: weeks, unit: 'week' },
    { value: days, unit: 'day' },
    { value: hours, unit: 'hour' },
    { value: minutes, unit: 'minute' },
  ]

  leftTime.value = timeUnits
    .filter((item) => item.value > 0) // Only include time units with a value greater than 0
    .map((item) => ({
      ...item,
      unit: item.value === 1 ? item.unit : item.unit + 's', // Pluralize units where necessary
    }))
}

const getLocationAndFounding = computed(() => {
  const location = `${props.event.location?.address?.city} ${props.event.location?.address?.state} ${props.event.location?.address?.country}`
  if (props.event.founding_year) {
    return `${location} • Since ${props.event.founding_year}`
  }
  return location
})

const { selected: selectedEvent } = useConventionList()

const conventionTickets = {
  'ala-2024': {
    link: 'https://animelosangeles.org/registration/attendee-registration/',
  },
}

const ticketButtonEnabled = computed(() => {
  return (
    document.getElementById('Tickets') ||
    (selectedEvent.value && conventionTickets[selectedEvent.value.slug])
  )
})

function getTickets() {
  let element = document.getElementById('Tickets')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  } else {
    if (selectedEvent.value && conventionTickets[selectedEvent.value.slug]) {
      const config = conventionTickets[selectedEvent.value.slug]
      if (config.link) {
        window.open(config.link, '_blank')
      }
    }
  }
}

let updateInterval: number
onMounted(() => {
  updateCountdownData()
  // update setInterval to 1 minute
  updateInterval = setInterval(updateCountdownData, 1000 * 60)
})

onUnmounted(() => {
  clearInterval(updateInterval)
})

function hexToRgba(hex, alpha) {
  let r = 0,
    g = 0,
    b = 0

  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
</script>

<style scoped></style>
