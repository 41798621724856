<template>
  <div
    class="flex items-start gap-5 pb-4"
    @mouseenter="showLearnMore = true"
    @mouseleave="showLearnMore = false"
  >
    <img
      loading="lazy"
      class="h-[180px] min-w-[180px] rounded object-cover"
      :src="props.image"
      alt="news image"
    />
    <div class="flex flex-col">
      <time
        class="mb-2 font-sans text-xs font-normal text-light-grey-100 lg:text-sm"
      >
        {{ props.whenPublished }}
      </time>
      <h4 class="font-heading text-base text-dark-grey lg:text-[20px]">
        {{ props.title }}
      </h4>
      <p class="mt-1 font-sans text-xs font-normal text-paragraph lg:text-sm">
        {{ props.description }}
      </p>

      <div
        class="mt-6 flex cursor-pointer select-none items-center gap-2 font-heading text-sm text-blue-muted"
        :class="showLearnMore ? 'opacity-100' : 'opacity-0'"
      >
        Read more
        <chevron-right />
      </div>
    </div>
  </div>
</template>

<script setup>
import ChevronRight from '@/icons/ChevronRight.vue'

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  whenPublished: {
    type: String,
    required: true,
  },
  showLearnMore: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped></style>
