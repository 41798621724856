<template>
  <div
    v-if="show"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="max-w-lg space-y-4 rounded-lg bg-white px-16 py-10 text-center">
      <h2 class="text-3xl font-semibold">Your meetup is ready!</h2>
      <p class="text-lg">
        <span class="text-blue-primary">Get five RSVP's</span>
        to appear on the official
        <b>{{ currentConvention.name }}</b>
        schedule
      </p>
      <div class="pt-3">
        <button
          class="rounded-full bg-blue-primary px-14 py-2 text-white"
          @click="close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3'
import useConventionList from '@/utils/composables/conventionList.js'

const emit = defineEmits(['update:show'])

defineOptions({
  name: 'SuccessPopup',
})

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  onClose: {
    type: Function,
    required: true,
  },
})

const page = usePage()
const { selected: currentConvention } = useConventionList()

const close = () => {
  props.onClose()
}
</script>

<style scoped></style>
