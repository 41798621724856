<template>
  <Link
    :class="props.cardStyle"
    :href="'/events/' + eventSlug + '/shop/' + productItem.id"
    as="button"
  >
    <div class="mx-auto flex w-full max-w-72 items-center">
      <div :class="props.wrappStyle">
        <span
          v-if="productItem.is_exclusive"
          class="absolute left-1 top-4 z-[2000] w-fit rotate-[-20deg] bg-[#D82525] px-3 text-sm text-white"
        >
          Exclusive
        </span>
        <background-image-component
          :class="props.imgStyle"
          :image="
            productItem.thumbnail ??
            productItem.images?.[0]
          "
          fallback-src="/images/placeholder_product.jpg"
        />
        <div
          :class="
            props.infoInColumn
              ? 'px-4 pt-6 md:flex-col md:px-3 lg:pt-3'
              : 'px-2 pt-2 md:flex-row'
          "
          class="flex w-full flex-col flex-wrap justify-between"
        >
          <div class="flex w-full justify-between">
            <h3
              :class="props.infoInColumn ? '' : 'max-w-[190px] truncate'"
              class="text-start text-lg font-bold md:text-base"
            >
              {{ productItem.name }}
            </h3>

            <span
              v-if="!props.infoInColumn"
              class="text-lg text-dark-grey md:text-base"
            >
              ${{ productItem.price }}
            </span>
          </div>
          <p
            v-if="props.infoInColumn"
            class="break-all pt-2 text-start font-sans text-sm font-normal text-paragraph md:text-sm"
          >
            {{ productItem.description }}
          </p>
        </div>

        <div
          :class="
            props.infoInColumn
              ? 'flex-row items-start px-3'
              : 'flex-row items-center px-2 pt-1'
          "
          class="flex w-full justify-between text-sm text-light-grey-100"
        >
          <span
            v-if="props.infoInColumn"
            class="text-lg text-dark-grey md:text-base"
          >
            ${{ productItem.price }}
          </span>
          <div
            :class="
              props.infoInColumn
                ? 'w-fit flex-col items-center sm:flex-col sm:items-start'
                : 'w-full'
            "
            class="flex flex-wrap justify-between"
          >
            <span
              v-if="showQuantity"
              class="font-sans"
            >
              {{ productItem.quantity }}
              pcs
            </span>
            <span :class="{ 'flex sm:hidden': props.infoInColumn }">
              {{ productItem.serial_number }}#100DA8
            </span>
          </div>
        </div>
      </div>
    </div>
  </Link>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import BackgroundImageComponent from '../../../components/ui/BackgroundImageComponent.vue'
import VendorProduct from '@/models/VendorProduct'

const props = defineProps({
  productItem: {
    type: Object as PropType<VendorProduct>,
    required: true,
  },
  eventSlug: {
    type: String,
    required: true,
  },
  cardStyle: {
    type: String,
    default:
      'h-fit w-32 md:w-72 cursor-pointer select-none md:h-full md:w-full',
  },
  imgStyle: {
    type: String,
    default:
      'relative flex aspect-square h-24 min-h-24 md:h-52 md:min-h-52 w-32 md:w-72 w-full items-end justify-start overflow-hidden rounded-lg bg-cover bg-center hover:shadow-lg ',
  },
  wrappStyle: {
    type: String,
    default: 'relative flex h-full w-full flex-col',
  },
  infoInColumn: {
    type: Boolean,
    default: false,
  },
})

const showQuantity = computed(() => {
  return props.productItem.quantity > 0
})
</script>

<style scoped></style>
