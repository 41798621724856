<template>
  <div class="mx-auto grow flex flex-col w-full sm:max-w-desktop lg:max-w-screen-2xl">
<!--
This layout is responsive only for the width of the screen.
It is not responsive for actions you do in real life. Be aware of your surroundings and don't use your phone while driving.
If you are using this layout while driving, please stop the car and park it in a safe place.
-->
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style scoped></style>
