<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.2502 5.41627L14.5835 8.7496M3.3335 16.6663H6.66683L15.4168 7.91627C15.8589 7.47424 16.1072 6.87472 16.1072 6.2496C16.1072 5.62448 15.8589 5.02496 15.4168 4.58293C14.9748 4.14091 14.3753 3.89258 13.7502 3.89258C13.125 3.89258 12.5255 4.14091 12.0835 4.58293L3.3335 13.3329V16.6663Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
