<template>
  <section
    class="flex w-full flex-col items-center gap-8 bg-text-white px-4 py-10 sm:px-8"
  >
    <div class="relative flex w-full flex-col items-center gap-4">
      <h3
        class="mx-auto flex flex-col items-center text-center font-sans text-2xl font-bold text-very-dark-blue"
      >
        Group members
        <span class="text-base font-bold text-slate-400">
          {{ userGroup.members_count }} People
        </span>
      </h3>

      <div class="mx-auto flex w-fit max-w-full items-center overflow-scroll">
        <button
          v-for="tab in tabs"
          :key="tab.value"
          :class="
            selectedTab == tab.value
              ? 'border-b border-blue-primary text-blue-primary'
              : 'text-slate-500'
          "
          class="mx-5 shrink-0 select-none pb-4 font-sans text-lg font-normal"
          @click="selectedTab = tab.value"
        >
          {{ tab.title }}
        </button>
      </div>

      <v-button
        class="right-0 flex w-fit shrink-0 items-center gap-2 sm:absolute"
        no-padding
        size="sm"
        variant="secondary"
        without-border
        @click="
          modalHandler.openType('link-copy-dialog', {
            props: {
              readonly: true,
              title: `Invite a friend to ${userGroup.name}`,
              subtitle: 'Share the link of the group',
              link: `https://fankind.app/groups/${userGroup.id}`,
              linkShareData: {
                title: `Fankind Group Invite`,
                text: `Join ${userGroup.name} on Fankind \n https://fankind.app/groups/${userGroup.id}`,
              },
            },
          })
        "
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.781C3.28115 10.281 3.95942 10 4.66667 10H7.33333C7.97333 10 8.56 10.2253 9.02 10.6007M10.6667 2.08667C11.2403 2.23354 11.7487 2.56714 12.1118 3.03488C12.4748 3.50262 12.6719 4.07789 12.6719 4.67C12.6719 5.26212 12.4748 5.83739 12.1118 6.30513C11.7487 6.77287 11.2403 7.10647 10.6667 7.25334M10.6667 12.6667H14.6667M12.6667 10.6667V14.6667M3.33333 4.66667C3.33333 5.37391 3.61428 6.05219 4.11438 6.55229C4.61448 7.05238 5.29276 7.33333 6 7.33333C6.70724 7.33333 7.38552 7.05238 7.88562 6.55229C8.38572 6.05219 8.66667 5.37391 8.66667 4.66667C8.66667 3.95942 8.38572 3.28115 7.88562 2.78105C7.38552 2.28095 6.70724 2 6 2C5.29276 2 4.61448 2.28095 4.11438 2.78105C3.61428 3.28115 3.33333 3.95942 3.33333 4.66667Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </svg>
        <span>Invite a friend</span>
      </v-button>
    </div>
    <div
      :class="
        selectedTab === 'pending'
          ? 'lg:no-wrap flex-wrap gap-8 sm:flex-row lg:gap-0'
          : 'flex-wrap gap-8 sm:flex-row'
      "
      class="flex w-full flex-col items-center justify-center"
    >
      <template v-if="!featureFlags.GroupMemberPrerequisites || selectedTab !== 'pending'">
        <group-member-card
          v-for="member in members"
          :key="member.id"
          :enable-actions="userGroup.role !== 'member' && member.id !== auth.id"
          :is-blocked="selectedTab === 'blocked'"
          :is-owner="userGroup.role === 'owner'"
          :is-pending="selectedTab === 'pending'"
          :is-private-group="userGroup.isPrivate"
          :member="member"
          class="max-w-[265px]"
          @accept="
            axios
              .post(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                if (userGroup.pending_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @block="
            axios
              .post(`/api/groups/${userGroup.id}/block/${member.id}`)
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @demote="
            axios
              .put(`/api/groups/${userGroup.id}/members/${member.id}`, {
                role: 'member',
              })
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @kick="
            axios
              .delete(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @promote="
            axios
              .put(`/api/groups/${userGroup.id}/members/${member.id}`, {
                role: 'moderator',
              })
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @reject="
            axios
              .delete(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                if (userGroup.pending_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @unblock="
            axios
              .delete(`/api/groups/${userGroup.id}/unblock/${member.id}`)
              .then(() => {
                if (userGroup.blocked_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
        />
      </template>
      <template v-else>
        <group-member-request-card
          v-for="member in members"
          :key="member.id"
          :enable-actions="userGroup.role !== 'member' && member.id !== auth.id"
          :is-blocked="selectedTab === 'blocked'"
          :is-owner="userGroup.role === 'owner'"
          :is-pending="selectedTab === 'pending'"
          :member="member"
          @accept="
            axios
              .post(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                if (userGroup.pending_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @block="
            axios
              .post(`/api/groups/${userGroup.id}/block/${member.id}`)
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @demote="
            axios
              .put(`/api/groups/${userGroup.id}/members/${member.id}`, {
                role: 'member',
              })
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @kick="
            axios
              .delete(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @promote="
            axios
              .put(`/api/groups/${userGroup.id}/members/${member.id}`, {
                role: 'moderator',
              })
              .then(() => {
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @reject="
            axios
              .delete(`/api/groups/${userGroup.id}/members/${member.id}`)
              .then(() => {
                if (userGroup.pending_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
          @unblock="
            axios
              .delete(`/api/groups/${userGroup.id}/unblock/${member.id}`)
              .then(() => {
                if (userGroup.blocked_count <= 1) {
                  selectedTab = 'all'
                }
                queryClient.invalidateQueries(['group-members', userGroup.id])
              })
          "
        />
      </template>
    </div>

    <div
      v-if="false"
      class="mx-auto flex w-fit items-center gap-6 border-t border-slate-300 p-3"
    >
      <button>
        <arrow-icon class="h-5 w-5 shrink-0 rotate-180 text-very-dark-blue" />
      </button>
      <p class="text-sm font-normal text-very-dark-blue">Page 1 of 10</p>
      <button>
        <arrow-icon class="h-5 w-5 shrink-0 text-very-dark-blue" />
      </button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ArrowIcon from '@/icons/ArrowIcon.vue'
import { computed, PropType, ref } from 'vue'
import UserGroup from '@/models/UserGroup'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import axios from 'axios'
import VButton from '@/components/buttons/VButton.vue'
import { useModal } from '@/utils/modal'
import GroupMemberCard from '@/components/ui/GroupMemberCard.vue'
import GroupMemberRequestCard from '@/components/ui/GroupMemberRequestCard.vue'
import User from '@/models/User'
import useAuth from '@/utils/composables/auth'
import featureFlags from '@/utils/composables/flags'

const props = defineProps({
  userGroup: {
    type: Object as PropType<UserGroup>,
    required: true,
  },
})
const auth = useAuth()
const tabs = computed(() => {
  const t = [{ title: 'All Members', value: 'all' }]
  if (props.userGroup?.pending_count) {
    t.push({
      title: 'Requests',
      value: 'pending',
      count: props.userGroup.pending_count,
    })
  }
  if (props.userGroup?.blocked_count) {
    t.push({
      title: 'Blocked',
      value: 'blocked',
      count: props.userGroup.blocked_count,
    })
  }
  return t
})
const selectedTab = ref('all')
const modalHandler = useModal()
const queryClient = useQueryClient()
const { data: members } = useQuery<User[]>({
  queryKey: ['group-members', props.userGroup.id, selectedTab],
  queryFn: async () =>
    axios.get(`/api/groups/${props.userGroup.id}/members`, {
      params: {
        type: selectedTab.value,
      },
    }),
  select: (data) => data.data,
})
</script>
