<template>
  <event-browse-layout>
    <template #sub-stick-header>
      <!--            <div class="w-full bg-white py-2.5 px-4 flex flex-row justify-between md:justify-center gap-4 mx-auto">-->
      <!--                <dropdown-->
      <!--                    class="w-52 block md:hidden"-->
      <!--                    :options="getDates"-->
      <!--                    @selected="handledSelected"-->
      <!--                />-->
      <!--                <div class="flex-row flex-nowrap gap-4 justify-center hidden md:flex">-->
      <!--                    <div-->
      <!--                        v-for="(category, categoryIndex) in getDates"-->
      <!--                        :key="categoryIndex"-->
      <!--                        class="text-base md:text-lg z-20 text-center font-bold col-span-2 p-2.5 rounded select-none"-->
      <!--                        :class="{-->
      <!--        'bg-gray-200': categoryIndex !== selectedCategory,-->
      <!--        'bg-blue-primary text-white': categoryIndex === selectedCategory,-->
      <!--        'cursor-pointer': categoryIndex !== selectedCategory,-->
      <!--        'hover:bg-gray-300': categoryIndex !== selectedCategory-->
      <!--    }"-->
      <!--                        @click="changeCategory(categoryIndex)"-->
      <!--                    >-->
      <!--                        {{ category }}-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="flex justify-center items-center bg-slate-100 rounded p-4 cursor-pointer hover:bg-slate-200 select-none">-->
      <!--                    <font-awesome-icon icon="search" class="" />-->
      <!--                </div>-->
      <!--                <div-->
      <!--                    class="flex justify-center items-center rounded p-4 cursor-pointer select-none"-->
      <!--                    :class="{' bg-blue-primary hover:bg-black text-white ' : areFiltersSet, ' bg-slate-100 hover:bg-slate-200 ' : !areFiltersSet, }"-->
      <!--                    @click="openFilterPopup"-->
      <!--                >-->
      <!--                    <font-awesome-icon icon="filter" />-->
      <!--                </div>-->
      <!--            </div>-->
    </template>

    <template #browse-page>
      <slide-up-popup
        :is-popup-open="popupGame != null"
        title="Game Slots"
        @close-popup="popupGame = null"
      >
        <activity-slots :activity="popupGame" />
      </slide-up-popup>
      <div class="mx-auto mt-2 flex w-full max-w-screen-lg flex-col gap-4 px-2">
        <section
          class="mx-auto grid w-full max-w-screen-md grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2"
        >
          <div
            v-for="(game, index) in games"
            :key="index"
            class="flex w-full cursor-pointer flex-row rounded-lg hover:shadow-lg"
          >
            <div
              :style="'background-image: url(' + game.image.path + ')'"
              class="flex aspect-square w-1/3 flex-shrink-0 items-end justify-start self-start overflow-hidden rounded bg-cover bg-center"
            />
            <div class="flex w-full flex-col justify-between self-stretch p-2">
              <div class="flex w-full flex-col gap-1">
                <div class="flex flex-row gap-2">
                  <div
                    v-for="(highlight, ih) in game.highlights"
                    :key="ih"
                    class="inline-block self-start rounded-full bg-tangerine px-2 text-xs text-white"
                  >
                    {{ highlight.name }}
                  </div>
                </div>
                <h2
                  class="whitespace-pre-line text-base font-medium uppercase md:text-base"
                >
                  {{ game.name }}
                </h2>
                <div class="flex flex-row justify-start gap-2 text-sm">
                  <div
                    class="rounded bg-slate-400 px-2 text-2xs font-bold text-white"
                  >
                    {{ game.minimum_age }}+
                  </div>
                  <div class="text-slate-400">
                    {{ formattedTime(game.min_minutes) }} -
                    {{ formattedTime(game.max_minutes) }}
                  </div>
                  <div class="text-slate-400">See details</div>
                </div>
                <div class="text-sm text-slate-700">
                  {{ game.short_description }}
                </div>
              </div>
              <button
                class="self-start rounded-full bg-black px-4 py-1 text-sm text-white"
                @click="popupGame = game"
              >
                {{ game.slots.length }} Game Slots
              </button>
            </div>
          </div>
        </section>
      </div>
    </template>
  </event-browse-layout>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import EventBrowseLayout from '../../../components/layout/EventBrowseLayout.vue'
import { calculateDistance } from '@/plugins/CalculateDistance'
import ActivitySlots from '../../../components/ui/ActivitySlots.vue'
import SlideUpPopup from '../../../components/deprecated/SlideUpPopup.vue'
defineOptions({
  name: 'BrowseWatch',
})




















































const props = defineProps({
  games: {
    type: Array,
    required: true,
  },
})

const popupGame = ref(null)

const state = reactive({
  latitude: null,
  longitude: null,
  error: null,
})

const formattedTime = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`
  } else {
    return `${remainingMinutes}m`
  }
}

const getDistance = (latitude, longitude) => {
  return calculateDistance(state.latitude, state.longitude, latitude, longitude)
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    )
  })
}

const selectedCategory = ref(0)

const handledSelected = (index) => {
  changeCategory(index)
}

const changeCategory = (index) => {
  selectedCategory.value = index
}

const getDates = computed(() => {
  return ['Fri, Mar 24', 'Sat, Mar 25', 'Sun, Mar 26']
})

// Filters
const showFilterPopup = ref(false)
const openFilterPopup = () => {
  showFilterPopup.value = true
}

const closeFilterPopup = () => {
  showFilterPopup.value = false
}

const areFiltersSet = computed(() => {
  return false
})

onMounted(async () => {
  try {
    const { coords } = await getLocation()
    state.latitude = coords.latitude
    state.longitude = coords.longitude
  } catch (error) {
    state.error = error.message
  }
})

const ratingBackgroundHexColors = [
  '#4a190f',
  '#873306',
  '#983307',
  '#a93408',
  '#b93509',
  '#c9360a',
  '#d9370b',
  '#e9370c',
  '#f9380d',
  '#ff390e',
]
</script>


<style scoped></style>
