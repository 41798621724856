<template>
  <div class="relative">
    <input
      type="text"
      class="form-input px-2 py-1.5 bg-slate-100 rounded"
      :value="displayValue"
      :readonly="!!props.prefill"
      :class="{
        ' bg-slate-200 text-slate-600 select-none ': !!props.prefill
      }"
      @input="onInput($event.target.value)"
      @focus="isOpen = true"
      @blur="onBlur"
      @keydown.enter="onEnter($event)"
    />

    <ul
      v-if="isOpen && filteredOptions?.length"
      class="autocomplete-options absolute bg-white border border-gray-300 w-full z-10 max-h-36 overflow-y-scroll text-base"
    >
      <li
        v-for="(option, index) in filteredOptions"
        :key="option.id"
        class="p-2 hover:bg-gray-200 cursor-pointer"
        @click="selectOption(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
const emit = defineEmits(["update:modelValue"]);




















































defineOptions({
    name: "AutocompleteInput"
})




















































const props = defineProps({
    options: {
        type: Array,
        default: () => []
    },
    prefill: {
        type: String,
        default: ""
    },
    modelValue: {
        type: Object,
        required: true
    }
});

const displayValue = ref(
    props.prefill ? props.prefill : (props.modelValue ? props.modelValue.name : '')
);


const isOpen = ref(false);

const filteredOptions = computed(() => {
    if (props.options === undefined || props.options.length === 0) {
        return [];
    }
    if (displayValue.value === null) {
        return [];
    }
    return props.options.filter((option) =>
        option.name.toLowerCase().includes(displayValue.value.toLowerCase())
    );
});

const onInput = (value) => {
    displayValue.value = value;

    const selectedOption = props.options.find(
        (option) => option.name.toLowerCase() === value.toLowerCase()
    );

    // if (selectedOption) {
    //     emit("update:modelValue", { isNew: false, value: selectedOption });
    // } else {
    //     emit("update:modelValue", { isNew: true, value: value });
    // }
};

const selectOption = (option) => {
    displayValue.value = option.name;
    emit("update:modelValue", option);
    isOpen.value = false;
};

const onBlur = () => {
    setTimeout(() => {
        isOpen.value = false;
    }, 200);
};

const onEnter = (event) => {
    event.preventDefault();

    const matchedOption = filteredOptions.value.find(
        (option) => option.name.toLowerCase() === displayValue.value.toLowerCase()
    );

    if (matchedOption) {
        selectOption(matchedOption);
    } else {
        emit("update:modelValue", { id: null, name: displayValue.value });
        isOpen.value = false;
        setTimeout(() => {
            const inputElement = document.activeElement;
            if (inputElement) {
                inputElement.blur();
            }
        }, 0);
    }
};
</script>


<style scoped>
</style>
