<template>
  <event-browse-layout active="shop">
    <showcase-renderer
      :enable-edit="vendor.allow_editing"
      :showcase-page="showcasePage"
      include-default-sections
      page-type="Showcase"
    />
  </event-browse-layout>
</template>

<script lang="ts" setup>
import useAxiosForm from '@/utils/form'
import { useModal } from '@/utils/modal'
import useResourceModal from '@/utils/composables/resourceModalHandler'
import { useDetachedModalForm } from '@/utils/modalForm'
import ShowcasePage from '@/models/ShowcasePage'
import Vendor from '@/models/Vendor'
import { ref } from 'vue'
import ShowcaseRenderer from '@/components/ui/showcase/ShowcaseRenderer.vue'
import EventBrowseLayout from '@/components/layout/EventBrowseLayout.vue'

//todo: check do we have props
const props = defineProps({
  vendor: {
    type: [Object, Vendor],
    required: true,
  },
  page: {
    type: [ShowcasePage<Vendor>, Object],
    required: true,
  },
})

function buildShowcasePage(): ShowcasePage<Vendor> {
  return ShowcasePage.fromData<ShowcasePage<Vendor>>({
    ...props.page,
    showcaseable: props.vendor as Vendor,
    theme: {
      name: 'default',
      textPrimary: '',
      backgroundPrimary: '',

      textSecondary: '',
      backgroundSecondary: '',

      textHighlight: '',
      backgroundHighlight: 'bg-blue-primary',

      textDark: '',
      backgroundDark: '',

      textLight: '',
      backgroundLight: '',

      textDetails: '',
      backgroundDetails: '',

      textDetailsLight: '',
      backgroundDetailsLight: '',

      textDetailsDark: '',
      backgroundDetailsDark: '',

      textTags: '',
      buttonColor: '',
      backgroundGradient: 'bg-gradient-to-b from-amber-600 to-amber-500',

      iconsMain: '',
      iconsSecondary: '',
    },
  })
}

const modalHandler = useModal()
const modalForm = useDetachedModalForm()
const resourceModal = useResourceModal()
const showcasePage = ref<ShowcasePage<Vendor>>(buildShowcasePage())

function saveSectionsForm() {
  console.log('save sections form')
  // tagForm.submitForm(axios.post('/api/fankind/tags', tagForm.form))
}

const pageSections = () => {
  return props.sections
}

const sectionsForm = useAxiosForm({
  sections: pageSections(),
})

const themeForm = useAxiosForm({
  title: '',
  theme: {
    name: 'default',
    textPrimary: '',
    backgroundPrimary: '',

    textSecondary: '',
    backgroundSecondary: '',

    textHighlight: '',
    backgroundHighlight: 'bg-blue-primary',

    textDark: '',
    backgroundDark: '',

    textLight: '',
    backgroundLight: '',

    textDetails: '',
    backgroundDetails: '',

    textDetailsLight: '',
    backgroundDetailsLight: '',

    textDetailsDark: '',
    backgroundDetailsDark: '',

    textTags: '',
    buttonColor: '',
    backgroundGradient: 'bg-gradient-to-b from-amber-600 to-amber-500',

    iconsMain: '',
    iconsSecondary: '',
  },
})

//todo: rewrite
// function saveSection() {
//   if (selectedSection.value === 'general') {
//     return
//   } else {
//     sectionForm.closeForm(() =>
//       axios
//         .post(
//           `/api/vendors/${props.vendor.id}/showcase/sections`,
//           sectionForm.formData()
//         )
//         .then((response) => {
//           response.data.draft = false
//           replaceSection(selectedSection.value, response.data)
//           sectionForm.resetData(response.data)
//           return response
//         })
//     )
//   }
// }
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from {
  transform: translateX(100%);
}

.list-leave-to {
  transform: translateX(100%);
  position: absolute;
  inset: 0;
}
</style>
