<template>
  <standard-layout>
    <slide-up-popup
      :is-popup-open="isLogOpen"
      title="Log"
      @close-popup="isLogOpen = false"
    >
      <prize-campaign-log
        :tickets="campaignUser.tickets"
        :campaign="campaign"
        :ticket-log="ticketLog"
        :source-types="sourceTypes"
      />
    </slide-up-popup>
    <div class="pb-10">
      <div class="flex flex-col w-full max-w-screen-md mx-auto md:pt-6">
        <div class="flex flex-col w-full max-w-sm items-center mx-auto">
          <image-component
            :src="campaign.image?.path ?? 'https://via.placeholder.com/250x250'"
            alt="Campaign Image"
            class="w-full aspect-4-3 object-cover"
          />
          <div class="flex flex-col w-full items-center p-3">
            <h1>{{ campaign.name }}</h1>
            <p>{{ campaign.description }}</p>
          </div>
        </div>

        <div
          v-if="campaignUser"
          class="flex flex-col w-full gap-1"
        >
          <div
            class="flex flex-col justify-center items-center max-w-fit mx-auto rounded-lg border-2 border-dashed border-blue bg-blue bg-opacity-20 py-2.5 px-16 text-lg"
          >
            <div class="text-sm">
              You've earned
            </div>
            <div class="font-bold">
              {{ campaignUser.tickets_count }}
              {{ pluralize(campaignUser.tickets_count, 'ticket') }}
            </div>
          </div>
          <button
            class="text-sm py-1"
            @click="isLogOpen = true"
          >
            Learn how to earn more tickets
            <font-awesome-icon
              icon="caret-right"
              class="ml-px"
            />
          </button>
        </div>

        <div
          v-if="campaign.maximum_tickets_to_apply>0"
          class="mx-auto"
        >
          Apply up to {{ campaign.maximum_tickets_to_apply - spendRaffleTickets }}
          {{ pluralize(campaign.maximum_tickets_to_apply - spendRaffleTickets, 'ticket') }} to this
          {{ pluralize(form.prizes.length, 'prize') }}.
        </div>
      </div>

      <div class="flex bg-white sticky top-16 my-4">
        <div
          class="flex flex-row justify-between w-full items-center max-w-screen-md px-2 lg:px-0 py-2.5 mx-auto gap-2 border-b border-slate-400"
        >
          <template v-if="getStatusByTime == 'Now open'">
            <div class="text-base md:text-lg">
              Closes <span class="font-bold">{{ getRelativeClosingTime }}</span>
            </div>
            <Link
              v-if="!store.currentUser"
              as="button"
              href="/register"
              class="md:bg-blue md:py-2.5 md:px-6 rounded md:text-blue-100 md:hover:bg-slate-800 md:hover:text-white active:bg-black cursor-pointer"
            >
              Sign up to enter
            </Link>
            <div
              v-else-if="form.isDirty"
              class="flex flex-row items-center gap-2"
            >
              <small
                v-if="spendRaffleTickets >= props.campaign.maximum_tickets_to_apply"
                class="text-slate-700 text-sm md:text-base"
              >
                Save your changes.
                <!--                                TODO: Need to update as this triggers when user still has tix avail-->
                <!--                                You applied all tickets for this campaign.-->
              </small>
              <small
                v-else-if="spendRaffleTickets > campaignUser.tickets_count"
                class="text-red"
              >
                You will use {{ spendRaffleTickets - campaignUser.tickets_count }} fankind
                {{ pluralize(spendRaffleTickets - campaignUser.tickets_count, 'ticket') }}.
              </small>
              <button
                class="py-2.5 px-3 rounded bg-blue text-blue-100 hover:bg-slate-800 hover:text-white active:bg-black cursor-pointer"
                :disabled="!form.isDirty"
                @click="form.put('/raffles/'+campaign.id, {preserveScroll: true})"
              >
                Save changes
              </button>
              <!--                        <button class="py-1 px-3 text-gray-800 text-base underline hover:text-gray-900 disabled:text-gray-500"-->
              <!--                                :disabled="!form.isDirty"-->
              <!--                                @click="form.reset()">-->
              <!--                            Reset selection-->
              <!--                        </button>-->
            </div>
            <div
              v-else
              class="text-slate-700 text-sm md:text-base py-3 pt-[11px]"
            >
              No changes to save
            </div>
          </template>
          <template v-else-if="getStatusByTime == 'Opening soon'">
            <div class="text-base md:text-lg">
              Opens <span class="font-bold">{{ getRelativeOpeningTime }}</span>
            </div>

            <div
              v-if="!$attrs.auth.user"
              class="flex flex-col items-end text-slate-700 text-sm md:text-base"
            >
              <Link
                href="/register"
                class="md:bg-blue md:py-2.5 md:px-6 rounded md:text-blue-100 md:hover:bg-slate-800 md:hover:text-white active:bg-black cursor-pointer"
              >
                Sign up <span class="md:hidden">to be notified</span>
              </Link>
              <div class="hidden md:block text-sm">
                to be notified when open
              </div>
            </div>
            <div
              v-else
              class="flex flex-col items-end text-slate-700 text-xs md:text-base"
            >
              We'll email when open
            </div>
          </template>
          <template v-else-if="getStatusByTime == 'Announcing soon'">
            <div class="text-base md:text-lg">
              Announces
              <span class="font-bold">
                {{
                  getFormattedDate(campaign.announces_at, campaign.timezone)
                }}
              </span>
            </div>
            <div
              v-if="$attrs.auth.user"
              class="flex flex-col items-end text-slate-700 text-xs md:text-base"
            >
              Confirm your selections
            </div>
          </template>
          <template v-else-if="getStatusByTime == 'Closed'">
            <div class="text-base md:text-lg">
              Winners announced
              <span class="font-bold">
                {{
                  getFormattedDate(campaign.announces_at, campaign.timezone)
                }}
              </span>
            </div>
            <div
              v-if="!$attrs.auth.user"
              class="flex flex-col items-end text-slate-700 text-xs md:text-base"
            >
              <Link
                href="/register"
                class="md:bg-blue md:py-2.5 md:px-6 rounded md:text-blue-100 md:hover:bg-slate-800 md:hover:text-white active:bg-black cursor-pointer"
              >
                Sign up <span class="md:hidden">for the next one</span>
              </Link>
              <div class="hidden md:block text-sm">
                to be notified of the next one
              </div>
            </div>
            <div
              v-else
              class="flex flex-col items-end text-right text-slate-700 text-xs md:text-base"
            >
              Check social media for winners
            </div>
          </template>
        </div>
      </div>

      <div class="flex flex-col w-full max-w-screen-md mx-auto">
        <div class="flex flex-col gap-6 md:gap-4">
          <div
            v-for="(prize, index) in campaign.prizes"
            :key="index"
            class="flex flex-col md:flex-row bg-slate-100 md:bg-white gap-2 md:items-start pb-4 border-b border-slate-200 last:border-b-0"
          >
            <image-component
              :src="prize.images[0]?.path ?? 'https://via.placeholder.com/250x250'"
              alt="Prize Image"
              class="flex flex-shrink-0 aspect-4-3 object-cover w-full md:w-1/3"
            />
            <div class="flex flex-col flex-shrink-0 w-full md:w-2/3 md:flex-row items-start py-2.5">
              <div class="flex flex-col gap-2 px-2">
                <div class="flex flex-col">
                  <h4 class="m-0">
                    {{ prize.name }}
                  </h4>
                  <small class="m-0">{{ prize.caption }}</small>
                </div>
                <p>{{ prize.description }}</p>
              </div>
              <div v-if="getStatusByTime === 'Now open'">
                <div

                  v-if="store.currentUser"
                  class="flex flex-shrink-0 items-stretch ml-auto border border-slate-100 shadow-lg rounded overflow-hidden mr-2.5"
                >
                  <div class="flex flex-row flex-nowrap bg-white p-2 gap-2">
                    <button
                      class="text-blue bg-slate-200 rounded w-8 h-8 hover:bg-slate-300 active:bg-slate-700 disabled:bg-slate-100 disabled:text-slate-400"
                      :disabled="getTicketCount(prize) === 0"
                      @click="updateRaffleTicketsCount(prize,getTicketCount(prize)-1)"
                    >
                      -
                    </button>
                    <button
                      class="text-blue bg-slate-200 rounded w-8 h-8 hover:bg-slate-300 active:bg-slate-700 disabled:bg-slate-100 disabled:text-slate-400"
                      :disabled="availableTicketsCount === 0"
                      @click="updateRaffleTicketsCount(prize,getTicketCount(prize)+1)"
                    >
                      +
                    </button>
                  </div>
                  <input
                    v-model="form.prizes.find(p => p.id === prize.id).tickets"
                    type="number"
                    class="flex flex-shrink-0 justify-center items-center w-12 h-12 appearance-none text-center select-none focus-none bg-slate-800 text-blue-100 font-monospace"
                    readonly
                    @input="updateRaffleTicketsCount(getTicketCount(prize))"
                  />
                </div>
                <small
                  v-if="props.campaign.prizes.find(p => p.id === prize.id).maximum_raffle_tickets > 0 &&form.prizes.find(p => p.id === prize.id).tickets === props.campaign.prizes.find(p => p.id === prize.id).maximum_raffle_tickets"
                  class="text-xs text-red-600"
                >
                  Maximum tickets reached for this prize.
                </small>
                <small
                  v-else-if="props.campaign.prizes.find(p => p.id === prize.id).minimum_raffle_tickets > 0 &&form.prizes.find(p => p.id === prize.id).tickets < props.campaign.prizes.find(p => p.id === prize.id).minimum_raffle_tickets"
                  class="text-xs text-red-600"
                >
                  Apply at least
                  {{ props.campaign.prizes.find(p => p.id === prize.id).minimum_raffle_tickets }}
                  tickets to win this prize.
                </small>
              </div>
              <div
                v-else-if="getStatusByTime !== 'Opening soon'"
                class="flex flex-shrink-0 items-center bg-white ml-auto border border-slate-100 shadow-lg rounded overflow-hidden mr-2.5"
              >
                <div
                  class="flex flex-shrink-0 justify-center items-center w-12 h-12 appearance-none text-center select-none focus-none bg-slate-800 text-blue-100 font-monospace"
                >
                  {{ getTicketCount(prize) }}
                </div>
                <div class="text-xs px-2">
                  Recorded <br/>Entries
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <debug-bar>
      <pre>{{ form }}</pre>
    </debug-bar>
  </standard-layout>
</template>

<script setup>
import StandardLayout from "@/components/layout/StandardLayout.vue";
import {DateTime} from "luxon";
import SlideUpPopup from "@/components/deprecated/SlideUpPopup.vue";
import {computed, inject, ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import DebugBar from "@/components/ui/DebugBar.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {Link} from "@inertiajs/vue3";
import {pluralize} from "../../../utils/string";
import PrizeCampaignLog from "../../../components/ui/PrizeCampaignLog.vue";
import ImageComponent from "@/components/ui/ImageComponent.vue";

const props = defineProps({
    campaign: {
        type: Object,
        required: true
    },
    campaignUser: {
        type: Object,
        required: true
    },
    ticketLog: {
        type: Array,
        required: true
    },
    sourceTypes: {
        type: Array,
        required: true
    }
});

const store = inject('store');

const isLogOpen = ref(false);

const spendRaffleTickets = computed(() => {
    return form.prizes.reduce((acc, prize) => acc + prize.tickets, 0);
});

const availableTicketsCount = computed(() => {
    return props.campaignUser.tickets_count - spendRaffleTickets.value;
});

const form = useForm({
    prizes: props.campaign.prizes.map(prize => ({
        id: prize.id,
        tickets: prize.raffleTicketsCount
    }))
});

function getTicketCount(prize) {
    return form.prizes.find(p => p.id === prize.id).tickets;
}

function updateRaffleTicketsCount(prize, count) {
    if (count < 0) {
        count = 0;
    }

    console.log(props.campaign.maximum_tickets_to_apply - spendRaffleTickets.value + getTicketCount(prize));

    let max = availableTicketsCount.value + getTicketCount(prize)

    if (props.campaign.maximum_tickets_to_apply > 0) {
        max = Math.min(max, props.campaign.maximum_tickets_to_apply - spendRaffleTickets.value + getTicketCount(prize));
    }

    if (props.campaign.prizes.find(p => p.id === prize.id).maximum_raffle_tickets > 0) {
        max = Math.min(max, props.campaign.prizes.find(p => p.id === prize.id).maximum_raffle_tickets);
    }

    if (count > max) {
        count = max;
    }
    form.prizes.find(p => p.id === prize.id).tickets = count;
}

const getStatusByTime = ref('');

function updateRaffleStatus() {
    let intervalId = setInterval(() => {
        const now = DateTime.now();

        if (now < DateTime.fromISO(props.campaign.schedule.starts_at, {zone: props.campaign.timezone})) {
            getStatusByTime.value = 'Opening soon';
        } else if (now < DateTime.fromISO(props.campaign.schedule.ends_at, {zone: props.campaign.timezone})) {
            getStatusByTime.value = 'Now open';
        } else if (now < DateTime.fromISO(props.campaign.announces_at, {zone: props.campaign.timezone})) {
            getStatusByTime.value = 'Announcing soon';
        } else {
            getStatusByTime.value = 'Closed';
            clearInterval(intervalId);
        }
    }, 1000);
}

const getRelativeClosingTime = computed(() => {
    return relativeClosingTime.value;
});

const getRelativeOpeningTime = computed(() => {
    return relativeStartingtime.value;
});

const getRelativeStartingTime = computed(() => {
    return DateTime
        .fromISO(props.campaign.schedule.starts_at, {zone: props.campaign.timezone})
        .toRelative();
});

const relativeClosingTime = ref(null);
const relativeStartingtime = ref(null);

// Watch the campaign prop and update the counters if it changes
watch(() => props.campaign, () => {
    updateRaffleStatus();
    updateClosingTimeCounter();
    updateStartingTimeCounter();
}, {immediate: true});

// Update the closing time counter every second
function updateClosingTimeCounter() {
    let intervalId = setInterval(() => {
        relativeClosingTime.value = DateTime
            .fromISO(props.campaign.schedule.ends_at, {zone: props.campaign.timezone})
            .toRelative();

        // Check if the ends_at time is now passed during the interval
        if (DateTime.fromISO(props.campaign.schedule.ends_at, {zone: props.campaign.timezone}) <= DateTime.now()) {
            // Stop the interval if the time has passed
            clearInterval(intervalId);
        }
    }, 1000);
}

// Update the starting time counter every second
function updateStartingTimeCounter() {
    setInterval(() => {
        relativeStartingtime.value = DateTime
            .fromISO(props.campaign.schedule.starts_at, {zone: props.campaign.timezone})
            .toRelative();
    }, 1000);
}

function getFormattedDate(datetime, timezone) {
    const endsAt = DateTime.fromISO(datetime).setZone(timezone);

    let formattedDate;
    const currentYear = DateTime.now().year;

    if (endsAt.year === currentYear) {
        formattedDate = endsAt.toFormat('LLL d'); // Format without year
    } else {
        formattedDate = endsAt.toLocaleString(DateTime.DATE_MED, {locale: 'en-US'}); // Format with year
    }
    return formattedDate;
}

function getFormattedTime(time, timezone) {
    const shortTime = DateTime
        .fromISO(time)
        .setZone(timezone)
        .toLocaleString(DateTime.DATETIME_SHORT, {locale: 'en-US'})
        .split(', ')[1]
        .replace(' ', '')
        .toLowerCase();

    const timezoneAbbreviation = getTimezoneAbbreviation(timezone);

    return shortTime + ' ' + timezoneAbbreviation;
}

function getTimezoneAbbreviation(timezone) {
    const date = new Date();
    const options = {
        timeZone: timezone,
        timeZoneName: 'short'
    };
    return date.toLocaleString('en-US', options).split(' ')[3];
}
</script>

<style scoped>

</style>
