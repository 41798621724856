<template>
  <div
    class="item-start flex w-full flex-col gap-5 md:flex-row lg:gap-10"
    @click="emit('selectActivity', activity)"
  >
    <!--    <div-->
    <!--      :class="-->
    <!--        pastActivities ? ' min-h-[140px]' : 'min-h-[250px] sm:min-h-[402px]'-->
    <!--      "-->
    <!--      :style="`background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%), url(${activity.banner?.path}), lightgray 50% ;-->
    <!--      background-repeat: no-repeat;-->
    <!--      background-size: cover;`"-->
    <!--      class="flex w-full max-w-full rounded-lg bg-no-repeat object-cover md:min-w-[360px] lg:w-[612px]"-->
    <!--    ></div>-->
    <background-image-component
      :class="
        pastActivities ? 'min-h-[140px]' : 'min-h-[250px] sm:min-h-[402px]'
      "
      :fallback-src="null"
      :image="activity.banner"
      class="w-full max-w-full lg:shrink-0 rounded-lg object-cover md:min-w-[360px] lg:w-[612px]"
      fallback-class="bg-slate-200"
      :force-loading="loading"
    />

    <div class="flex w-full max-w-[612px] flex-col">
      <time
        v-if="!pastActivities"
        :class="{
          'skeleton w-48': loading,
        }"
        class="mb-4 self-start flex items-center gap-2 rounded bg-slate-200 px-2 py-1 text-lg font-semibold text-slate-600"
      >
        <calendar-icon-v2 class="h-6 w-6" />
        {{ DateTime.fromISO(activity.starts_at).toFormat('EEE, MMM dd') }}
      </time>

      <span
        :class="{
          'skeleton w-32': loading,
        }"
        :style="{
          color: activity.type?.color,
        }"
        class="mt-2 text-base font-bold text-slate-600"
      >
        {{ activity.type?.name }}
      </span>
      <h6
        :class="{
          'skeleton h-8 w-64': loading,
        }"
        class="mt-2 line-clamp-2 text-xl font-bold text-very-dark-blue"
      >
        {{ activity.name }}
      </h6>

      <template v-if="loading">
        <p class="skeleton mt-2 w-full"></p>
        <p class="skeleton mt-2 w-11/12"></p>
        <p class="skeleton mt-2 w-10/12"></p>
      </template>
      <p
        v-else-if="!pastActivities && activity?.description"
        class="mb-8 line-clamp-3 text-lg font-normal text-slate-600"
      >
        {{ activity.description }}
      </p>

      <span
        v-if="activity.attendees?.length && !loading"
        class="mb-4 text-xl font-bold text-very-dark-blue"
      >
        Attendees
      </span>
      <div
        v-if="activity.attendee_count || loading"
        class="mb-8 flex shrink-0 flex-row items-center"
      >
        <div
          v-if="loading"
          class="flex mt-4"
        >
          <div
            v-for="i in 6"
            :key="i"
            class="skeleton -mr-3 h-8 w-8 rounded-full"
          />
        </div>
        <template v-else>
          <div
            v-if="activity.attendees?.length"
            class="flex shrink-0 flex-row gap-0"
          >
            <image-component
              v-for="user in activity.attendees.slice(0, 5)"
              :key="user.id"
              :image="user.avatar"
              class="-mr-3 h-8 w-8 shrink-0 rounded-full border-[0.5px] border-solid border-blue-primary bg-slate-200 object-cover"
            />
          </div>
          <span class="ml-6 text-lg font-bold text-slate-400">
            {{ activity.attendee_count }} Going
          </span>
        </template>
      </div>

      <div
        v-if="!pastActivities && !editFlag && !loading"
        class="flex items-center gap-6"
      >
        <v-button
          v-if="!attending.isAttending(activity.id)"
          size="md"
          variant="primary"
          @click="attending.rsvp(activity)"
        >
          RSVP
        </v-button>
        <v-button
          v-else
          size="md"
          variant="secondary"
          @click="attending.unrsvp(activity)"
        >
          unRSVP
        </v-button>

        <v-button
          variant="secondary"
          without-border
          @click="attending.openModal(activity)"
        >
          Learn more
        </v-button>
      </div>
      <template v-else-if="editFlag">
        <div
          v-if="props.isUser"
          class="flex items-center gap-6"
        >
          <button
            class="flex items-center gap-2 whitespace-nowrap text-base text-blue-primary"
          >
            <calendar-icon-v2 class="h-6 w-6" />
            Go to full schedule
          </button>

          <div
            class="flex items-center gap-2 whitespace-nowrap text-base text-blue-primary"
          >
            <button
              :class="
                makePrivate
                  ? 'border-blue-primary bg-blue-primary'
                  : 'border-slate-300 bg-slate-300'
              "
              class="w-7 shrink-0 rounded-full border"
              @click="makePrivate = !makePrivate"
            >
              <div
                :class="makePrivate ? 'ml-auto mr-0' : 'ml-0 mr-auto'"
                class="h-4 w-4 shrink-0 rounded-full bg-text-white"
              />
            </button>
            Make it Private
          </div>
        </div>
        <button
          v-else-if="!props.isUser"
          class="flex w-fit shrink-0 items-center gap-2 rounded-lg bg-blue-primary px-10 py-2 text-text-white hover:bg-blue-muted"
        >
          <edit-icon-v2 />
          Edit
        </button>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { useAttending } from '@/utils/attending'

import EditIconV2 from '@/icons/EditIconV2.vue'
import CalendarIconV2 from '@/icons/CalendarIconV2.vue'

import ImageComponent from '@/components/ui/ImageComponent.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import { EventActivity } from '@/models/EventActivity'
import VButton from '@/components/buttons/VButton.vue'
import { DateTime } from 'luxon'

const emit = defineEmits(['selectActivity'])

const props = defineProps({
  isUser: {
    type: Boolean,
    default: false,
  },
  activity: {
    type: Object as PropType<EventActivity>,
    required: true,
  },
  pastActivities: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const attending = useAttending()

const editFlag = false
const makePrivate = ref(false)
</script>
