<template>
  <standard-layout>
    <responsive-layout class="!bg-text-white">
      <div
        :style="`background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${user.banner.path}), lightgray 50% / cover no-repeat;`"
        class="flex h-fit min-h-[500px] w-full flex-col items-center justify-between gap-4 !bg-cover !bg-no-repeat py-8 md:flex-row md:px-8 md:py-0"
      >
        <div
          class="flex flex-col items-center gap-6 px-4 py-8 md:flex-row md:items-start md:px-0"
        >
          <image-component
            :image="user.avatar"
            class="h-[250px] w-full items-start rounded-lg object-cover sm:h-[432px] md:w-[330px]"
          />
          <div
            class="flex w-full flex-col items-start py-2 md:max-w-[55%] lg:px-8"
          >
            <h2
              :class="{ 'skeleton w-40 rounded-xl': !user.name }"
              class="text-4xl font-bold text-text-white"
            >
              {{ user.name }}
            </h2>
            <div
              v-if="user?.tags?.length"
              class="mt-2 text-sm font-normal capitalize text-text-white opacity-50"
            >
              <span
                v-for="(tag, tagID) in user.tags"
                :key="tagID"
              >
                {{ tag }}
                <span :class="{ hidden: tagID === user.tags.length - 1 }">
                  |
                </span>
              </span>
            </div>

            <h6 class="mt-4 text-sm font-bold text-slate-400">About</h6>
            <p class="text-base font-normal text-text-white">
              {{ user.about }}
            </p>

            <h6 class="mt-4 text-sm font-bold text-slate-400">Top interests</h6>
            <p class="text-base font-normal text-text-white">
              {{ user.about }}
            </p>

            <button
              v-if="!editFlag"
              class="mt-10 flex items-center gap-2.5 rounded-lg bg-indigo-50 p-2.5 text-base font-normal text-blue-800 hover:stroke-blue hover:text-blue-primary"
            >
              <heart-icon />
              Add to favorite
            </button>

            <button
              v-else
              class="mt-10 flex items-center gap-2.5 rounded-lg bg-blue-primary px-4 py-2.5 text-base font-normal text-text-white"
            >
              <edit-icon-v2 />
              Edit Bio
            </button>
          </div>
        </div>

        <div
          v-if="false"
          class="mx-auto flex max-h-[500px] max-w-full overflow-scroll md:m-0 md:w-full md:max-w-[115px] md:flex-col md:items-center md:gap-12"
        >
          <p
            v-for="(item, itemID) in userActivities"
            :key="itemID"
            class="mx-6 flex flex-col items-center gap-2 text-center md:m-0"
          >
            <span class="text-2xl font-bold text-text-white">
              {{ item.count }}
            </span>
            <span
              class="font-sans text-sm font-normal text-text-white opacity-50"
            >
              {{ item.title }}
            </span>
          </p>
          <button
            v-if="editFlag"
            class="mx-6 flex w-fit shrink-0 flex-col items-center gap-2 rounded-lg bg-blue-primary p-2 text-center md:m-0"
          >
            <plus-in-circle class="text-text-white" />
            <span
              class="whitespace-nowrap font-sans text-sm font-normal text-text-white opacity-50"
            >
              Add New Badge
            </span>
          </button>
        </div>
      </div>
      <section class="flex w-full flex-col items-center gap-8 px-0 py-10">
        <h2 class="mx-4 font-sans text-2xl font-bold text-very-dark-blue">
          Groups
        </h2>

        <div class="flex max-w-full items-start gap-10 overflow-scroll">
          <div
            v-for="(group, groupID) in groups"
            :key="groupID"
            class="flex w-[215px] shrink-0 flex-col items-center gap-2 overflow-scroll px-3 text-center"
          >
            <image-component
              :image="group.image"
              class="h-[155px] w-full rounded-lg object-cover"
            />
            <h6 class="font-sans text-base font-bold text-black">
              {{ group.title }}
            </h6>
            <span class="font-sans text-xs font-normal text-slate-600">
              {{ group.memeber_since }}
            </span>
          </div>
        </div>
      </section>

      <section class="flex w-full flex-col items-center gap-8 px-0 py-10">
        <group-events-section
          :user-group="userGroup"
          is-user
          title="Schedule"
        />
      </section>

      <section class="flex w-full flex-col items-center gap-8 px-0 py-10">
        <div class="relative flex w-full flex-col items-center">
          <h2 class="mx-4 font-sans text-2xl font-bold text-very-dark-blue">
            Gallery
          </h2>
          <div
            v-if="editFlag"
            class="right-4 top-0 flex w-fit shrink-0 items-center gap-2 text-slate-400 md:absolute"
          >
            <download-icon />
            <span class="whitespace-nowrap text-base font-bold">
              Upload Pictures
            </span>
          </div>
        </div>
        <div
          class="item-center mx-auto flex w-fit flex-col gap-4 px-4 sm:flex-row sm:flex-wrap"
        >
          <image-component
            v-for="(photo, photoID) in photoGallery"
            :key="photoID"
            :src="photo"
            class="h-[280px] w-full rounded-lg object-cover sm:w-[250px]"
          />
        </div>
      </section>
    </responsive-layout>
  </standard-layout>
</template>

<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3'

import StandardLayout from '@/components/layout/StandardLayout.vue'
import ResponsiveLayout from '@/components/layout/ResponsiveLayout.vue'

import GroupEventsSection from '@/shared/components/group/sections/GroupEventsSection.vue'

import HeartIcon from '@/icons/HeartIcon.vue'
import EditIconV2 from '@/icons/EditIconV2.vue'
import DownloadIcon from '@/icons/DownloadIcon.vue'
import PlusInCircle from '@/icons/PlusInCircle.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'

const editFlag = false
const user = {
  banner: { path: 'https://picsum.photos/500/1000/' },
  avatar: { path: 'https://picsum.photos/500/1000/' },
  name: 'Annette Black',
  tags: ['Fan', 'Cosplayer'],
  about:
    'I’m a fan of something... something about my life and why I’m here at the convention. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.',
  interests: '“Hunger Games”, “Passengers”, “X-Men”',
  conventions_count: 1,
  meetups_count: 6,
  celebrities_count: 3,
}

const userActivities = [
  { title: 'Conventions attended', count: user.conventions_count },
  { title: 'Meetups', count: user.meetups_count },
  { title: 'Celebrities met', count: user.celebrities_count },
]

const userGroup = { id: 1 }
const groups = [
  {
    id: 1,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
  {
    id: 2,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
  {
    id: 3,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
  {
    id: 4,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
  {
    id: 5,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
  {
    id: 6,
    image: { path: 'https://picsum.photos/500/200' },
    title: 'Cosplay Photography Club',
    memeber_since: 'Member since 2012',
  },
]
const photoGallery = [
  'https://picsum.photos/500/1000/',
  'https://picsum.photos/600/500',
  'https://picsum.photos/900/500',
  'https://picsum.photos/500/600',
  'https://picsum.photos/500/300',
]

const page = usePage()
const id = page.url.match(/\/users\/(\d+)/)?.[1]

// const userGroup = ref<UserGroup | null>(null)
</script>

<style scoped></style>
