<template>
  <div class="event-menu flex w-full flex-col gap-5">
    <div
      class="flex w-full select-none flex-row flex-nowrap justify-between overflow-hidden rounded-lg bg-slate-100 text-base text-slate-700 hover:text-black md:justify-center md:text-lg"
    >
      <button
        :class="{ 'bg-slate-200': tab === 'got' }"
        class="px-6 py-2.5 hover:bg-slate-200 active:bg-slate-300"
        @click="tab = 'got'"
      >
        Where did i got them?
      </button>
      <button
        :class="{ 'bg-slate-200': tab === 'spend' }"
        class="px-6 py-2.5 hover:bg-slate-200 active:bg-slate-300"
        @click="tab = 'spend'"
      >
        Where did i spend them?
      </button>
      <button
        :class="{ 'bg-slate-200': tab === 'earn' }"
        class="px-6 py-2.5 hover:bg-slate-200 active:bg-slate-300"
        @click="tab = 'earn'"
      >
        How to earn more?
      </button>
    </div>
    <div
      v-if="tab === 'got'"
      class="flex flex-col gap-3"
    >
      <h3>This Raffle</h3>
      <div v-if="tickets.slice().filter((t) => !t.global).length === 0">
        <p>You have not earned any tickets for this raffle yet.</p>
        <button
          class="text-sm text-blue hover:text-slate-800"
          @click="tab = 'earn'"
        >
          Learn how to earn tickets
        </button>
      </div>
      <div
        v-for="ticket in tickets.slice().filter((t) => !t.global)"
        :key="ticket.id"
        class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
      >
        <p>
          Earned {{ ticket.count }} {{ pluralize(ticket.count, 'ticket') }}
          <template v-if="ticket.type === 'meetup_verified'">
            for creating a verified meetup
          </template>
          <template v-else-if="ticket.type === 'starting_ticket'">
            for joining the raffle
          </template>
          <template v-else-if="ticket.type === 'friend_referral'">
            for referring a friend
          </template>
          <template v-else-if="ticket.type === 'review_written'">
            for writing a review
          </template>
          <template v-else-if="ticket.type === 'rsvp_activity'">
            for RSVPing to events
          </template>
        </p>
        <small>
          {{
            DateTime.fromISO(ticket.date)
              .setZone(campaign.timezone)
              .toLocaleString(DateTime.DATETIME_MED, { locale: 'en-US' })
          }}
        </small>
      </div>
      <div class="flex flex-col">
        <h3 class="m-0 p-0">Fankind Raffles</h3>
        <small class="m-0 p-0 text-xs text-slate-500">
          Tickets earned from official Fankind raffles. You can spend them on
          any raffle.
        </small>
      </div>
      <p v-if="tickets.slice().filter((t) => t.global).length === 0">
        You have not earned any Fankind tickets yet.
      </p>
      <div
        v-for="ticket in tickets.slice().filter((t) => t.global)"
        :key="ticket.id"
        class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
      >
        <p>
          Earned {{ ticket.count }}
          <b>Fankind</b>
          {{ pluralize(ticket.count, 'ticket') }}
          <template v-if="ticket.type === 'daily_tickets'">
            for logging in daily
          </template>
          <template v-else-if="ticket.type === 'meetup_verified'">
            for creating a verified meetup
          </template>
          <template v-else-if="ticket.type === 'friend_referral'">
            for referring a friend
          </template>
          <template v-else-if="ticket.type === 'review_written'">
            for writing a review
          </template>
          <template v-else-if="ticket.type === 'starting_ticket'">
            for joining the raffle
          </template>
          <template v-else-if="ticket.type === 'rsvp_activity'">
            for RSVPing to events
          </template>
        </p>
        <small>
          {{
            DateTime.fromISO(ticket.date)
              .setZone(campaign.timezone)
              .toLocaleString(DateTime.DATETIME_MED, { locale: 'en-US' })
          }}
        </small>
      </div>
    </div>

    <div
      v-if="tab === 'spend'"
      class="flex flex-col gap-3"
    >
      <h3>This Raffle</h3>
      <p
        v-if="
          ticketLog
            .slice()
            .filter((t) => t.prize.prize_campaign_id === campaign.id).length ===
          0
        "
      >
        You have not spent any tickets on this raffle yet.
      </p>
      <div
        v-for="ticket in ticketLog
          .slice()
          .filter((t) => t.prize.prize_campaign_id === campaign.id)"
        :key="ticket.id"
        class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
      >
        <p>
          Spent {{ ticket.count }}
          <b v-if="ticket.global">Fankind</b>
          {{ pluralize(ticket.count, 'ticket') }} on {{ ticket.prize.name }}
        </p>
      </div>

      <template
        v-if="
          ticketLog
            .slice()
            .filter(
              (t) =>
                t.prize.prize_campaign_id !== campaign.id &&
                t.campaign_ended === false
            ).length
        "
      >
        <h3>Other Raffles</h3>
        <div
          v-for="ticket in ticketLog
            .slice()
            .filter(
              (t) =>
                t.prize.prize_campaign_id !== campaign.id &&
                t.campaign_ended === false
            )"
          :key="ticket.id"
          class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
        >
          <p>
            Spent {{ ticket.count }}
            <b v-if="ticket.global">Fankind</b>
            {{ pluralize(ticket.count, 'ticket') }}
            on
            <Link
              :href="'/raffles/' + ticket.prize.prize_campaign_id"
              class="text-blue"
            >
              {{ ticket.prize.name }}
            </Link>
          </p>
        </div>
      </template>

      <template
        v-if="
          ticketLog
            .slice()
            .filter(
              (t) =>
                t.prize.prize_campaign_id !== campaign.id &&
                t.campaign_ended === true
            ).length
        "
      >
        <h3>Past Raffles</h3>
        <div
          v-for="ticket in ticketLog
            .slice()
            .filter(
              (t) =>
                t.prize.prize_campaign_id !== campaign.id &&
                t.campaign_ended === true
            )"
          :key="ticket.id"
          class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
        >
          <p>
            Spent {{ ticket.count }}
            <b v-if="ticket.global">Fankind</b>
            {{ pluralize(ticket.count, 'ticket') }}
            on
            <Link
              :href="'/raffles/' + ticket.prize.prize_campaign_id"
              class="text-blue"
            >
              {{ ticket.prize.name }}
            </Link>
          </p>
        </div>
      </template>
    </div>

    <div
      v-if="tab === 'earn'"
      class="flex flex-col gap-3"
    >
      <div
        v-for="sourceType in sourceTypes"
        :key="sourceType.name"
        class="flex items-end justify-between rounded bg-slate-200 px-3 py-5"
      >
        <p>
          <template v-if="getRemainingTickets(sourceType.name) > 0">
            Earn up to {{ getRemainingTickets(sourceType.name) }}
            {{ pluralize(getRemainingTickets(sourceType.name), 'ticket') }} by
          </template>
          <template v-else>
            Earned all {{ pluralize(sourceType.max, 'ticket') }} by
          </template>

          <template v-if="sourceType.name === 'daily_tickets'">
            logging in daily.
          </template>
          <template v-else-if="sourceType.name === 'meetup_verified'">
            getting 5 attendees for your meetup.
            <br />
            <a
              class="text-sm text-blue hover:text-slate-800"
              href="/meetups/create"
            >
              Create a meetup
            </a>
          </template>
          <template v-else-if="sourceType.name === 'friend_referral'">
            referring friends to Fankind.
            <br />
            <span class="text-sm text-slate-600">{{ getReferralLink }}</span>
          </template>
          <template v-else-if="sourceType.name === 'review_written'">
            writing a reviews on Fankind.
          </template>
          <template v-else-if="sourceType.name === 'rsvp_activity'">
            RSVPing to activities.
            <br />
            <a
              class="text-sm text-blue hover:text-slate-800"
              href="/event/fanfusion-2023/schedule"
            >
              RSVP now
            </a>
          </template>
          <template v-else>using Fankind.</template>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'
import { DateTime } from 'luxon'
import { pluralize } from '@/utils/string'
import { Link } from '@inertiajs/vue3'

const props = defineProps({
  campaign: {
    type: Object,
    required: true,
  },
  tickets: {
    type: Array,
    default: () => [],
  },
  globalTickets: {
    type: Array,
    default: () => [],
  },
  ticketLog: {
    type: Array,
    default: () => [],
  },
  sourceTypes: {
    type: Array,
    default: () => [],
  },
})

const store = inject('store')

const tab = ref('got')

const getReferralLink = computed(() => {
  return store.currentUser.referral_link
})

function getRemainingTickets(type) {
  return (
    props.sourceTypes.find((s) => s.name === type).max -
    props.ticketLog
      .slice()
      .filter((t) => t.type === type)
      .reduce((a, b) => a + b.count, 0)
  )
}
</script>

<style scoped></style>
