<template>
  <div
    class="border-[1px]-gray-200 flex items-center gap-5 rounded-lg bg-white p-1 pr-5 sm:p-2"
  >
    <img
      v-if="image"
      :class="props.imageClass"
      :src="props.image"
      alt="location image"
      class="h-full w-full shrink-0 rounded object-cover"
    />
    <div class="flex flex-col justify-between">
      <h4 class="font-heading text-base text-dark-grey md:text-[20px]">
        {{ props.title }}
      </h4>
      <p
        class="max-w-[200px] font-heading text-sm font-normal text-paragraph md:text-base"
      >
        {{ location?.address }}
        <template
          v-if="
            location.city ||
              location.state ||
              location.postal_code
          "
        >
          {{ location.city }},
          {{ location.state }}
          {{ location.postal_code }}
        </template>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Location from '@/models/Location'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  image: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    required: true,
  },
  location: {
    type: [Object, Location],
    required: true,
  },
  imageClass: {
    type: String,
    required: true,
  },
})
</script>

<style lang="scss" scoped></style>
