<template>
  <listbox
    v-model="selected"
    as="div"
  >
    <listbox-label
      v-if="labelName"
      class="mb-2 block text-sm font-medium leading-6 text-gray-900"
    >
      {{ labelName }}
    </listbox-label>
    <div class="relative">
      <listbox-button
        class="relative w-full cursor-pointer select-none rounded-md bg-slate-100 py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm outline-none active:bg-slate-200 sm:text-sm sm:leading-6"
      >
        <span class="block truncate text-lg font-semibold">{{ selected }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <font-awesome-icon
            aria-hidden="true"
            class="h-5 w-5 text-gray-400"
            icon="caret-down"
          />
        </span>
      </listbox-button>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <listbox-options
          class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:text-lg"
        >
          <listbox-option
            v-for="(option, optionIndex) in options"
            :key="optionIndex"
            v-slot="{ active, selected: optionSelected }"
            :value="option"
            as="template"
            @click="emits('selected', optionIndex)"
          >
            <li
              :class="[active ? 'bg-blue-primary text-white' : 'text-gray-900']"
              class="w-50 relative flex cursor-pointer select-none flex-row flex-nowrap items-center justify-start px-2 py-2"
            >
              <font-awesome-icon
                :class="[
                  active ? 'text-white' : 'text-blue-primary',
                  optionSelected ? 'text-blue-primary' : 'text-white',
                ]"
                aria-hidden="true"
                class="mr-2 flex items-center text-sm md:text-base"
                icon="check"
              />

              <div
                :class="[optionSelected ? 'font-semibold' : 'font-normal']"
                class="block w-full truncate"
              >
                {{ option }}
              </div>
            </li>
          </listbox-option>
        </listbox-options>
      </transition>
    </div>
  </listbox>
</template>

<script setup>
import { ref } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const emits = defineEmits(['selected'])

defineOptions({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dropdown',
})

const props = defineProps({
  labelName: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: null,
  },
  defaultSelectionIndex: {
    type: Number,
    default: 0,
  },
})

const selected = ref(props.options[props.defaultSelectionIndex])
</script>

<style scoped></style>
