<template>
  <standard-layout>
    <header
      :style="{
        'background-image':
          'url(' + getBackgroundImage(conInstance.image) + ')',
      }"
      class="relative flex h-56 w-full bg-cover"
    >
      <div class="absolute inset-0 z-0 bg-black bg-opacity-50" />
      <div
        class="z-10 flex w-full flex-col items-center justify-center gap-6 p-4"
      >
        <div class="flex flex-col items-center gap-1 text-white">
          <!-- TODO: this solution is not good, but it works for now, need to find a better way to do this -->
          <h2 class="m-0 text-4xl uppercase italic md:text-5xl">
            {{ conventionLines[0] }}
          </h2>
          <h1
            v-if="conventionLines.length > 1"
            class="m-0 text-2xl uppercase italic md:text-3xl"
          >
            {{ conventionLines[1] }}
          </h1>
        </div>
        <Link
          :href="`/event/${currentConvention.slug}`"
          class="select-none rounded-full bg-white bg-opacity-50 px-4 py-2 text-white hover:bg-opacity-100 hover:text-blue-primary focus:outline-none"
        >
          Full 2023 schedule
        </Link>
      </div>
    </header>
    <div class="mx-auto flex w-full max-w-screen-md flex-col pt-4">
      <div class="flex w-full flex-col px-2 py-4 md:px-4">
        <div class="mb-4 flex items-center justify-start md:justify-center">
          <h2 class="text-2xl font-bold">Upcoming Meetups</h2>
        </div>

        <!-- Meetups content -->
        <div
          v-if="meetups.length > 0"
          class="mini-activity-carousel flex justify-start gap-6 overflow-x-auto md:justify-center"
        >
          <Link href="/meetups/create">
            <div
              class="flex min-h-[7rem] w-56 cursor-pointer flex-col justify-center rounded-lg border border-gray-300 bg-blue p-4 text-white shadow-md hover:bg-slate-900"
            >
              <h3 class="text-lg font-semibold">Create Meetup</h3>
              <div class="flex items-center gap-4">
                <p class="text-base text-slate-400">
                  Bring fans together around a common topic
                </p>
              </div>
            </div>
          </Link>
          <a
            v-for="(meetup, index) in meetups"
            :key="index"
            :href="'/activities/' + meetup.id"
          >
            <mini-activity-card
              :meetup="meetup"
              class=""
            />
          </a>
        </div>

        <div
          v-else
          class="rounded-lg bg-slate-200 px-4 py-2 text-slate-400"
        >
          No upcoming meetups... maybe it's time you created one.
        </div>
      </div>
      <div
        class="mx-auto my-6 h-px w-1/3 max-w-screen-md bg-slate-300 text-center"
      />
      <section class="mx-auto w-full max-w-screen-md">
        <h2 class="mb-4 text-center">My Schedule</h2>
        <template v-if="plannerData.days.length != 0">
          <div class="flex w-full items-center justify-center">
            <div class="mb-6 flex flex-row justify-start gap-4 overflow-x-auto">
              <div
                v-for="(day, dayIndex) in plannerData.days"
                :key="dayIndex"
                :class="{
                  'bg-gray-200': dayIndex !== selectedDay,
                  'bg-blue-primary text-white': dayIndex === selectedDay,
                  'cursor-pointer': dayIndex !== selectedDay,
                  'hover:bg-gray-300': dayIndex !== selectedDay,
                }"
                class="z-20 col-span-2 my-2 select-none whitespace-nowrap rounded p-2.5 text-center text-base font-bold md:text-xl"
                @click="clickedDayButton(dayIndex)"
              >
                {{ day.name }}
              </div>
            </div>
          </div>
          <!--                <simple-calendar :activities="getDayActivities" :planner-data="plannerData" />-->
          <div
            v-for="(activities, time) in activitiesByTime"
            :key="time"
            class="flex-column mx-auto flex w-full max-w-screen-md"
          >
            <div
              v-if="activities"
              class="mb-10 flex w-full flex-row items-start gap-6"
            >
              <div
                class="mb-20 w-12 flex-shrink-0 whitespace-nowrap px-2 text-sm text-slate-500 md:mb-10 md:text-base"
              >
                {{ props.plannerData.times[time] }}
              </div>
              <div class="flex flex-row gap-6 overflow-x-auto">
                <div
                  v-for="activity in activities"
                  :key="activity.id"
                  class="mb-6 flex w-[320px] flex-shrink-0 flex-row gap-2 rounded-lg bg-slate-100 p-4 md:w-[420px]"
                >
                  <Link
                    :href="getActivityPath(activity.id)"
                    class="flex w-full flex-col gap-1"
                  >
                    <div class="text-base font-bold leading-tight md:text-lg">
                      {{ activity.name }}
                    </div>
                    <p class="text-base">
                      {{ activity.short_description }}
                    </p>
                  </Link>
                  <button
                    class="block h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-slate-400 text-sm font-semibold text-white hover:bg-slate-800 hover:text-white"
                    @click="unrsvp(activity.id)"
                    @click.stop
                  >
                    <font-awesome-icon icon="close" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-else
          class="flex flex-col items-center justify-center rounded-lg bg-gray-200 px-4 py-4 text-black"
        >
          <p class="text-center">You have no activities scheduled</p>
          <Link
            :href="`/event/${currentConvention.slug}`"
            class="select-none rounded-full bg-blue-800 px-4 py-2 text-white hover:bg-opacity-70 focus:outline-none"
          >
            Browse Activities
          </Link>
        </div>
      </section>
    </div>
  </standard-layout>
</template>

<script setup>
import { computed, inject, onBeforeMount, ref } from 'vue'
import StandardLayout from '../../components/layout/StandardLayout.vue'
import MiniActivityCard from '../../components/ui/MiniActivityCard.vue'
import { Link, router, usePage } from '@inertiajs/vue3'

const props = defineProps({
  meetups: {
    type: Array,
    required: true,
  },
  plannerData: {
    type: Object,
    required: true,
  },
  conInstance: {
    type: Object,
    required: true,
  },
})

const page = usePage()

const currentConvention = computed(() => {
  return page.props.shared.currentConvention
})

const conventionLines = computed(() => {
  let lines = []
  let split = currentConvention.value.name.split(' ')
  if (split.length === 4) {
    lines.push(split[0] + ' ' + split[1])
    lines.push(split[2] + ' ' + split[3])
  } else if (split.length === 3) {
    lines.push(split[0] + ' ' + split[1])
    lines.push(split[2])
  } else if (split.length === 2) {
    lines.push(split[0])
    lines.push(split[1])
  } else {
    lines.push(currentConvention.value.name)
  }
  console.log(lines)
  return lines
})

const selectedDay = ref(0)
const store = inject('store')

const getDayActivities = computed(() => {
  return props.plannerData.days[selectedDay.value].activities
})

const getBackgroundImage = function (image) {
  return image?.path ?? 'http://placekitten.com/1920/400'
}

const setDefaultSelectedDay = function () {
  const today = new Date()
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')
  const todayIndex = props.plannerData.days.findIndex(
    (day) => day.date === today
  ) // find the index of the day that matches today's date

  if (todayIndex !== -1) {
    selectedDay.value = todayIndex
  } else {
    selectedDay.value = 0
  }
}

const getActivityPath = function (index) {
  return '/activities/' + index + '/'
}

const activitiesByTime = ref([])

const clickedDayButton = function (dayIndex) {
  selectedDay.value = dayIndex
  setSchedule()
}

const setSchedule = function () {
  activitiesByTime.value = []
  if (props.plannerData.days.length == 0) return
  props.plannerData.days[selectedDay.value].activities.forEach((activity) => {
    const activityTime = props.plannerData.times[activity.starts_at]
    const activityObj = { ...activity, readable_time: activityTime }
    if (activitiesByTime.value[activity.starts_at]) {
      activitiesByTime.value[activity.starts_at].push(activityObj)
    } else {
      activitiesByTime.value[activity.starts_at] = [activityObj]
    }
  })
}

const unrsvp = function (activityId) {
  router.delete('/activities/' + activityId + '/attend', {
    preserveState: true,
    preserveScroll: true,
    onSuccess: () => {
      setSchedule()
    },
  })
}

onBeforeMount(() => {
  setDefaultSelectedDay()
  setSchedule()
})
</script>


<style scoped>
/* Hide scrollbar on mobile */
@media only screen and (max-width: 600px) {
  .mini-activity-carousel {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .mini-activity-carousel::-webkit-scrollbar {
    display: none; /* WebKit */
  }
}
</style>
