<template>
  <auth-layout :background-image="backgroundImage">
    <div class="flex w-full text-center border-b pb-3 border-gray-200">
      <h2 class="text-xl">
        Reset password
      </h2>
    </div>
    <div class="flex flex-col gap-4 my-6">
      <!-- Keep the email field hidden to work with user's browser autofill -->
      <input
        type="hidden"
        name="email"
        :value="email"
      />

      <div class="flex flex-col">
        <label
          class="text-sm"
          for="password"
        >Password</label>
        <input
          id="password"
          v-model="form.password"
          class="bg-gray-200 rounded py-2 px-3"
          type="password"
        />
        <div v-if="form.errors.password">
          {{ form.errors.password }}
        </div>
      </div>
      <div class="flex flex-col">
        <label
          class="text-sm"
          for="password_confirmation"
        >Confirm password</label>
        <input
          id="password_confirmation"
          v-model="form.password_confirmation"
          class="bg-gray-200 rounded py-2 px-3"
          type="password"
        />
        <div v-if="form.errors.password_confirmation">
          {{ form.errors.password_confirmation }}
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <button
        type="submit"
        class="bg-blue-primary rounded-full py-2.5 px-16 text-white"
        @click.prevent="form.post('/password/reset')"
      >
        Reset
      </button>
      <div class="text-sm flex w-full gap-1 text-gray-400">
        Don't have an account? <Link
          href="/register"
          class="text-blue-primary font-bold"
        >
          Sign up now
        </Link>
      </div>
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import {useForm} from "@inertiajs/vue3";
import FankindLogo from "../../../icons/FankindLogo.vue";
import AuthLayout from "../../../components/layout/AuthLayout.vue";
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    backgroundImage: {
        type: String,
        required: true,
    },
    token: {
        type: String,
        required: true,
    },
    email: {
        type: String,
        required: true,
    },
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
})
</script>

<style scoped>
form {
    width: 70%;
    min-width: 300px;
}

form > div {
    display: flex;
    flex-direction: column;
}
</style>
