<template>
  <div
    :class="{
      'bg-blue-primary text-white': selected,
    }"
    class="flex w-24 flex-col items-center justify-center gap-2 rounded-lg border border-slate-300 p-2"
  >
    <small class="text-lg opacity-90 font-sans font-normal">
      {{ dt.toFormat('LLL d') }}
    </small>
    <h3 class="text-2xl font-semibold">
      {{ dt.toFormat('ccc') }}
    </h3>
  </div>
</template>

<script lang="ts" setup>
import EventDay from '@/models/EventDay'
import { computed } from 'vue'
import { DateTime } from 'luxon'

const props = withDefaults(
  defineProps<{
    item: EventDay
    selected?: boolean
  }>(),
  {
    selected: false,
  }
)

const dt = computed(() => {
  return DateTime.fromISO(props.item.date)
})
</script>

<style scoped></style>
