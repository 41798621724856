<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M8.53374 11.087H5.73926V13.6213H8.53374V11.087Z"
      fill="currentColor"
    />
    <path
      d="M20.7593 11.087H17.9648V13.6213H20.7593V11.087Z"
      fill="currentColor"
    />
    <path
      d="M16.6861 11.087H13.8916V13.6213H16.6861V11.087Z"
      fill="currentColor"
    />
    <path
      d="M8.53374 15.0472H5.73926V17.5815H8.53374V15.0472Z"
      fill="currentColor"
    />
    <path
      d="M16.6861 15.0472H13.8916V17.5815H16.6861V15.0472Z"
      fill="currentColor"
    />
    <path
      d="M12.6089 15.0472H9.81445V17.5815H12.6089V15.0472Z"
      fill="currentColor"
    />
    <path
      d="M20.7593 19.0067H17.9648V21.541H20.7593V19.0067Z"
      fill="currentColor"
    />
    <path
      d="M16.6861 19.0067H13.8916V21.541H16.6861V19.0067Z"
      fill="currentColor"
    />
    <path
      d="M12.6089 19.0067H9.81445V21.541H12.6089V19.0067Z"
      fill="currentColor"
    />
    <path
      d="M23.3159 2.74133H21.0435V4.71708C21.0435 5.45429 20.3737 6.05383 19.5505 6.05383H16.5834C15.7602 6.05383 15.0905 5.45429 15.0905 4.71708V2.74133H11.4055V4.6985C11.4055 5.44597 10.7284 6.05383 9.89606 6.05383H6.9619C6.12956 6.05383 5.45243 5.44597 5.45243 4.6985V2.74133H3.17997C1.70224 2.74133 0.5 3.82169 0.5 5.14958V23.0915C0.5 24.4193 1.70224 25.4997 3.17997 25.4997H23.3159C24.7959 25.4997 26 24.4194 26 23.0915V5.14958C26 3.82174 24.7959 2.74133 23.3159 2.74133ZM24.0788 23.0447C24.0788 23.471 23.7629 23.7574 23.2928 23.7574H3.20716C2.73703 23.7574 2.42117 23.471 2.42117 23.0447V8.86943H24.0788V23.0447Z"
      fill="currentColor"
    />
    <path
      d="M6.96414 5.10531H9.89654C10.1448 5.10531 10.3468 4.92338 10.3468 4.69971V1.02064C10.3468 0.457853 9.83772 0 9.21183 0H7.6488C7.02296 0 6.51367 0.457803 6.51367 1.02064V4.69971C6.51373 4.92338 6.71573 5.10531 6.96414 5.10531Z"
      fill="currentColor"
    />
    <path
      d="M16.5843 5.10531H19.5514C19.7902 5.10531 19.9844 4.93035 19.9844 4.71554V1.00496C19.9844 0.45088 19.4831 0 18.8667 0H17.269C16.6527 0 16.1514 0.45088 16.1514 1.00496V4.71554C16.1513 4.93035 16.3456 5.10531 16.5843 5.10531Z"
      fill="currentColor"
    />
  </svg>
</template>
