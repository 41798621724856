export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    if(!lat1 || !lon1 || !lat2 || !lon2){
        return '';
    }

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let distance = R * c; // Distance in km
    let units = 'km';
    if(distance < 1){
        units = 'meters';
        distance = distance * 100;
    }
    return distance.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' ' + units;
}

const deg2rad = (deg) => {
    return deg * (Math.PI/180)
}
