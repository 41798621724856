<template>
  <div
    :style="`grid-template-columns: 60px 1fr; grid-template-rows: repeat(${
      times.length * 2
    }, 64px)`"
    class="grid grow grid-flow-row gap-y-2 py-2"
  >
    <div
      class="z-[12] bg-white bg-opacity-75 backdrop-blur"
      style="grid-area: 1/1/-1/2"
    />
    <template
      v-for="(time, i) in times"
      :key="i"
    >
      <div
        :style="{ gridRow: i * 2 + 1 }"
        class="relative z-[12] h-32 text-sm text-gray-600"
        style="grid-column: 1"
      >
        <div class="absolute -top-3 w-full text-center">
          {{ time.toFormat('h:mma').toLowerCase().replace('m', '') }}
        </div>
      </div>
      <div
        :style="{ gridRow: i * 2 + 1, gridColumnStart: 2, gridColumnEnd: -1 }"
        class="relative"
        style="grid-column: 2"
      >
        <div class="absolute -top-1 h-[1px] w-full bg-light-grey-100" />
      </div>
    </template>
    <div
      v-for="(group, index) in positionedGroups"
      :key="index"
      :class="[
        group.activities.length > 1
          ? 'snap-x snap-mandatory scroll-pl-20 overflow-y-visible overflow-x-scroll'
          : '',
      ]"
      :style="{
        gridArea: `${group.start_row + 1} / 1 / ${group.end_row + 1} / 3`,
        gridTemplateColumns: `repeat(${Math.max(
          ...group.activities.map((a) => a.end_col)
        )}, ${95 / cols}%)`,
        gridTemplateRows: `repeat(${group.end_row - group.start_row}, 64px)`,
      }"
      class="z-[11] ml-1 grid grid-flow-col gap-2 pl-20 pr-5 transition-all duration-300"
    >
      <schedule-activity-presenter
        v-for="activity in group.activities"
        :key="activity.id"
        :activity="activity"
        :expanded="expandedActivity === activity.id"
        :style="{
          gridArea: `${activity.start_row} / ${activity.start_col} / ${activity.end_row} / ${activity.end_col}`,
        }"
        class="snap-start"
        hide-attending
        mini
        opaque
        @click="expandActivity(activity.id)"
        @open="emit('open', activity)"
        @rsvp="
          router.post('/activities/' + activity.id + '/attend', null, {
            only: ['auth.user', 'userSchedule'],
            preserveScroll: true,
            preserveState: true,
          })
        "
        @unrsvp="
          router.delete('/activities/' + activity.id + '/attend', {
            only: ['auth.user', 'userSchedule'],
            preserveScroll: true,
            preserveState: true,
          })
        "
      />
    </div>
  </div>
</template>

<script setup>
import { DateTime } from 'luxon'
import { computed, ref } from 'vue'
import ScheduleActivityPresenter from '@/components/ui/ScheduleActivityPresenter.vue'
import { router } from '@inertiajs/vue3'

const emit = defineEmits(['open'])

const props = defineProps({
  startTime: {
    type: DateTime,
    required: true,
  },
  endTime: {
    type: DateTime,
    required: true,
  },
  userSchedule: {
    type: Array,
    required: true,
  },
  cols: {
    type: Number,
    default: 1,
  },
})

const expandedActivity = ref(null)

function expandActivity(id) {
  if (expandedActivity.value === id) {
    expandedActivity.value = null
  } else {
    expandedActivity.value = id
  }
}

const times = computed(() => {
  const times = []
  let time = props.startTime
  while (time <= props.endTime) {
    times.push(time)
    time = time.plus({ minutes: 30 })
  }
  return times
})

const positionedGroups = computed(() => {
  const groups = []
  const unGrouped = []

  // First, sort the activities by start time
  // and position all activities that don't overlap
  props.userSchedule
    .slice()
    .sort((a, b) => {
      return DateTime.fromISO(a.starts_at, { setZone: true }).diff(
        DateTime.fromISO(b.starts_at, { setZone: true }),
        'minutes'
      ).minutes
    })
    .forEach((activity, index) => {
      // activity.typeTag = activity.tags?.filter((t) => t.type === 'activity')?.at(0)
      const start = DateTime.fromISO(activity.starts_at, { setZone: true })
      const end = DateTime.fromISO(activity.ends_at, { setZone: true })
      const start_row = Math.round(
        start.diff(props.startTime, 'minutes').minutes / 15
      )
      const end_row = Math.round(
        end.diff(props.startTime, 'minutes').minutes / 15
      )

      // check if activity overlaps with any other activities
      const overlaps = groups.filter((group) => {
        return (
          // (start_row > group.start_row && start_row < group.end_row) ||
          // (end_row > group.start_row && end_row < group.end_row) ||
          // (start_row === group.start_row && end_row === group.end_row)
          start_row < group.end_row && end_row > group.start_row
        )
      })

      // if it does, add it to the group
      if (overlaps.length) {
        unGrouped.push({
          ...activity,
          start_row,
          end_row,
          start_col: 1,
          end_col: 2,
        })
      } else {
        groups.push({
          start_row,
          end_row,
          start_col: 2,
          end_col: 3,
          activities: [
            {
              ...activity,
              start_row,
              end_row,
              start_col: 1,
              end_col: 2,
            },
          ],
        })
      }
    })

  // Then, position all activities that do overlap with other activities
  // if an activity overlaps with multiple groups, merge the groups
  unGrouped.forEach((overlap) => {
    // find all groups that overlap with the current activity
    const overlappingGroups = groups.filter((group) => {
      return (
        // (overlap.start_row > group.start_row &&
        //   overlap.start_row < group.end_row) ||
        // (overlap.end_row > group.start_row &&
        //   overlap.end_row < group.end_row) ||
        // (overlap.start_row === group.start_row &&
        //   overlap.end_row === group.end_row)
        overlap.start_row < group.end_row && overlap.end_row > group.start_row
      )
    })

    let group = null

    // if the activity overlaps with multiple groups, merge the groups
    if (overlappingGroups.length > 1) {
      // change row span of merged group
      const mergedGroup = {
        start_row: Math.min(
          ...overlappingGroups.map((group) => group.start_row),
          overlap.start_row
        ),
        end_row: Math.max(
          ...overlappingGroups.map((group) => group.end_row),
          overlap.end_row
        ),
        start_col: 2,
        end_col: 3,
        activities: overlappingGroups.flatMap((group) => group.activities),
      }
      groups.push(mergedGroup)

      // remove the overlapping groups from the groups array
      overlappingGroups.forEach((group) => {
        groups.splice(groups.indexOf(group), 1)
      })

      group = mergedGroup
    } else {
      group = overlappingGroups[0]
      group.start_row = Math.min(group.start_row, overlap.start_row)
      group.end_row = Math.max(group.end_row, overlap.end_row)
    }

    if (!group) {
      throw new Error('No overlapping group found')
    }

    // get number of activities that overlap with the current activity
    // this will be the starting column for the current activity
    const start_col = Math.max(
      ...group.activities
        .filter((activity) => {
          return (
            // (overlap.start_row > activity.start_row &&
            //   overlap.start_row < activity.end_row) ||
            // (overlap.end_row > activity.start_row &&
            //   overlap.end_row < activity.end_row) ||
            // (overlap.start_row === activity.start_row &&
            //   overlap.end_row === activity.end_row)
            overlap.start_row < activity.end_row &&
            overlap.end_row > activity.start_row
          )
        })
        .map((a) => a.end_col)
    )

    // add the current activity to the group
    group.activities.push({
      ...overlap,
      start_row: overlap.start_row,
      end_row: overlap.end_row,
      start_col,
      end_col: start_col + 1,
    })
  })

  // reposition rows in each group
  // so that the first activity in each group starts at row 1
  groups.forEach((group) => {
    group.activities.forEach((activity) => {
      activity.start_row = activity.start_row - group.start_row + 1
      activity.end_row = activity.end_row - group.start_row + 1
    })
  })
  console.log(groups)
  return groups
})
</script>

<style scoped></style>
