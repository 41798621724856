<template>
  <div
    class="relative flex flex-col"
    tabindex="0"
    @focus="open"
    @blur="blur"
  >
    <slot>
      <form-input
        v-bind="$attrs"
        :model-value="modelValue"
        @update:model-value="emit('update:modelValue', $event)"
        @focus="open"
        @blur="blur"
      >
        <template #before>
          <slot
            name="before"
            :focus="open"
            :blur="blur"
          />
        </template>
        <template #inner="{ focus }">
          <slot name="inner">
            <button
              v-if="modelValue"
              class="absolute bottom-0 right-0 top-0 rounded-r px-3 py-2"
              @click="clear"
            >
              <svg
                class="h-4 w-4 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <button
              v-else
              class="absolute bottom-0 right-0 top-0 rounded-r px-3 py-2"
              @click.prevent="emit('search')"
            >
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle
                  cx="11"
                  cy="11"
                  r="8"
                />
                <line
                  x1="21"
                  y1="21"
                  x2="16.65"
                  y2="16.65"
                />
              </svg>
            </button>
          </slot>
          <div
            v-show="showDropdown"
            ref="dropdown"
            class="absolute left-0 right-0 top-full z-50 max-h-64 overflow-y-auto rounded-md border-gray-200 bg-white shadow-md"
            @click="focus"
          >
            <slot
              v-if="$slots.dropdown"
              name="dropdown"
              :close="close"
            />
          </div>
        </template>
      </form-input>
    </slot>
  </div>
</template>

<script setup lang="ts">
import FormInput from '../deprecated/FormInput.vue'
import { nextTick, ref, watch } from 'vue'
import { debounce } from '../../utils/timing'

const emit = defineEmits(['update:modelValue', 'search', 'open', 'close'])

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  timeout: {
    type: Number,
    default: 500,
  },
  immediate: {
    type: Boolean,
    default: false,
  },
})

const dropdown = ref(null)

function open() {
  showDropdown.value = true
  emit('open')
  // nextTick(() => {
  //     document.addEventListener('click', close)
  // })
}

function clear() {
  emit('update:modelValue', '')
  focus()
}

function blur($event) {
  showDropdown.value = false
  emit('close')
}

function close() {
  showDropdown.value = false
  console.log('close')
  emit('close')
  document.removeEventListener('click', close)
}

const showDropdown = ref(false)

const modelValueDebounce = debounce(
  () => {
    if (props.modelValue.length < 1) {
      return
    }
    console.log('searching', props.modelValue)
    emit('search', props.modelValue)
  },
  props.timeout,
  props.immediate
)

watch(
  () => props.modelValue,
  (value) => {
    modelValueDebounce()
  }
)
</script>

<style scoped></style>
