<template>
  <div class="relative mt-3 flex w-full flex-col gap-10 bg-white px-2">
    <div class="flex w-full flex-col items-center justify-center">
      <Link
        :href="
          '/event/' +
            props.conventionEvent.slug +
            '/game/scavenger-hunt/sessions/create'
        "
        class="relative flex w-fit flex-col items-center justify-center gap-0 rounded-lg bg-blue px-10 py-3"
      >
        <div class="font-bold text-white">
          Create a Game
        </div>
        <div class="text-base text-indigo-600">
          and invite your friends
        </div>
      </Link>
    </div>
    <div class="flex w-full flex-col gap-4">
      <div>
        <h2 class="text-2xl font-bold">
          Join games. Meet new friends.
        </h2>
        <p class="text-sm text-gray-500">
          We cap each game to a small number of fans, and once maxed out, we'll
          create a new game.
        </p>
      </div>
      <div class="flex flex-col gap-3">
        <option-selector
          v-if="selectedEventId"
          :initial-id="selectedEventId.value"
          :options="createWeekDayOptions"
          @option-selected="handleSelectEventActivityId"
        />
        <div class="flex flex-col gap-3">
          <div
            v-for="(gameSession, key) in gameSessions"
            :key="key"
            class="flex cursor-pointer flex-row items-stretch justify-between gap-0 overflow-hidden rounded-xl border border-indigo-400 hover:bg-indigo-100"
          >
            <div
              class="flex w-full flex-col justify-start gap-4 p-4"
              @click="handleGameSessionRowClick(gameSession)"
            >
              <div class="font-bold">
                {{ getGameSessionName(gameSession) }}
              </div>
              <user-avatar-group
                v-if="gameSession.users.length > 0"
                :key="userAvatarGroupUpdateKey.value"
                class="w-full max-w-xs"
                :show-user-counts="false"
                size="sm"
                :users="gameSession.users"
                :max="8"
              />
              <div
                v-else
                class="text-sm opacity-50"
              >
                Be the first to join!
              </div>
            </div>
            <button
              class="flex w-28 cursor-pointer flex-col items-center justify-center text-base font-bold"
              :class="getGameSessionButtonClass(gameSession)"
              @click="handleGameSessionJoinButtonClicked(gameSession)"
            >
              {{ getGameSessionButtonName(gameSession) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  ref,
  useAttrs,
  watch,
} from 'vue'
import { Link } from '@inertiajs/vue3'
import OptionSelector from '../../../components/input/OptionSelector.vue'
import UserAvatarGroup from '../../../components/ui/UserAvatarGroup.vue'
import axios from 'axios'
const emit = defineEmits(['join-game-session', 'leave-game-session'])


















































defineOptions({
  name: 'EventGameSessions',
})


















































const props = defineProps({
  conventionEvent: {
    type: Object,
    required: true,
  },
  eventActivitiesWithGameSessions: {
    type: Array,
    required: true,
  },
})

const createWeekDayOptions = computed(() => {
  return props.eventActivitiesWithGameSessions.map((eventActivity) => {
    return {
      id: eventActivity.id,
      event_activity_id: eventActivity.id,
      date: eventActivity.date,
      name: eventActivity.weekday,
      caption: eventActivity.short_date,
    }
  })
})

const selectedEventId = ref(null)

watch(selectedEventId, (newVal) => {
  if (newVal) {
    const matchedEventActivity = props.eventActivitiesWithGameSessions.find(
      function (eventActivity) {
        return eventActivity.id === newVal
      }
    )

    gameSessions.value = matchedEventActivity
      ? matchedEventActivity.unfilled_game_sessions
      : []
  }
})

const userAvatarGroupUpdateKey = ref(0)

// const eventActivitiesWithGameSessions = ref([]);
const gameSessions = ref([])

const attrs = useAttrs()
const currentUser = computed(() => {
  return store.currentUser ?? null
})

const store = inject('store')

function handleSelectEventActivityId(id) {
  selectedEventId.value = id
}

function handleGameSessionRowClick(gameSession) {
  if (gameSession.is_attending) {
    window.location.href = gameSession.path
  } else {
    joinGameSession(gameSession)
  }
}

const getSessionsOfGamesByEventActivity = computed(() => {
  if (!selectedEventId.value) {
    return []
  }
  const matchedEventActivity = props.eventActivitiesWithGameSessions.find(
    function (eventActivity) {
      return eventActivity.id === selectedEventId.value
    }
  )
  return matchedEventActivity ? matchedEventActivity.unfilled_game_sessions : []
})

function getGameSessionButtonClass(gameSession) {
  if (gameSession.is_attending) {
    return 'bg-slate-200 hover:bg-slate-300 text-slate-600'
  }
  return 'bg-indigo-400 hover:bg-indigo-600 text-indigo-900'
}

function getGameSessionButtonName(gameSession) {
  if (gameSession.is_attending) {
    return 'Leave'
  }
  return 'Join in'
}

function handleGameSessionJoinButtonClicked(gameSession) {
  if (gameSession.is_attending) {
    leaveGameSession(gameSession)
  } else {
    joinGameSession(gameSession)
  }
}

function joinGameSession(gameSession) {
  axios
    .post('/api/game-session-user', {
      game_session_id: gameSession.id,
      user_id: currentUser.value.id,
    })
    .then((response) => {
      if (response.status === 201) {
        const returnedGameSession = response.data.game_session
        console.log(returnedGameSession)
        updateGameSessionInArray(gameSession.id, returnedGameSession)
        userAvatarGroupUpdateKey.value = Date.now()
        emit('join-game-session', returnedGameSession)
      } else {
        alert(response.data)
      }
    })
}

function leaveGameSession(gameSession) {
  axios
    .delete('/api/game-session-user/' + gameSession.slug, {
      data: {
        user_id: currentUser.value.id,
      },
    })
    .then((response) => {
      if (response.status === 204) {
        gameSession.is_attending = false
        gameSession.users = gameSession.users.filter((user) => {
          return user.id !== currentUser.value.id
        })
        userAvatarGroupUpdateKey.value = Date.now()
        emit('leave-game-session', gameSession)
      } else {
        alert(response.data)
      }
    })
}

function updateGameSessionInArray(oldGameSessionId, newGameSession) {
  const index = gameSessions.value.findIndex(
    (gameSession) => gameSession.id === oldGameSessionId
  )
  if (index !== -1) {
    gameSessions.value[index] = newGameSession
  }
}

onMounted(() => {
  selectedEventId.value = props.eventActivitiesWithGameSessions[0].id
})

const getGameSessionName = (gameSession) => {
  if (gameSession.name) {
    return gameSession.name
  }
  return 'Game Session'
}

onBeforeMount(() => {
  // TODO: Create new initial select logic
  // const currentDate = new Date().toISOString().split('T')[0];
  // const availableDates = Object.entries(props.gameSessionsByDate).map(([date, gameSessions]) => date);
  // if (availableDates.includes(currentDate)) {
  //     selectedDate.value = currentDate;
  // } else {
  //     selectedDate.value = availableDates.reduce((a, b) => {
  //         return Math.abs(new Date(a) - new Date(currentDate)) < Math.abs(new Date(b) - new Date(currentDate)) ? a : b;
  //     });
  // }
})
</script>


<style scoped></style>
