<template>
  <button
    :class="getCardClasses"
    class="flex min-h-[7rem] w-64 shrink-0 select-none flex-col justify-between gap-4 self-stretch rounded-lg p-4 sm:w-72"
    @click="emit('selectActivity', meetup)"
  >
    <div class="flex w-full flex-col items-start justify-start gap-1 text-left">
      <div
        :class="getTagTypeColor"
        class="text-sm"
      >
        {{ getMeetupTypeName }}
      </div>
      <h3 class="text-base font-semibold leading-tight">
        {{ meetup.name }}
      </h3>
      <div class="flex items-center gap-1 text-sm opacity-75">
        <div class="">
          {{ formatDate(meetup.date) }}
        </div>
        &#8226;
        <div class="">
          {{ meetup.starts_at.replace(' AM', 'a').replace(' PM', 'p') }} -
          {{ meetup.ends_at.replace(' AM', 'a').replace(' PM', 'p') }}
        </div>
      </div>
    </div>
    <div>
      <user-avatar-group
        :clickable-user="false"
        :max="6"
        :show-user-counts="false"
        :total-user-count="getAttendeeCount"
        :users="meetup.attendees"
        size="sm"
      />
    </div>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import UserAvatarGroup from './UserAvatarGroup.vue'
const emit = defineEmits(['selectActivity'])




















































defineOptions({
  name: 'MiniActivityCard',
})




















































const props = defineProps({
  meetup: {
    type: Object,
    required: true,
  },
})

const getAttendeeCount = computed(() => {
  // TODO: May need to update the ActivityResource to include Attendee count
  return props.meetup.attendees.length
})

const getCardClasses = computed(() => {
  return [getTypeColor.value + '-100', 'hover:' + getTypeColor.value + '-200']
})

const getTypeColor = computed(() => {
  return 'bg-' + (props.meetup.type_tag?.color_name ?? 'rose')
})

const getTagTypeColor = computed(() => {
  return 'text-' + (props.meetup.type_tag?.color_name ?? 'rose') + '-500'
})

const getMeetupTypeName = computed(() => {
  return props.meetup.type?.name ?? 'No Type'
})

const formatDate = (date) => {
  const d = new Date(date)
  const options = { weekday: 'short', month: 'short', day: 'numeric' }
  return d.toLocaleDateString('en-US', options)
}

const formatTime = (time) => {
  const [hour, minute] = time.split(':')
  const d = new Date()
  d.setHours(hour, minute)
  return d.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })
}
</script>


<style scoped></style>
