<template>
  <section
    class="mx-auto flex w-full max-w-[1200px] flex-col gap-8 px-4 py-20 md:px-8"
    :style="dynamicStyle"
  >
    <h2
      :style="{
        color: palette?.highlight ? palette.highlight : '#e0d666',
      }"
      class="mx-auto text-center font-heading text-[40px]"
    >
      Raffle prizes
    </h2>
    <div class="flex max-w-full items-center gap-6 overflow-scroll">
      <prizes-card
        v-for="(item, index) in [1, 2, 3, 4]"
        :key="index"
        image="/images/backgrounds/hero-bg.png"
        title="Title"
        subtitle="subtitile sdkfjhdskjfhkd sdkfjhds sdhj"
        description="jksdf ksejhr sdkjdf weurb xfdoigu wkehrgsdfcvx wekjrfgd"
      />
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import PrizesCard from '@/shared/components/event/sections/elements/PrizesCard.vue'

const props = defineProps({
  palette: {
    type: Object,
    default: () => ({}),
  },
  imagePath: {
    type: String,
    default: '',
  },
})

function hexToRgba(hex, alpha) {
  let r = 0,
    g = 0,
    b = 0

  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const dynamicStyle = computed(() => {
  const primaryColor = props.palette?.primary
    ? hexToRgba(props.palette.primary, 0.75)
    : hexToRgba('#1613BD', 0.75)

  const secondaryColor = props.palette?.secondary
    ? hexToRgba(props.palette.secondary, 0.75)
    : hexToRgba('#302E85', 0.75)
  return `
    background: 
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), 
      linear-gradient(180deg, ${secondaryColor} 0%, ${primaryColor} 100%), 
      url(${props.imagePath}), 
      lightgray 50% / cover no-repeat;
      background-repeat: no-repeat;
      background-size: cover;
  `
})
</script>

<style lang="scss" scoped></style>
