<template>
  <section
    class="flex w-full flex-col items-center gap-8 bg-text-white px-4 py-10 sm:px-8"
  >
    <div
      class="flex w-full max-w-[968px] flex-col items-center gap-8 rounded-lg border-slate-300 px-0 py-4 sm:border sm:px-8 sm:py-10"
    >
      <h3
        class="relative flex w-full flex-col items-center gap-3 text-center font-sans text-2xl font-bold capitalize text-very-dark-blue"
      >
        Our rules and Terms

        <button
          class="right-0 flex cursor-pointer select-none items-center gap-2 font-sans text-sm font-bold text-blue-primary sm:absolute"
          @click="
            modalHandler.openType('edit-rules-dialog', {
              props: {
                form: group.form({
                  only: ['rules'],
                }),
              },
              onClosed: () => {
                queryClient.invalidateQueries({
                  queryKey: ['groups', group.id],
                })
              },
            })
          "
        >
          <edit-icon-v2
            class="h-4 w-4 text-blue-primary"
            icon="plus"
          />
          Edit
        </button>
      </h3>

      <accordion-list
        v-if="group.rules?.length"
        :items="group.rules"
        class="flex w-full flex-col gap-12"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { useModal } from '@/utils/modal'

import EditIconV2 from '@/icons/EditIconV2.vue'
import AccordionList from '@/components/ui/AccordionList.vue'
import UserGroup from '@/models/UserGroup'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps({
  group: {
    type: Object as PropType<UserGroup>,
    required: true,
  },
})

const queryClient = useQueryClient()

const modalHandler = useModal()
</script>
