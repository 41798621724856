<template>
  <div
    class="flex h-auto group/card w-full select-none flex-col flex-wrap gap-4 rounded-md bg-white p-5 px-2 shadow-lg sm:flex-nowrap md:min-h-[340px] md:flex-row md:gap-0 md:px-4 lg:px-6 xl:px-8"
  >
    <div class="flex flex-col gap-2 md:hidden">
      <div class="flex flex-row flex-wrap justify-between">
        <div class="line-clamp-1 flex flex-nowrap gap-1">
          <span
            v-for="tag in tags"
            :key="tag.id"
            class="text-sm text-blue-neon"
          >
            {{ tag }}
          </span>
        </div>
        <slot name="info-top" />
      </div>
      <Link
        :href="link"
        class="text-2xl font-bold"
      >
        {{ name }}
      </Link>
    </div>
    <Link
      :disabled="link === '#'"
      :href="link"
      as="button"
      class="w-full cursor-pointer rounded-lg md:w-auto"
    >
      <background-image-component
        :src="banner"
        class="h-40 w-full group-hover/card:shadow rounded-md bg-cover bg-center md:h-full md:w-[300px] lg:w-[400px]"
        fallback-src="/images/placeholder_meal.jpg"
      >
        <div
          :style="{ opacity: scrollPercent }"
          class="flex h-full flex-col items-start justify-between overflow-hidden rounded bg-gradient-to-b from-black/50 from-10% via-black/[0.13] via-[25%] to-black/0 to-[37%] sm:rounded-lg"
        >
          <div
            :style="{
              transform: `translateY(${scrollPercent * 10}%)`,
            }"
            class="flex flex-col"
          >
            <slot
              :opacity="scrollPercent"
              name="overlay"
            />
          </div>
        </div>
      </background-image-component>
    </Link>
    <div
      ref="scrollContainer"
      class="scrollbar-hide flex w-full flex-col items-center overflow-x-hidden md:snap-x md:snap-mandatory md:scroll-ps-9 md:flex-row md:overflow-x-scroll"
      @scroll="handleScroll"
    >
      <!-- Invisible element to force scroll-snap to work on chrome -->
      <div class="snap-start" />

      <div
        :style="{
          opacity: 1 - scrollPercent,
          transform: `translateX(${scrollPercent * -5}%)`,
        }"
        class="-mt-3 hidden h-full w-full min-w-[60%] flex-col gap-6 pl-10 md:sticky md:left-0 md:mt-0 md:flex"
      >
        <slot name="info">
          <div class="flex flex-col gap-4 font-heading">
            <div class="hidden flex-col gap-2 md:flex">
              <div class="flex flex-row flex-wrap justify-between">
                <div class="line-clamp-1 flex flex-nowrap gap-1">
                  <span
                    v-for="tag in tags"
                    :key="tag.id"
                    class="text-sm text-blue-neon"
                  >
                    {{ tag }}
                  </span>
                </div>
              </div>
              <slot name="info-top" />
              <Link
                :href="link"
                class="font-heading text-xl text-dark-grey md:text-2xl font-bold"
              >
                {{ name }}
              </Link>
            </div>
            <div class="hidden md:block">
              <span class="line-clamp-4 font-heading text-base text-paragraph">
                {{ description }}
              </span>
            </div>
            <slot name="info-additions" />
          </div>
        </slot>

        <slot name="other-info" />
      </div>
      <div
        v-if="items.length"
        class="sticky left-0 z-[11] hidden h-full items-center bg-white px-3 shadow shadow-white md:flex"
      >
        <div
          :class="toggledScroll ? 'rotate-180' : ''"
          class="cursor-pointer"
          @click="handleToggleScroll"
        >
          <svg
            fill="none"
            height="17"
            viewBox="0 0 16 17"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 13.1666L5.33337 8.49998L10 3.83331"
              stroke="#ECECF4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              d="M10 13.1666L5.33337 8.49998L10 3.83331"
              stroke="#DADAE7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              d="M10 13.1666L5.33337 8.49998L10 3.83331"
              stroke="#DADEE7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </div>
        <!--        <div-->
        <!--          v-if="toggledScroll"-->
        <!--          class="cursor-pointer"-->
        <!--          @click="handleToggleScroll">-->
        <!--          <svg-->
        <!--            fill="none"-->
        <!--            height="16"-->
        <!--            viewBox="0 0 16 16"-->
        <!--            width="16"-->
        <!--            xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path-->
        <!--              d="M6.00033 3.33329L10.667 7.99996L6.00033 12.6666"-->
        <!--              stroke="#ECECF4"-->
        <!--              stroke-linecap="round"-->
        <!--              stroke-linejoin="round"-->
        <!--              stroke-width="2" />-->
        <!--            <path-->
        <!--              d="M6.00033 3.33329L10.667 7.99996L6.00033 12.6666"-->
        <!--              stroke="#DADAE7"-->
        <!--              stroke-linecap="round"-->
        <!--              stroke-linejoin="round"-->
        <!--              stroke-width="2" />-->
        <!--            <path-->
        <!--              d="M6.00033 3.33329L10.667 7.99996L6.00033 12.6666"-->
        <!--              stroke="#DADEE7"-->
        <!--              stroke-linecap="round"-->
        <!--              stroke-linejoin="round"-->
        <!--              stroke-width="2" />-->
        <!--          </svg>-->
        <!--        </div>-->
      </div>
      <div
        class="scrollbar-hide flex h-full w-full snap-x snap-mandatory flex-row gap-2 overflow-x-scroll shadow shadow-white md:snap-none md:overflow-x-visible"
      >
        <div
          ref="grid"
          class="z-10 flex flex-row gap-4 bg-white p-1 md:gap-2"
        >
          <slot name="items">
            <div
              v-for="item in items"
              :key="item.id"
              class="h-full w-[250px] snap-start"
            >
              <slot
                :item="item"
                name="item"
              />
            </div>
            <div
              v-if="itemsCount && items.length && itemsCount > items.length"
              class="flex items-center"
            >
              <Link
                :href="link"
                as="button"
                class="p-2"
              >
                <div class="flex flex-col items-center justify-center gap-2">
                  <font-awesome-icon
                    class="text-2xl"
                    icon="chevron-right"
                  />
                  <span class="whitespace-nowrap text-sm text-paragraph">
                    View all
                  </span>
                </div>
              </Link>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  link: {
    type: String,
    default: '#',
  },
  banner: {
    type: String,
    default: '/images/placeholder_meal.jpg',
  },
  tags: {
    type: Array,
    default: () => [],
  },
  itemsCount: {
    type: Number,
    default: undefined,
  },
  items: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
})

const scrollPercent = ref(0)
const toggledScroll = ref(false)
const scrollContainer = ref(null)
const grid = ref(null)

function handleScroll() {
  scrollPercent.value = Math.min(1, Math.max(0, event.target.scrollLeft / 250))
  if (scrollPercent.value >= 0.9) {
    toggledScroll.value = true
  } else if (scrollPercent.value <= 0.1) {
    toggledScroll.value = false
  }
}

function handleToggleScroll() {
  if (toggledScroll.value) {
    scrollContainer.value.scrollTo({ left: 0, behavior: 'smooth' })
  } else {
    grid.value.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })
  }
}
</script>

<style scoped></style>
