<template>
  <search-with-dropdown
    :input-style="inputStyle"
    :label="label"
    :model-value="modelValue"
    @search="search"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #inner>
      <slot name="inner" />
    </template>
    <template #before="{ focus, blur }">
      <slot
        v-if="selected"
        name="selected"
      >
        <div
          class="my-2 flex flex-wrap gap-2"
          @click="blur"
        >
          <slot
            v-for="item in selected"
            :key="item.id"
            name="selected-item"
          >
            <div
              class="flex cursor-pointer items-center gap-2 rounded bg-gray-200 p-[6px] text-sm"
              @click="select(item)"
            >
              <slot
                :item="item"
                name="item-label"
              >
                {{ item.label }}
              </slot>
              <svg
                class="h-3 w-3 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6l12 12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </div>
          </slot>
        </div>
      </slot>
    </template>
    <template v-if="selected && showSelected && !showSelectedLabels">
      <div class="flex flex-col">
        <div class="flex justify-between">
          <label class="text-sm">{{ label }}</label>
          <button
            class="text-sm text-gray-800"
            @click="select(null)"
          >
            clear
          </button>
        </div>
        <input-wrapper
          :input-style="inputStyle"
          class="flex-col"
        >
          <slot
            :item="selected"
            name="selected"
          >
            <slot
              :item="selected"
              name="item"
            >
              {{ selected }}
            </slot>
          </slot>
        </input-wrapper>
      </div>
    </template>
    <template
      v-if="!selected || showSelectedLabels"
      #dropdown="{ close }"
    >
      <slot
        :close="close"
        :results="results"
        name="results"
      >
        <div
          v-for="(result, index) in results"
          :key="index"
          class="mb-1 last:mb-0 hover:bg-gray-100"
          @click="handleClickResult(result)"
        >
          <slot
            :item="result"
            name="item"
          >
            {{ result }}
          </slot>
        </div>
      </slot>
    </template>
  </search-with-dropdown>
</template>

<script setup>
import SearchWithDropdown from './SearchWithDropdown.vue'
import InputWrapper from '../deprecated/InputWrapper.vue'

const emit = defineEmits([
  'update:modelValue',
  'update:selected',
  'search',
  'select',
])

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String,
    required: true,
  },
  selected: {
    type: Object,
    default: () => null,
  },
  results: {
    type: Array,
    required: true,
  },
  showSelected: {
    type: Boolean,
    default: true,
  },
  showSelectedLabels: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  inputStyle: {
    type: String,
    default: 'default',
  },
})

function handleClickResult(result) {
  select(result)
  if (props.closeOnSelect) close()
}

function search(value) {
  // if element already selected, don't search
  if (props.selected) return

  emit('search', value)
}

function select(result) {
  emit('select', result)
  emit('update:selected', result)
}
</script>

<style scoped></style>
