<template>
  <div class="flex flex-col gap-6">
    <div class="flex justify-between">
      <label
        class="text-lg font-semibold"
        for=""
      >
        {{ label }}
      </label>
      <button
        :class="{
          'rotate-180 transform': !collapse,
        }"
        class="flex items-center justify-center gap-2 transition-transform duration-100"
        @click="collapse = !collapse"
      >
        <font-awesome-icon
          class="h-4 w-4"
          icon="chevron-down"
        />
      </button>
    </div>
    <accordion-item>
      <div
        v-if="!collapse"
        class="flex flex-col gap-6"
      >
        <form-field-input-search
          v-if="type === 'checkbox'"
          v-model="searchQuery"
          variant="searchbar-white"
        />
        <ul
          ref="listElement"
          :class="[
            direction === 'col'
              ? 'flex-col'
              : 'flex-row gap-4 overflow-y-hidden pb-1',
          ]"
          :style="{
            height: savedHeight ? savedHeight + 'px' : 'auto',
          }"
          class="show-scroll flex max-h-72 overflow-auto"
        >
          <li
            v-if="filteredSuggestions.length === 0"
            class="text-center text-sm text-slate-600"
          >
            No results found.
          </li>
          <li
            v-for="item in filteredSuggestions"
            :key="item[keyBy]"
            @click="check(item)"
          >
            <slot
              :is-checked="checked.includes(item[keyBy])"
              :item="item"
              name="item"
            >
              <div class="flex select-none items-center gap-2 px-2.5 py-1">
                <label
                  :for="item[keyBy] + '-item'"
                  class="h-[18px] w-[18px] shrink-0 cursor-pointer overflow-hidden rounded"
                >
                  <input
                    v-if="['checkbox', 'radio'].includes(type)"
                    :id="item[keyBy] + '-item'"
                    :checked="checked.includes(item[keyBy])"
                    :name="$.uid + '-input'"
                    :type="type"
                    :value="item[keyBy]"
                    class="peer hidden"
                    @input="check(item)"
                  />
                  <span
                    class="flex h-full w-full items-center justify-center rounded border-1.5 border-blue-primary bg-white transition-colors duration-100 peer-checked:bg-blue-primary"
                  >
                    <font-awesome-icon
                      v-if="type === 'checkbox'"
                      class="h-3.5 w-3.5 text-white"
                      icon="check"
                    />
                  </span>
                </label>
                <label
                  :for="item[keyBy] + '-item'"
                  class="line-clamp-1 cursor-pointer text-base"
                >
                  {{ item[searchBy] }}
                  <small
                    v-if="item.count"
                    class="text-xs text-slate-600"
                  >
                    ({{ item.count }})
                  </small>
                </label>
              </div>
            </slot>
          </li>
        </ul>
      </div>
    </accordion-item>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import FormFieldInputSearch from '@/components/input/FormFieldInputSearch.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AccordionItem from '@/components/ui/AccordionItem.vue'

const emit = defineEmits(['update:modelValue'])

const props = withDefaults(
  defineProps<{
    label: string
    type: 'checkbox' | 'radio' | 'select'
    url?: string
    suggestions?: Array<{ value: number | string; label: string }>
    modelValue?: number | string | Array<number | string>
    keyBy?: string
    searchBy?: string
    sort?: ((a: any, b: any) => number) | undefined
    direction?: 'col' | 'row'
  }>(),
  {
    url: undefined,
    suggestions: undefined,
    modelValue: undefined,
    keyBy: 'id',
    searchBy: 'name',
    sort: undefined,
    direction: 'col',
  }
)

const listElement = ref<HTMLUListElement | null>(null)
const savedHeight = ref<number | undefined>(undefined)

const collapse = ref(false)
const searchQuery = ref('')

const suggestionsGetter = computed(() => {
  return props.suggestions ?? []
})

const filteredSuggestions = computed(() => {
  return suggestionsGetter.value
    .filter((item) =>
      item[props.searchBy]
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase())
    )
    .sort((a, b) => {
      // if (checked.value.includes(a[props.keyBy]) + checked.value.includes(b[props.keyBy]) === 1) {
      //   return checked.value.includes(a[props.keyBy]) ? -1 : 1
      // }

      if (props.sort) return props.sort(a, b)
      else {
        if (a.count && b.count) return b.count - a.count
        else return a[props.searchBy].localeCompare(b[props.searchBy])
      }
    })
})

const checked = computed(
  () => props.modelValue?.map((v) => v?.[props.keyBy]) ?? []
)

function check(item) {
  if (props.type === 'checkbox') {
    // check if item is already checked
    if (props.modelValue && checked.value.includes(item[props.keyBy])) {
      // uncheck item
      const res = props.modelValue.filter(
        (v) => v[props.keyBy] !== item[props.keyBy]
      )
      // if no items are checked, set modelValue to undefined
      emit('update:modelValue', res.length > 0 ? res : undefined)
    } else {
      // add item to array
      if (props.modelValue)
        emit('update:modelValue', [...props.modelValue, item])
      else emit('update:modelValue', [item])
    }
  } else {
    if (props.modelValue && checked.value.includes(item[props.keyBy])) {
      emit('update:modelValue', undefined)
    } else emit('update:modelValue', [item])
  }
}

onMounted(() => {
  requestAnimationFrame(() => {
    if (listElement.value) {
      console.log(
        props.label,
        listElement.value.scrollHeight > listElement.value.clientHeight
      )
      if (listElement.value.scrollHeight > listElement.value.clientHeight) {
        savedHeight.value = listElement.value.clientHeight
      }
    }
  })
})
</script>

<style scoped></style>
