<template>
  <div
    :class="[mini ? 'px-1 pb-1' : 'px-3 pb-6']"
    :style="{
      backgroundColor: bgDisabled ? 'transparent' : bgColor,
      borderColor:
        attending.isAttending(activity.id) &&
        !props.styled &&
        !props.hideAttending
          ? borderColor
          : 'transparent',
    }"
    class="card flex cursor-pointer flex-col rounded-lg border-4 pt-2 transition-colors duration-75"
    @mouseenter="mouseOver = true"
    @mouseleave="mouseOver = false"
    @click.stop="handlePresenterClick"
  >
    <div
      :class="[
        mini ? 'px-1' : 'px-3',
        {
          'flex-row': direction === 'row',
          'flex-col': direction === 'col',
          'max-w-sm md:max-w-md': limitWidth,
        },
      ]"
      class="sticky left-8 flex w-full items-start justify-between gap-2"
    >
      <div class="flex flex-col gap-2">
        <div :class="{ 'flex flex-col gap-1': styled }">
          <slot name="typeTag">
            <div
              v-if="activity.typeTag && !styled"
              :class="[mini ? 'text-xs' : 'text-sm']"
              :style="{
                color: colorScale[6],
              }"
              class="select-none"
              v-html="highlightText(activity.typeTag.name)"
            />
          </slot>
          <div
            :class="[
              mini ? 'line-clamp-2 text-xs' : 'line-clamp-3',
              { 'font-sans': styled },
            ]"
            class="select-none font-bold leading-tight"
            v-html="highlightText(activity.name)"
          />
          <slot name="typeTag">
            <div
              v-if="activity.typeTag && styled"
              class="w-fit rounded-full bg-black px-2.5 py-1 text-sm text-white"
              v-html="highlightText(activity.typeTag.name)"
            />
          </slot>
          <small
            v-if="activity.highlightTags && activity.highlightTags.length > 0"
            class="text-sm"
          >
            <slot name="highlightTags">
              <div class="mt-1 flex flex-row gap-8">
                <div
                  v-for="(tag, index) in activity.highlightTags"
                  :key="index"
                  :style="{
                    backgroundColor: colorScale[2],
                    color: colorScale[6],
                  }"
                  class="select-none rounded-full px-3"
                >
                  {{ tag.name }}
                </div>
              </div>
            </slot>
          </small>
        </div>
        <slot
          v-if="styled"
          name="time"
        >
          <div
            :class="{ 'mb-2': expanded }"
            class="flex select-none flex-col font-sans text-sm sm:flex-row"
          >
            {{ time }}
            <span>• {{ activity.venue.name }}</span>
          </div>
        </slot>
        <slot
          v-if="expanded && !mini"
          name="description"
        >
          <div
            :class="[
              expanded ? '' : mini ? 'line-clamp-1' : 'line-clamp-3',
              { 'mb-2 font-sans text-sm': styled },
            ]"
            class="max-w-prose select-none text-base"
            v-html="highlightText(activity.short_description)"
          />
        </slot>
        <div class="select-none">
          <div class="flex flex-col items-center justify-start text-sm">
            <div class="flex w-full flex-row justify-start gap-2">
              <template v-if="!hideDate">
                <div>
                  {{ activity.readableDate }}
                </div>
                <div class="opacity-60">from</div>
              </template>
              <slot
                v-if="!styled"
                name="time"
              >
                <div>
                  {{ time }}
                </div>
              </slot>
            </div>
            <slot
              v-if="!styled"
              name="location"
            >
              <div
                class="truncate-lines-1 flex w-full flex-row hover:text-clip"
              >
                {{
                  [
                    activity.station?.name,
                    activity.space?.name,
                    activity.venue?.name,
                    activity.venue?.parent_venue?.name,
                  ]
                    .filter((l) => l)
                    .join(' • ')
                }}
              </div>
            </slot>
          </div>
        </div>
      </div>
      <div
        v-if="expanded"
        :class="[
          mini ? 'gap-1' : 'gap-4',
          {
            'w-full flex-row': direction === 'col',
            'flex-col justify-center': direction === 'row' || mini,
            'hover-buttons': hoverButtons,
            'flex-row-reverse': buttonReverse,
            'flex-col-reverse': mini,
            'justify-[start] flex-col md:flex-row': styled,
          },
        ]"
        class="mt-4 flex justify-start"
      >
        <button
          :class="mini ? 'w-full' : 'border px-8 py-2.5 hover:saturate-150'"
          :style="{
            color: colorScale[8],
            backgroundColor: bgColor,
            borderColor: colorScale[8],
          }"
          class="cursor-pointer select-none whitespace-nowrap rounded-full text-sm font-semibold md:text-base"
          @click.stop="emit('open')"
        >
          More Details
        </button>
        <button
          v-if="!attending.isAttending(activity.id)"
          :style="{
            backgroundColor: colorScale[6],
            color: invert(colorScale[6], true),
          }"
          as="button"
          class="cursor-pointer select-none rounded-full px-8 py-2.5 text-sm font-semibold text-white hover:shadow hover:brightness-150 md:px-10 md:text-base"
          @click.stop="rsvp"
        >
          RSVP
        </button>
        <button
          v-else
          class="cursor-pointer select-none rounded-full bg-slate-500 px-8 py-2.5 text-sm font-semibold text-white hover:bg-slate-800 hover:text-white md:px-10 md:text-base"
          @click.stop="unrsvp"
        >
          unRSVP
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { DateTime } from 'luxon'
import { useAttending } from '@/utils/attending'
import chroma from 'chroma-js'
import invert from 'invert-color'

const emit = defineEmits(['rsvp', 'unrsvp', 'open'])

const props = defineProps({
  activity: {
    type: Object,
    default: () => ({}),
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  disableClick: {
    type: Boolean,
    default: true,
  },
  direction: {
    type: String,
    default: 'col',
  },
  limitWidth: {
    type: Boolean,
    default: false,
  },
  hoverButtons: {
    type: Boolean,
    default: false,
  },
  highlightQuery: {
    type: String,
    default: null,
  },
  hoverHidden: {
    type: Boolean,
    default: false,
  },
  buttonReverse: {
    type: Boolean,
    default: false,
  },
  bgDisabled: {
    type: Boolean,
    default: false,
  },
  mini: {
    type: Boolean,
    default: false,
  },
  styled: {
    type: Boolean,
    default: false,
  },
  hideAttending: {
    type: Boolean,
    default: false,
  },
  hideDate: {
    type: Boolean,
    default: true,
  },
  opaque: {
    type: Boolean,
    default: false,
  },
  solid: {
    type: Boolean,
    default: false,
  },
})

const store = inject('store')

const attending = useAttending()

const colors = [
  'amber',
  'blue',
  'cyan',
  'emerald',
  'fuchsia',
  'orange',
  'purple',
  'rose',
  'teal',
]

const getRandomColor = function () {
  return colors[props.activity.id % colors.length]
}

function getActivityColor(typeTag) {
  if (typeTag) {
    if (typeTag.color_name) {
      return typeTag.color_name
    } else if (typeTag.color) {
      if (typeTag.color?.startsWith('#')) {
        return typeTag.color
      }
      return typeTag.color
    } else {
      return getRandomColor()
    }
  } else {
    return getRandomColor()
  }
}

function highlightText(text) {
  if (props.highlightQuery === null) {
    return text
  } else {
    let keys = props.highlightQuery.split(' ').filter((k) => k?.length > 0)
    const regex = new RegExp(keys.join('|'), 'gi')
    return text?.replace(
      regex,
      (match) => `<span class="bg-yellow-300">${match}</span>`
    )
  }
}

const time = computed(() => {
  let start =
    typeof props.activity?.starts_at === 'string'
      ? DateTime.fromISO(props.activity?.starts_at, { setZone: true })
      : props.activity?.starts_at
  let end =
    typeof props.activity?.ends_at === 'string'
      ? DateTime.fromISO(props.activity?.ends_at, { setZone: true })
      : props.activity?.ends_at
  if (start && end)
    return `${start?.toFormat('h:mma').toLowerCase().replace('m', '')} - ${end?.toFormat('h:mma').toLowerCase().replace('m', '')}`
  return ''
})

function handlePresenterClick() {
  if (!props.disableClick) {
    emit('open', props.activity.id)
  }
}

function rsvp() {
  attending.rsvp(props.activity).then(() => {
    emit('rsvp', props.activity.id)
  })
}

function unrsvp() {
  attending.unrsvp(props.activity).then(() => {
    emit('unrsvp', props.activity.id)
  })
}

onMounted(() => {
  console.log('mounted schedule activity presenter')
})

const mouseOver = ref(false)
const colorScale = computed(() => {
  return chroma
    .scale(['white', props.activity.typeTag?.color ?? '#00f', 'black'])
    .mode('lrgb')
    .correctLightness()
    .colors(10)
    .slice(0)
})
const bgBaseColor = computed(() => {
  return chroma(colorScale.value[props.solid || props.opaque ? 2 : 1])
})
const bgHoverColor = computed(() => {
  const opacity = props.solid ? 2 : props.opaque ? 5 : 2
  return chroma(colorScale.value[opacity])
})

const bgColor = computed(() => {
  return mouseOver.value && !props.hoverHidden
    ? bgHoverColor.value
    : bgBaseColor.value
})
const borderColor = computed(() => {
  return chroma(colorScale.value[4])
})
</script>

<style scoped>
.hover-buttons {
  opacity: 0;
  transition: 0.1s;
}

.card:hover .hover-buttons {
  opacity: 1;
}
</style>
