<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M2.25 13.4393V16.2518H5.0625L13.3575 7.95683L10.545 5.14433L2.25 13.4393ZM15.5325 5.78183C15.602 5.71245 15.6572 5.63003 15.6948 5.5393C15.7325 5.44857 15.7518 5.35131 15.7518 5.25308C15.7518 5.15486 15.7325 5.05759 15.6948 4.96686C15.6572 4.87613 15.602 4.79372 15.5325 4.72433L13.7775 2.96933C13.7081 2.89981 13.6257 2.84464 13.535 2.80701C13.4442 2.76937 13.347 2.75 13.2488 2.75C13.1505 2.75 13.0533 2.76937 12.9625 2.80701C12.8718 2.84464 12.7894 2.89981 12.72 2.96933L11.3475 4.34183L14.16 7.15433L15.5325 5.78183Z"
      fill="currentColor"
    />
    <path
      d="M2.25 13.4393V16.2518H5.0625L13.3575 7.95683L10.545 5.14433L2.25 13.4393ZM15.5325 5.78183C15.602 5.71245 15.6572 5.63003 15.6948 5.5393C15.7325 5.44857 15.7518 5.35131 15.7518 5.25308C15.7518 5.15486 15.7325 5.05759 15.6948 4.96686C15.6572 4.87613 15.602 4.79372 15.5325 4.72433L13.7775 2.96933C13.7081 2.89981 13.6257 2.84464 13.535 2.80701C13.4442 2.76937 13.347 2.75 13.2488 2.75C13.1505 2.75 13.0533 2.76937 12.9625 2.80701C12.8718 2.84464 12.7894 2.89981 12.72 2.96933L11.3475 4.34183L14.16 7.15433L15.5325 5.78183Z"
      fill="currentColor"
    />
  </svg>
</template>
