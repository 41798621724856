import {openDB} from "idb";

/**
 * Get the database from indexedDB. If the table is not found, it will increment the version and try again
 * @param database
 * @param table
 * @param version
 */
export async function getDB<T>(database: string, table: string, version: number | undefined = undefined) {
    // open current version of the database
    const db = await openDB<T>(database, version, {
        upgrade(db) {
            db.createObjectStore(table)
        },
    })
    if (!db) {
        throw new Error('Could not open idb')
    }

    // if the table is not found, increment the version and try again
    if (!db.objectStoreNames.contains(table)) {
        return getDB<T>(database, table, db.version + 1)
    }
    return db
}