// www/app.js

import '../css/app.css'
import '../css/fankind.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowLeft,
  faAward,
  faBars,
  faBell,
  faCalendarAlt,
  faCalendarDays,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faClose,
  faComment,
  faComments, faEllipsis,
  faFaceFrown,
  faFaceMeh,
  faFaceSmileBeam,
  faFileUpload,
  faFilter,
  faFire,
  faGift,
  faHamburger,
  faHandshakeAngle,
  faHeart as fasHeart,
  faHome,
  faIdCardClip,
  faImage,
  faLink,
  faLinkSlash,
  faList,
  faLocation,
  faLocationDot,
  faMapMarkerAlt, faMinus,
  faNewspaper,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPeopleGroup,
  faPlus,
  faReply,
  faSearch,
  faSpinner,
  faSquarePen,
  faStar,
  faTicket,
  faTrash,
  faTriangleExclamation,
  faTrophy,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCircle,
  faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons'
import {
  duplicateInitialPageProps,
  initAuthInterceptor,
  initInertiaApp,
} from '@/utils/setup'
import { VueQueryPlugin } from '@tanstack/vue-query'

library.add(
  faFileUpload,
  faUser,
  faLink,
  faLinkSlash,
  faTriangleExclamation,
  faReply,
  faBell,
  faComments,
  faPaperPlane,
  faPaperclip,
  faTrophy,
  faXmark,
  faArrowLeft,
  faAward,
  faBars,
  faCalendarAlt,
  faCalendarDays,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faClose,
  faComment,
  faFaceFrown,
  faFaceMeh,
  faFaceSmileBeam,
  faFilter,
  faFire,
  faHamburger,
  faHandshakeAngle,
  farHeart,
  fasHeart,
  faHome,
  faIdCardClip,
  faList,
  faMapMarkerAlt,
  faLocation,
  faLocationDot,
  faPlus,
  faSearch,
  faSpinner,
  faCircleNotch,
  faStar,
  faTicket,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPeopleGroup,
  faGift,
  faNewspaper,
  faImage,
  faPalette,
  faTrash,
  faSquarePen,
  faMinus,
  faEllipsis,
)

initInertiaApp('./views/www', {
  pages: import.meta.glob('./views/www/**/*.vue', { eager: true }),
  onSetup(appInstance, store, props) {
    appInstance.component('FontAwesomeIcon', FontAwesomeIcon)

    duplicateInitialPageProps(store, props)

    appInstance.use(VueQueryPlugin)
  },
  mixin: {
    methods: {
      devicePlatform() {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS'
        }

        if (/android/i.test(userAgent)) {
          return 'Android'
        }

        return 'Desktop'
      },
      isNativeMapAppAvailable() {
        const platform = this.devicePlatform()

        return platform === 'iOS' || platform === 'Android'
      },
      openMap(latitude, longitude) {
        const platform = this.devicePlatform()

        if (platform === 'iOS') {
          window.open(`maps://maps.apple.com/?q=${latitude},${longitude}`)
        } else if (platform === 'Android') {
          window.open(`geo:${latitude},${longitude}?q=${latitude},${longitude}`)
        } else {
          window.open(`https://www.google.com/maps?q=${latitude},${longitude}`)
        }
      },
    },
  },
})
