<template>
  <user-settings-layout>
    <div class="flex flex-col gap-3 rounded border px-3 py-2 shadow">
      <h1 class="text-xl font-normal">
        Email Address
      </h1>

      <input-field
        v-model="form.email"
        type="email"
        :error="form.errors.email"
        label="Email Address"
        variant="gray"
      >
        <small class="text-slate-600">
          Your email address is used to log in to your account and to send you
          notifications.
        </small>
      </input-field>

      <div class="flex justify-end">
        <button
          :disabled="!form.isDirty"
          class="rounded bg-blue-primary px-4 py-2 font-bold text-white hover:brightness-90 disabled:bg-blue-700"
          @click="form.put(`/users/${props.user.id}`)"
        >
          Save
        </button>
      </div>
    </div>

    <div class="flex flex-col gap-3 rounded border px-3 py-2 shadow">
      <h1 class="text-xl font-normal">
        Password
      </h1>

      <input-field
        v-model="passwordForm.current_password"
        type="password"
        :error="passwordForm.errors.current_password"
        label="Your Password"
        variant="gray"
      >
        <small class="text-slate-600">
          Enter your current password to change it.
        </small>
      </input-field>

      <input-field
        v-model="passwordForm.password"
        type="password"
        :error="passwordForm.errors.password"
        label="New Password"
        variant="gray"
      >
        <small class="text-slate-600">
          Enter your new password.
        </small>
      </input-field>

      <div class="flex justify-end">
        <button
          :disabled="!passwordForm.isDirty || passwordForm.password.length === 0 || passwordForm.current_password.length === 0"
          class="rounded bg-blue-primary px-4 py-2 font-bold text-white hover:brightness-90 disabled:bg-blue-700"
          @click="passwordForm.put(`/users/${props.user.id}`, { onSuccess: () => passwordForm.reset() })"
        >
          Save
        </button>
      </div>
    </div>
  </user-settings-layout>
</template>

<script setup>
import UserSettingsLayout from '@/components/layout/UserSettingsLayout.vue'
import { useForm } from '@inertiajs/vue3'
import InputField from '@/components/input/InputField.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const form = useForm({
  email: props.user.email,
})

const passwordForm = useForm({
  current_password: '',
  password: '',
})
</script>

<style scoped></style>