<template>
  <div
    :class="[
      'relative m-8 flex flex-col items-center rounded-lg',
      cardSizeClass,
      { 'justify-center': isHovered, 'justify-end': !isHovered },
      contentClass,
    ]"
    :style="backgroundStyle"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      :class="isHovered ? 'bg-white/10 backdrop-blur-sm' : ''"
      class="absolute inset-0 z-[1] rounded-lg"
    ></div>
    <slot
      :is-hovered="isHovered"
      name="content"
    ></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Image from '@/models/Image'

const props = withDefaults(
  defineProps<{
    background: Image | undefined
    type: string
    contentClass?: string
  }>(),
  {
    contentClass: '',
  }
)

const isHovered = ref(false)

const cardSizeClass = computed(() => {
  switch (props.type) {
    case 'meetup':
      return 'h-[338px] w-[472px] p-6'
    case 'group':
    case 'show':
      return 'h-[391px] w-[285px] py-8 px-5'
    case 'game':
      return 'h-[281px] w-[295px] p-6'
    default:
      return ''
  }
})

const backgroundStyle = computed(() => {
  return props.background?.path
    ? `background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), url(${props.background.path}) center/cover no-repeat;`
    : `background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url('/images/branding/fankind_placeholder.png') center/cover no-repeat;`
})
</script>

<style scoped></style>
