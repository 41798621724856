<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M10.0003 10.0241C9.44779 10.0241 8.91789 9.8046 8.52719 9.41389C8.13649 9.02319 7.91699 8.49329 7.91699 7.94076C7.91699 7.38822 8.13649 6.85832 8.52719 6.46762C8.91789 6.07692 9.44779 5.85742 10.0003 5.85742C10.5529 5.85742 11.0828 6.07692 11.4735 6.46762C11.8642 6.85832 12.0837 7.38822 12.0837 7.94076C12.0837 8.21434 12.0298 8.48525 11.9251 8.73801C11.8204 8.99077 11.6669 9.22044 11.4735 9.41389C11.28 9.60735 11.0503 9.76081 10.7976 9.8655C10.5448 9.9702 10.2739 10.0241 10.0003 10.0241ZM10.0003 2.10742C8.45323 2.10742 6.9695 2.722 5.87554 3.81597C4.78157 4.90993 4.16699 6.39366 4.16699 7.94076C4.16699 12.3158 10.0003 18.7741 10.0003 18.7741C10.0003 18.7741 15.8337 12.3158 15.8337 7.94076C15.8337 6.39366 15.2191 4.90993 14.1251 3.81597C13.0312 2.722 11.5474 2.10742 10.0003 2.10742Z"
      fill="currentColor"
    />
  </svg>
</template>
