

<template>
  <div class="flex flex-col relative flex-shrink-0 w-24 z-0 gap-1">
    <comment-indicator
      :count="props.gameSession.unread_comments"
      class="absolute top-0 right-0 z-10"
    />
    <div
      class="flex justify-center text-center relative text-xs text-indigo-800 p-2 items-center w-full aspect-square rounded-full bg-center bg-cover border-2 border-solid"
      :class="getGameSessionBorder"
      :style="getGameSessionStyle"
    >
      {{ getSessionMessage }}
    </div>
    <div
      class="text-slate-800 text-xs text-center relative"
    >
      {{ props.gameSession.name }}
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import CommentIndicator from "../../../components/ui/CommentIndicator.vue";
import {DateTime} from "luxon";
defineOptions({
    name: 'GameSessionItem',
})




















































const props = defineProps({
    gameSession: {
        type: Object,
        required: true,
    },
});

const unseenUpdates = computed(() => {
    return props.gameSession.unseen_updates > 0;
});

const getGameSessionBorder = computed(() => {
    if (unseenUpdates.value === true) {
        return ' border-blue ';
    } else {
        return ' border-white ';
    }
});

const isEnded = computed(() => {
    return DateTime.fromISO(props.gameSession.schedule[0].ends_at) < DateTime.now();
})

const hasSubmissionWithImage = computed(() => {
    return props.gameSession.all_submissions && props.gameSession.all_submissions.length > 0 && props.gameSession.all_submissions[0].image && props.gameSession.all_submissions[0].image.length > 0 && props.gameSession.all_submissions[0].image[0].sm_path;
});

const now = ref(Date.now());

const getStatus = computed(() => {
    const startsAt = new Date(props.gameSession.schedule[0].starts_at);
    const endsAt = new Date(props.gameSession.schedule[0].ends_at);

    if (now.value < startsAt) {
        return 'upcoming';
    } else if (now.value > endsAt) {
        return 'past';
    } else {
        return 'active';
    }
});

const getSessionMessage = computed(() => {
    if (isEnded.value) {
        return 'See Results';
    }
    if (getStatus.value === 'upcoming' && !hasSubmissionWithImage.value) {
        return 'Starts ' + DateTime.fromISO(props.gameSession.schedule[0].starts_at).toUTC().toRelative();
    }
    return '';
});

const getGameSessionStyle = computed(() => {
    const style = {};
    style.background = '';
    if (isEnded.value) {
        style.fontWeight = 'bold';
        style.color = '#ffffff';
        style.background = ' linear-gradient(0deg, rgb(38, 42, 224,0.75), rgb(38, 42, 224,0.75)), ';
    }

    if (hasSubmissionWithImage.value) {
        style.background += ' url(' + props.gameSession.all_submissions.at(-1).image[0].sm_path + ') ';
    } else {
        style.background += ' linear-gradient(180deg, #DAD9FF 3.13%, #ECECF6 100%)  ';
    }

    if (unseenUpdates.value === true) {
        style.boxShadow = ' #ffffff 0px 0px 0px 2px inset ';
    }

    return style;
});
</script>

<style scoped>

</style>
