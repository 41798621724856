<template>
  <transition
    name="expand"
    @enter="enter"
    @leave="leave"
    @after-enter="afterEnter"
  >
    <slot />
  </transition>
</template>

<script lang="ts" setup>
function enter(element: Element) {
  if (!(element instanceof HTMLElement)) return

  const width = getComputedStyle(element).width

  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = 'auto'
  element.style.position = 'static'
  element.style.visibility = 'visible'
  element.style.height = '0px'

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height
  })
}

function afterEnter(element: Element) {
  if (!(element instanceof HTMLElement)) return

  element.style.height = 'auto'
}

function leave(element: Element) {
  if (!(element instanceof HTMLElement)) return

  const height = getComputedStyle(element).height

  element.style.height = height

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = '0px'
  })
}
</script>

<style scoped>


.expand-enter-active,
.expand-leave-active {
  transition: height 100ms ease-in-out;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0;
}
</style>
