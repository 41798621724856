<template>
  <svg
    fill="none"
    viewBox="0 0 18 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4608 10.2266C14.4085 10.1743 14.3465 10.1328 14.2782 10.1045C14.2099 10.0762 14.1367 10.0616 14.0628 10.0616C13.9889 10.0616 13.9157 10.0762 13.8474 10.1045C13.7791 10.1328 13.7171 10.1743 13.6648 10.2266L9.56281 14.3293V3.31206C9.56281 3.16287 9.50355 3.0198 9.39806 2.91431C9.29257 2.80882 9.1495 2.74956 9.00031 2.74956C8.85113 2.74956 8.70805 2.80882 8.60257 2.91431C8.49708 3.0198 8.43781 3.16287 8.43781 3.31206V14.3293L4.33578 10.2266C4.23023 10.121 4.08708 10.0617 3.93781 10.0617C3.78855 10.0617 3.64539 10.121 3.53984 10.2266C3.4343 10.3321 3.375 10.4753 3.375 10.6246C3.375 10.7738 3.4343 10.917 3.53984 11.0225L8.60234 16.085C8.65458 16.1373 8.71662 16.1788 8.78491 16.2071C8.8532 16.2354 8.92639 16.25 9.00031 16.25C9.07423 16.25 9.14743 16.2354 9.21572 16.2071C9.284 16.1788 9.34604 16.1373 9.39828 16.085L14.4608 11.0225C14.5131 10.9703 14.5546 10.9082 14.5829 10.84C14.6112 10.7717 14.6258 10.6985 14.6258 10.6246C14.6258 10.5506 14.6112 10.4774 14.5829 10.4092C14.5546 10.3409 14.5131 10.2788 14.4608 10.2266Z"
      fill="currentColor"
    />
  </svg>
</template>
