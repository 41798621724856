<template>
  <section
    class="flex w-full flex-col items-start gap-10 bg-text-white px-4 py-10 sm:px-8"
  >
    <template v-if="!selectedAlbum">
      <div class="relative flex w-full flex-col items-center gap-8">
        <h3
          class="mx-auto text-center font-sans text-2xl font-bold text-very-dark-blue"
        >
          Recent Photos
        </h3>

        <v-button
          class="right-0 flex w-fit shrink-0 items-center gap-2 sm:absolute"
          no-padding
          size="sm"
          variant="secondary"
          without-border
          @click="
            modalHandler.openType('image-upload-dialog', {
              props: {
                title: 'Upload Photos',
              },
            })
          "
        >
          <font-awesome-icon icon="file-upload" />
          <span>Upload Photos</span>
        </v-button>

        <template v-if="recentPhotos?.length">
          <div
            class="mx-auto flex w-fit flex-col flex-wrap items-center gap-6 sm:flex-row"
          >
            <div
              v-for="(photo, photoID) in recentPhotos?.slice(0, 8)"
              :key="photo.id"
              class="relative h-fit w-fit"
            >
              <dropdown-button
                v-if="enableActions"
                class="!absolute right-2 top-2"
                rounded
                variant="gray"
              >
                <template #button>
                  <font-awesome-icon
                    class="h-6 w-6"
                    icon="ellipsis"
                  />
                </template>
                <template #buttons>
                  <button
                    class="whitespace-nowrap px-2 py-1 text-start text-sm hover:bg-slate-300"
                    @click="emit('delete')"
                  >
                    Delete
                  </button>
                </template>
              </dropdown-button>
              <!-- todo: add dynamic data -->
              <image-component
                :image="photo.image"
                class="h-[240px] w-[290px] cursor-pointer rounded-lg object-cover"
                @click="
                  modalHandler.openType('photo-viewer-dialog', {
                    props: {
                      image: photo,
                      images: recentPhotos,
                    },
                  })
                "
              />
            </div>
          </div>
          <a
            v-if="recentPhotos?.length"
            class="mx-auto cursor-pointer select-none font-sans text-sm font-bold text-blue-primary"
            href=""
          >
            See more
          </a>
        </template>
        <template v-else>
          <form-drop-file-input
            v-model="formPictures.pictures"
            class="mx-auto h-40 w-full max-w-[830px]"
            image-aspect-ratio="1"
            image-resize
            multiple
            image-type
          />
        </template>
      </div>
      <div class="flex w-full flex-col items-center gap-8">
        <h3
          class="relative mx-auto flex w-full flex-col gap-2 text-center font-sans text-2xl font-bold text-very-dark-blue"
        >
          Photo Albums

          <button
            class="right-0 mx-auto flex cursor-pointer select-none items-center gap-2 font-sans text-sm font-bold text-blue-primary sm:absolute"
            @click="
              modalHandler.openType('album-create-dialog', {
                props: {
                  edit: false,
                },
              })
            "
          >
            <font-awesome-icon
              icon="plus"
              class="h-4 w-4 text-blue-primary"
            />
            Create album
          </button>
        </h3>

        <div
          v-if="albums?.length"
          class="flex flex-col flex-wrap items-center gap-6 sm:flex-row"
        >
          <div
            v-for="(album, albumID) in albums"
            :key="albumID"
            :style="`
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%), url(${album.image.path}), lightgray 50%;
          background-repeat: no-repeat;
          background-size: cover;`"
            class="flex h-[222px] w-[290px] cursor-pointer select-none rounded-lg bg-no-repeat object-cover p-4 sm:w-[405px]"
            @click="openAlbum(album)"
          >
            <h6 class="mx-auto mb-0 mt-auto text-lg font-bold text-text-white">
              {{ album.name }}
            </h6>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="selectedAlbum?.length">
      <div class="flex w-full flex-col gap-8">
        <div
          class="relative flex w-full flex-col items-center justify-between gap-4 lg:flex-row"
        >
          <div
            class="left-0 top-0 ml-0 mr-auto flex w-fit shrink-0 items-center gap-2 lg:absolute"
            href=""
          >
            <span class="text-sm text-slate-500">Gallery</span>
            <chevron-right class="h-4 w-4 shrink-0 text-slate-500" />
            <span class="text-sm text-slate-500">Photo Albums</span>
            <chevron-right class="h-4 w-4 shrink-0 text-slate-500" />
            <span class="text-sm font-bold text-slate-800">
              {{ selectedAlbum.name }}
            </span>
          </div>
          <h3
            class="mx-auto text-center text-center font-sans text-2xl font-bold text-very-dark-blue"
          >
            {{ selectedAlbum.name }}
          </h3>
        </div>

        <div class="mx-auto flex w-fit flex-wrap gap-6">
          <image-component
            v-for="(photo, photoID) in selectedAlbum.photos.slice(0, 8)"
            :key="photoID"
            :src="photo"
            class="h-[240px] w-[290px] rounded-lg object-cover"
          />
        </div>
        <a
          class="mx-auto cursor-pointer select-none font-sans text-sm font-bold text-blue-primary"
          href=""
        >
          See more
        </a>
      </div>
    </template>
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import axios from 'axios'

import { useModal } from '@/utils/modal'
import { useForm } from '@inertiajs/vue3'
import { useQuery } from '@tanstack/vue-query'

import DropdownButton from '@/components/buttons/DropdownButton.vue'

import VButton from '@/components/buttons/VButton.vue'
import ChevronRight from '@/icons/ChevronRight.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  userGroup: {
    type: Object,
    required: true,
  },
})

const enableActions = true
const modalHandler = useModal()
// todo: add dynamic data 
const recentPhotos = [
  {
    id: 0,
    image: { path: 'https://picsum.photos/200/300' },
    date: '21.09.2023',
    author: 'Author Name',
  },
  {
    id: 1,
    image: { path: 'https://picsum.photos/300/300' },
    date: '23.09.2023',
    author: 'Author Name',
  },
  {
    id: 2,
    image: { path: 'https://picsum.photos/600/600' },
    date: '26.09.2023',
    author: 'Author Name',
  },
]

// const { data: recentPhotos } = useQuery({
//   queryKey: ['group-recent-photos', props.userGroup.id],
//   queryFn: () => axios.get(`/api/groups/${props.userGroup.id}/images`),
//   select: (data) => data.data,
// })

const { data: albums } = useQuery({
  queryKey: ['group-albums', props.userGroup.id],
  queryFn: () => axios.get(`/api/groups/${props.userGroup.id}/albums`),
  select: (data) => data.data,
})

const selectedAlbum = ref(null)
const openAlbum = (album) => {
  selectedAlbum.value = album
}

const formPictures = useForm({
  pictures: null,
})
</script>
