<template>
  <section
    class="item-center mx-auto flex w-full flex-col gap-12 bg-text-white px-6 py-20 sm:py-[112px] lg:px-20 xl:px-[100px]"
  >
    <h2
      class="text-center text-3xl font-bold leading-[140%] tracking-[0.8px] text-dark-grey lg:text-4xl"
    >
      Always in the loop with Fankind
    </h2>
    <div
      class="mx-auto flex w-full max-w-[1300px] flex-col items-center gap-3 lg:flex-row"
    >
      <convention-news-card
        :item="conventionNewsList[0]"
        class="h-[274px] max-w-[800px] lg:h-[445px] lg:max-w-[444px]"
      />

      <div class="flex w-full flex-col gap-3">
        <convention-news-card
          :item="conventionNewsList[1]"
          class="hidden h-[155px] max-w-[800px] sm:h-[170px] lg:flex lg:h-[250px]"
        />

        <div
          class="mx-auto flex w-full max-w-[800px] flex-row gap-3 lg:max-w-full"
        >
          <convention-news-card
            :item="conventionNewsList[2]"
            class="max-w-1/2 h-[155px] sm:h-[170px] lg:max-w-[395px]"
          />
          <convention-news-card
            :item="conventionNewsList[3]"
            class="max-w-1/2 h-[155px] sm:h-[170px] lg:max-w-[395px]"
          />
        </div>
      </div>
    </div>

    <a
      class="mx-auto flex items-center gap-2 text-center text-lg font-bold text-blue-primary"
    >
      Explore all updates
      <arrow-in-circle />
    </a>
  </section>
</template>

<script setup>
import ArrowInCircle from '@/icons/ArrowInCircle.vue'
import ConventionNewsCard from '@/components/ui/ConventionNewsCard.vue'

const conventionNewsList = [
  {
    title: 'Second wave of Special Guests accounts',
    date: 'Today',
    images: {
      banner: { path: 'images/backgrounds/home/news-image1.png' },
      logo: { path: 'images/backgrounds/comiccon_sandiego_logo.png' },
    },
  },
  {
    title: 'Fan Experiences: Memorable Moments from Recent Conventions',
    date: 'Yesterday',
    images: {
      banner: { path: 'images/backgrounds/home/news-image2.png' },
      logo: { path: 'images/backgrounds/comiccon_sandiego_logo.png' },
    },
  },
  {
    title: 'Convention Industry Insights: Trends and Future...',
    date: '2 days ago',
    images: {
      banner: { path: 'images/backgrounds/home/news-image3.jpeg' },
      logo: { path: 'images/backgrounds/comiccon_sandiego_logo.png' },
    },
  },
  {
    title: 'Global Comic Con Calendar: Find Events Near You',
    date: '4 days ago',
    images: {
      banner: { path: 'images/backgrounds/home/news-image4.png' },
      logo: { path: 'images/backgrounds/comiccon_sandiego_logo.png' },
    },
  },
]
</script>

<style lang="scss" scoped></style>
