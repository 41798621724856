<template>
  <div class="mx-auto w-full max-w-lg flex-col px-6 pb-6 pt-3">
    <div class="mt-20 flex w-full border-b border-gray-200 pb-3 text-center">
      <h1 class="text-2xl font-bold">Review Menu Item</h1>
    </div>
    <form
      class="relative z-10 flex flex-col justify-end rounded-xl bg-white"
      @submit.prevent="submitForm"
    >
      <div class="my-6 flex flex-col gap-8">
        <div class="flex flex-col">
          <label class="text-sm">Restaurant</label>
          <autocomplete-input
            v-model="form.restaurant"
            :options="state.restaurants"
            :prefill="prefill.restaurant"
            option-label="Restaurant"
            @update:model-value="updateRestaurant($event)"
          />
        </div>

        <div class="flex flex-col">
          <label class="text-sm">Menu Item</label>
          <autocomplete-input
            v-model="form.menuItem"
            :options="state.menuItems"
            :prefill="prefill.menu_item"
            option-label="Menu Item"
            @update:model-value="updateMenuItem($event)"
          />
        </div>

        <div class="flex flex-col">
          <label class="text-sm">Rate</label>
          <div class="flex w-full max-w-[280px] flex-col gap-4 text-xl">
            <rating-input
              v-model="form.tasteRating"
              label="Taste"
            />
            <rating-input
              v-model="form.valueRating"
              label="Value"
            />
            <rating-input
              v-model="form.serviceRating"
              label="Service"
            />
          </div>
        </div>

        <div class="flex flex-col">
          <label class="text-sm">Review (Optional)</label>
          <textarea
            v-model="form.review"
            class="rounded bg-gray-200 px-3 py-2"
          />
        </div>

        <div class="flex flex-col">
          <label class="text-sm">Photos (Optional)</label>
          <photo-uploader v-model="form.photos" />
          <div class="text-xs text-gray-500">
            You will receive 2 extra tickets if your photo is used to represent
            the menu item... so take a good photo.
          </div>
        </div>
      </div>

      <div class="mt-5 text-center">
        <button
          :disabled="!isFormValid"
          class="rounded-full bg-blue-primary px-16 py-2.5 text-white"
          type="submit"
        >
          Submit Review
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import AutocompleteInput from '../../../components/input/AutocompleteInput.vue'
import RatingInput from '../../../components/input/RatingInput.vue'
import PhotoUploader from '../../../components/input/PhotoUploader.vue'

const props = defineProps({
  restaurants: {
    type: Array,
    default: () => [],
  },
  menuItems: {
    type: Array,
    default: () => [],
  },
})

const state = ref({
  restaurants: props.restaurants, // Update to pull from new Pinia store
  menuItems: props.menuItems, // Update to pull from new Pinia store
})

const route = usePage()

const prefill = {
  restaurant: route.props.query.restaurant
    ? route.props.query.restaurant || ''
    : '',
  menu_item: route.props.query.menu_item
    ? route.props.query.menu_item || ''
    : '',
}

const updateRestaurant = (payload) => {
  form.restaurant = payload
}

const updateMenuItem = (payload) => {
  console.log('this is updated to ' + payload.value)
  form.menuItem = payload
}

const form = useForm({
  restaurant: null,
  menuItem: null,
  tasteRating: null,
  valueRating: null,
  serviceRating: null,
  review: '',
  photos: [],
})

const isFormValid = computed(
  () =>
    form.restaurant &&
    form.menuItem &&
    form.tasteRating !== null &&
    form.valueRating !== null &&
    form.serviceRating !== null
)

const submitForm = async () => {
  if (!isFormValid.value) return

  // Prepare form data
  const formData = new FormData()

  if (form.restaurant.isNew) {
    formData.append('newRestaurant', form.restaurant.value)
  } else {
    formData.append('restaurantId', form.restaurant.value.id)
  }

  if (form.menuItem.isNew) {
    formData.append('newMenuItem', form.menuItem.value)
  } else {
    formData.append('menuItemId', form.menuItem.value.id)
  }

  formData.append('tasteRating', form.tasteRating)
  formData.append('valueRating', form.valueRating)
  formData.append('serviceRating', form.serviceRating)
  formData.append('review', form.review)

  form.photos.forEach((photo, index) => {
    formData.append(`photos[${index}]`, photo)
  })

  // ... The rest of the submitForm function
}
</script>


<style scoped></style>
