<template>
  <div class="flex min-h-screen flex-col gap-0 bg-white">
    <div
      v-if="form.isUploading"
      class="absolute inset-0 z-10 bg-black bg-opacity-75"
    >
      <div class="flex h-full flex-col items-center justify-center gap-6">
        <font-awesome-icon
          class="animate-spin text-4xl text-white"
          icon="fas fa-spinner"
        />
        <div class="font-bold text-white">Loading...</div>
      </div>
    </div>
    <div
      class="flex flex-col items-center bg-gradient-to-br from-indigo-900 to-blue pb-4"
    >
      <div class="flex w-full flex-row items-center justify-between p-4">
        <div>
          <font-awesome-icon
            class="text-xl text-white opacity-90 hover:opacity-100"
            icon="fas fa-caret-left"
            @click="goBack"
          />
        </div>
        <div
          class="px-2 text-center text-base font-bold leading-snug text-white opacity-90 md:text-lg"
        >
          Challenge
        </div>
        <div />
      </div>
      <div
        class="relative flex w-10/12 max-w-lg flex-col items-center gap-2 md:w-2/3"
      >
        <div class="text-center leading-tight text-white">
          {{ gameChallenge.name }}
        </div>

        <!-- Completed Submission -->
        <div v-if="userSubmission">
          <div class="relative flex w-full">
            <div
              class="absolute -bottom-2 -right-3.5 -rotate-12 rounded-lg bg-tangerine px-2 py-0.5 text-xs text-white md:text-sm lg:text-base"
            >
              +{{ userSubmission.points_earned }}
            </div>
            <!-- Large centered image -->
            <img
              :src="userSubmission.image[0].md_path"
              alt="submitted image"
              class="w-full rounded-lg"
            />
          </div>
          <div>
            <div
              v-if="userSubmission"
              class="p-2 text-center text-base text-indigo-200"
            >
              "{{ userSubmission.image[0].caption }}"
            </div>
          </div>
        </div>

        <!-- CTA to add image -->
        <div
          v-else
          class="relative flex w-full flex-col"
        >
          <form @submit.prevent="submitForm">
            <div
              class="flex aspect-square w-full flex-col items-center justify-center gap-1 rounded-lg border-2 border-dashed border-indigo-600 bg-indigo-500 bg-opacity-20 text-indigo-600 md:gap-4 md:border-4"
              @click="fileInput.click()"
            >
              <template v-if="!form.image_file">
                <font-awesome-icon
                  class="text-4xl"
                  icon="fas fa-camera"
                />
                <div class="text-xl font-bold text-white md:text-2xl">
                  +{{ gameChallenge.points }}
                </div>
              </template>
              <template v-else>
                <img
                  :src="form.image_path"
                  alt="submitted image"
                  class="w-full rounded-lg"
                />
              </template>
            </div>
            <input
              ref="fileInput"
              accept="image/jpeg, image/png, image/webp, image/gif"
              class="hidden"
              name="image"
              type="file"
              @change="handleFileUpload"
            />
            <div
              v-if="form.image_file"
              class="flex w-full flex-col items-end gap-2"
            >
              <textarea
                v-model="form.caption"
                class="mt-4 w-full rounded-lg border-none bg-indigo-500 bg-opacity-20 p-2 text-base text-white outline-none"
                placeholder="Share a fun quote or caption"
                type="text"
              />
              <div
                v-if="errors.caption"
                class="mt-1 text-sm text-red-500"
              >
                {{ errors.caption }}
              </div>
              <button
                class="rounded-lg bg-white bg-opacity-90 px-4 py-2 text-sm font-bold text-blue hover:bg-opacity-100"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <div
            v-if="!form.image_file"
            class="p-2 text-center text-sm text-indigo-300 md:text-base"
          >
            {{ gameChallenge.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full p-2 md:p-4">
      <div v-if="submissions.length > 0">
        <div
          class="mb-4 border-b border-solid border-slate-300 pb-1 text-base text-blue-muted"
        >
          Also completed by
        </div>
        <div
          class="grid w-full grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-3 md:gap-x-4 lg:grid-cols-4 lg:grid-cols-5"
        >
          <div
            v-for="submission in submissions"
            :key="submission.id"
            class="flex flex-col"
          >
            <div
              :style="{
                backgroundImage: `url(${submission.image[0].sm_path})`,
              }"
              class="flex aspect-square w-full rounded-lg bg-cover bg-center"
            />
            <div
              class="flex flex-col p-2 text-base leading-tight text-slate-700"
            >
              <div class="truncate-lines-2 relative inline-flex">
                "{{ submission.image[0].caption }}"
              </div>
              <div class="self-end text-sm italic text-slate-500">
                - {{ submission.user.first_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>Be the first to complete this challenge!</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
defineOptions({
  name: 'ShowGameChallengeSubmission',
})


















































const props = defineProps({
  gameSession: {
    type: Object,
    default: () => null,
  },
  gameChallenge: {
    type: Object,
    required: true,
  },
  submissions: {
    type: Array,
    required: true,
    default: () => [],
  },
  userSubmissionData: {
    type: Object,
    default: () => null,
  },
})

const goBack = () => {
  window.history.back()
}

const userSubmission = ref(null)

const form = ref({
  image_file: null,
  image_path: null,
  caption: '',
  isUploading: false,
})

const errors = reactive({})

const fileInput = ref(null)

const handleFileUpload = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  console.log('please god')

  reader.onload = (e) => {
    console.log('file loaded')
    form.value.image_file = file
    form.value.image_path = e.target.result
  }

  reader.readAsDataURL(file)
}

const submitForm = async () => {
  // Clear previous errors
  errors.caption = ''

  // Perform submission
  try {
    form.value.isUploading = true
    const formData = new FormData()
    formData.append('image_file', form.value.image_file)
    formData.append('caption', form.value.caption)
    if (props.gameSession) {
      formData.append('game_session_id', props.gameSession.id)
    }
    const response = await axios.post(
      '/api/game-challenge/' + props.gameChallenge.id + '/submission',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (response.status === 200) {
      userSubmission.value = response.data
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const { errors: responseErrors } = error.response.data
      if (responseErrors.caption) {
        errors.caption = responseErrors.caption[0]
      }
    } else {
      if (error.response && error.response.data && error.response.data.errors) {
        const { errors: responseErrors } = error.response.data
        if (responseErrors.caption) {
          errors.caption = responseErrors.caption[0]
        }
        // Log the response error to the console
        console.error('Submission error:', error.response)
      } else {
        console.error(error)
      }
    }
  } finally {
    form.value.isUploading = false
  }
}

onMounted(() => {
  userSubmission.value = props.userSubmissionData
})
</script>


<style scoped></style>
