<template>
  <div
    class="group relative flex w-full flex-col gap-6"
    @mouseenter="pause"
    @mouseleave="play"
    @mouseover="pause"
  >
    <div class="mx-auto flex w-fit items-center gap-6">
      <div
        v-for="(featured, index) in event?.featured"
        :key="'featured-' + index"
      >
        <h4
          :style="{
            color:
              index == selectedFeaturedIndex
                ? palette?.primary
                  ? palette.primary
                  : '#1613BD'
                : 'rgb(100 116 139)',
          }"
          class="w-fit cursor-pointer select-none text-center text-base font-normal hover:text-blue-primary xl:text-xl"
          @click="selectedFeaturedIndex = index"
        >
          {{ featured.name }}
        </h4>
        <div
          :style="{
            backgroundColor:
              index == selectedFeaturedIndex
                ? palette?.primary
                  ? palette.primary
                  : '#1613BD'
                : '',
          }"
          class="h-[2px] w-full"
        />
      </div>
    </div>
    <transition-group
      v-if="selectedFeatured && selectedFeatured.type && selectedFeaturedItem"
      :enter-from-class="
        (direction === 1 ? 'translate-x-full' : '-translate-x-full') +
        ' opacity-0 scale-90'
      "
      :leave-to-class="
        (direction === 1 ? '-translate-x-full' : 'translate-x-full') +
        ' opacity-0 scale-90'
      "
      enter-active-class="transition-all duration-500"
      enter-to-class="opacity-100"
      leave-active-class="absolute transition-all duration-500 top-12 md:bottom-0"
      leave-from-class="opacity-100"
      @before-leave="savePosition"
    >
      <div
        v-for="(selected, index) in {
          [selectedFeaturedIndex]: selectedFeatured,
        }"
        :key="selected.type + '-' + selected.item.id"
        :data-index="index"
        class="flex w-full grid-cols-2 flex-col items-start gap-6 md:grid md:h-[500px]"
      >
        <background-image-component
          :fallback-src="
            ['vendor', 'vendor-product'].includes(selected.type)
              ? '/images/placeholder_meal.jpg'
              : undefined
          "
          :image="bannerImage"
          alt="photo of event"
          class="relative h-[295px] w-full shrink-0 overflow-hidden rounded-[12px] bg-cover bg-center transition group-hover:scale-[1.01] group-hover:shadow-xl md:h-full"
        >
          <transition
            enter-active-class="transition-all duration-100"
            enter-from-class="scale-y-0"
            enter-to-class="scale-y-100"
            leave-active-class="transition-all duration-100"
            leave-from-class="scale-y-100"
            leave-to-class="scale-y-0"
            @before-enter="progress = 0"
            @after-enter="
              () => {
                progress = 100
              }
            "
            @after-leave="progress = 0"
          >
            <div
              v-if="!isPaused"
              class="absolute bottom-0 left-0 right-0 z-floating origin-bottom"
            >
              <div
                :style="[
                  {
                    width: `${progress}%`,
                  },
                  isPlaying
                    ? {
                        transition: `width ${AUTOPLAY_INTERVAL}ms linear`,
                      }
                    : {},
                ]"
                class="h-1 bg-very-light-blue bg-opacity-90"
              ></div>
            </div>
          </transition>
        </background-image-component>
        <div class="flex h-full w-full flex-col items-start overflow-hidden">
          <div
            v-if="selectedFeatured.type === 'activity'"
            class="flex items-center gap-2 rounded bg-slate-200 px-2 py-1 text-lg text-slate-800"
          >
            <calendar-icon-v2 class="h-6 w-6" />
            {{ formatStartDate(selectedFeatured.item.starts_at) }}
          </div>
          <Link
            v-if="detailsUrl"
            :href="detailsUrl"
            class="mt-2 cursor-pointer text-3xl font-bold text-black hover:underline"
          >
            {{ selectedFeaturedItem.name }}
          </Link>
          <h5
            v-else
            class="mt-2 cursor-pointer text-3xl font-bold text-black"
          >
            {{ selectedFeaturedItem.name }}
          </h5>
          <span
            v-if="selectedFeatured.type === 'vendor-product'"
            class="mt-1 flex items-center gap-2 text-sm font-bold text-black opacity-50"
          >
            <svg
              fill="none"
              height="19"
              viewBox="0 0 18 19"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 9.125C8.50272 9.125 8.02581 8.92746 7.67417 8.57583C7.32254 8.22419 7.125 7.74728 7.125 7.25C7.125 6.75272 7.32254 6.27581 7.67417 5.92417C8.02581 5.57254 8.50272 5.375 9 5.375C9.49728 5.375 9.97419 5.57254 10.3258 5.92417C10.6775 6.27581 10.875 6.75272 10.875 7.25C10.875 7.49623 10.8265 7.74005 10.7323 7.96753C10.638 8.19502 10.4999 8.40172 10.3258 8.57583C10.1517 8.74994 9.94502 8.88805 9.71753 8.98227C9.49005 9.0765 9.24623 9.125 9 9.125ZM9 2C7.60761 2 6.27226 2.55312 5.28769 3.53769C4.30312 4.52226 3.75 5.85761 3.75 7.25C3.75 11.1875 9 17 9 17C9 17 14.25 11.1875 14.25 7.25C14.25 5.85761 13.6969 4.52226 12.7123 3.53769C11.7277 2.55312 10.3924 2 9 2Z"
                fill="black"
                fill-opacity="0.5"
              />
            </svg>
            {{ selectedFeatured.item.vendor?.name }}
          </span>
          <span
            v-if="selectedFeatured.type === 'guest'"
            class="mt-1 flex items-center gap-2 text-sm font-bold text-black opacity-50"
          >
            {{ selectedFeatured.item.profession?.name }}
          </span>
          <div
            v-if="
              selectedFeaturedItem.description ||
              selectedFeaturedItem.short_description ||
              selectedFeaturedItem.bio
            "
            class="overflow-hidden pt-4 text-lg text-paragraph md:h-0 md:shrink md:grow"
          >
            {{
              selectedFeaturedItem.description ??
              selectedFeaturedItem.short_description ??
              selectedFeaturedItem.bio
            }}
          </div>

          <strong
            v-if="selectedFeatured.type === 'vendor-product'"
            class="mt-4 font-sourcePro text-2xl"
          >
            ${{ selectedFeatured.item.price }}
          </strong>

          <Link
            v-if="selectedFeatured.type === 'vendor-product'"
            :href="`/events/${event.slug}/shops/${selectedFeatured.item.vendor?.id}`"
            :style="{
              backgroundColor: palette?.primary ? palette.primary : '#1613BD',
            }"
            class="mt-6 w-fit select-none rounded-lg border-[1px] px-6 py-2.5 text-center font-sourcePro text-lg text-text-white hover:!bg-accent-orange"
          >
            Go to the Restaurant Page
          </Link>
          <h6
            v-if="
              selectedFeaturedItem?.guests?.length ||
              selectedFeaturedItem?.recommended_products?.length
            "
            class="mx-auto mt-8 text-center text-base text-black sm:mx-0 sm:text-left"
          >
            <template
              v-if="
                selectedFeatured.type == 'vendor' &&
                selectedFeatured.item.recommended_products?.length
              "
            >
              Recommended Products
            </template>
            <template
              v-else-if="
                selectedFeatured.type == 'activity' &&
                selectedFeatured.item.guests?.length
              "
            >
              Key participants
            </template>
            <template
              v-else-if="
                selectedFeatured.type == 'vendor-product' &&
                selectedFeatured.item.recommended_products?.length
              "
            >
              Other dishes at Tangerine Room
            </template>
          </h6>
          <div
            v-if="selectedFeatured.type === 'guest' && selectedFeaturedItem.highlighted_activities.length"
            class="w-full"
          >
            <h2 class="text-2xl font-bold">
              Meet {{ selectedFeaturedItem.name }} at:
            </h2>
            <div class="flex flex-col divide-y">
              <div
                v-for="activity in selectedFeaturedItem.highlighted_activities"
                :key="activity.id"
                class="flex justify-between pt-4"
              >
                <div>
                  <h4 class="line-clamp-1 text-lg leading-none">
                    {{ activity.name }}
                  </h4>
                  <small class="text-sm leading-none text-slate-500">
                    {{
                      DateTime.fromISO(activity.starts_at, { setZone: true }).toFormat(
                        'EEE, LLL d @ t',
                      )
                    }}
                  </small>
                </div>
                <button
                  :style="{
                    color: palette?.primary ? palette.primary : undefined,
                  }"
                  class="text-lg font-bold text-purple-700"
                  @click="attending.openModal(activity)"
                >
                  More info
                </button>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mt-4 flex w-full flex-row items-center gap-4 overflow-x-auto sm:flex-row sm:flex-wrap sm:items-start"
          >
            <!-- todo: add guests case -->
            <template
              v-if="
                (selectedFeatured.type == 'vendor' ||
                  selectedFeatured.type == 'vendor-product') &&
                selectedFeatured.item.recommended_products?.length
              "
            >
              <Link
                v-for="product in selectedFeatured.item.recommended_products.slice(
                  0,
                  2
                )"
                :key="product.id!"
                :href="`/events/${event.slug}/eat/${product.id}`"
                class="group/card flex max-w-[240px] flex-col items-start gap-1 sm:max-w-[150px] lg:max-w-[205px]"
              >
                <image-component
                  :image="product.thumbnail"
                  alt="photo of meal"
                  class="h-[150px] w-[240px] rounded-lg object-cover sm:h-[100px] sm:w-[150px] lg:w-[205px]"
                  fallback-src="/images/placeholder_meal.jpg"
                />
                <h6
                  class="ml-0 line-clamp-1 text-base font-normal text-black group-hover/card:underline"
                >
                  {{ product.name }}
                </h6>
                <strong class="ml-0 text-sm">${{ product.price }}</strong>
              </Link>
            </template>
            <template v-else-if="selectedFeatured.type == 'activity'">
              <div
                v-for="guest in selectedFeatured.item.guests"
                :key="guest.id!"
                class="flex max-w-[100px] flex-col items-center gap-1 text-center"
              >
                <avatar-image
                  :image="guest.avatar"
                  alt="photo of guest"
                  class="h-[70px] w-[70px] rounded-full object-cover"
                />
                <h6 class="text-base font-normal text-black">
                  {{ guest.name }}
                </h6>
              </div>
            </template>
          </div>

          <div
            v-if="['guest', 'activity'].includes(selectedFeatured.type)"
            class="mx-auto mt-8 flex w-full max-w-[400px] items-center gap-4 xl:mx-0"
          >
            <!-- todo: add function to open page like for activity -->
            <Link
              v-if="detailsUrl && selectedFeatured.type !== 'guest'"
              :href="detailsUrl"
              :style="{
                borderColor: palette?.primary ? palette.primary : '#1613BD',
                color: palette?.primary ? palette.primary : '#1613BD',
              }"
              class="w-full rounded-lg border-[1px] p-2.5 text-center font-sourcePro text-lg"
            >
              Learn more
            </Link>

            <template
              v-if="
                selectedFeatured.type === 'activity' &&
                selectedFeatured.item?.id
              "
            >
              <button
                v-if="!attending.isAttending(selectedFeatured.item.id)"
                :style="{
                  backgroundColor: palette?.primary
                    ? palette.primary
                    : '#1613BD',
                  borderColor: palette?.primary ? palette.primary : '#1613BD',
                }"
                class="w-full select-none rounded-lg border-[1px] p-2.5 text-center font-sourcePro text-lg uppercase text-text-white hover:border-accent-orange hover:!bg-accent-orange"
                @click="attending.rsvp(selectedFeatured.item)"
              >
                RSVP
              </button>
              <button
                v-else
                :style="{
                  backgroundColor: palette?.primary
                    ? palette.primary
                    : '#1613BD',
                  borderColor: palette?.primary ? palette.primary : '#1613BD',
                }"
                class="w-full select-none rounded-lg border-[1px] p-2.5 text-center font-sourcePro text-lg text-text-white hover:border-accent-orange hover:!bg-accent-orange"
                @click="attending.unrsvp(selectedFeaturedItem)"
              >
                unRSVP
              </button>
            </template>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, PropType, ref, watch } from 'vue'
import { Link } from '@inertiajs/vue3'

import { format, parseISO } from 'date-fns'
import { useAttending } from '@/utils/attending'

import ImageComponent from '@/components/ui/ImageComponent.vue'
import CalendarIconV2 from '@/icons/CalendarIconV2.vue'
import ConventionEvent, { ConventionPalette } from '@/models/ConventionEvent'
import Image from '@/models/Image'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import { DateTime } from 'luxon'

const props = defineProps({
  event: {
    type: [Object, ConventionEvent],
    required: true,
  },
  palette: {
    type: Object as PropType<ConventionPalette | undefined>,
    default: undefined,
  },
})

const device = inject('device')

const AUTOPLAY_INTERVAL = 7500
const progress = ref(0)
const isPlaying = ref(true)
const isPaused = ref(true)
const direction = ref(1)
const selectedFeaturedIndex = ref(0)
const attending = useAttending()

watch(
  () => selectedFeaturedIndex.value,
  (value, oldValue) => {
    if (value > oldValue) {
      if (value === props.event.featured.length - 1 && oldValue === 0) {
        direction.value = -1
      } else {
        direction.value = 1
      }
    } else {
      if (value === 0 && oldValue === props.event.featured.length - 1) {
        direction.value = 1
      } else {
        direction.value = -1
      }
    }
  }
)

function openNextFeatured() {
  selectedFeaturedIndex.value =
    (selectedFeaturedIndex.value + 1) % props.event.featured.length
  isPaused.value = true
  requestAnimationFrame(() => {
    isPaused.value = false
  })
}

function savePosition(el: Element) {
  if (el instanceof HTMLElement) {
    el.style.top = `${el.offsetTop}px`
  }
}

let interval: number | undefined = undefined

function pause() {
  isPaused.value = true
  clearInterval(interval)
}

function play() {
  if (device !== 'desktop') return
  isPaused.value = false
  interval = setInterval(openNextFeatured, AUTOPLAY_INTERVAL)
}

function formatStartDate(startDate) {
  const parsedDate = parseISO(startDate)
  return format(parsedDate, 'MMM dd')
}

const selectedFeatured = computed(() => {
  return props.event?.featured[selectedFeaturedIndex.value]
})
const selectedFeaturedItem = computed(() => {
  return props.event?.featured[selectedFeaturedIndex.value]?.item
})

const detailsUrl = computed(() => {
  if (selectedFeatured.value && selectedFeaturedItem.value && props.event) {
    if (selectedFeatured.value.type === 'activity') {
      return `/events/${props.event.slug}/activities/${selectedFeaturedItem.value.id}`
    } else if (selectedFeatured.value.type === 'vendor') {
      return `/events/${props.event.slug}/shops/${selectedFeaturedItem.value.id}`
    } else if (selectedFeatured.value.type === 'vendor-product') {
      return `/events/${props.event.slug}/eat/${selectedFeaturedItem.value.id}`
    }
  }
  return null
})

const bannerImage = computed<Image | null>(() => {
  switch (selectedFeatured.value?.type) {
    case 'activity':
      return selectedFeatured.value?.item?.banner
    case 'vendor':
      return selectedFeatured.value?.item?.banner
    case 'vendor-product':
      return selectedFeatured.value?.item?.thumbnail
    case 'guest':
      return selectedFeatured.value?.item?.avatar
    default:
      return null
  }
})

onMounted(() => {
  play()
})
</script>

<style lang="scss" scoped></style>
