<template>
  <section
    class="bgImages mx-auto flex w-full w-full max-w-[1200px] flex-col items-center gap-8 px-4 py-20 sm:px-0 sm:py-[112px] md:gap-y-16 2xl:max-w-full">
    <h2
      class="mx-auto w-fit text-center text-2xl font-bold leading-[140%] tracking-[0.8px] text-dark-grey sm:text-3xl lg:text-4xl"
    >
      We provide everything for your
      <br class="hidden lg:block"/>
      best convention experience
    </h2>

    <div
      class="mx-0 mx-auto hidden w-fit max-w-full items-center overflow-scroll md:flex">
      <convention-experience-card
        v-for="(item, index) in conventionsExperienceList"
        :key="index"
        class="mx-5"
        :item="item"
      />
    </div>
    <div
      class="mx-auto flex w-fit max-w-full flex-col items-center gap-10 overflow-scroll md:hidden">
      <div class="flex items-center gap-6 text-lg font-bold text-black">
        <button
          v-for="(title, index) in experienceTitles"
          :key="index"
          :class="selectedExperience.title == title ? 'text-blue-primary' : ''"
          @click="
            selectedExperience = {
              title: title,
              value: conventionsExperienceList[index],
            }
          "
        >
          {{ title }}
        </button>
      </div>
      <convention-experience-card
        :item="selectedExperience.value"
        class="sm:!h-[400px] sm:!max-w-[400px]" />
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import ConventionExperienceCard from '@/components/ui/ConventionExperienceCard.vue'

const experienceTitles = ['Fans', 'Event organizers', 'Exhibitors']
const conventionsExperienceList = [
  {
    image: { path: '/images/backgrounds/home/experience_0.png' },
    title: 'Fans',
    options: [
      'Personalized Event Scheduler',
      'Real-Time Notifications',
      'Community Connection',
      'Exclusive Content Access',
    ],
  },
  {
    image: { path: '/images/backgrounds/home/experience_1.png' },
    title: 'Event organizers',
    options: [
      'Centralized Event Management',
      'Analytics and Reporting',
      'Staff and Volunteer Coordination',
      'Community Engagement Tools',
    ],
  },
  {
    image: { path: '/images/backgrounds/home/experience_2.png' },
    title: 'Exhibitors',
    options: [
      'Unified Storefront',
      'Inventory Management',
      'Promotion Features',
      'Direct Fan Interaction',
    ],
  },
]

const selectedExperience = ref({
  title: 'Fans',
  value: conventionsExperienceList[0],
})
</script>

<style lang="css" scoped>
@media screen and (min-width: 1440px) {
  .bgImages {
    background-image: none !important;
  }
}

@media screen and (max-width: 1300px) {
  .bgImages {
    background-image: none !important;
  }
}

.bgImages {
  background-size: 450px, 250px;
  background-image: url('images/backgrounds/home/commics-bang-1.png'),
    url('images/backgrounds/home/commics-bang-2.png');
  background-position:
    20px 60px,
    97% bottom;
  background-repeat: no-repeat, no-repeat;
}
</style>
