<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M23.166 12C23.166 14.1097 22.5404 16.172 21.3684 17.9261C20.1963 19.6802 18.5304 21.0474 16.5813 21.8547C14.6322 22.6621 12.4875 22.8733 10.4184 22.4617C8.34926 22.0501 6.44864 21.0342 4.95688 19.5425C3.46512 18.0507 2.44922 16.1501 2.03764 14.081C1.62606 12.0118 1.8373 9.86714 2.64463 7.91806C3.45197 5.96898 4.81914 4.30307 6.57327 3.13101C8.32739 1.95894 10.3897 1.33335 12.4994 1.33335C15.3283 1.33335 18.0414 2.45716 20.0418 4.45754C22.0422 6.45793 23.166 9.17104 23.166 12ZM12.786 6.66668C12.6117 6.84483 12.5142 7.08413 12.5142 7.33335C12.5142 7.58256 12.6117 7.82187 12.786 8.00002L15.8327 11.0667L6.40602 11.0667C6.15848 11.0667 5.92108 11.165 5.74605 11.34C5.57102 11.5151 5.47268 11.7525 5.47268 12C5.47268 12.2476 5.57102 12.4849 5.74605 12.66C5.92108 12.835 6.15848 12.9333 6.40602 12.9333L15.7393 12.9333L12.786 15.8867C12.6092 16.0635 12.5099 16.3033 12.5099 16.5533C12.5099 16.8034 12.6092 17.0432 12.786 17.22C12.9628 17.3968 13.2026 17.4962 13.4527 17.4962C13.7027 17.4962 13.9425 17.3968 14.1193 17.22L19.366 11.9467L14.106 6.66668C14.0193 6.58001 13.9165 6.51125 13.8032 6.46434C13.69 6.41743 13.5686 6.39328 13.446 6.39328C13.3234 6.39328 13.2021 6.41743 13.0888 6.46434C12.9756 6.51125 12.8727 6.58001 12.786 6.66668Z"
      fill="#201CDB"
    />
  </svg>
</template>
