<template>
  <div
    class="relative flex flex-row"
    :class="inputClass"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  inputStyle: {
    type: String,
    default: '',
  },
})

const inputClass = computed(() => {
  switch (props.inputStyle) {
    case 'default':
      return 'focus:outline-none overflow-visible border-bottom-1-5'
    case 'gray':
      return 'flex-grow items-center px-3 py-2 bg-gray-100 rounded text-slate-700 mt-2'
    case 'outline':
      return 'flex-grow items-center px-3 py-2 bg-transparent rounded border border-gray-300 mt-2'
    case 'row-outline':
      return 'flex-grow items-center px-3 py-2 bg-transparent rounded hover:outline focus-within:outline outline-blue-neon outline-1 border border-gray-300'
    case 'row':
      return 'flex-grow items-center px-3 py-2 bg-transparent rounded mt-2'
  }
  return ''
})
</script>

<style scoped>
input#\32 4 {
  padding: 0;
}
</style>
