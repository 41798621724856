<template>
  <user-settings-layout>
    <div class="flex flex-col gap-3 rounded border px-3 py-2 shadow">
      <h1 class="text-xl font-normal">Notifications Settings</h1>
      <toggle-switch
        v-model="form.all_email"
        label="Email Notifications"
      />
      <toggle-switch
        v-model="form.messages_email"
        :disabled="!form.all_email"
        label="Messages"
      />
      <toggle-switch
        v-model="form.replies_email"
        :disabled="!form.all_email"
        label="Replies"
      />
      <toggle-switch
        v-model="form.mentions_email"
        :disabled="!form.all_email"
        label="Mentions"
      />
      <div class="flex justify-end">
        <button
          :disabled="!form.isDirty"
          class="rounded bg-blue-primary px-4 py-2 font-bold text-white hover:brightness-90 disabled:bg-blue-700"
          @click="form.put(`/users/${user.id}/settings`)"
        >
          Save
        </button>
      </div>
    </div>
  </user-settings-layout>
</template>

<script setup lang="ts">
import UserSettingsLayout from '@/components/layout/UserSettingsLayout.vue'
import ToggleSwitch from '@/components/input/ToggleSwitch.vue'
import { useForm } from '@inertiajs/vue3'
import { watch } from 'vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: true,
  },
})

const form = useForm({
  all_email: props.settings.all_email.value,
  messages_email: props.settings.messages_email.value,
  replies_email: props.settings.replies_email.value,
  mentions_email: props.settings.mentions_email.value,
})

watch(
  () => form.all_email,
  (value) => {
    if (value) {
      form.messages_email = true
      form.replies_email = true
      form.mentions_email = true
    } else {
      form.messages_email = false
      form.replies_email = false
      form.mentions_email = false
    }
  }
)
</script>

<style scoped></style>
