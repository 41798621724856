<template>
  <section class="flex flex-col items-center gap-12">
    <div
      :style="{
        gridTemplateColumns: gridTemplateColumns,
      }"
      class="grid w-full grid-rows-2 items-center"
    >
      <template
        v-for="(step, index) in steps"
        :key="step.name"
      >
        <div class="flex h-8 justify-center">
          <button
            :id="step.name + '-button'"
            :class="{
              'bg-blue-primary': index < _currentStep,
              'bg-slate-200': index >= _currentStep,
              'border-blue-primary': index <= _currentStep,
              'border-slate-300': index > _currentStep,
            }"
            :disabled="
              !enableStepSkip && (index > _currentStep || step.disabled)
            "
            class="h-8 w-8 rounded-full border-[3px] duration-100"
            @click="updateCurrentStep(index)"
          >
            <font-awesome-icon
              :class="{
                'scale-0 opacity-0': index >= _currentStep,
              }"
              class="text-slate-200 duration-100"
              icon="check"
            />
          </button>
        </div>
        <div
          v-if="index !== steps.length - 1"
          :class="{
            'bg-blue-primary': index <= _currentStep,
            'bg-slate-300': index > _currentStep,
          }"
          class="h-[2px] duration-100"
        />
      </template>

      <template
        v-for="(step, index) in steps"
        :key="step.name"
      >
        <label
          :for="step.name + '-button'"
          :style="{
            gridColumn: index * 2 + 1,
          }"
          class="font-regular text-center font-sans text-sm text-slate-600"
        >
          {{ step.label }}
        </label>
      </template>
    </div>
    <form
      :class="[
        formClass,
        {
          'flex w-full flex-col gap-12 rounded-lg bg-[#F2F5F9] px-10 py-6':
            !customForm,
        },
      ]"
      @submit.prevent="updateCurrentStep(_currentStep + 1)"
    >
      <input
        hidden
        type="submit"
      />
      <header
        v-if="!customForm"
        class="text-center font-sans"
      >
        <h1
          v-if="currentStepObject.title"
          class="text-2xl font-bold text-black"
        >
          {{ currentStepObject.title }}
        </h1>
        <h3
          v-if="currentStepObject.subtitle"
          class="text-base font-semibold text-slate-400"
        >
          {{ currentStepObject.subtitle }}
        </h3>
      </header>
      <slot :name="currentStepObject.name" />
      <transition-group
        class="relative mx-auto flex gap-8"
        enter-active-class="transition-all ease-in-out duration-100"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition absolute"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        tag="footer"
      >
        <v-button
          v-if="_currentStep > 0"
          key="back"
          variant="secondary"
          @click="updateCurrentStep(_currentStep - 1)"
        >
          Go back
        </v-button>
        <v-button
          key="continue"
          variant="primary"
          @click="updateCurrentStep(_currentStep + 1)"
        >
          Continue
        </v-button>
      </transition-group>
    </form>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VButton from '@/components/buttons/VButton.vue'

const emit = defineEmits(['update:currentStep', 'submit'])

const props = withDefaults(
  defineProps<{
    /**
     * Array of steps to render
     */
    steps: MultistepFormStep[]
    /**
     * Optional current step index.
     */
    currentStep?: number
    /**
     * Whether user can jump to any step or only to previous steps.
     * Overridden by step.disabled
     */
    enableStepSkip?: boolean

    /**
     * Class to apply to the form
     */
    formClass: string | object | Array<string | object>

    /**
     * Whether to use the default form layout or a custom form layout
     */
    customForm?: boolean
  }>(),
  {
    steps: () =>
      [
        {
          label: 'Step 1',
          name: 'step-1',
        },
        {
          label: 'Step 2',
          name: 'step-2',
        },
      ] as MultistepFormStep[],
    currentStep: undefined,
    enableStepSkip: false,
    formClass: '',
    customForm: false,
  }
)

export interface MultistepFormStep {
  /**
   * Visual label for the step
   */
  label: string

  /**
   * Name of the slot to render the step component into
   * should be unique
   */
  name: string

  /**
   * Whether the step is disabled and cannot be navigated to
   */
  disabled?: boolean

  /**
   * Optional title to display in the form if using default form layout
   */
  title?: string
  subtitle?: string
}

const gridTemplateColumns = computed(() => {
  return props.steps
    .map((step, index) => {
      return '70px' + (index === props.steps.length - 1 ? '' : ' auto')
    })
    .join(' ')
})

const _currentStep = ref(props.currentStep ?? 0)
const currentStepObject = computed(() => props.steps[_currentStep.value])

watch(
  () => props.currentStep,
  (newVal) => {
    if (newVal) _currentStep.value = newVal
  }
)

function updateCurrentStep(index: number) {
  console.log('step', index)
  if (index < 0) return
  if (index >= props.steps.length) {
    emit('submit')
    return
  }
  _currentStep.value = index
  emit('update:currentStep', index)
}
</script>

<style scoped></style>
