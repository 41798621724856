<template>
  <div class="flex h-fit w-full flex-col gap-5 bg-white p-2">
    <div class="flex items-center gap-4">
      <image-component
        v-if="props.item.image"
        :src="props.item.image"
        class="h-16 w-16 rounded object-cover"
      />

      <div
        v-if="props.item.title"
        class="flex flex-col"
      >
        <h3 class="font-sans text-[20px] text-blue-primary">
          {{ props.item.title }}
        </h3>
        <p
          v-if="props.item.address"
          class="text-sm text-black"
        >
          {{ props.item.address }}
        </p>
      </div>
    </div>
    <div
      v-if="
        props.item.contacts?.email ||
        props.item?.description ||
        props.item.contacts?.phone
      "
      class="flex flex-col gap-2"
    >
      <p
        v-if="props.item.description"
        class="font-noraml mb-1 font-sans text-sm text-paragraph"
      >
        {{ props.item.description }}
      </p>
      <a
        v-if="props.item.contacts?.phone"
        :href="`tel:${props.item.contacts?.phone}`"
        class="item-center flex cursor-pointer select-none gap-2 font-sans text-sm font-normal text-paragraph"
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 17 16"
          width="17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.89052 2.39052C2.64048 2.64057 2.5 2.97971 2.5 3.33333V4C2.5 9.52267 6.97733 14 12.5 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V10.4807C14.5 10.3407 14.456 10.2043 14.3742 10.0907C14.2923 9.97714 14.1768 9.89223 14.044 9.848L11.0487 8.84933C10.8964 8.79873 10.7311 8.80472 10.5829 8.86619C10.4347 8.92766 10.3137 9.0405 10.242 9.184L9.48867 10.6887C7.85631 9.95112 6.54888 8.64369 5.81133 7.01133L7.316 6.258C7.4595 6.1863 7.57234 6.06527 7.63381 5.9171C7.69528 5.76893 7.70127 5.60356 7.65067 5.45133L6.652 2.456C6.6078 2.3233 6.52299 2.20787 6.40957 2.12603C6.29615 2.0442 6.15986 2.00011 6.02 2H3.83333C3.47971 2 3.14057 2.14048 2.89052 2.39052Z"
            stroke="#47474F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.89052 2.39052C2.64048 2.64057 2.5 2.97971 2.5 3.33333V4C2.5 9.52267 6.97733 14 12.5 14H13.1667C13.5203 14 13.8594 13.8595 14.1095 13.6095C14.3595 13.3594 14.5 13.0203 14.5 12.6667V10.4807C14.5 10.3407 14.456 10.2043 14.3742 10.0907C14.2923 9.97714 14.1768 9.89223 14.044 9.848L11.0487 8.84933C10.8964 8.79873 10.7311 8.80472 10.5829 8.86619C10.4347 8.92766 10.3137 9.0405 10.242 9.184L9.48867 10.6887C7.85631 9.95112 6.54888 8.64369 5.81133 7.01133L7.316 6.258C7.4595 6.1863 7.57234 6.06527 7.63381 5.9171C7.69528 5.76893 7.70127 5.60356 7.65067 5.45133L6.652 2.456C6.6078 2.3233 6.52299 2.20787 6.40957 2.12603C6.29615 2.0442 6.15986 2.00011 6.02 2H3.83333C3.47971 2 3.14057 2.14048 2.89052 2.39052Z"
            stroke="#334155"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ props.item.contacts?.phone }}
      </a>
      <a
        v-if="props.item.contacts?.email"
        :href="`mailto:${props.item.contacts?.email}`"
        class="item-center flex cursor-pointer select-none gap-2 font-sans text-sm font-normal text-paragraph"
      >
        <svg
          fill="none"
          height="16"
          viewBox="0 0 17 16"
          width="17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 5.3335L7.76 8.84016C7.97911 8.98635 8.2366 9.06436 8.5 9.06436C8.7634 9.06436 9.02089 8.98635 9.24 8.84016L14.5 5.3335M3.83333 12.6668H13.1667C13.5203 12.6668 13.8594 12.5264 14.1095 12.2763C14.3595 12.0263 14.5 11.6871 14.5 11.3335V4.66683C14.5 4.31321 14.3595 3.97407 14.1095 3.72402C13.8594 3.47397 13.5203 3.3335 13.1667 3.3335H3.83333C3.47971 3.3335 3.14057 3.47397 2.89052 3.72402C2.64048 3.97407 2.5 4.31321 2.5 4.66683V11.3335C2.5 11.6871 2.64048 12.0263 2.89052 12.2763C3.14057 12.5264 3.47971 12.6668 3.83333 12.6668Z"
            stroke="#47474F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 5.3335L7.76 8.84016C7.97911 8.98635 8.2366 9.06436 8.5 9.06436C8.7634 9.06436 9.02089 8.98635 9.24 8.84016L14.5 5.3335M3.83333 12.6668H13.1667C13.5203 12.6668 13.8594 12.5264 14.1095 12.2763C14.3595 12.0263 14.5 11.6871 14.5 11.3335V4.66683C14.5 4.31321 14.3595 3.97407 14.1095 3.72402C13.8594 3.47397 13.5203 3.3335 13.1667 3.3335H3.83333C3.47971 3.3335 3.14057 3.47397 2.89052 3.72402C2.64048 3.97407 2.5 4.31321 2.5 4.66683V11.3335C2.5 11.6871 2.64048 12.0263 2.89052 12.2763C3.14057 12.5264 3.47971 12.6668 3.83333 12.6668Z"
            stroke="#334155"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ props.item.contacts?.email }}
      </a>
    </div>

    <hr v-if="props.item?.schedule" />

    <div
      v-if="props.item?.schedule"
      class="grid grid-cols-2 gap-x-8 gap-y-6 md:grid-cols-4"
    >
      <div
        v-for="(day, index) in props.item.schedule"
        :key="index"
        class="flex flex-col"
      >
        <h1 class="mb-2 font-sans text-sm font-bold text-black">
          {{ day.day }}
        </h1>
        <span
          v-for="(s, i) in day.schedule"
          :key="i"
          class="font-sans text-sm font-normal text-black"
        >
          {{ s }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import ImageComponent from '@/components/ui/ImageComponent.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})
</script>

<style lang="scss" scoped></style>
