import { Model } from '@/models/Model'
import ShowcaseSection from '@/models/ShowcaseSection'

export default class ShowcasePage<T extends Model> extends Model {
  id: number | null = null

  showcaseable: T | null = null
  theme: object | null = null

  sections: { [key: string]: ShowcaseSection } = {}
}
