<template>
  <user-settings-layout>
    <div class="flex h-48 gap-5 rounded border p-5 shadow">
      <div class="h-32 w-32 overflow-hidden rounded-full">
        <image-component
          :image="auth.data.value.avatar"
          class="h-full w-full object-cover object-center"
        />
      </div>
      <div class="flex flex-col gap-1">
        <h2 class="font-sans text-sm text-blue-neon">About me</h2>
        <h2
          v-if="!form.private_profile_information_name"
          class="font-heading text-2xl text-dark-grey"
        >
          {{ user.first_name }} {{ user.last_name }}
        </h2>
        <small>@{{ user.username }}</small>
        <tag-collection :tags="user.userTags" />
        <p
          v-if="!form.private_profile_information_description"
          class="line-clamp-2 max-w-screen-sm font-sans text-base text-paragraph"
        >
          {{ user.description }}
        </p>
      </div>
    </div>
    <div class="flex flex-col gap-3 rounded border px-3 py-2 shadow">
      <h1 class="text-xl font-normal">Privacy Settings</h1>
      <toggle-switch
        v-model="form.private_profile"
        label="Private Profile"
      />
      <toggle-switch
        v-model="form.private_profile_information_name"
        :disabled="form.private_profile"
        label="Hide Name"
      />
      <toggle-switch
        v-model="form.private_profile_information_description"
        :disabled="form.private_profile"
        label="Hide Bio"
      />
      <toggle-switch
        v-model="form.private_profile_information_tags"
        :disabled="form.private_profile"
        label="Hide Tags"
      />
      <div class="flex justify-end">
        <button
          :disabled="!form.isDirty"
          class="rounded bg-blue-primary px-4 py-2 font-bold text-white hover:brightness-90 disabled:bg-blue-700"
          @click="form.put(`/users/${user.id}/settings`)"
        >
          Save
        </button>
      </div>
    </div>
  </user-settings-layout>
</template>

<script lang="ts" setup>
import UserSettingsLayout from '@/components/layout/UserSettingsLayout.vue'
import ToggleSwitch from '@/components/input/ToggleSwitch.vue'
import { useForm } from '@inertiajs/vue3'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import TagCollection from '@/components/ui/TagCollection.vue'
import { watch } from 'vue'
import useAuth from '@/utils/composables/auth'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: true,
  },
})

const auth = useAuth()

const form = useForm({
  private_profile: props.settings.private_profile.value,
  private_profile_information_name:
    props.settings.private_profile_information_name.value,
  private_profile_information_description:
    props.settings.private_profile_information_description.value,
  private_profile_information_tags:
    props.settings.private_profile_information_tags.value,
})

watch(
  () => form.private_profile,
  (value) => {
    if (value) {
      form.private_profile_information_name = true
      form.private_profile_information_picture = true
      form.private_profile_information_description = true
      form.private_profile_information_tags = true
    } else {
      form.private_profile_information_name =
        props.settings.private_profile_information_name.value
      form.private_profile_information_description =
        props.settings.private_profile_information_description.value
      form.private_profile_information_tags =
        props.settings.private_profile_information_tags.value
    }
  }
)
</script>

<style scoped></style>
