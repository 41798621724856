<template>
  <section
    class="mx-auto flex w-full max-w-[1200px] flex-col gap-8 bg-text-white px-4 py-20 md:px-8"
  >
    <h2 class="text-center font-heading text-[40px] text-dark-grey">
      Tickets
    </h2>
    <div class="mb-4 w-full border-b">
      <div class="mx-auto flex w-fit gap-12">
        <button
          v-for="(button, index) in btns"
          :key="index"
          class="rounded-xl font-heading text-lg lg:text-2xl"
          :class="
            btnActive === button
              ? `font-bold underline`
              : 'font-normal text-black'
          "
          :style="{
            color:
              btnActive === button
                ? palette?.primary
                : '#302e85'
                  ? palette.primary
                  : '#000000',
          }"
          @click="btnActive = button"
        >
          {{ button }}
        </button>
      </div>
    </div>

    <div
      class="flex max-w-[1200px] flex-wrap items-center justify-center gap-10"
    >
      <template v-if="btnActive === 'Daily Passes'">
        <ticket-card
          v-for="(item, index) in tickets.dailyPasses"
          :key="index"
          :image="item.image.path"
          :date="item.date"
          :price="item.price"
          :list="item.list"
        />
      </template>
      <template v-if="btnActive === 'Add-Ons'">
        <ticket-card
          v-for="(item, index) in tickets.addOns"
          :key="index"
          :type="item.type"
          :date="item.date"
          :price="item.price"
          :list="item.list"
        />
      </template>
      <template v-if="btnActive === 'Bundles'">
        <ticket-card
          v-for="(item, index) in tickets.bundles"
          :key="index"
          :type="item.type"
          :date="item.date"
          :price="item.price"
          :list="item.list"
        />
      </template>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue'

import TicketCard from '@/shared/components/event/sections/elements/TicketCard.vue'
const props = defineProps({
  palette: {
    type: Object,
    default: () => ({}),
  },
})

const btnActive = ref('Daily Passes')

const btns = ['Daily Passes', 'Bundles', 'Add-Ons']

const tickets = {
  dailyPasses: [
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Daily Passes',
      image: { path: 'https://picsum.photos/200/300' },
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
  ],
  addOns: [
    {
      type: 'Add on',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Add on',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Add on',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
  ],
  bundles: [
    {
      type: 'Bundles',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Bundles',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
    {
      type: 'Bundles',
      date: { weekDay: 'Monday', fullDate: 'Sep 7, 2023' },
      price: '50.90',
      list: [
        'Access to 400+ exhibitors',
        'Artist Alley',
        'Studio & brand experiences',
        'Show features such as Family HQ and Cosplay Central',
        'Show features such as Family HQ and Cosplay Central',
      ],
    },
  ],
}
</script>

<style lang="scss" scoped></style>
