<template>
  <background-image-component
    :image="event.banner"
    class="!no-repeat relative min-h-[316px] w-full px-4 py-8 md:py-14"
  >
    <div
      class="absolute inset-0 z-content"
      style="
        background: linear-gradient(
            98deg,
            rgba(153, 92, 16, 0.25) 2.51%,
            rgba(82, 46, 0, 0.25) 44.97%,
            rgba(14, 16, 30, 0.25) 80.42%
          ),
          linear-gradient(
            98deg,
            #000 2.51%,
            rgba(0, 0, 0, 0.75) 44.97%,
            rgba(0, 0, 0, 0) 80.42%
          );
      "
    />
    <div
      class="relative z-content+1 mx-auto flex max-w-[1200px] flex-col items-center justify-between gap-6 px-4 lg:flex-row xl:px-0"
    >
      <div class="mx-auto my-14 flex flex-col gap-3 lg:ml-0">
        <h1
          :style="{
            color: event.palette?.primary ? event.palette.primary : '#e0d666',
          }"
          class="font-poppins text-4xl md:text-6xl"
        >
          Meet at {{ event.name }}
        </h1>
        <p
          class="mt-1 font-sourcePro text-lg font-normal capitalize text-slate-100"
        >
          {{
            [
              event.meetups_count ? `${event.meetups_count} Meetups` : null,
              event.guests_count
                ? `${event.guests_count} Special Guests`
                : null,
              event.groups_count
                ? `${event.groups_count} Groups Attending`
                : null,
            ]
              .filter((item) => item)
              .join(' • ')
          }}
        </p>

        <template v-if="featureFlags.UserPreferences">
          <div class="w-fit rounded-full border border-white p-1">
            <button
              :style="{
                backgroundColor:
                  type == 'Featured'
                    ? event.palette?.primary
                      ? event.palette.primary
                      : '#e0d666'
                    : '',
              }"
              class="rounded-full px-4 py-2.5 font-sourcePro font-normal text-white md:px-8"
              @click="emit('changePageType', 'Featured')"
            >
              Featured
            </button>
            <button
              :style="{
                backgroundColor:
                  type == 'Personalized'
                    ? event.palette?.primary
                      ? event.palette.primary
                      : '#e0d666'
                    : '',
              }"
              class="rounded-full px-4 py-2.5 font-sourcePro font-normal text-white md:px-8"
              @click="emit('changePageType', 'Personalized')"
            >
              Personalized
            </button>
          </div>

          <button
            class="ml-0 mr-auto mt-2 inline flex select-none items-center gap-2 text-left text-text-white"
          >
            <span>
              <strong
                :style="{
                  color: event.palette?.secondary
                    ? event.palette.secondary
                    : '#ffffff',
                }"
              >
                Add more preferences
              </strong>
              to see more personalized Items
            </span>
            <chevron-right />
          </button>
        </template>
      </div>
      <div
        v-if="findPreference"
        class="mx-auto flex max-w-[600px] flex-col items-center justify-between gap-4 rounded-lg px-4 py-6 sm:w-full sm:flex-row sm:p-6 lg:mr-0 lg:w-fit"
        style="background: rgba(231, 235, 255, 0.7)"
      >
        <image-component src="/images/icons/rocket.png" />

        <div
          class="flex w-full max-w-[340px] flex-col items-center gap-4 text-center"
        >
          <h3 class="font-poppins text-2xl font-bold text-black">
            You’re almost there!
          </h3>
          <div class="relative h-[6px] w-full rounded-full bg-text-white">
            <div
              class="absolute left-0 top-0 h-[6px] w-[80%] rounded-full bg-blue-primary"
            />
          </div>
          <p class="font-sourcePro text-lg font-normal text-blue-muted">
            Please complete setting up your preferences so that we could suggest
            you more personalized items for Wonder Con 2024.
          </p>
          <v-button
            class="flex items-center gap-2"
            variant="primary"
          >
            <font-awesome-icon
              class="h-4 w-4 shrink-0"
              icon="plus"
            />
            Add preferences
          </v-button>
        </div>
      </div>
    </div>
  </background-image-component>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ChevronRight from '@/icons/ChevronRight.vue'

import VButton from '@/components/buttons/VButton.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import ConventionEvent from '@/models/ConventionEvent'
import { PropType } from 'vue'
import BackgroundImageComponent from '@/components/ui/BackgroundImageComponent.vue'
import featureFlags from '@/utils/composables/flags'

const emit = defineEmits(['changePageType'])
const props = defineProps({
  type: {
    type: String,
    default: 'Featured',
  },
  findPreference: {
    type: Boolean,
    default: false,
  },
  event: {
    type: Object as PropType<ConventionEvent>,
    required: true,
  },
})
</script>

<style scoped></style>
