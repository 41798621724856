<template>
  <user-group-layout :user-group="userGroup">
    <template #home>
      <section
        class="flex w-full flex-col items-center gap-8 bg-text-white px-4 py-10 sm:px-8"
      >
        <h3
          class="mx-auto text-center font-sans text-2xl font-bold text-very-dark-blue"
        >
          About Us
        </h3>
        <div
          v-if="userGroup"
          class="flex w-full flex-col items-start justify-between gap-8 md:flex-row xl:gap-[80px]"
        >
          <image-component
            v-if="userGroup?.about_image?.path"
            :image="userGroup?.about_image"
            class="h-[300px] w-full rounded-lg object-cover"
          />
          <form-drop-file-input
            v-else
            v-model="userGroup.about_image"
            class="h-[300px] w-full cursor-pointer border-text-white bg-slate-200 text-slate-400"
            icon-class="text-slate-400"
            image-type
            @update:model-value="
              userGroup
                ?.update({ about_image: $event })
                .form({ only: ['about_image'] })
                .patch(`/api/groups/${userGroup.id}`)
            "
          />
          <div class="flex w-full shrink-0 flex-col gap-2 md:w-2/3">
            <h4
              :class="{
                'skeleton w-40 rounded-xl': !userGroup,
              }"
              class="font-sans text-xl font-bold text-very-dark-blue"
            >
              {{ userGroup?.name }}
            </h4>
            <p
              :class="{
                'skeleton w-full rounded-xl': !userGroup,
              }"
              class="text-lg text-very-dark-blue"
            >
              {{ userGroup?.description }}
            </p>
          </div>
        </div>
      </section>

      <section
        v-if="editFlag || userGroup?.upcoming_activities?.length"
        class="flex flex-col gap-8 bg-text-white px-4 py-10 sm:px-8"
      >
        <div
          class="relative flex w-full flex-col items-center gap-4 sm:flex-row"
        >
          <h3
            class="mx-auto shrink-0 text-center font-sans text-2xl font-bold text-very-dark-blue"
          >
            Group’s Life
          </h3>
          <a
            v-if="userGroup?.upcoming_activities?.length && !editFlag"
            class="right-0 cursor-pointer select-none font-sans text-sm font-bold text-blue-primary sm:absolute"
            href=""
          >
            See more
          </a>
          <button
            class="right-0 flex cursor-pointer select-none items-center gap-2 font-sans text-sm font-bold text-blue-primary sm:absolute"
            @click="openCreateMeetupDialog"
          >
            <font-awesome-icon
              class="h-4 w-4 text-blue-primary"
              icon="plus"
            />
            Create activity
          </button>
        </div>

        <div
          v-if="userGroup?.upcoming_activities?.length"
          class="flex flex-col items-center gap-10 md:gap-8"
        >
          <group-activity-card
            v-for="activity in userGroup?.upcoming_activities"
            :key="activity"
            :activity="activity"
          />
        </div>
        <div
          v-else
          class="mx-auto flex h-[220px] w-full max-w-[830px] flex-col items-center rounded-lg !bg-cover !bg-center !bg-no-repeat p-4 sm:p-8"
          style="
            background:
              linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.45) 0%,
                rgba(0, 0, 0, 0.45) 100%
              ),
              url(/images/backgrounds/group_placeholder.png) lightgray 0px -94.942px /
                100% 245.229% no-repeat;
          "
        >
          <h6 class="mb-0 mt-auto font-sans text-2xl font-bold text-text-white">
            No Activities Yet
            <button
              class="mx-auto flex cursor-pointer select-none items-center gap-2 font-sans text-sm font-bold text-text-white"
              @click="openCreateMeetupDialog"
            >
              <font-awesome-icon
                class="h-4 w-4 text-text-white"
                icon="plus"
              />
              Create activity
            </button>
          </h6>
        </div>
      </section>

      <section
        v-if="photoGallery.length || editFlag"
        class="flex flex-col gap-8 bg-text-white px-4 py-10 sm:px-8"
      >
        <div class="relative flex w-full flex-col items-center gap-4">
          <h3
            class="mx-auto text-center font-sans text-2xl font-bold text-very-dark-blue"
          >
            Recent pictures
          </h3>
          <a
            v-if="photoGallery.length && !editFlag"
            class="right-0 cursor-pointer select-none font-sans text-sm font-bold text-blue-primary sm:absolute"
            href=""
          >
            See more
          </a>
          <button
            v-if="editFlag"
            class="right-0 cursor-pointer select-none font-sans text-sm font-bold text-blue-primary sm:absolute"
            @click="showEditGalleryMenu = !showEditGalleryMenu"
          >
            <dots-icon class="text-blue-primary" />
          </button>
          <div
            v-if="showEditGalleryMenu"
            class="absolute right-0 top-8 flex select-none flex-col gap-1 rounded-lg bg-slate-200"
          >
            <button
              class="p-2 text-left text-sm font-semibold text-paragraph hover:text-blue-primary"
              @click="
                modalHandler.openType('image-upload-dialog', {
                  props: {
                    title: 'Upload Photos',
                  },
                })
              "
            >
              Upload pictures
            </button>
            <button
              class="p-2 text-left text-sm font-semibold text-paragraph hover:text-blue-primary"
              @click="
                modalHandler.openType('album-create-dialog', {
                  props: {
                    edit: false,
                  },
                })
              "
            >
              Create album
            </button>
          </div>
        </div>

        <div
          v-if="photoGallery.length"
          class="item-center mx-auto flex w-fit flex-col gap-6 sm:flex-row sm:flex-wrap"
        >
          <image-component
            v-for="(photo, photoID) in photoGallery"
            :key="photoID"
            :src="photo"
            class="h-[240px] w-[290px] rounded-lg object-cover"
            @click="
              modalHandler.openType('photo-viewer-dialog', {
                props: {
                  image: photo,
                  images: photoGallery,
                },
              })
            "
          />
        </div>
        <template v-else>
          <form-drop-file-input
            v-model="formPictures.pictures"
            class="mx-auto h-40 w-full max-w-[830px]"
            image-aspect-ratio="1"
            image-resize
            image-type
            multiple
          />
        </template>
      </section>
    </template>
    <template #discussions>
      <app-chat
        v-if="userGroup"
        :api-url="`/api/groups/${userGroup.id}/comments`"
        :channel="`group-comments-${userGroup.id}`"
        class="mx-auto w-full max-w-prose grow p-5"
        comment-replies
      >
        <template #empty-messages>
          <div
            class="flex flex-col items-center justify-center gap-4 py-10 text-gray-500"
          >
            <font-awesome-icon
              class="text-6xl text-blue-neon"
              icon="comments"
            />
            <div class="text-center">
              <h4>No comments yet</h4>
              <p>Be the first to comment on this activity!</p>
            </div>
          </div>
        </template>
        <template #message="{ message }">
          <activity-comment :comment="message" />
        </template>
        <template #input="{ sending, sendFunction }">
          <comment-form
            v-model:text="commentText"
            :sending="sending"
            @send="sendFunction($event).then(() => (commentText = ''))"
          />
        </template>
      </app-chat>
    </template>
    <template #events>
      <group-events-section
        :user-group="userGroup"
        title="Group’s Life"
      />
    </template>
    <template #members>
      <members-section :user-group="userGroup" />
    </template>
    <template #gallery>
      <gallery-section :user-group="userGroup" />
    </template>
    <template #rules>
      <group-rules-section :group="userGroup" />
    </template>
  </user-group-layout>
</template>

<script lang="ts" setup>
import axios, { AxiosResponse } from 'axios'
import { ref, toRaw, watch } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { useModal } from '@/utils/modal'
import { useQuery, useQueryClient } from '@tanstack/vue-query'

import DotsIcon from '@/icons/DotsIcon.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import UserGroup from '@/models/UserGroup'
import UserGroupLayout from '@/components/layout/UserGroupLayout.vue'
import ImageComponent from '@/components/ui/ImageComponent.vue'
import GroupActivityCard from '@/components/ui/GroupActivityCard.vue'
import MembersSection from '@/shared/components/group/sections/MembersSection.vue'
import GallerySection from '@/shared/components/group/sections/GallerySection.vue'
import GroupRulesSection from '@/shared/components/group/sections/GroupRulesSection.vue'
import GroupEventsSection from '@/shared/components/group/sections/GroupEventsSection.vue'
import FormDropFileInput from '@/components/input/FormDropFileInput.vue'
import AppChat from '@/components/widgets/AppChat.vue'
import ActivityComment from '@/components/ui/ActivityComment.vue'
import CommentForm from '@/components/input/CommentForm.vue'

const editFlag = true
const showEditGalleryMenu = ref(false)
const photoGallery = []

const modalHandler = useModal()
const formPictures = useForm({
  banner: null,
  pictures: null,
})

const page = usePage()
const id = page.url.match(/\/groups\/(\d+)/)?.[1] ?? 0
const userGroup = ref(null as UserGroup | null)
const commentText = ref('')
const { data } = useQuery<UserGroup>({
  queryKey: ['groups', +id],
  queryFn: async () => axios.get(`/api/groups/${id}`),
  select: (data) => UserGroup.make(data.data),
})
watch(
  () => data.value,
  (newData) => {
    userGroup.value = toRaw(newData ?? null)
  },
  { immediate: true }
)
const queryClient = useQueryClient()

function openCreateMeetupDialog() {
  modalHandler.openType('meetup-create', {
    events: {
      onPrimary: (payload, close, props) => {
        props.form
          .post(`/api/groups/${userGroup.value?.id}/activities`)
          .then((res: AxiosResponse) => {
            close()
            modalHandler.openType('activityDetail', {
              props: {
                activity: res.data,
              },
            })
          })
      },
    },
    onClosed: () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', +id],
      })
    },
  })
}
</script>

<style scoped></style>
