<template>
  <event-product-browse-layout
    v-model:selected-tab="filters.type"
    :tabs="tabs"
  >
    <template #section-title-name>
      Shopping at {{ selectedEvent?.name }}
    </template>
    <resource-paginator
      :items="items"
      :list-class="
        filters.type === 'shop'
          ? 'flex flex-col align-items-center gap-5 justify-content-center w-full mx-auto max-w-screen-2xl px-2'
          : 'grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-12 w-full mx-auto max-w-screen-2xl px-2'
      "
      :loading="loading || fetching"
      :reached-end="!hasNextPage"
      @load-next-page="hasNextPage && fetchNextPage()"
    >
      <template #item="{ item }">
        <shop-card-with-products
          v-if="filters.type === 'shop'"
          :event-slug="selectedEvent?.slug"
          :shop="item"
        />

        <item-card
          v-else
          :is-menu-item="false"
          :event-slug="selectedEvent?.slug"
          :item="item"
          class="shrink-0 md:max-w-[290px]"
          image-class="h-[220px]"
        />
      </template>
    </resource-paginator>
  </event-product-browse-layout>
</template>

<script lang="ts" setup>
import ResourcePaginator from '@/components/layout/ResourcePaginator.vue'
import ShopCardWithProducts from '@/components/ui/ShopCardWithProducts.vue'
import EventProductBrowseLayout from '@/components/layout/EventProductBrowseLayout.vue'
import { useInfiniteTable } from '@/utils/composables/table'
import axios from 'axios'
import useConventionList from '@/utils/composables/conventionList'
import ItemCard from '@/views/www/MenuItems/ItemCard.vue'

const { selected: selectedEvent } = useConventionList()

const {
  loading,
  fetching,
  items,
  sort,
  filters,
  columns,
  hasNextPage,
  fetchNextPage,
  invalidate,
} = useInfiniteTable({
  queryKey: 'browseShop',
  baseUrl: `/api/conventions/${selectedEvent.value?.slug}/products`,
  queryFn: (params) => {
    switch (params.type) {
      case 'product':
        return axios.get(
          `/api/conventions/${selectedEvent.value?.slug}/products`,
          {
            params: {
              ...params,
            },
          }
        )
      case 'shop':
      default:
        return axios.get(
          `/api/conventions/${selectedEvent.value?.slug}/vendors`,
          {
            params: {
              ...params,
            },
          }
        )
    }
  },
  filters: {
    type: 'product',
  },
})

const tabs = [
  {
    name: 'Products',
    value: 'product',
  },
  {
    name: 'Shops',
    value: 'shop',
  },
]
</script>

<style scoped></style>
