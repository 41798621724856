<template>
  <div class="flex flex-col">
    <label
      :for="'form-image-'+$.uid"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>
    <div
      class="mt-1 px-5 flex gap-5 justify-start items-center rounded-lg text-gray-500 bg-gray-200 overflow-x-auto scrollbar-hide overflow-y-hidden flex-nowrap h-full"
    >
      <div
        class="flex flex-col justify-center p-3 cursor-pointer w-32"
        @click="formInput.click()"
      >
        <div class="mx-auto">
          <svg
            class="block w-16 h-10 text-gray-400 group-hover:text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </div>
        <div class="text-xs text-center">
          Add image
        </div>
      </div>
      <div
        v-for="(image, index) in modelValue"
        :key="index"
        class="h-full aspect-square relative flex justify-center items-center"
      >
        <img
          :src="image.path"
          class="w-full"
          alt="preview image"
        />
        <button
          type="button"
          class="absolute top-0 right-0 p-1 bg-gray-500 text-white rounded-full"
          @click="removeImage(index)"
        >
          <svg
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
    <validation-error :error="error" />
    <input
      :id="'form-image-'+$.uid"
      ref="formInput"
      type="file"
      accept="image/jpeg, image/png, image/webp, image/gif"
      :disabled="disabled"
      class="bg-gray-200 rounded py-2 px-3 hidden"
      multiple
      @change="addFile"
    />
  </div>
</template>

<script setup>
import ValidationError from "../ui/ValidationError.vue";
import {ref} from "vue";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
    max: {
        type: Number,
        default: 5
    },
    error: {
        type: String,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: 'Image'
    },
    hint: {
        type: String,
        default: ''
    },
    clear: {
        type: Boolean,
        default: false
    }
})

const formInput = ref(null)

function removeImage(index) {
    if (props.modelValue.length > 0 && props.modelValue.length > index) {
        emit('update:modelValue', props.modelValue.filter((_, i) => i !== index))
    }
}

function addFile(event) {
    const files = event.target.files
    if (files.length > 0) {
        console.log(files)
        let newImages = []
        for (let i = 0; i < files.length; i++) {
            if ((props.modelValue.length + newImages.length) >= props.max) {
                break;
            }
            newImages.push({
                file: files[i],
                path: URL.createObjectURL(files[i])
            })
        }

        emit('update:modelValue', [...props.modelValue, ...newImages])
    }
}
</script>

<style scoped>

</style>
