<template>
  <modal-dialog-layout
    title="Group Rules"
    @scroll="checkScrollState"
  >
    <accordion-list
      :items="rules"
      class="flex w-full flex-col gap-12 p-10"
      default-expanded
    />
    <template #footer>
      <div class="flex justify-between p-3">
        <v-button
          size="sm"
          variant="secondary"
          @click="emit('close')"
        >
          Cancel
        </v-button>
        <div class="flex w-1/2 items-center justify-end gap-1">
          <p class="text-end text-[11px] text-slate-500">
            <template v-if="!scrolledToBottom">
              Read all the rules to accept
            </template>
            <template v-else>
              By clicking "Accept", you agree that you have read and accepted
              all the group rules
            </template>
          </p>
          <v-button
            :disabled="!scrolledToBottom"
            size="sm"
            variant="primary"
            @click="emit('confirm')"
          >
            Accept
          </v-button>
        </div>
      </div>
    </template>
  </modal-dialog-layout>
</template>

<script lang="ts" setup>
import ModalDialogLayout from '@/components/modal/layout/ModalDialogLayout.vue'
import { PropType, ref } from 'vue'
import AccordionList from '@/components/ui/AccordionList.vue'
import VButton from '@/components/buttons/VButton.vue'

const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
  rules: {
    type: Array as PropType<string[]>,
    required: true,
  },
})

const scrolledToBottom = ref(false)

function checkScrollState({ scroll, maxScroll }) {
  if (scrolledToBottom.value) return
  if (scroll >= maxScroll - 10) {
    scrolledToBottom.value = true
  }
}
</script>

<style scoped></style>
