<template>
  <div>
    <div
      v-if="showComment"
      class="comment-indicator relative flex justify-center items-start w-6 h-6"
    >
      <div class="comment-count relative flex pt-0.5 text-2xs text-white z-10 font-bold">
        {{ displayCount }}
      </div>
      <div class="absolute inset-0 z-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 701 582"
          class="w-full text-blue"
        >
          <title>Comment</title>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Group">
              <path
                id="outline"
                fill="#FFFFFF"
                d="M261.903,501.246 L151.985,577.026 C137.596,587.222 125.861,581.25 125.861,563.627 L125.861,450.143 L124.968,449.808 C48.5566,403.072 0,333.045 0,254.775 C0,114.067 156.924,0 350.5,0 C544.076,0 701,114.067 701,254.775 C701,395.483 544.076,509.55 350.5,509.55 C320.075,509.55 290.555,506.732 262.407,501.435 L261.903,501.246 Z"
                transform="scale(1.01)"
              />
              <path
                id="frontComment"
                fill="currentColor"
                fill-rule="evenodd"
                d="M262.524082,463.571782 L177.963992,523.978409 C166.330427,532.221917 156.842631,527.39353 156.842631,513.145263 L158.018825,423.553228 L157.844262,422.845761 C96.065333,385.059514 55.0834985,326.718774 55.0834985,263.437159 C55.0834985,149.674166 181.957194,43.0001483 338.464181,43.0001483 C494.971168,43.0001483 647.916502,133.627995 647.916502,263.437159 C647.916502,393.246322 494.971168,469.423787 338.464181,469.423787 C313.865444,469.423787 285.689341,468.007235 262.931568,463.724589 L262.524082,463.571782 Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    count: {
        type: Number,
        required: true,
    },
});

const displayCount = computed(() => {
    if (props.count <= 9) {
        return props.count;
    } else {
        return '9+';
    }
});

const showComment = computed(() => {
    return props.count > 0;
});
</script>





<style scoped>

</style>
