<template>
  <div class="max-w-full overflow-scroll">
    <div
      class="relative flex min-h-[110px] w-full min-w-[1200px] shrink-0 flex-row items-center justify-between gap-4 rounded-lg border-b border-solid border-slate-300 bg-slate-100 p-4"
    >
      <div class="flex flex-row items-start gap-2">
        <avatar-image
          :image="member.avatar"
          class="mb-2 flex h-12 w-12 shrink-0 rounded-full"
        />
        <div class="flex w-full flex-col items-start gap-1">
          <h5
            class="flex w-full max-w-[160px] flex-col items-center text-left text-lg font-semibold text-very-dark-blue"
          >
            {{ member.name !== '' ? member.name : '@' + member.username }}
          </h5>
          <div class="text-left text-sm text-slate-400">
            {{ member.user_tag?.name ?? 'No additional details' }}
          </div>
        </div>

        <div class="flex flex-col gap-2"></div>
      </div>
      <div
        class="max-h-[110px] min-w-[200px] max-w-[220px] overflow-y-scroll text-left text-lg text-very-dark-blue"
      >
        {{ member.bio }} todo: I'm looking to meet fellow fans and share tips on
        creating cosplay costumes.
      </div>
      <div
        class="flex max-h-[110px] min-w-[160px] max-w-[310px] flex-wrap gap-3 overflow-y-scroll"
      >
        <!-- todo: replace with dynamic data -->
        <div
          v-for="item in [1, 2]"
          :key="item"
          class="shrink-0 rounded-full bg-indigo-100 px-4 py-1 text-sm text-blue-muted"
        >
          Game of Thrones
        </div>
      </div>

      <!-- todo: replace with dynamic data -->
      <button
        v-if="answers_count"
        class="text-base font-bold text-very-dark-blue"
        @click="
          modalHandler.openType('member-request-dialog', {
            props: {
              readonly: true,
              avatar: { path: 'https://picsum.photos/200/30' },
              name: `Catherine Milano`,
              location: 'Boston, Massachusetts',
              whyJoin: `It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. `,
              fandoms: [],
              rulesAgreement: false,
            },
          })
        "
      >
        +{{ answers_count }} more answers
      </button>

      <!-- todo: add logic -->
      <div class="flex items-center gap-4">
        <v-button
          variant="primary"
          class="shrink-0 rounded !py-1 px-8 text-sm"
        >
          Accept
        </v-button>
        <v-button
          variant="secondary"
          class="shrink-0 rounded !py-1 px-8 text-sm"
        >
          Decline
        </v-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useModal } from '@/utils/modal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { PropType } from 'vue'
import User from '@/models/User'

import VButton from '@/components/buttons/VButton.vue'
import AvatarImage from '@/components/ui/AvatarImage.vue'
import DropdownButton from '@/components/buttons/DropdownButton.vue'

const emit = defineEmits([
  'promote',
  'demote',
  'block',
  'kick',
  'unblock',
  'accept',
  'reject',
])

const props = defineProps({
  member: {
    type: Object as PropType<User>,
    required: true,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  isBlocked: {
    type: Boolean,
    default: false,
  },
})

const modalHandler = useModal()
const answers_count = 3
</script>

<style lang="scss" scoped></style>
