<template>
  <div :class="componentClass">
    <form-label
      :for="$.uid"
      :input-style="inputStyle"
      :label="label"
    />
    <slot name="before" />
    <input-wrapper :input-style="inputStyle">
      <component
        :is="type === 'textarea' ? 'textarea' : 'input'"
        :id="$.uid"
        ref="input"
        :disabled="disabled"
        :max="props.max"
        :min="props.min"
        :placeholder="placeholder"
        :rows="rows"
        :step="step"
        :type="type"
        :value="modelValue"
        class="flex-grow rounded-md bg-transparent focus:outline-none"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
        @input="emit('update:modelValue', $event.target.value)"
      ></component>
      <slot
        :focus="focusOnInput"
        name="inner"
      />
    </input-wrapper>
    <slot />
    <validation-error
      v-if="error"
      :class="validationClass"
      :error="error"
    />
  </div>
</template>

<script lang="ts" setup>
import ValidationError from '@/components/ui/ValidationError.vue'
import InputWrapper from './InputWrapper.vue'
import { computed, onMounted, PropType, ref } from 'vue'
import FormLabel from '../ui/FormLabel.vue'

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  step: {
    type: String,
    default: 'any',
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  rows: {
    type: Number,
    default: 3,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  inputStyle: {
    type: String as PropType<InputStyle>,
    default: () => 'default',
  },
})

enum InputStyle {
  DEFAULT = 'default', // label on top, input on bottom with border bottom on input
  GRAY = 'gray', // label on top, input on bottom with gray bg
  OUTLINE = 'outline', // label on top, input on bottom with outline
  ROW = 'row', // label on left, input on right
  ROW_OUTLINE = 'row-outline', // label on left, input on right, outline
}

const componentClass = computed(() => {
  switch (props.inputStyle) {
    case InputStyle.DEFAULT:
    case InputStyle.GRAY:
    case InputStyle.OUTLINE:
      return 'flex flex-col'
    case InputStyle.ROW_OUTLINE:
    case InputStyle.ROW:
      return 'flex flex-wrap flex-row gap-3'
    default:
      return ''
  }
})

const validationClass = computed(() => {
  switch (props.inputStyle) {
    case InputStyle.DEFAULT:
    case InputStyle.GRAY:
    case InputStyle.OUTLINE:
      return 'mt-2'
    case InputStyle.ROW_OUTLINE:
    case InputStyle.ROW:
      return 'mt-0 w-full'
    default:
      return ''
  }
})

const input = ref(null)

function focusOnInput() {
  input.value.focus()
}

onMounted(() => {
  if (props.autofocus) {
    input.value.focus()
  }
})
</script>
