<template>
  <div
    v-if="isPopupOpen"
    class="fixed inset-0 z-50 flex items-end justify-center"
    @click.self="$emit('close-popup')"
  >
    <transition name="fade">
      <div
        v-if="isPopupOpen"
        class="fixed inset-0 z-40 bg-black opacity-50"
      />
    </transition>
    <transition name="slide-up">
      <div
        v-if="isPopupOpen"
        :style="{ marginTop: `${marginTop}px`, height: '90%' }"
        class="slide-up-popup z-50 w-full max-w-2xl rounded-t-lg bg-white shadow-lg"
      >
        <div
          class="flex items-center justify-between border-b border-gray-200 p-6"
        >
          <h2 class="text-2xl">
            {{ title }}
          </h2>
          <button @click="$emit('close-popup')">
            <font-awesome-icon icon="close" />
          </button>
        </div>
        <div
          class="slide-up-content max-h-[calc(100vh-10rem)] p-6"
          style="overflow-y: auto"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const emits = defineEmits(['close-popup'])




















































defineOptions({
  name: 'SlideUpPopup',
})




















































const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  isPopupOpen: {
    type: Boolean,
    default: false,
  },
})
const isOpen = ref(false)
const marginTop = ref(64)

function openPopup() {
  props.isPopupOpen = true
}

function closePopup() {
  props.isPopupOpen = false
}
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 500ms ease-in-out;
}

.slide-up-enter-from {
  transform: translateY(100%);
}

.slide-up-leave-to {
  transform: translateY(100%);
}

.shadow-lg {
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
