<template>
  <section
    class="no-repeat mx-auto w-fit items-center gap-y-16 px-2 py-20 sm:px-6 sm:py-[112px] xl:px-[100px]"
  >
    <div
      class="flex w-full max-w-[1200px] flex-col gap-4 md:flex-row md:gap-10 lg:gap-16"
    >
      <div class="flex w-full flex-col items-start gap-5 md:max-w-[40%]">
        <h2
          class="hidden w-fit text-left text-3xl font-bold leading-[140%] tracking-[0.8px] text-dark-grey sm:flex lg:text-4xl"
        >
          Frequently asked questions
        </h2>
        <h2
          class="mx-auto w-fit text-center text-3xl font-bold leading-[140%] tracking-[0.8px] text-dark-grey sm:flex sm:hidden lg:text-4xl"
        >
          FAQ
        </h2>
        <p
          class="text-titillium hidden text-left text-lg text-paragraph sm:flex"
        >
          We've organized the questions into different categories for your
          convenience, so you can find the answers you need without hassle. Feel
          free to Contact Us if you couldn’t find an answer to you question.
        </p>

        <a
          class="hidden items-center gap-2 text-left text-paragraph sm:flex"
          href="https://forms.gle/qh7MyzZK89veLe2o8"
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact Us
          <arrow-in-circle />
        </a>
      </div>

      <ul class="flex w-full flex-col md:max-w-[60%]">
        <li
          v-for="(option, index) in faqList"
          :key="index"
          class="flex w-full flex-col items-start gap-4 border-b-2 border-slate-200 px-3 py-6 text-paragraph"
        >
          <label
            :for="`faq-${index}`"
            class="flex w-full cursor-pointer select-none items-center justify-between"
          >
            {{ option.title }}
            <button
              :id="`faq-${index}`"
              class="ml-2 flex h-4 w-4 items-center justify-center"
              @click="toggleFaq(index)"
            >
              <font-awesome-icon
                :icon="option.showFull ? 'minus' : 'plus'"
                class="h-4 w-4 text-slate-600"
              />
            </button>
          </label>
          <div v-if="option.showFull">
            {{ option.text }}
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

import ArrowInCircle from '@/icons/ArrowInCircle.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const faqList = ref([
  {
    showFull: false,
    title: 'How do I get started with Fankind?',
    text: 'Just grab your phone and download the Fankind app from your app store. Sign up, set up your profile, and boom – you`re ready to dive into all things Comic-Con. It`s super easy to start planning your next convention adventure with us.',
  },
  {
    showFull: false,
    title: 'Do I have to pay to use Fankind?',
    text: 'Nope, getting in on the basic action doesn`t cost a dime. You can check out schedules, join forums, and meet other fans for free. If you`re looking for some extra cool features, we`ve got some premium stuff that you can check out for a little extra.',
  },
  {
    showFull: false,
    title: 'Can I meet other comic-con fans on Fankind?',
    text: 'Absolutely! Our app is a great place to meet people just as excited about comic cons as you are. Join discussions, check out meetups, and share your experiences. It`s a fun way to make new friends who are into the same stuff you are.',
  },

  {
    showFull: false,
    title: 'I`m new to comic cons. How`s Fankind gonna help me out?',
    text: 'First time? No worries! Fankind`s like having a guidebook in your pocket. It gives you all the details on schedules, what to check out, and even tips to make sure you have a blast. You can also link up with other fans, making it super easy to dive into the comic con world and have fun right from the start.',
  },
  {
    showFull: false,
    title: 'I`ve been to cons before and love cosplay. Is Fankind for me too?',
    text: 'Oh, for sure! If you`re all about hitting up cons and getting into cosplay, Fankind`s going to be your new favorite app. It lets you customize your schedule, join meetups, and connect with other cosplay fans. Plus, you`ll get updates and exclusive content to help you make the most of every con.',
  },
  {
    showFull: false,
    title: 'I organize comic con events. What`s in Fankind for me?',
    text: 'For event organizers, big or small, Fankind is a game-changer. It helps you manage everything smoothly – from lines to volunteers and keeping attendees in the loop. Plus, with tools to analyze what`s happening and get fans engaged, you can make every event a hit and keep people coming back for more. ',
  },
])

const toggleFaq = (index) => {
  const updatedFaq = {
    ...faqList.value[index],
    showFull: !faqList.value[index].showFull,
  }

  faqList.value.splice(index, 1, updatedFaq)
}
</script>

<style lang="scss" scoped></style>
