<template>
  <standard-layout>
    <div
      id="event-browse-layout"
      class="flex w-full grow flex-col"
    >
      <section class="sticky top-16 z-fixed w-full bg-white shadow">
        <div class="event-menu w-full">
          <div
            class="flex w-full select-none flex-row flex-nowrap justify-between gap-1 overflow-x-scroll bg-slate-100 text-base text-slate-700 hover:text-black md:justify-center md:gap-8 md:text-lg"
          >
            <Link
              v-if="activeTabs.includes('home')"
              :class="{
                'font-bold text-blue-primary ':
                  component === 'Event/BrowseHome' || isActivePage('home'),
              }"
              :href="`/events/${selectedConvention?.slug}`"
              class="px-6 py-2.5"
            >
              Home
            </Link>
            <Link
              v-if="activeTabs.includes('schedule')"
              :class="{
                'font-bold text-blue-primary ': isActivePage('schedule'),
              }"
              :href="`/events/${selectedConvention?.slug}/schedule`"
              class="px-6 py-2.5"
            >
              Schedule
            </Link>
            <Link
              v-if="activeTabs.includes('meet')"
              :class="{ 'font-bold text-blue-primary ': isActivePage('meet') }"
              :href="`/events/${selectedConvention?.slug}/meet`"
              class="px-6 py-2.5"
            >
              Meet
            </Link>
            <Link
              v-if="activeTabs.includes('browse')"
              :class="{ 'font-bold text-blue-primary ': isActivePage('browse') }"
              :href="`/events/${selectedConvention?.slug}/browse`"
              class="px-6 py-2.5"
            >
              Browse
            </Link>
            <Link
              v-if="activeTabs.includes('eat')"
              :class="{ 'font-bold text-blue-primary ': isActivePage('eat') }"
              :href="`/events/${selectedConvention?.slug}/eat`"
              class="px-6 py-2.5"
            >
              Eat
            </Link>
            <Link
              v-if="activeTabs.includes('shop')"
              :class="{ 'font-bold text-blue-primary ': isActivePage('shop') }"
              :href="`/events/${selectedConvention?.slug}/shop`"
              class="px-6 py-2.5"
            >
              Shop
            </Link>
            <!--                        <Link v-if="activeTabs.includes('play')" href="play" class="px-6 py-2.5" :class="{ 'text-blue-primary font-bold ': isActivePage('play') }">Play</Link>-->
            <!--                        <Link v-if="activeTabs.includes('watch')" href="watch" class="px-6 py-2.5" :class="{ 'text-blue-primary font-bold ': isActivePage('watch') }">Watch</Link>-->
            <!--                        <Link v-if="activeTabs.includes('schedule')" href="schedule" class="px-6 py-2.5" :class="{ 'text-blue-primary font-bold ': isActivePage('schedule') }">Schedule</Link>-->
            <!--                        <Link href="shop" class="px-6 py-2.5" :class="{ 'text-blue-primary font-bold ': isActivePage('shop') }">Shop</Link>-->
          </div>
        </div>
        <div class="mx-auto max-w-screen-2xl px-2 md:px-4">
          <div
            v-if="$slots['section-title']"
            class="pb-2 pt-6 md:pb-4 lg:py-6 xl:py-8"
          >
            <slot name="section-title" />
          </div>
          <slot name="sub-stick-header" />
        </div>
      </section>
      <div class="relative flex grow flex-col">
        <slot name="browse-page" />
        <slot />
      </div>
    </div>
  </standard-layout>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3'
import StandardLayout from './StandardLayout.vue'
import useConventionList from '@/utils/composables/conventionList'

defineOptions({
  name: 'EventBrowseLayout',
})

const props = defineProps({
  active: {
    type: String,
    default: () => undefined,
  },
})

const { url, props: pageProps, component } = usePage()

const { selected: selectedConvention } = useConventionList()

const activeTabs = usePage().props.activeTabs ?? []

const isActivePage = (name) => {
  const urlWithoutQueryParams = url.split('?')[0].split('#')[0]
  const lastPart = urlWithoutQueryParams.split('/').pop()
  return lastPart === name || props.active === name
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}

/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Make scrollbar transparent */
::-webkit-scrollbar {
  background-color: transparent;
}
</style>
