<template>
  <standard-layout>
    <div class="flex grow items-start gap-5 px-5 py-5">
      <nav
        class="sticky top-20 flex w-full max-w-xs flex-col gap-3 rounded border px-3 py-2 shadow"
      >
        <h2 class="text-2xl font-semibold">
          Settings
        </h2>
        <ul class="flex flex-col gap-2">
          <Link
            :class="{
              'bg-gray-200': $page.component === 'User/UserGeneralSettingsView',
            }"
            :href="`/users/${user.id}/settings/general`"
            as="li"
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-gray-200"
          >
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.121 17.804C7.21942 16.6179 9.58958 15.9963 12 16C14.5 16 16.847 16.655 18.879 17.804M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>

            General
          </Link>
          <Link
            :class="{
              'bg-gray-200': $page.component === 'User/UserPrivacySettingsView',
            }"
            :href="`/users/${user.id}/settings/privacy`"
            as="li"
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-gray-200"
          >
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15V17M6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V13C20 12.4696 19.7893 11.9609 19.4142 11.5858C19.0391 11.2107 18.5304 11 18 11H6C5.46957 11 4.96086 11.2107 4.58579 11.5858C4.21071 11.9609 4 12.4696 4 13V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21ZM16 11V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V11H16Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>

            Privacy
          </Link>
          <Link
            :class="{
              'bg-gray-200':
                $page.component === 'User/UserSecuritySettingsView',
            }"
            :href="`/users/${user.id}/settings/security`"
            as="li"
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-gray-200"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Security
          </Link>
          <Link
            :class="{
              'bg-gray-200':
                $page.component === 'User/UserNotificationsSettingsView',
            }"
            :href="`/users/${user.id}/settings/notifications`"
            as="li"
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-gray-200"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Notifications
          </Link>
        </ul>
      </nav>
      <div class="flex grow flex-col gap-5">
        <div
          v-if="user.email_verified_at === null"
          class="mb-3 flex justify-between border-l-4 border-yellow-500 bg-yellow-100 p-4 text-yellow-700"
          role="alert"
        >
          <div>
            <p class="font-bold">
              Your email address is not verified.
            </p>
            <p>Please check your email for a verification link.</p>
          </div>
          <button
            class="mt-2 rounded bg-yellow-600 px-4 py-2 font-bold text-white hover:bg-yellow-700"
          >
            Resend verification email
          </button>
        </div>
        <slot />
      </div>
    </div>
  </standard-layout>
</template>

<script setup>
import StandardLayout from '@/components/layout/StandardLayout.vue'
import { Link } from '@inertiajs/vue3'
import useAuth from '@/utils/composables/auth'

const { user } = useAuth()
</script>

<style scoped></style>
