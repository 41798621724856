<template>
  <div
    :class="[
      props.theme?.backgroundGradient
        ? props.theme.backgroundGradient
        : 'bg-dark-blue',
      theme?.textLight,
    ]"
    class="relative flex w-full flex-col items-center justify-center gap-14 px-1 pb-20 pt-24"
  >
    <v-button
      v-if="edit"
      class="absolute right-10 top-2 z-20 rounded-full bg-violet-100 px-10 py-2 text-sm md:top-4"
      variant="secondary"
      @click="emit('update:edit', !edit)"
    >
      Manage Activities
    </v-button>
    <!--    <modal-->
    <!--      :open="!!activityDetails"-->
    <!--      position="center"-->
    <!--      size="lg"-->
    <!--      @update:open="activityDetails = null"-->
    <!--    >-->
    <!--      <modal-buttons-layout-->
    <!--        :title="null"-->
    <!--        back-button-->
    <!--        disable-bottom-->
    <!--        secondary-button="Back"-->
    <!--        @cancel="activityDetails = null"-->
    <!--      >-->
    <!--        <activity-detail-->
    <!--          :activity="activityDetails"-->
    <!--          class="grow"-->
    <!--        />-->
    <!--      </modal-buttons-layout>-->
    <!--    </modal>-->

    <div class="flex flex-col px-14 text-center">
      <showcase-text-element
        v-model="section.title"
        :class="[theme?.textPrimary ? theme?.textPrimary : 'text-white']"
        class="mb-6 min-w-[100px] text-[40px] font-medium"
        label="Title"
        tag="h1"
      />
      <showcase-text-element
        v-model="section.description"
        :class="[theme?.textSecondary ? theme?.textSecondary : 'text-white']"
        class="text-base font-normal"
        label="Description"
        tag="p"
      />
    </div>
    <div
      class="ml-0 mr-auto flex w-full items-center overflow-x-scroll px-4 pb-5 md:px-8 lg:px-14"
    >
      <button
        v-for="convention in section.events"
        :key="convention.id"
        :class="{
          'bg-orange-950': selectedEventID === convention.id,
        }"
        class="rounded-lg px-6 py-2 text-white duration-100"
        @click="selectedEventID = convention.id"
      >
        {{ convention.name }}
      </button>
    </div>
    <div
      v-if="section.events?.length"
      class="scrollbar-hide flex w-full flex-row flex-nowrap gap-6 overflow-scroll px-4 md:px-8 lg:px-14"
    >
      <div
        v-if="section.events[selectedEventID]?.activities?.length === 0"
        :class="[
          theme?.backgroundDark ? theme?.backgroundDark : 'bg-slate-300',
          theme?.textDark ? theme?.textDark : 'text-slate-950',
        ]"
        class="mx-auto rounded-lg p-4"
      >
        No activities scheduled
      </div>
      <div
        v-for="eventDay in groupBy(
          section.events.find((e) => e.id === selectedEventID)?.activities,
          'event_day_id'
        )"
        :key="eventDay[0].event_day_id"
        class="flex w-full min-w-[300px] max-w-[390px] flex-shrink-0 flex-col gap-6"
      >
        <div
          :class="[
            'flex flex-col gap-4 rounded-lg p-3',
            theme?.backgroundSecondaryLight
              ? theme?.backgroundSecondaryLight
              : 'bg-white',
          ]"
        >
          <h3
            :class="[
              'text-center',
              theme?.textDark ? theme?.textDark : 'text-slate-950',
            ]"
          >
            {{
              DateTime.fromISO(eventDay[0].starts_at, {
                setZone: true,
              }).toFormat('EEE, MMM dd')
            }}
          </h3>

          <div
            v-if="eventDay.length === 0"
            :class="[
              theme?.backgroundDark ? theme?.backgroundDark : 'bg-slate-300',
              theme?.textDark ? theme?.textDark : 'text-slate-950',
            ]"
            class="rounded-lg p-4"
          >
            No activities scheduled for this day
          </div>
          <schedule-activity-presenter
            v-for="activity in eventDay"
            :key="activity.id"
            :activity="activity"
            bg-disabled
            button-reverse
            class="bg-[#CDDBFF] bg-opacity-100 text-black"
            expanded
            hover-hidden
            @open="getActivityDetails(activity)"
            @rsvp="
              router.post('/activities/' + activity.id + '/attend', null, {
                only: ['auth.user'],
                preserveScroll: true,
                preserveState: true,
              })
            "
            @unrsvp="
              router.delete('/activities/' + activity.id + '/attend', {
                only: ['auth.user'],
                preserveScroll: true,
                preserveState: true,
              })
            "
          />
        </div>
      </div>
    </div>
    <div
      v-else
      :class="[
        theme?.backgroundDark ? theme?.backgroundDark : 'bg-slate-300',
        theme?.textDark ? theme?.textDark : 'text-slate-950',
      ]"
      class="mx-auto rounded-lg p-4"
    >
      No activities scheduled
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import axios from 'axios'
import { router } from '@inertiajs/vue3'

import { DateTime } from 'luxon'
import ScheduleActivityPresenter from '../ScheduleActivityPresenter.vue'
import useConventionList from '@/utils/composables/conventionList'
import VButton from '@/components/buttons/VButton.vue'
import ShowcaseTextElement from '@/components/ui/showcase/ShowcaseTextElement.vue'
import { useModal } from '@/utils/modal'

const emit = defineEmits(['edit', 'update:edit'])

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  theme: {
    type: Object,
    required: true,
  },
})

const { selected: selectedEvent } = useConventionList()
const modalHandler = useModal()
const edit = inject('showcase-edit-mode')

const activityDetails = ref(null)
const selectedEventID = ref(
  props.section.events.find((e) => e.id === selectedEvent.value.id)?.id ??
    props.section.events[0]?.id ??
    null
)

function getActivityDetails(activity) {
  activityDetails.value = activity
  modalHandler.openType('activityDetail', {
    props: {
      activity: activity,
    },
  })
  axios
    .get(`/api/activities/${activity.id}`)
    .then((response) => {
      activityDetails.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

console.log(props.section)

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
</script>

<style scoped></style>
