<template>
  <transition-group
    :tag="tag"
    v-bind="$attrs"
    @before-enter="beforeEnter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @after-leave="afterLeave">
    <slot />
  </transition-group>
</template>

<script lang="ts" setup>
interface Duration {
  enter?: number
  leave?: number
}

const emit = defineEmits([
  'before-enter',
  'after-enter',
  'before-leave',
  'after-leave',
])

const props = withDefaults(
  defineProps<{
    tag: string
    duration?: number | Duration
    delay?: number | Duration
  }>(),
  {
    tag: 'div',
    duration: 300,
    delay: 0,
  }
)

function setStyles(el: HTMLElement) {
  // setTransformOrigin(el)
  // Object.keys(this.styles).forEach(key => {
  //   const styleValue = this.styles[key]
  //   if (styleValue) {
  //     el.style[key] = styleValue
  //   }
  // })
}

function cleanUpStyles(el: HTMLElement) {
  // Object.keys(this.styles).forEach(key => {
  //   const styleValue = this.styles[key]
  //   if (styleValue) {
  //     el.style[key] = ''
  //   }
  // })
  el.style.animationDuration = ''
  el.style.animationDelay = ''
}

// function setTransformOrigin(el) {
//   if (this.origin) {
//     el.style.transformOrigin = this.origin
//   }
//   return this
// }

function beforeEnter(el: HTMLElement) {
  let enterDuration = props.duration.enter
    ? props.duration.enter
    : props.duration
  el.style.animationDuration = `${enterDuration}ms`

  let enterDelay = props.delay.enter ? props.delay.enter : props.delay
  el.style.animationDelay = `${enterDelay}ms`

  setStyles(el)
  emit('before-enter', el)
}

function afterEnter(el: HTMLElement) {
  cleanUpStyles(el)
  emit('after-enter', el)
}

function beforeLeave(el: HTMLElement) {
  let leaveDuration = props.duration.leave
    ? props.duration.leave
    : props.duration
  el.style.animationDuration = `${leaveDuration}ms`

  let leaveDelay = props.delay.leave ? props.delay.leave : props.delay
  el.style.animationDelay = `${leaveDelay}ms`

  setStyles(el)
  emit('before-leave', el)
}

function afterLeave(el: HTMLElement) {
  cleanUpStyles(el)
  emit('after-leave', el)
}
</script>

<style scoped></style>
