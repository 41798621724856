<template>
  <event-browse-layout hide-footer>
    <template #browse-page>
      <transition
        name="slide-left"
        @after-enter="updateCalendarSize('after')"
        @before-leave="updateCalendarSize('before')"
        @after-leave="updateCalendarSize('after')"
      >
        <div
          v-if="sidebarPreference"
          ref="sidebarTimeline"
          :class="{
            'w-full md:w-full lg:w-2/3': sidebarSize === 'w-2/3',
            'w-full md:w-1/2 lg:w-1/3': sidebarSize === 'w-1/3',
          }"
          class="absolute inset-y-0 right-0 z-modal flex flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl transition-all duration-300"
          @scroll="onSidebarTimelineScroll"
          @transitionstart.self="updateCalendarSize('before')"
          @transitionend.self="updateCalendarSize('after')"
        >
          <header
            class="sticky top-0 z-20 flex items-start justify-between bg-white bg-opacity-100 py-4 pl-6 pr-14 shadow backdrop-blur"
          >
            <div
              v-if="selectedDay"
              class="relative flex grow-0 flex-col"
            >
              <h2 class="pl-2 text-base font-medium leading-none">
                My Planner
              </h2>
              <small
                :class="[
                  showSidebarDaySelector
                    ? 'text-blue-primary'
                    : 'hover:text-blue-primary',
                ]"
                class="w-full cursor-pointer select-none px-2 py-1 text-base font-normal text-gray-800 transition-colors duration-100 md:text-sm"
                @click="showSidebarDaySelector = !showSidebarDaySelector"
              >
                {{ selectedDay.caption }} -
                {{ DateTime.fromISO(selectedDay.date).toFormat('ccc, LLL dd') }}
              </small>
              <div
                v-if="showSidebarDaySelector"
                class="absolute left-0 top-full w-full overflow-hidden rounded-b-lg bg-gray-100 bg-opacity-90 backdrop-blur"
              >
                <div
                  v-for="day in selectedEvent?.days"
                  :key="day.id"
                  class="line-clamp-1 cursor-pointer select-none p-2 text-lg font-normal text-gray-900 hover:bg-blue-primary hover:text-gray-50 md:text-base"
                  @click="filters['date'][0] = day.id"
                >
                  {{ day.caption }} -
                  {{ DateTime.fromISO(day.date).toFormat('ccc, LLL dd') }}
                </div>
              </div>
            </div>
            <div class="flex shrink-0 grow justify-end gap-2">
              <button
                class="hidden items-center justify-center rounded-lg p-1 transition-colors duration-75 hover:bg-blue-primary hover:text-gray-50 md:flex"
                @click="scrollSync = !scrollSync"
              >
                <font-awesome-icon
                  v-if="scrollSync"
                  class="h-6 w-6 text-blue-primary"
                  icon="link"
                />
                <font-awesome-icon
                  v-else
                  class="h-6 w-6 text-gray-400"
                  icon="link-slash"
                />
              </button>

              <button
                class="hidden rounded-lg p-1 transition-colors duration-75 hover:bg-gray-100 md:block"
                @click="setSidebarSize('w-2/3')"
              >
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 1 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="paint0_linear_1512_13765"
                      gradientUnits="userSpaceOnUse"
                      x1="12"
                      x2="12"
                      y1="5"
                      y2="21"
                    >
                      <stop
                        offset="0.21875"
                        stop-color="#1F1BDB"
                      />
                      <stop
                        offset="1"
                        stop-color="#100DA8"
                      />
                    </linearGradient>
                  </defs>
                  <rect
                    :fill="sidebarSize === 'w-2/3' ? '#797FFF' : '#DADEE7'"
                    height="14"
                    opacity="0.5"
                    width="11"
                    x="9"
                    y="6"
                  />
                  <path
                    :stroke="
                      sidebarSize === 'w-2/3'
                        ? 'url(#paint0_linear_1512_13765)'
                        : '#5F6775'
                    "
                    d="M8.5 21V5M8.5 5H12H15.5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H8.5Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <button
                class="hidden rounded-lg p-1 transition-colors duration-75 hover:bg-gray-100 md:block"
                @click="setSidebarSize('w-1/3')"
              >
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 1 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    :fill="sidebarSize === 'w-1/3' ? '#797FFF' : '#DADEE7'"
                    height="14"
                    opacity="0.5"
                    width="5"
                    x="15"
                    y="6"
                  />
                  <path
                    :stroke="
                      sidebarSize === 'w-1/3'
                        ? 'url(#paint0_linear_1512_13765)'
                        : '#5F6775'
                    "
                    d="M15.5 21V5M15.5 5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H15.5ZM15.5 5H12"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1512_13765"
                      gradientUnits="userSpaceOnUse"
                      x1="12"
                      x2="12"
                      y1="5"
                      y2="21"
                    >
                      <stop
                        offset="0.21875"
                        stop-color="#1F1BDB"
                      />
                      <stop
                        offset="1"
                        stop-color="#100DA8"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
              <button
                class="rounded-lg p-1 transition-colors duration-75 hover:bg-gray-100"
                @click="setSidebarSize('close')"
              >
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6L6 18Z"
                    stroke="#5F6775"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </div>
          </header>
          <my-schedule-timeline
            :cols="sidebarSize === 'w-1/3' ? 1 : 2"
            :end-time="roundTime(maxDateTime, 30, true)"
            :start-time="roundTime(minDateTime, 30)"
            :user-schedule="filteredUserSchedule"
            @open="getActivityDetails"
          />
        </div>
      </transition>

      <div
        :class="[calendarSize, animateCalendarSize ? '' : '']"
        class="flex grow flex-col"
      >
        <div
          class="mx-auto flex w-full max-w-screen-2xl items-center justify-between px-5 py-2 md:px-10"
        >
          <query-filters
            v-model:selected="filters"
            :categories="filterCategories"
            :loading="pageLoading"
            main="date"
            search-enabled
            variant="left"
            @search="
              modalHandler.open(ActivitySearchModalView, {
                props: {
                  position: 'center',
                  size: 'lg',
                  activities: activities,
                  typeTags: typeTags,
                  topicTags: topicTags,
                },
                events: {
                  onOpen: ([a]) => getActivityDetails(a),
                },
              })
            "
          />
          <button
            v-if="!sidebarPreference"
            :class="{
              'skeleton rounded-lg text-transparent': pageLoading,
            }"
            :disabled="pageLoading"
            class="relative overflow-visible whitespace-nowrap rounded-full bg-blue-primary px-4 py-1 text-white"
            @click="setSidebarSize('open')"
          >
            My Planner
            <div
              v-if="!pageLoading && filteredUserSchedule.length"
              class="absolute -right-1 -top-1 flex items-center justify-center rounded-full bg-red p-0.5 px-1 text-xs leading-none text-white"
            >
              {{ filteredUserSchedule.length }}
            </div>
          </button>
        </div>

        <div
          v-if="scheduleError"
          class="mt-32 flex h-full flex-col items-center justify-center gap-2"
        >
          <font-awesome-icon
            class="text-6xl text-gray-500"
            icon="exclamation-triangle"
          />
          <div class="text-2xl font-bold text-gray-500">
            Error loading schedule
          </div>
          <div class="text-gray-500">Try refreshing the page</div>
        </div>
        <schedule-calendar
          v-else-if="filteredActivities?.length > 0 || pageLoading"
          v-memo="[pageLoading, filteredActivities]"
          :activities="filteredActivities ?? []"
          :end-time="roundTime(maxDateTime, 30, true)"
          :loading="pageLoading"
          :schedule="schedule ?? []"
          :start-time="roundTime(minDateTime, 30)"
          :type-tags="typeTags"
          class="transition-all"
          @open="getActivityDetails"
          @update:scroll="onScheduleCalendarScroll"
        />
        <div
          v-else
          class="flex grow flex-col items-center justify-center"
        >
          <div class="text-2xl font-bold text-gray-500">
            No activities found for this date
          </div>
          <div class="text-gray-500">Try changing your filters or date</div>
        </div>
        <button
          v-if="
            !pageLoading &&
            Object.keys(filters)
              .filter((k) => k !== 'date')
              .some((category) => filters[category]?.length)
          "
          class="fixed bottom-10 left-1/2 z-50 -translate-x-1/2 rounded-full border border-gray-300 bg-ultra-light-blue px-2 py-3 text-gray-900"
          @click="resetFilters"
        >
          Clear Filters
        </button>
      </div>
    </template>
  </event-browse-layout>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import EventBrowseLayout from '../../../components/layout/EventBrowseLayout.vue'
import axios from 'axios'
import { DateTime } from 'luxon'
import { decodeTime, roundTime } from '@/utils/datetime'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MyScheduleTimeline from '@/components/ui/MyScheduleTimeline.vue'
import ScheduleCalendar from '@/components/widgets/ScheduleCalendar.vue'
import QueryFilters from '@/components/ui/QueryFilters.vue'
import { useAttending } from '@/utils/attending'
import { useModal } from '@/utils/modal'
import ActivitySearchModalView from '@/components/view/table/ActivitySearchModalView.vue'
import useConventionList from '@/utils/composables/conventionList'
import { getDB } from '@/utils/database'
import { EventActivity } from '@/models/EventActivity'
import EventDay from '@/models/EventDay'

const { selected: selectedEvent, onLoaded, fetch } = useConventionList()

const {
  attending,
  attendingActivities: userSchedule,
  fetchUserSchedule,
} = useAttending()
const modalHandler = useModal()

const sidebarTimeline = ref(null)
const days = ref<EventDay[]>([])
const activities = ref<EventActivity[]>([])

const schedule = ref(null)

const sidebarPreference = ref(false)
const sidebarAnimationDirection = ref('before')
const sidebarFullyClosed = ref(true)
const showSidebarDaySelector = ref(false)
const sidebarSize = ref('w-1/3')
const calendarSize = ref('w-full')
const animateCalendarSize = ref(false) // if we are opening/expanding sidebar, no need to animate calendar size. if we are closing/shrinking sidebar, we need to animate calendar size

onMounted(() => {
  if (openActivityId !== null) {
    // if there is openActivityId, find activity and open modal
    const activityToOpen = filteredActivities.value.find(
      (a) => a.id === openActivityId
    )
    if (activityToOpen) {
      getActivityDetails(activityToOpen)
    }
  }

  onLoaded().then(() => {
    if (!selectedEvent.value?.id) throw new Error('No event selected')
    loadData().then(() => {
      fetchUserSchedule(selectedEvent.value?.id)
      fetch(selectedEvent.value?.id)
    })
  })
})

const filteredUserSchedule = computed(() => {
  return userSchedule.value.filter((a) => {
    if (a.event_day_id === selectedDay.value?.id) {
      return true
    }
    if (a.day_id === selectedDay.value?.id) {
      return true
    }
  })
})

function uniqueEntries(tags) {
  return Object.values(
    tags
      .filter((t) => t)
      .reduce((acc, cur) => {
        if (!acc[cur.id]) {
          acc[cur.id] = cur
        }
        return acc
      }, {})
  ).sort((a, b) => a.name?.localeCompare(b.name))
}

const typeTags = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.typeTag))
})

const topicTags = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.topicTags).flat())
})

const highlightTags = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.highlightTags).flat())
})

const otherTags = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.otherTags).flat())
})

const venues = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.venue))
})

const spaces = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.space))
})

const stations = computed(() => {
  return uniqueEntries(activities.value.map((a) => a.station))
})

watch(showSidebarDaySelector, (value) => {
  if (value) {
    requestAnimationFrame(() => {
      document.addEventListener('click', closeSelector)
    })
  } else {
    document.removeEventListener('click', closeSelector)
  }
})

function closeSelector() {
  document.removeEventListener('click', closeSelector)
  showSidebarDaySelector.value = false
}

const scrollSync = ref(true)
let scrollPercent = ref(0)
let syncScrollThrottleSchedule = false
let syncScrollTimeoutSchedule = null
let syncScrollThrottleSidebar = false
let syncScrollTimeoutSidebar = null

function onScheduleCalendarScroll(scrollRatio) {
  scrollPercent.value = scrollRatio
  if (!scrollSync.value) return
  if (sidebarTimeline.value) {
    clearTimeout(syncScrollTimeoutSchedule)
    if (!syncScrollThrottleSchedule) {
      syncScrollThrottleSchedule = true
      syncScrollThrottleSidebar = true
      sidebarTimeline.value.scrollTop =
        scrollRatio *
        (sidebarTimeline.value.scrollHeight -
          sidebarTimeline.value.clientHeight)
    } else {
      syncScrollThrottleSchedule = false
    }
    syncScrollTimeoutSchedule = setTimeout(() => {
      syncScrollThrottleSchedule = false
    }, 100)
  }
}

function onSidebarTimelineScroll(e) {
  if (!scrollSync.value) return
  clearTimeout(syncScrollTimeoutSidebar)
  if (!syncScrollThrottleSidebar) {
    syncScrollThrottleSidebar = true
    syncScrollThrottleSchedule = true
    const ratio =
      e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)
    const el = document.getElementById('schedule-calendar-body')
    el.scrollLeft = ratio * (el.scrollWidth - el.clientWidth)
  } else {
    syncScrollThrottleSidebar = false
  }
  syncScrollTimeoutSidebar = setTimeout(() => {
    syncScrollThrottleSidebar = false
  }, 100)
}

function setSidebarSize(action) {
  switch (action) {
    case 'open':
      animateCalendarSize.value = false
      sidebarPreference.value = true
      sidebarAnimationDirection.value = 'after'
      requestAnimationFrame(() => {
        console.log('scrollPercent', scrollPercent.value)
        syncScrollThrottleSidebar = true
        sidebarTimeline.value.scrollTop =
          scrollPercent.value *
          (sidebarTimeline.value.scrollHeight -
            sidebarTimeline.value.clientHeight)
      })
      break
    case 'close':
      animateCalendarSize.value = true
      sidebarPreference.value = false
      sidebarAnimationDirection.value = 'before'

      break
    case 'w-1/3':
      animateCalendarSize.value = false
      // if coming from bigger size, then we change the size of content first
      if (sidebarSize.value === 'w-2/3') {
        animateCalendarSize.value = true
        sidebarAnimationDirection.value = 'before'
      }
      sidebarSize.value = 'w-1/3'
      break
    case 'w-2/3':
      animateCalendarSize.value = false
      if (sidebarSize.value === 'w-1/3') {
        sidebarAnimationDirection.value = 'after'
      }
      sidebarSize.value = 'w-2/3'
  }
}

function updateCalendarSize(time) {
  // time is 'before' or 'after'

  // if time is 'before', then either we are opening the schedule or we are expanding it
  if (time === sidebarAnimationDirection.value) {
    if (sidebarPreference.value) {
      calendarSize.value = sidebarSize.value === 'w-1/3' ? 'w-2/3' : 'w-1/3'
      sidebarFullyClosed.value = false
    } else {
      calendarSize.value = 'w-full'
    }
  }

  if (time === 'after' && !sidebarPreference.value) {
    sidebarFullyClosed.value = true
  }
}

const pageLoading = ref(true)
const scheduleError = ref(false)

const openActivityId = null

const openActivity = ref<EventActivity | null>(null)
const filters = ref({ date: [schedule.value?.days[0].date] })
const searchOpen = ref(false)
const searchQuery = ref('')

function selectCurrentDay() {
  let selected = false
  days.value
    .slice()
    .reverse()
    .forEach((day) => {
      if (
        DateTime.fromISO(day.date, { setZone: true }) < DateTime.now() &&
        !selected
      ) {
        selected = true
        filters.value = { date: [day.id] }
      }
    })
  if (!selected) {
    filters.value = { date: [selectedEvent.value?.days?.[0]?.id] }
  }
}

/**
 * Decodes the time of an activity
 * @throws Error if activity ends before it starts
 * @param activity
 * @param date
 * @returns {*}
 */
function decodeActivityTime(activity, date) {
  const start = decodeTime(
    +activity.starts_at,
    DateTime.fromISO(date, { setZone: true })
  )
  const end = decodeTime(
    +activity.ends_at,
    DateTime.fromISO(date, { setZone: true })
  )
  if (end < start) {
    throw new Error(`Activity ${activity.id} ends before it starts`)
  }
  activity.starts_at = start.toISO()
  activity.ends_at = end.toISO()
  return activity
}

function decodeTags(ids, tags) {
  if (Array.isArray(ids)) {
    return ids.map((id) => decodeTags(id, tags)).filter((t) => t)
  } else {
    // if the id is not set, return null
    const id = ids?.id ?? ids
    if (!id) {
      return null
    } else if (tags[id]) {
      return {
        id: id,
        ...tags[id],
      }
    } else {
      throw new Error(`Tag ${id} not found in config`)
    }
  }
}

function decodeActivityTags(
  activity,
  typeTags,
  topicTags,
  highlightTags,
  otherTags
) {
  activity.typeTag = decodeTags(activity.typeTag, typeTags)
  activity.topicTags = decodeTags(activity.topicTags, topicTags)
  activity.highlightTags = decodeTags(activity.highlightTags, highlightTags)
  activity.otherTags = decodeTags(activity.otherTags, otherTags)

  return activity
}

function decodeActivityLocation(activity, venues, spaces, stations) {
  if (activity.station) {
    const id =
      typeof activity.station === 'object'
        ? activity.station.id
        : activity.station
    activity.locationType = 'station'
    activity.station = { id: id, ...stations[id] }
    activity.space = {
      id: activity.station.space,
      ...spaces[activity.station.space],
    }
    activity.venue = {
      id: activity.space.venue,
      ...venues[activity.space.venue],
    }
  } else if (activity.space) {
    const id =
      typeof activity.space === 'object' ? activity.space.id : activity.space
    activity.locationType = 'space'
    activity.space = { id: id, ...spaces[id] }
    activity.venue = {
      id: activity.space.venue,
      ...venues[activity.space.venue],
    }
  } else if (activity.venue) {
    const id =
      typeof activity.venue === 'object' ? activity.venue.id : activity.venue
    activity.locationType = 'venue'
    activity.venue = { id: id, ...venues[id] }
  }
  return activity
}

async function loadData(force = false) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    pageLoading.value = true

    const key = `${selectedEvent.value?.slug}`
    // schedules info is stored in local storage
    let schedules = JSON.parse(localStorage.getItem('schedules') ?? '{}')
    // if there is no cached schedule, create it
    if (!schedules[key]) {
      schedules[key] = {
        version: null,
        timestamp: null,
        checksum: null,
      }
    }

    console.log(key)

    const db = await getDB<EventActivity>('schedule', key)
    const checksum = await db.count(key)

    // if force is true or version or timestamp is null, then force reload
    if (
      force ||
      !schedules[key].version ||
      !schedules[key].timestamp ||
      !schedules[key].checksum ||
      checksum !== schedules[key].checksum
    ) {
      console.info('Forcing reload of schedule')
      // force reload schedule
      // clear idb cache
      console.info('Cleared idb cache')
      await db.clear(key)

      // reset version and timestamp
      schedules[key].version = null
      schedules[key].timestamp = null

      console.info('Loading full schedule from server')
      try {
        const res = await axios.get(
          `/api/conventions/${selectedEvent.value?.id}/schedule`
        )
        resolve(true)
        console.info('Loaded full schedule from server')
        const tx = db.transaction(key, 'readwrite')
        activities.value = []
        days.value = []
        res.data.days.forEach((day) => {
          day.activities.forEach((activity) => {
            try {
              activity.day_id = day.id
              const decoded = decodeActivityLocation(
                decodeActivityTags(
                  decodeActivityTime(activity, day.date),
                  res.data.typeTags,
                  res.data.topicTags,
                  res.data.highlightTags,
                  res.data.otherTags
                ),
                res.data.venues,
                res.data.spaces,
                res.data.stations
              )
              db.put(key, decoded, activity.id)
              activities.value.push(decoded)
            } catch (e) {
              console.error(e)
            }
          })

          days.value.push({
            id: day.id,
            date: day.date,
            caption: day.caption,
          })
        })
        pageLoading.value = false
        selectCurrentDay()
        await tx.done
        console.info('Saved schedule to idb')
        schedules[key].version = res.data.version
        schedules[key].timestamp = res.data.timestamp
        schedules[key].days = res.data.days.map((d) => {
          return {
            id: d.id,
            date: d.date,
            caption: d.caption,
          }
        })
        schedules[key].checksum = await db.count(key)
        localStorage.setItem('schedules', JSON.stringify(schedules))
        console.info('Saved config to local storage')
      } catch (e) {
        console.error(e)
        scheduleError.value = true
      }
    } else {
      resolve(true)
      console.info('Schedule is loaded from cache')
      console.info(
        'Last updated',
        DateTime.fromSeconds(schedules[key].timestamp).toRelative()
      )
      axios
        .get(`/api/conventions/${selectedEvent.value?.id}/schedule`, {
          params: {
            after: schedules[key].timestamp,
          },
        })
        .then(async (res) => {
          console.info('Loaded schedule updates from server')

          if (schedules[key].version !== res.data.version) {
            console.warn('Schedule version has changed')
            await loadData(true)
            return
          }

          if (res.data.deleted?.length > 0) {
            res.data.deleted.forEach((id) => {
              db.delete(key, id)
            })
            console.info(`Removed ${res.data.deleted.length} activities`)
          }

          if (res.data.days?.length > 0) {
            let count = 0
            res.data.days.forEach((day) => {
              if (!schedules[key].days.find((d) => d.id === day.id)) {
                schedules[key].days.push({
                  id: day.id,
                  date: day.date,
                  caption: day.caption,
                })
              }
              day.activities.forEach((activity) => {
                try {
                  activity.day_id = day.id
                  db.put(
                    key,
                    decodeActivityLocation(
                      decodeActivityTags(
                        decodeActivityTime(activity, day.date),
                        res.data.typeTags,
                        res.data.topicTags,
                        res.data.highlightTags,
                        res.data.otherTags
                      ),
                      res.data.venues,
                      res.data.spaces,
                      res.data.stations
                    ),
                    activity.id
                  )
                  count++
                } catch (e) {
                  console.error(e)
                }
              })
            })
            console.info(`Added ${count} activities`)
          }

          schedules[key].version = res.data.version
          schedules[key].timestamp = res.data.timestamp
          schedules[key].checksum = await db.count(key)
          localStorage.setItem('schedules', JSON.stringify(schedules))

          activities.value = (await db.getAll(key)).map(
            (activity: EventActivity) => {
              const a = decodeActivityLocation(
                decodeActivityTags(
                  activity,
                  res.data.typeTags,
                  res.data.topicTags,
                  res.data.highlightTags,
                  res.data.otherTags
                ),
                res.data.venues,
                res.data.spaces,
                res.data.stations
              )
              db.put(key, a, a.id)
              return a
            }
          )
          pageLoading.value = false
        })
      // load schedule from idb
      console.info('Loading schedule from idb')
      activities.value = await db.getAll(key)
      console.info('Loaded schedule from idb')
      days.value = schedules[key].days
        .map((d) => {
          return {
            id: d.id,
            date: d.date,
            caption: d.caption,
          }
        })
        .sort((a, b) => {
          return DateTime.fromISO(b.date) - DateTime.fromISO(a.date)
        })
      pageLoading.value = false
      selectCurrentDay()
    }
  })
}

const searchResults = computed(() => {
  let searchKeywords = searchQuery.value.toLowerCase().split(' ')

  const filterTags = (tags) => {
    return tags
      .filter((tag) => {
        return searchKeywords.some((k) => tag.name.toLowerCase().includes(k))
      })
      .map((tag) => {
        return tag.id
      })
  }

  let availableTypes = filterTags(typeTags.value)
  let availableTopics = filterTags(topicTags.value)

  return activities.value
    .filter((a) => {
      return (
        searchKeywords.some((k) => a.name?.toLowerCase().includes(k)) ||
        searchKeywords.some((k) =>
          a.short_description?.toLowerCase().includes(k)
        ) ||
        availableTypes.includes(a.typeTag?.id) ||
        a.topicTags.map((t) => t.id).some((t) => availableTopics.includes(t))
      )
    })
    .sort((a, b) => {
      let aScore = 0
      let bScore = 0
      searchKeywords.forEach((k) => {
        if (a.name?.toLowerCase().includes(k)) {
          aScore += 3
        }
        if (b.name?.toLowerCase().includes(k)) {
          bScore += 3
        }
        if (a.short_description?.toLowerCase().includes(k)) {
          aScore += 2
        }
        if (b.short_description?.toLowerCase().includes(k)) {
          bScore += 2
        }
        if (availableTypes?.includes(a.typeTag)) {
          aScore += 1
        }
        if (availableTypes?.includes(b.typeTag)) {
          bScore += 1
        }
        if (a.topicTags?.some((t) => availableTopics.includes(t))) {
          aScore += 1
        }
        if (b.topicTags?.some((t) => availableTopics.includes(t))) {
          bScore += 1
        }
      })
      return bScore - aScore
    })
    .slice(0, 20)
})

const locations = computed(() => {
  return venues.value
    .map((k) => {
      return {
        value: 'venue-' + k.id,
        label: k.name,
      }
    })
    .concat(
      spaces.value.map((k) => {
        return {
          value: 'spaces-' + k.id,
          label: k.name,
        }
      })
    )
    .concat(
      stations.value.map((k) => {
        return {
          value: 'station-' + k.id,
          label: k.name,
        }
      })
    )
  // .sort((a, b) => a.label.localeCompare(b.label))
})

/**
 * Resets all filters to null except for date
 */
function resetFilters() {
  filters.value = {
    date: filters.value['date'],
  }
}

/**
 * Categories object for QueryFilter component
 * @type {ComputedRef<unknown>}
 */
const filterCategories = computed(() => {
  if (days.value == null || activities.value === null) return {}
  return {
    date: {
      options: days.value
        .sort((a, b) => {
          return DateTime.fromISO(a.date) - DateTime.fromISO(b.date)
        })
        .map((d) => {
          return {
            value: d.id,
            label: d.caption,
          }
        }),
    },
    location: {
      title: 'Locations',
      options: locations.value,
      allow_multiple: true,
    },
    type_tag: {
      title: 'Activity type',
      options: typeTags.value.map((t) => {
        return {
          value: t.id,
          label: t.name,
        }
      }),
      allow_multiple: true,
    },
    topic_tag: {
      title: 'Topics',
      options: topicTags.value.map((t) => {
        return {
          value: t.id,
          label: t.name,
        }
      }),
      allow_multiple: true,
    },
    highlight_tag: {
      title: 'Highlights',
      options: highlightTags.value.map((t) => {
        return {
          value: t.id,
          label: t.name,
        }
      }),
      allow_multiple: true,
    },
    otherTags: {
      title: 'Other Tags',
      options: otherTags.value.map((t) => {
        return {
          value: t.id,
          label: t.name,
        }
      }),
      allow_multiple: true,
    },
    // 'age': {
    //     title: 'Age',
    //     type: 'checkbox',
    //     options: [
    //         {value: 'any', label: 'All'},
    //         {value: '0+', label: 'For children'},
    //         {value: '16+', label: '16+'},
    //         {value: '21+', label: '21+'},
    //     ],
    // },
    // 'cost': {
    //     title: 'Entrance',
    //     type: 'checkbox',
    //     options: [
    //         {value: 'paid', label: 'Paid'},
    //         {value: 'included', label: 'Included with Badge'}
    //     ],
    // },
    // 'verification': {
    //     title: 'Verification',
    //     type: 'checkbox',
    //     options: [
    //         {value: 'verified', label: 'Official'},
    //         {value: 'unverified', label: 'Unofficial'}
    //     ],
    // },
  }
})

const selectedDay = computed(() => {
  if (pageLoading.value) return null
  if (!filters.value['date']) return null
  return days.value?.find((d) => d.id === filters.value['date'][0])
})

/**
 * Filters activities based on the current filters
 * @type {ComputedRef<unknown>}
 */
const filteredActivities = computed(() => {
  if (!filters.value['date']) {
    return []
  }
  return activities.value
    .filter((a) => {
      if (selectedDay.value?.id !== a.day_id) return false
      if (filters.value['location']?.length) {
        if (
          ![
            'venue-' + a.venue?.id,
            'spaces-' + a.space?.id,
            'station-' + a.station?.id,
          ].some((v) => filters.value['location'].includes(v))
        ) {
          return false
        }
      }

      if (filters.value['type_tag']?.length) {
        if (!filters.value['type_tag'].includes(a.typeTag?.id)) {
          return false
        }
      }

      if (filters.value['topic_tag']?.length) {
        if (
          !filters.value['topic_tag'].some((t) =>
            a.topicTags.map((t) => t.id).includes(+t)
          )
        ) {
          return false
        }
      }

      if (filters.value['highlight_tag']?.length) {
        if (
          !filters.value['highlight_tag'].some((t) =>
            a.highlightTags.map((t) => t.id).includes(+t)
          )
        ) {
          return false
        }
      }

      if (filters.value['otherTags']?.length) {
        if (
          !filters.value['otherTags'].some((t) =>
            a.otherTags.map((t) => t.id).includes(+t)
          )
        ) {
          return false
        }
      }

      //
      // if (filters.value['age']?.length) {
      //   if (filters.value['age'][0] === '0+' && a.age >= 16) {
      //     return false
      //   }
      //   if (filters.value['age'][0] === '16+' && (a.age < 16 || a.age >= 21)) {
      //     return false
      //   }
      //   if (filters.value['age'][0] === '21+' && a.age < 21) {
      //     return false
      //   }
      // }

      return true
    })
    .map((a) => {
      return {
        ...a,
        starts_at: DateTime.fromISO(a.starts_at, { setZone: true }),
        ends_at: DateTime.fromISO(a.ends_at, { setZone: true }),
      }
    })
})

/**
 * Gets the minimum time of all activities
 * @type {ComputedRef<>}
 */
const minDateTime = computed(() => {
  if (filteredActivities.value.length === 0) return null
  let min = filteredActivities.value[0]?.starts_at ?? Number.MAX_SAFE_INTEGER
  filteredActivities.value.forEach((activity) => {
    if (activity.starts_at < min) {
      min = activity.starts_at
    }
  })
  return min.minus({ minutes: 15 })
})

/**
 * Gets the maximum time of all activities
 * @type {ComputedRef<>}
 */
const maxDateTime = computed(() => {
  if (filteredActivities.value.length === 0) return null
  let max = filteredActivities.value[0]?.ends_at ?? Number.MIN_SAFE_INTEGER
  filteredActivities.value.forEach((activity) => {
    if (activity.ends_at > max) {
      max = activity.ends_at
    }
  })
  return max
})

function getActivityDetails(activity: EventActivity) {
  openActivity.value = activity
  openActivity.value.type = activity.typeTag
  modalHandler.openType('activityDetail', {
    props: {
      activity: openActivity,
    },
  })

  axios
    .get(`/api/activities/${activity.id}`)
    .then((response) => {
      openActivity.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}

/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Make scrollbar transparent */
::-webkit-scrollbar {
  background-color: transparent;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(100%);
}
</style>
